import React from "react";

import "./index.css";
import Flag from "../../Assets/Images/Flag.svg";
import moment from "moment";

import { getLocalTime, getLocalOnlyDate } from "../../utils/LocalTimeFormat";

function RowBody({
    medicineList,
    isSelectedOption,
    onClickOrderIdNew,
    onInFiniteScroll,
    onClickAddCommentNew,
}) {
    const onClickOrderId = (item) => {
        onClickOrderIdNew(item);
        // console.log(item,"hii");
    };

    const onClickAddComment = (item) => {
        onClickAddCommentNew(item);
    };

    return (
        <div className="boxMain">
            {medicineList?.map((item, i) => {
                return (
                    <>
                        <div className="rowList" key={i}>
                            <div className="boxSub" style={{ width: 120 }}>
                                <div>
                                    <div className="flagView">
                                        {item?.redAllert === 1 && (
                                            <img
                                                src={Flag}
                                                alt={"Goto icon"}
                                                className="calenderImg"
                                            />
                                        )}
                                        
                                        <div className="boxSub" style={{ display:"block", gap:"10px"}}>
                                        <div
                                            className="patientId"
                                           
                                        >

                                            {item?.patientName}
                                        </div>
                                        
                                        <div
                                            className="patientId"
                                         
                                        >

                                            {item?.patientCode}
                                        </div>
                                        <div
                                            className="patientId"

                                        >

                                            {item?.mobile}
                                        </div>

                                            </div>




                                    </div>
                                 
                                </div>
                            </div>

                            <div className="boxSub">
                                <div className="patientId" >

                                    {item?.city}
                                </div>
                                <div className="patientId" >

                                    {item?.pincode}
                                </div>

                            </div>

                            <div className="boxSub">
                                <p className="patientId" >

                                    {moment(item?.givenDate).format("DD/MM/YY")}
                                </p>
                            </div>

                            <div className="boxSub">
                                <p className="patientId" >

                                    {item?.doctorName}
                                </p>

                            </div>

                            <div className="boxSub">
                                <p className="patientId" >

                                    {item?.translateStatus}
                                </p>
                            </div>
                        </div>
                    </>
                );
            })}
        </div>
    );
}

export default RowBody;
