import React from "react";
import { useState, useEffect } from "react";
import axios from "axios";
import moment from "moment";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Dropdown } from "primereact/dropdown";
// import { Icon } from 'primereact/icon';
// import FullCalendar from "@fullcalendar/react";
import { Calendar } from "primereact/calendar";
import "primereact/resources/primereact.min.css";
import "primeicons/primeicons.css";
// import 'primeflex/primeflex.css';
import { useNavigate } from "react-router-dom";
import { Button } from "primereact/button";
import { Tag } from "primereact/tag";
import { TabView, TabPanel } from "primereact/tabview";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { Dialog } from "primereact/dialog";
import businessEventData from "../../CarePlan/index";
import http from "../../../Redux/services/http-common"
import PopupDash from "../PopupDash";
function MedicareDash() {
const [businessEventData, setBusinessEventData] = useState([]);
const [patientdetailsdash, setPatientdetails] = useState("");
  const [careplanscheduledash, setCareplanSchedule] = useState();
  const [labcareplanscheduledash, setLabCareplanSchedule] = useState();
  const [nurseappointment, setnurseappointment] = useState();
  const [sosdetails, setSosdetails] = useState();
  const [kpidash, setKpidash] = useState();
  const [filters, setFilters] = useState({ status: null });
  const [selectedStatus, setSelectedStatus] = useState(null);
  const [assesment, setPreassment] = useState();
  const [changeforrequest, setChangeforrequest] = useState();

  const [showDropdown, setShowDropdown] = useState(false);
  const [calendarVisible, setCalendarVisible] = useState(true);
  const [activeIndex, setActiveIndex] = useState(0);
  const [selectedNodeKey, setSelectedNodeKey] = useState(null);
  const [selectedDash, setSelectedDash] = useState("Today");
  // const [selectedDate1, setSelectedDate1] = useState(null);
  const [selectedDate1, setSelectedDate1] = useState(new Date());
  const [inputDate, setInputDate] = useState("");
  // const [selectYear, setSelectYear] = useState(false);
  const [selectYear, setSelectYear] = useState(new Date());
  const [selecteclinic, setSelecteclinic] = useState(null);
  const [alleclinic, setAllclinic] = useState();
  const [kpidashboard, setKpidashboard] = useState();
  const [appointmentcount, setAppointmentCount] = useState();
  const [dateupcomingfilter, setDateupcomingfilter] = useState();

  const [patientCount, setGetpatientCount] = useState();
  const [revenuecount, setRevenuecount] = useState();
  const [startDate, setStartDate] = useState(new Date(new Date().getTime() - 24 * 60 * 60 * 1000));
  const [endDate, setEndDate] = useState(new Date());
  const [openDialog, setDialog] = useState(false);
  const [giverdetails, setGiverdetail] = useState("");
  const [selectdocList, setSelectdocList] = useState(null);
  const [doctortlistApi, setDoctortlistApi] = useState();
  const [doclist, setDocList] = useState("")
  const [seoid, setSeoid] = useState();
  const [selectlabList, setSelectlabList] = useState(null);
  const [selectedOptionassesment, setSelectedOptionassesment] = useState(null);
  const [lablistApi, setlablistApi] = useState();
  const [lablocationId, setlablocationId] = useState();
  const [searchName, setSearchName] = useState();
  const [careplancount, setCareplancount] = useState();
  const [nurselistApi, setnurselistApi] = useState();
  const [startDatenew, setStartDatenew] = useState(new Date());
  const [endDatenew, setEndDatenew]  = useState(new Date());
  const [serviceprovider, setServiceprovider] = useState();
  const formatstartDatenew = startDatenew ? moment(startDatenew).format("YYYY-MM-DD") : "";
  const formatendDatenew = endDatenew ? moment(endDatenew).format("YYYY-MM-DD") : "";
  useEffect(() => {

    http.get(`${process.env.REACT_APP_ELASTIC_BASEURL}labs?latitude=20.2960587&longitude=85.8245398&source=b2c`)
        .then((res) => {


            setlablistApi(res.data);

            let locationIdLab = res.data.map((obj) => {

                return (obj.locationId)

            })

            setlablocationId(locationIdLab)

            // let seoId = res.data.map((obj) => {

            //     return (obj.seoNameId)

            // })
            // setSeoid(seoId)

            console.log(res.data, "qwertymnbvcx");


        })
        .catch((err) => {

            console.log(err, "Something went wrong1");

        })


}, [])
  const dropdownOptionsLab = lablistApi?.map(item => ({
    label: item?.labName,
    value: item?.labName,
    // value: {
    //     id: item?.labName,


        // email: item?.email, 
        // code: item?.userId, 
        // number: item?.contactNo,


    //}
}));
  const onFilterLab = (event) => {
    if (event.query) {
        const filteredOptionsLab = dropdownOptionsLab.filter((option) =>
            option.label.toLowerCase().includes(event.query.toLowerCase())
        );
        setSelectlabList(filteredOptionsLab);
    } else {
        setSelectlabList(dropdownOptionsLab);
    }

};

  const handleStartDateChange = (date) => {
    setStartDate(date);
  };

  const handleEndDateChange = (date) => {
    setEndDate(date);
  };

  const handleOptionChange = (e) => {
    setSelectedDash(e.value);
  };

  const handleYearChange = () => {
    setSelectYear();
  };

  const navigate = useNavigate();

  const handleClick = () => {
    navigate("/careplansSchedule");
  };


  const onClickOrderIdMain = (e, id) => {
    setDialog(true);

    http
      .get(
        `${process.env.REACT_APP_BASEURL}medicare/patients/receiver?user_seq_id=${e}`
      )

      .then((res) => {
        console.log(res.data, "aaja");

        setPatientdetails(res.data);
        // let apidate = res.data?.planDetailsDTO?.map((obj) => {
        //   return obj.purchasedDate;
        // });
        // setTransactionMonth(apidate);
      })
      .catch((err) => {
        console.log(err, "Something went wrong1");
      });
  };

  const onClickAnotherApi = (e, id) => {
    setDialog(true);

    console.log(e, "item_data");

    http
      .get(
        `${process.env.REACT_APP_BASEURL}medicare/patients/giver?user_seq_id=${e}`
      )

      .then((res) => {
        console.log(res.data, "aaja");

        setGiverdetail(res.data);
        
      })
      .catch((err) => {
        console.log(err, "Something went wrong1");
      });
  };


 

 
  

 

 
  const formattedupcomingappdate = dateupcomingfilter
    ? moment(dateupcomingfilter).format("YYYY-MM-DD")
    : "";
  useEffect(() => {
    if (activeIndex === 0) {
      let requestBodynew = {};
      if (formattedupcomingappdate === "") {
        requestBodynew = {
        cheLocationId: selecteclinic?.loc,
        userId:selectdocList,
        fromDate: formatstartDatenew,
        toDate:formatendDatenew,
        serviceStatus:"2"
        };
      }
      else{
        requestBodynew = {
          cheLocationId: selecteclinic?.loc,
          whenAppointment: formattedupcomingappdate,
          userId:selectdocList,
          fromDate: formatstartDatenew,
          toDate:formatendDatenew,
          serviceStatus:"2"
        };
      } 
      http
        .post(
          `${process.env.REACT_APP_BASEURL}medicare/getDoctorAppointments`,
          requestBodynew
          // {
          //   cheLocationId: selecteclinic?.loc,
          //   whenAppointment: formattedupcomingappdate,
          //   userId:selectdocList
          // }
        )
        .then((res) => {
          console.log(res.data, "event");
          setCareplanSchedule(res.data);
        })
        .catch((err) => {
          console.log(err, "Something went wrong for the first API");
        });
    } 
  
  }, [activeIndex, selecteclinic, formattedupcomingappdate,selectdocList]);
console.log(activeIndex,"activeIndex")
  useEffect(() => {
    if (activeIndex === 1) {

      let requestBodynew = {};
      if (formattedupcomingappdate === "") {
        requestBodynew = {
          cheLocationId: selecteclinic?.loc,
          hospitalName:selectlabList,
          fromDate: formatstartDatenew,
          toDate:formatendDatenew,
          serviceStatus:"2"
        };
      }
      else{
        requestBodynew = {
          cheLocationId: selecteclinic?.loc,
          whenAppointment: formattedupcomingappdate,
          hospitalName:selectlabList,
          fromDate: formatstartDatenew,
          toDate:formatendDatenew,
          serviceStatus:"2"
        };
      } 
      http
        .post(`${process.env.REACT_APP_BASEURL}medicare/getLabAppointments`,
        requestBodynew
        //  {
        //   cheLocationId: selecteclinic?.loc,
        //   whenAppointment: formattedupcomingappdate,
        //   hospitalName:""
        // }
        )
        .then((res) => {
          console.log(res.data, "event");

          setLabCareplanSchedule(res.data);
        })
        .catch((err) => {
          console.log(err, "Something went wrong1");
        });
    }
  }, [activeIndex, selecteclinic, dateupcomingfilter,selectlabList]);

  useEffect(() => {
    if (activeIndex === 2) {

      let requestBodynew = {};
      if (formattedupcomingappdate === "") {
        requestBodynew = {
          cheLocationId: selecteclinic?.loc,
          userId:serviceprovider?.id,
          fromDate: formatstartDatenew,
          toDate:formatendDatenew,
          serviceStatus:"2"
        };
      }
      else{
        requestBodynew = {
          cheLocationId: selecteclinic?.loc,
          whenAppointment: formattedupcomingappdate,
          userId:serviceprovider?.id,
          fromDate: formatstartDatenew,
          toDate:formatendDatenew,
          serviceStatus:"2"
        };
      } 
      http
        .post(`${process.env.REACT_APP_BASEURL}medicare/getNurseAppointments`,
        requestBodynew
        )
        .then((res) => {
          console.log(res.data, "event");

          setnurseappointment(res.data);
        })
        .catch((err) => {
          console.log(err, "Something went wrong1");
        });
    }
  }, [activeIndex,serviceprovider,selecteclinic]);

console.log(nurseappointment,"nurseappointment")
  useEffect(() => {
    http
      .get(`${process.env.REACT_APP_BASEURL}dashboard/medicare/sosDetails`)
      .then((res) => {
        console.log(res.data, "event");

        setSosdetails(res.data);
      })
      .catch((err) => {
        console.log(err, "Something went wrong1");
      });
  }, []);

  useEffect(() => {
    http
      .get(`${process.env.REACT_APP_BASEURL}medicare/requestforChange`)
      .then((res) => {
        console.log(res.data, "event");

        setChangeforrequest(res.data);
      })
      .catch((err) => {
        console.log(err, "Something went wrong1");
      });
  }, []);

  useEffect(() => {
    let requestBody = {};
    
    http
      .post(
        `${process.env.REACT_APP_BASEURL}medicare/getPreassessmentForChildAndParent`,
        // requestBody
        {
        fromDate : moment().format("YYYY-MM-DD"),
        toDate: moment().format("YYYY-MM-DD")
        }
      )

      .then((res) => {
        console.log(res.data, "event");

        setPreassment(res.data);
      })
      .catch((err) => {
        console.log(err, "Something went wrong1");
      });
  }, []);

  const options = [
    { label: "All", value: "All" },
    { label: "In Progress", value: "In Progress" },
    { label: "Yet to Start", value: "Yet to Start" },
    { label: "Completed", value: "Completed" },
  ];

  const dashboardOptions = [
    { label: "Today", value: "Today" },
    { label: "Month", value: "Month" },
    { label: "Year", value: "Year" },
    { label: "Date", value: "Date" },
    { label: "All", value: "All" },
  ];

  const CustomPlaceholder = () => (
    <div>
      EClinic <i className="pi pi-filter-fill" style={{ marginLeft: "64px" }} />
    </div>
  );
  const CustomPlaceholder1 = () => (
    <div>
      Name <i className="pi pi-filter-fill" style={{ marginLeft: "64px" }} />
    </div>
  );

  const CustomPlaceholder2 = () => (
    <div>
      Severity{" "}
      <i className="pi pi-filter-fill" style={{ marginLeft: "64px" }} />
    </div>
  );
  const CustomPlaceholder3 = () => (
    <div>
      Search by Status <i className="pi pi-filter-fill" style={{ marginLeft: "8px" }} />
    </div>
  );

  const yourDropdownOptions = ["Yet to Start", "In Progress", "Completed"];

  const statusBodyTemplate = (rowData) => {
    let statusLabel, statusSeverity;
    switch (rowData.status) {
      case 1:
        statusLabel = "Yet to Start";
        statusSeverity = "warning";
        break;
      case 2:
        statusLabel = "Appointment Booked";
        statusSeverity = "success";
        break;
      case 3:
        statusLabel = "Completed";
        statusSeverity = "danger";
        break;
      case 4:
        statusLabel = "Request For Update";
        statusSeverity = "warning";
        break;
      default:
        statusLabel = "";
        statusSeverity = "";
        break;
    }
    return <Tag value={statusLabel} severity={statusSeverity}></Tag>;
  };
  const nursestatusBodyTemplate = (rowData) => {
    let statusLabel, statusSeverity;
    switch (rowData.status) {
      case 1:
        statusLabel = "Yet to Start";
        statusSeverity = "warning";
        break;
      case 2:
        statusLabel = "Appointment Booked";
        statusSeverity = "success";
        break;
      case 3:
        statusLabel = "Completed";
        statusSeverity = "danger";
        break;
      case 4:
        statusLabel = "Request For Update";
        statusSeverity = "warning";
        break;
      default:
        statusLabel = "";
        statusSeverity = "";
        break;
    }
    return <Tag value={statusLabel} severity={statusSeverity}></Tag>;
  };

  const statusBodyTemplateType = (rowData) => {
    let statusLabel, statusSeverity;
    switch (rowData.consultationsType) {
      case "H":
        statusLabel = "Home Visit";
        statusSeverity = "warning";
        break;
      case "V":
        statusLabel = "Video";
        statusSeverity = "warning";
        break;
      case "E":
        statusLabel = "Eclinic";
        statusSeverity = "warning";
        break;
      case "P":
        statusLabel = "Phone";
        statusSeverity = "warning";
        break;
      default:
        statusLabel = "";
        statusSeverity = "";
        break;
    }
    return <Tag value={statusLabel} severity={statusSeverity}></Tag>;
  };

  const nursestatusBodyTemplateType = (rowData) => {
    let statusLabel, statusSeverity;
    switch (rowData.consultationsType) {
      case "H":
        statusLabel = "Home Visit";
        statusSeverity = "warning";
        break;
      case "V":
        statusLabel = "Video";
        statusSeverity = "warning";
        break;
      case "E":
        statusLabel = "Eclinic";
        statusSeverity = "warning";
        break;
      case "P":
        statusLabel = "Phone";
        statusSeverity = "warning";
        break;
      default:
        statusLabel = "";
        statusSeverity = "";
        break;
    }
    return <Tag value={statusLabel} severity={statusSeverity}></Tag>;
  };
  useEffect(() => {
    http
      .post(
        `${process.env.REACT_APP_MEDICARE_BASEURL}location/getAllEclinics`,
        {}
      )
      .then((res) => {
        setAllclinic(res.data.succesObject);

        console.log(res.data.succesObject, "alleclinics");
      })
      .catch((err) => {
        console.log(err, "Something went wrong1");
      });
  }, []);

  const dropdownEclinic = alleclinic?.map((item) => ({
    label: item?.hospitalCode,
    value: {
      name: item?.hospitalCode,
      loc: item?.locationCode,
    },
  }));

  const onFilter = (event) => {
    if (event.query) {
      const filteredOptions = dropdownEclinic.filter((option) =>
        option.label.toLowerCase().includes(event.query.toLowerCase())
      );
      setSelecteclinic(filteredOptions);
    } else {
      setSelecteclinic(dropdownEclinic);
    }
  };
  const dateDash = moment().format('YYYY-MM-DD');;
  

  const dateDashformat = moment(dateDash).format("DD-MM-YYYY");
 
  

  const formattedDate = dateDash ? moment(dateDash).format("YYYY-MM-DD") : "";

  const formatstartDate = startDate ? moment(startDate).format("YYYY-MM-DD") : "";
  const formatendDate = endDate ? moment(endDate).format("YYYY-MM-DD") : "";

 
  const formattedMonth = selectedDate1
    ? moment(selectedDate1).format("MM")
    : "";
  const formattedMonthYear = selectedDate1
    ? moment(selectedDate1).format("YYYY")
    : "";

  const formattedYear = selectYear ? moment(selectYear).format("YYYY") : "";

  const datesearch = formattedDate === "" ? "" : formattedDate;
  const monthSearch = formattedMonth === "" ? "" : formattedMonth;
  const yearSearch = formattedYear === "" ? moment().format("YYYY") : formattedYear;

  console.log(patientdetailsdash, "dateqwertyyu");

  const handleClickSignup = () => {
    
    navigate("/bookCarePlan", {
      state: {
        selectedDash: selectedDash,
        datesearch: datesearch,
        monthSearch: monthSearch,
        yearSearch: yearSearch,
        formattedMonthYear: formattedMonthYear,
        formatstartDate:formatstartDate,
        formatendDate:formatendDate,
      },
    });
  };

 

  const handleClickCarereceiver = () => {
    
    navigate("/bookCarePlan", {
      state: {
        selectedDash1: selectedDash,
        datesearch1: datesearch,
        monthSearch1: monthSearch,
        yearSearch1: yearSearch,
        formattedMonthYear1: formattedMonthYear,
        formatstartDate1:formatstartDate,
        formatendDate1:formatendDate,
      },
    });
  };


  useEffect(() => {
    let requestBody = {};

    if (selectedDash === "Today") {
      requestBody = {
        type: "T",
        date: datesearch,
      };
    } else if (selectedDash === "Month") {
      requestBody = {
        type: "M",
        month: monthSearch,
        year: formattedMonthYear,
      };
    } else if (selectedDash === "Year") {
      requestBody = {
        type: "Y",
        year: yearSearch,
      };
    } else if (selectedDash === "Date") {
      requestBody = {
        type: "D",
        fromDate: formatstartDate,
        toDate:formatendDate
      };
    } else if (selectedDash === "All") {
      requestBody = {};
    }

    http
      .post(
        `${process.env.REACT_APP_BASEURL}dashboard/medicare/appointmentCount`,
        requestBody
      )
      .then((res) => {
        console.log(res.data, "appointment");
        setAppointmentCount(res.data);
      })
      .catch((err) => {
        console.log(err, "Something went wrong1");
      });
  }, [
    selectedDash === "Today",
    selectedDash ,
    monthSearch,
    yearSearch,
    selectedDash === "All",
    formatstartDate,formatendDate
  ]);

  useEffect(() => {
    let requestBody = {};

    if (selectedDash === "Today") {
      requestBody = {
        type: "T",
        date: datesearch,
      };
    } else if (selectedDash === "Month") {
      requestBody = {
        type: "M",
        month: monthSearch,
        year: formattedMonthYear,
      };
    } else if (selectedDash === "Year") {
      requestBody = {
        type: "Y",
        year: yearSearch,
      };
    } else if (selectedDash === "Date") {
      requestBody = {
        type: "D",
        fromDate: formatstartDate,
        toDate:formatendDate
      };
    } else if (selectedDash === "All") {
      requestBody = {};
    }

    http
      .post(
        `${process.env.REACT_APP_BASEURL}dashboard/medicare/dashboardCount`,
        requestBody
      )
      .then((res) => {
        console.log(res.data, "kpi");
        setKpidashboard(res.data);
      })
      .catch((err) => {
        console.log(err, "Something went wrong1");
      });
  }, [
    selectedDash === "Today",
    selectedDash ,
    monthSearch,
    yearSearch,
    selectedDash === "All",
    formatstartDate,formatendDate
  ]);

  

  // useEffect(() => {
  //   let requestBody = {};

  //   if (selectedDash === "Today") {
  //     requestBody = {
  //       type: "T",
  //       date: datesearch,
  //     };
  //   } else if (selectedDash === "Month") {
  //     requestBody = {
  //       type: "M",
  //       month: monthSearch,
  //       year: formattedMonthYear,
  //     };
  //   } else if (selectedDash === "Year") {
  //     requestBody = {
  //       type: "Y",
  //       year: yearSearch,
  //     };
  //   }
  //   else if (selectedDash === "Date") {
  //     requestBody = {
  //       type: "D",
  //       fromDate: formatstartDate,
  //       toDate:formatendDate
  //     }
  //   }
  //    else if (selectedDash === "This Week") {
  //     requestBody = {
  //       type: "W",
  //     };
  //   } else if (selectedDash === "All") {
  //     requestBody = {};
  //   }

  //   http
  //     .post(
  //       `${process.env.REACT_APP_BASEURL}dashboard/medicare/patientCount`,
  //       requestBody
  //     )
  //     .then((res) => {
  //       setGetpatientCount(res.data);
  //     })
  //     .catch((err) => {
  //       console.log(err, "Something went wrong1");
  //     });
  // }, [
  //   selectedDash === "Today",
  //   monthSearch,
  //   yearSearch,
  //   selectedDash === "All",
  //   formatstartDate,formatendDate
  // ]);

  // useEffect(() => {
  //   let requestBody = {};

  //   if (selectedDash === "Today") {
  //     requestBody = {
  //       type: "T",
  //       date: datesearch,
  //     };
  //   } else if (selectedDash === "Month") {
  //     requestBody = {
  //       type: "M",
  //       month: monthSearch,
  //       year: formattedMonthYear,
  //     };
  //   } else if (selectedDash === "Year") {
  //     requestBody = {
  //       type: "Y",
  //       year: yearSearch,
  //     };
  //   }
  //   else if (selectedDash === "Date") {
  //     requestBody = {
  //       type: "D",
  //       fromDate: formatstartDate,
  //       toDate:formatendDate
  //     }
  //    } else if (selectedDash === "This Week") {
  //     requestBody = {
  //       type: "W",
  //     };
  //   } else if (selectedDash === "All") {
  //     requestBody = {};
  //   }

  //   http
  //     .post(
  //       `${process.env.REACT_APP_BASEURL}dashboard/medicare/revenue`,
  //       requestBody
  //     )
  //     .then((res) => {
  //       setRevenuecount(res.data);
  //     })
  //     .catch((err) => {
  //       console.log(err, "Something went wrong1");
  //     });
  // }, [
  //   selectedDash === "Today",
  //   monthSearch,
  //   yearSearch,
  //   selectedDash === "All",
  //   formatstartDate,formatendDate
  // ]);

    useEffect(() => {
        let requestBody = {}; 
 
        // if (selectedDash === "Today") {
        //   requestBody = {
        //     type :"T",
        //     date: datesearch,
        //   };
        // } else
        //  if (selectedDash === "Month") {
        //   requestBody = {
        //     type :"M",
        //     month: monthSearch,
        //     year :formattedMonthYear
        //   };
        // } 
        // else if (selectedDash === "Year") {
        //   requestBody = {
        //     type :"Y",
        //     year: yearSearch,
        //   };
          
        // }
        // else if (selectedDash === "All") {
        //   requestBody = {
           
        //   };
          
        // }
        http.post(`${process.env.REACT_APP_BASEURL}medicare/patients`,requestBody
        )

            .then((res) => {
                console.log(res.data, "1234")
                setBusinessEventData(res.data);
            })


            .catch((err) => {

                console.log(err, "Something went wrong");

            })

    }, [])
    useEffect(() => {

      http.get(`${process.env.REACT_APP_ELASTIC_BASEURL}doctors?freeTextSearch=&pageNo=1&pageSize=100`)
          .then((res) => {


              setDoctortlistApi(res.data);

              let docList = res.data.map((obj) => {

                  return (obj.userName)

              })
              // let planids = res.data.map((obj) => {

              //   return (obj.planId)

              // })
              setDocList(docList)

              let seoId = res.data.map((obj) => {

                  return (obj.seoNameId)

              })
              setSeoid(seoId)

              console.log(res.data, "qwertymnbvcx");


          })
          .catch((err) => {

              console.log(err, "Something went wrong1");

          })


  }, [])
    const dropdownOptions = doctortlistApi?.map(item => ({
      label: item?.userName,
      value: item?.userId,
      // value: {
      //     id: item?.seoNameId,
      //     name: item?.userName,

      //     email: item?.email,
      //     code: item?.userId,
      //     number: item?.contactNo,


      // }
  }));
console.log(selectlabList,"selectdocList")

const handleSearchPlanpurchased = () => {
  // e.prveventDefult();
  // alert("hii");
  console.log(searchName, "name")

  http.get(`${process.env.REACT_APP_BASEURL}medicare/searchPlanPurchasedByNameAndMobile?receiverName=${searchName}`)

    .then((res) => {
      console.log(res.data, "search")
      // setPlanapi(res.data);

    })


    .catch((err) => {

      console.log(err, "Something went wrong");

    })
};

const handleSearchChange = (e) => {



    setSearchName(e.target.value);
   


}

useEffect(() => {
  let requestBody = {};

  if (selectedDash === "Today") {
    requestBody = {
      type: "T",
      date: datesearch,
    };
  } else if (selectedDash === "Month") {
    requestBody = {
      type: "M",
      month: monthSearch,
      year: formattedMonthYear,
    };
  } else if (selectedDash === "Year") {
    requestBody = {
      type: "Y",
      year: yearSearch,
    };
  } else if (selectedDash === "Date") {
    requestBody = {
      type: "D",
      fromDate: formatstartDate,
      toDate:formatendDate
    };
  } else if (selectedDash === "All") {
    requestBody = {};
  }

  http
    .post(
      `${process.env.REACT_APP_BASEURL}dashboard/medicare/carePlansCount`,
      requestBody
    )
    .then((res) => {
      console.log(res.data , "kpkpii");
      setCareplancount(res.data);
    })
    .catch((err) => {
      console.log(err, "Something went wrong2");
    });
}, [
  selectedDash === "Today",
  selectedDash ,
  monthSearch,
  yearSearch,
  selectedDash === "All",
  formatstartDate,formatendDate
]);
console.log(careplancount,"planCount")



useEffect(() => {
  http
    .get(
      `${process.env.REACT_APP_BASEURL}user/list/filter?roleCode=AH&status=1`
    )
    .then((res) => {
      setnurselistApi(res.data);
    })
    .catch((err) => {
      console.log(err, "Something went wrong1");
    });
}, []);

const dropdownOptionsNurse = nurselistApi?.map((item) => ({
  label: item?.userName,
  value: {
    id: item?.code,
    name: item?.userName,

    email: item?.email,
    code: item?.userId,
    number: item?.mobile,
  },
}));


const inComplete = ()=>{
  
 
  navigate("/careplanbusinessevent", {
    state: {
      selectedDashBe: selectedDash,
      datesearchBe: datesearch,
      monthSearchBe: monthSearch,
      yearSearchBe: yearSearch,
      formattedMonthYearBe: formattedMonthYear,
      formatstartDateBe:formatstartDate,
      formatendDateBe:formatendDate,
    },
  });
}



  return (
    <>
      <h2>Totalcare Dashboard</h2>

      <div>
        <div style={{ display: "flex", marginLeft: "20px" }}>
          <div className="" style={{ marginTop: "10px", display: "flex" }}>
            <Dropdown
              value={selectedDash}
              options={dashboardOptions}
              onChange={handleOptionChange}
              placeholder="Select Date"
              className="dropdowndashcss"
            />
          </div>
          <div style={{ marginLeft: "20px" }}>
            {selectedDash === "Today" ? (
              <div className="month" style={{ color: " #6c757d" }}>
                {dateDashformat}
              </div>
            ) : selectedDash === "Month" ? (
              <DatePicker
                showIcon
                className="datepicker1"
                dateFormat="MM/yyyy"
                selected={selectedDate1}
                //  selected={selectedDate1}
                onChange={(date) => setSelectedDate1(date)}
                showMonthYearPicker
                customInput={
                  <div
                    style={{
                      position: "relative",
                      display: "flex",
                      flexDirection: "column",
                    }}
                  >
                    <div style={{ marginTop: "20px" }}>
                      <input
                        type="text"
                        className="formcontroldash"
                        style={{ color: "#6c757d" }}
                        value={
                          selectedDate1
                            ? moment(selectedDate1).format("MM/YYYY")
                            : "" // Placeholder text with default values
                        }
                        readOnly
                      />
                    </div>

                    <img
                      src="images/calander.svg"
                      alt="Calendar"
                      style={{
                        position: "absolute",
                        right: "21px",
                        top: "20px",
                        cursor: "pointer",
                      }}
                    />
                  </div>
                }
              ></DatePicker>
            ) : selectedDash === "Year" ? (
              <DatePicker
                showIcon
                className="datepicker1"
                dateFormat="yyyy"
                selected={selectYear}
                //  selected={selectedDate1}
                onChange={(date) => setSelectYear(date)}
                // onChange={(date) => setSelectYear(date.getFullYear())}
                showYearPicker
                customInput={
                  <div
                    style={{
                      position: "relative",
                      display: "flex",
                      flexDirection: "column",
                    }}
                  >
                    <div style={{ marginTop: "20px" }}>
                      <input
                        type="text"
                        className="formcontroldash"
                        style={{ color: "#6c757d" }}
                        value={
                          selectYear ? moment(selectYear).format("YYYY") : "" // Placeholder text with default values
                        }
                        readOnly
                      />
                    </div>

                    <img
                      src="images/calander.svg"
                      alt="Calendar"
                      style={{
                        position: "absolute",
                        right: "21px",
                        top: "20px",
                        cursor: "pointer",
                      }}
                    />
                  </div>
                }
              ></DatePicker>
            ) : selectedDash === "Date" ? (
              <div style={{ display: "flex" }}>
                <DatePicker
                  selected={startDate}
                  onChange={handleStartDateChange}
                  selectsStart
                  startDate={startDate}
                  endDate={endDate}
                  placeholderText="Start Date"
                  className="datepicker1"
                  customInput={
                    <div
                      style={{
                        position: "relative",
                        display: "flex",
                        flexDirection: "column",
                        marginRight:"20px"
                      }}
                    >
                      <div style={{ marginTop: "20px" }}>
                        <input
                          type="text"
                          className="formcontroldash"
                          style={{ color: "#6c757d" }}
                          value={
                            startDate
                              ? moment(startDate).format("DD-MM-YYYY")
                              : "Start Date" // Placeholder text with default values
                          }
                          readOnly
                        />
                      </div>

                      <img
                        src="images/calander.svg"
                        alt="Calendar"
                        style={{
                          position: "absolute",
                          right: "21px",
                          top: "20px",
                          cursor: "pointer",
                        }}
                      />
                    </div>
                  }
                />

                <DatePicker
                  selected={endDate}
                  onChange={handleEndDateChange}
                  selectsEnd
                  startDate={startDate}
                  endDate={endDate}
                  placeholderText="End Date"
                  minDate={startDate}
                  className="datepicker1"
                  customInput={
                    <div
                      style={{
                        position: "relative",
                        display: "flex",
                        flexDirection: "column",
                      }}
                    >
                      <div style={{ marginTop: "20px" }}>
                        <input
                          type="text"
                          className="formcontroldash"
                          style={{ color: "#6c757d" }}
                          value={
                            endDate
                              ? moment(endDate).format("DD-MM-YYYY")
                              : "" 
                          }
                          readOnly
                        />
                      </div>

                      <img
                        src="images/calander.svg"
                        alt="Calendar"
                        style={{
                          position: "absolute",
                          right: "21px",
                          top: "20px",
                          cursor: "pointer",
                        }}
                      />
                    </div>
                  }
                />
              </div>
            ) : (
              ""
            )}
          </div>
        </div>
        <div class="containerdash">
          <div className="boxdash" onClick={handleClickSignup}>
            <div className="imagedivdash">
              <i class="fa  fa-user-plus" aria-hidden="true"></i>
            </div>
            <div>
              <p className="textdash">No. of Signups</p>
              <p className="numberdash">{kpidashboard?.numberOfSignUps}</p>
            </div>
          </div>

          <div className="boxdash" onClick={handleClickCarereceiver}>
            <div className="imagedivdash">
              <i class="fa fa-users" aria-hidden="true"></i>
            </div>
            <div>
              <p className="textdash">No. of Patients : {kpidashboard?.numberOfPatients}</p>
              <div style={{ display: "flex", gap: "20px",marginTop:"20px" }}>
              <div>
                <span style={{ color: "#0A59A3", fontWeight: "700" }}>
                  {" "}
                  Subscriber : {kpidashboard?.subscribedPatientCount}{" "}
                </span>
              </div>
              <div>
              <span style={{ color: "#0A59A3", fontWeight: "700" }}>
                  {" "}
                  Non-Subscriber : {kpidashboard?.numberOfPatients - kpidashboard?.subscribedPatientCount}{" "}
                </span>
              </div>
              </div>
           
            </div>
          
          </div>

          

          {/* <div className="boxdash">
            <div className="imagedivdash">
              <i class="fa fa-heartbeat" aria-hidden="true"></i>
            </div>
            <div>
              <p className="textdash">Service Extended</p>
              <p className="numberdash">
                {kpidashboard?.numberOfServiceExtended}
              </p>
            </div>
          </div> */}

          {/* <div className="boxdash">
            <div className="imagedivdash">
              <i class="fa fa-user-md" aria-hidden="true"></i>
            </div>
            <div>
              <p className="textdash">Doctor Appointments</p>
             
              <div
                style={{
                  display: "flex",
                  marginTop: "11px",
                  gap: "10px",
                  fontSize: "12px",
                }}
              >
              

                <div style={{ display: "block", gap: "5px" }}>
                  <div>
                    <span style={{ color: "dimgray", fontWeight: "700" }}>
                      {" "}
                      Total : {kpidashboard?.numberOfDoctorAppointments}{" "}
                    </span>
                  </div>
                  <div style={{ marginTop: "10px" }}>
                    <span style={{ color: "green", fontWeight: "700" }}>
                      {" "}
                      Completed :{" "}
                      {kpidashboard?.numberOfCompletedDoctorAppointment}{" "}
                    </span>
                  </div>
                </div>
                <div style={{ display: "block" }}>
                  <div>
                    <span style={{ color: "red", fontWeight: "700" }}>
                      Cancelled :{" "}
                      {kpidashboard?.numberOfCancelledDoctorAppointment}{" "}
                    </span>
                  </div>
                  <div style={{ marginTop: "10px" }}>
                    <span style={{ color: "orange", fontWeight: "700" }}>
                      {" "}
                      Pending : {
                        kpidashboard?.numberOfPendingDoctorAppointment
                      }{" "}
                    </span>
                  </div>
                </div>
              </div>
            </div>

          
          </div> */}

          {/* <div className="boxdash">
            <div className="imagedivdash">
              <i class="fa fa-medkit" aria-hidden="true"></i>
            </div>
            <div>
              <p className="textdash">Lab Appointments</p>
              <div
                style={{
                  display: "flex",
                  marginTop: "11px",
                  gap: "10px",
                  fontSize: "12px",
                }}
              >
              

                <div style={{ display: "block", gap: "5px" }}>
                  <div>
                    <span style={{ color: "dimgray", fontWeight: "700" }}>
                      {" "}
                      Total : {kpidashboard?.numberOfLabAppointments}{" "}
                    </span>
                  </div>
                  <div style={{ marginTop: "10px" }}>
                    <span style={{ color: "green", fontWeight: "700" }}>
                      {" "}
                      Completed :{" "}
                      {kpidashboard?.numberOfCompletedLabAppointment}{" "}
                    </span>
                  </div>
                </div>
                <div style={{ display: "block" }}>
                  <div>
                    <span style={{ color: "red", fontWeight: "700" }}>
                      Cancelled :{" "}
                      {kpidashboard?.numberOfCancelledLabAppointment}{" "}
                    </span>
                  </div>
                  <div style={{ marginTop: "10px" }}>
                    <span style={{ color: "orange", fontWeight: "700" }}>
                      {" "}
                      Pending : {
                        kpidashboard?.numberOfPendingLabAppointment
                      }{" "}
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div> */}

          {/* <div className="boxdash">
            <div className="imagedivdash">
              <i class="fa fa-check-square-o" aria-hidden="true"></i>
            </div>
            <div>
              <p className="textdash">Services as per On-time</p>
              <p className="numberdash">{kpidashboard?.serviceAsPerOnTime}</p>
            </div>
          </div> */}

          <div className="boxdash">
            <div className="imagedivdash">
              <i class="fa fa-money" aria-hidden="true"></i>
            </div>
            <div>
              <p className="textdash">Total Revenue</p>
              <p className="numberdash">₹ {kpidashboard?.totalRevenue}</p>
            </div>
          </div>

          <div className="boxdash">
            <div className="imagedivdash">
              <i class="fa fa-hospital-o" aria-hidden="true"></i>
            </div>
            <div>
              <p className="textdash">No. of Cancelled Subscription </p>
              <p className="numberdash">
                {kpidashboard?.numberOfCancelledSubscriptions}
              </p>
            </div>
          </div>
        </div>
      </div>

      <div
        style={{
          display: "flex",
          gap: "50px",
          justifyContent: "space-between",
          // marginTop: "30px",
          padding: "20px",
        }}
      >
        <div
          style={{
            width: "25%",
            padding: "10px",
            backgroundColor: "white",
            borderRadius: "10px",

            boxShadow:
              " inset 2px 2px 2px 0px rgba(255,255,255,.5), 7px 7px 20px 0px rgba(0,0,0,.1), 4px 4px 5px 0px rgba(0,0,0,.1)",
          }}
        >
          <div
            className=""
            style={{
              fontSize: "24px",
              color: "cornflowerblue",
              textAlign: "center",
              display: "flex",
              gap: "20px",
              marginLeft: "10px",
              // justifyContent: "center",
            }}
          >
            <div>
              <i class="fa fa-user-md" aria-hidden="true"></i>
            </div>
            <div
              className=""
              style={{
                fontSize: "16px",
                fontWeight: "700",
                color: "#616161",
                marginTop: "5px",
              }}
            >
              Doctor Appointments
            </div>
          </div>

          <div
            style={{
              display: "flex",
              marginTop: "20px",
              gap: "40px",
              fontSize: "14px",
            }}
          >
            <div style={{ display: "block", gap: "5px" }}>
              <div>
                <span style={{ color: "#0A59A3", fontWeight: "700" }}>
                  {" "}
                  Total : {appointmentcount?.numberOfDoctorAppointments}{" "}
                </span>
              </div>
             
              <div style={{ marginTop: "10px" }}>
                <span style={{ color: "green", fontWeight: "700" }}>
                  {" "}
                  Completed : {
                    appointmentcount?.numberOfCompletedDoctorAppointment
                  }{" "}
                </span>
              </div>
              <div style={{ marginTop: "10px",cursor:"pointer" }} onClick={inComplete}>
                <span style={{ color: "red", fontWeight: "700" }}>
                  {" "}
                  Incomplete : {
                    appointmentcount?.numberOfIncompleteDoctorAppointment
                  }{" "}
                </span>
              </div>
            </div>
            <div style={{ display: "block" }}>
            <div>
                <span style={{ color: "orange", fontWeight: "700" }}>
                  {" "}
                  Yet To Start : {appointmentcount?.numberOfDoctorServicesYetToStart}{" "}
                </span>
              </div>
              <div style={{ marginTop: "10px" }}>
                <span style={{ color: "red", fontWeight: "700" }}>
                  Cancelled : {appointmentcount?.numberOfCancelledDoctorAppointment}{" "}
                </span>
              </div>
              <div style={{ marginTop: "10px" }}>
                <span style={{ color: "orange", fontWeight: "700" }}>
                  {" "}
                  Pending : {
                    appointmentcount?.numberOfPendingDoctorAppointment
                  }{" "}
                </span>
              </div>
            </div>
            
          </div>
        </div>

        <div
          style={{
            width: "25%",
            padding: "10px",
            backgroundColor: "white",
            borderRadius: "10px",
            boxShadow:
              " inset 2px 2px 2px 0px rgba(255,255,255,.5), 7px 7px 20px 0px rgba(0,0,0,.1), 4px 4px 5px 0px rgba(0,0,0,.1)",
          }}
        >
          <div
            className=""
            style={{
              fontSize: "36px",
              color: "cornflowerblue",
              textAlign: "center",
              display: "flex",
              gap: "10px",
             
            }}
          >
            <div >
            <img src="/images/lab.svg" alt="Laboratory"  style={{height:"40px",width:"30px"}}/>
             
            </div>
            <div
              className=""
              style={{
                fontSize: "16px",
                fontWeight: "700",
                color: "#616161",
                marginTop: "12px",
              }}
            >
              Lab Appointments
            </div>
          </div>

          <div
            style={{
              display: "flex",
              // marginTop: "20px",
              gap: "40px",
              fontSize: "14px",
            }}
          >
            <div style={{ display: "block", gap: "5px" }}>
              <div>
                <span style={{ color: "#0A59A3", fontWeight: "700" }}>
                  {" "}
                  Total : {appointmentcount?.numberOfLabAppointments}{" "}
                </span>
              </div>
             
              <div style={{ marginTop: "10px" }}>
                <span style={{ color: "green", fontWeight: "700" }}>
                  {" "}
                  Completed : {
                    appointmentcount?.numberOfCompletedLabAppointment
                  }{" "}
                </span>
              </div>
              <div style={{ marginTop: "10px" }}>
                <span style={{ color: "red", fontWeight: "700" }}>
                  {" "}
                  Incomplete : {
                    appointmentcount?.numberOfIncompleteLabAppointment
                  }{" "}
                </span>
              </div>
            </div>
            <div style={{ display: "block" }}>
            <div>
                <span style={{ color: "orange", fontWeight: "700" }}>
                  {" "}
                  Yet To Start : {appointmentcount?.numberOfLabServicesYetToStart}{" "}
                </span>
              </div>
              <div style={{marginTop:"10px"}}>
                <span style={{ color: "red", fontWeight: "700" }}>
                  Cancelled : {appointmentcount?.numberOfCancelledLabAppointment}{" "}
                </span>
              </div>
              <div style={{ marginTop: "10px" }}>
                <span style={{ color: "orange", fontWeight: "700" }}>
                  {" "}
                  Pending : {appointmentcount?.numberOfPendingLabAppointment}{" "}
                </span>
              </div>
            </div>
          </div>
        </div>

        <div
          style={{
            width: "25%",
            padding: "10px",
            backgroundColor: "white",
            borderRadius: "10px",
            boxShadow:
              " inset 2px 2px 2px 0px rgba(255,255,255,.5), 7px 7px 20px 0px rgba(0,0,0,.1), 4px 4px 5px 0px rgba(0,0,0,.1)",
          }}
        >
          <div
            className=""
            style={{
              fontSize: "28px",
              color: "cornflowerblue",
              textAlign: "center",
              display: "flex",
              gap: "9px",
              // marginLeft: "10px",
            }}
          >
            <div>
            <img src="/images/nurse.svg" style={{height: "22px",width: "22px"}} alt="Laboratory" />
            </div>
            <div
              className=""
              style={{
                fontSize: "16px",
                fontWeight: "700",
                color: "#616161",
                marginTop: "5px",
              }}
            >
             Nurse Appointments
            </div>
          </div>

          <div
               style={{
                display: "flex",
                marginTop: "20px",
                gap: "40px",
                fontSize: "14px",
              }}
              >
              

                <div style={{ display: "block", gap: "5px" }}>
                  <div>
                    <span style={{ color: "#0A59A3", fontWeight: "700" }}>
                      {" "}
                      Total : {appointmentcount?.numberOfNurseAppointments}{" "}
                    </span>
                  </div>
                  <div style={{ marginTop: "10px" }}>
                    <span style={{ color: "green", fontWeight: "700" }}>
                      {" "}
                      Completed :{" "}
                      {appointmentcount?.numberOfCompletedNurseAppointment}{" "}
                    </span>
                  </div>
                  <div style={{ marginTop: "10px" }}>
                <span style={{ color: "red", fontWeight: "700" }}>
                  {" "}
                  Incomplete : {
                    appointmentcount?.numberOfIncompleteNurseAppointment
                  }{" "}
                </span>
              </div>
                </div>
                <div style={{ display: "block" }}>
                <div>
                <span style={{ color: "orange", fontWeight: "700" }}>
                  {" "}
                  Yet To Start : {appointmentcount?.numberOfNurseServicesYetToStart}{" "}
                </span>
              </div >
                  <div style={{ marginTop: "10px" }}>
                    <span style={{ color: "red", fontWeight: "700" }}>
                      Cancelled :{" "}
                      {appointmentcount?.numberOfCancelledNurseAppointment}{" "}
                    </span>
                  </div>
                  <div style={{ marginTop: "10px" }}>
                    <span style={{ color: "orange", fontWeight: "700" }}>
                      {" "}
                      Pending : {
                        appointmentcount?.numberOfPendingNurseAppointment
                      }{" "}
                    </span>
                  </div>
                </div>
              </div>
        </div>

        <div
          style={{
            width: "25%",
            padding: "10px",
            backgroundColor: "white",
            borderRadius: "10px",
            boxShadow:
              " inset 2px 2px 2px 0px rgba(255,255,255,.5), 7px 7px 20px 0px rgba(0,0,0,.1), 4px 4px 5px 0px rgba(0,0,0,.1)",
          }}
        >
          <div
            className=""
            style={{
              fontSize: "36px",
              color: "cornflowerblue",
              textAlign: "center",
              display: "flex",
              gap: "9px",
              // marginLeft: "10px",
            }}
          >
            <div>
            <img src="/images/planpurchased.svg" style={{height: "40px",width: "30px"}} alt="Laboratory" />
            </div>
            <div
              className=""
              style={{
                fontSize: "16px",
                fontWeight: "700",
                color: "#616161",
                marginTop: "12px",
              }}
            >
              Plans Purchased
            </div>
          </div>

          <div style={{ display: "flex", flexDirection: "column" }}>
  {careplancount?.map((careplancount1, index) => (
    <div
      key={index}
      style={{
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        marginBottom: "5px",
      }}
    >
      <span style={{ color: "#616161", fontWeight: "700" }}>
        {careplancount1?.planName}: {careplancount1?.planCount}
      </span>
    </div>
  ))}
</div>
        </div>
      </div>

      <div style={{ padding: "10px", marginTop: "50px" }}>
        
      <h2 style={{ padding: "10px", color: "#004172" }}>
                {" "}
                No. of Signups: {businessEventData?.length}{" "}
              </h2>
      <DataTable
                value={businessEventData}
                paginator
                rows={5}
                rowsPerPageOptions={[5, 10, 25, 50]}
                tableStyle={{ minWidth: "50rem" }}
              >
                 <Column
                  field="giver_name"
                  header="Name"
                  body={(rowbody) => (
                    <>
                      <div style={{ display: "flex" }}>
                        <div
                          style={{
                            height: "22px",
                            width: "22px",
                            border: "1px solid #dcd9d9",
                            textAlign: "center",
                            cursor: "pointer",
                            marginTop: "5px" ,
                          }}
                          onClick={() => onClickOrderIdMain(rowbody?.user_seq_id)}
                        >
                          <i class="fa fa-caret-down" aria-hidden="true"></i>
                        </div>
                     
                        <div style={{ display: "block", marginLeft: "15px" }}>
                          <div style={{ fontSize: "12px" }}>
                            {rowbody.giver_name}
                          </div>

                          <div style={{ fontSize: "12px", marginTop: "10px" }}>
                            {rowbody.userCode}
                          </div>
                        </div>
                      </div>
                    </>
                  )}
                  sortable
                ></Column>
                <Column
                  // field="mobile_number"
                  header="Mobile No."
                  body={(rowbody) => (
                    <>
                     
                        <div style={{ display: "flex" }}>
                          <div style={{ fontSize: "12px" }}>
                            {rowbody.countryCode}
                          </div>

                          <div style={{ fontSize: "12px",marginLeft:"5px" }}>
                            {rowbody.mobile_number}
                          </div>
                        </div>
                     
                    </>
                  )}
                ></Column>
                <Column field="email" header="Email" ></Column>
                <Column field="signUpDate" header="Signed Up Date" ></Column>

              
              </DataTable>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            fontSize: "20px",
            backgroundColor: "white",
          }}
        >
          <div>
            <h4>Today's Appointments</h4>
          </div>
          <div className="" style={{ display: "flex", gap: "20px" }}>
            
            <Dropdown
              id="eclinic"
              name="eclinic"
              value={selecteclinic}
              options={dropdownEclinic}
              onChange={(e) => setSelecteclinic(e.value)}
              optionLabel="label"
              optionValue="value"
              optionIcon="icon"
              placeholder={<CustomPlaceholder />}
              filter
              filterBy="label"
              filterMatchMode="contains"
              filterPlaceholder="Type to filter"
              filterInputAutoFocus
              onFilter={onFilter}
              className="searchbar custom-dropdown"
              style={{ width: "160px", marginTop: "26px", marginRight: "20px" }}
            />
           

            {/* <DatePicker
              showIcon
              className="datepicker1"
              selected={dateupcomingfilter}
              onChange={(date) => setDateupcomingfilter(date)}
              customInput={
                <div
                  style={{
                    position: "relative",
                    display: "flex",
                    flexDirection: "column",
                  }}
                >
                  <div style={{ marginTop: "28px" }}>
                    <input
                      type="text"
                      className="formcontroldash"
                      style={{ color: "#6c757d" }}
                      value={
                        dateupcomingfilter
                          ? moment(dateupcomingfilter).format("DD-MM-YYYY")
                          : "Select Date"
                      }
                      readOnly
                    />
                  </div>

                  <img
                    src="images/calander.svg"
                    alt="Calendar"
                    style={{
                      position: "absolute",
                      right: "21px",
                      top: "28px",
                      cursor: "pointer",
                    }}
                  />
                </div>
              }
            ></DatePicker> */}

     

            <div
              className="custom-btn btn-2"
              style={{
                fontWeight: "700",
                marginTop: "26px",
                fontSize: "14px",
                width: "100%",
                marginBottom: "26px",
              }}
              onClick={handleClick}
            >
              {" "}
              + New Appointment
            </div>
          </div>
        </div>

        <TabView
          activeIndex={activeIndex}
          onTabChange={(e) => setActiveIndex(e.index)}
        >
          <TabPanel header="Doctor Appointment">
<div style={{display:"flex"}}>
          <Dropdown
      id="disease"
      name="disease"
      value={selectdocList}
      options={dropdownOptions}
      onChange={(e) => setSelectdocList(e.value)}
      placeholder="Select Doctor"
     
      optionLabel="label"
      filter
      filterBy="label"
      filterMatchMode="contains"
      filterPlaceholder="Type to filter"
      filterInputAutoFocus
      // onFilter={onFilter}
      className="searchbar custom-dropdown dropdowntd"
      style={{ width: "160px", marginRight: "20px" ,border:"1px solid #ccc"}}
      />


<p style={{marginTop:"9px"}}><b> Total Appointments: {careplanscheduledash?.length} </b></p>
</div>
            <DataTable
              value={careplanscheduledash}
              paginator
              rows={3}
              rowsPerPageOptions={[3, 6, 9, 12]}
              tableStyle={{ padding: "10px" }}
            >
              <Column field="id" header="Id"></Column>
              <Column field="patientName" header="Patient Name"></Column>
              <Column field="planName" header="Plan Name"></Column>
              <Column field="userName" header="Service Provider Name"></Column>
              {/* <Column field="cheLocationId" header="Eclinic"></Column> */}
              <Column
                field="serviceName"
                header="Service Name"
                style={{ width: "150px" }}
              ></Column>
              {/* <Column field="email" header="Email" sortable></Column> */}
              <Column field="whenAppointment" header="Consultation Date" body={(rowData) => {

const formattedDate = rowData.whenAppointment ? moment(rowData.whenAppointment).format("DD-MM-YYYY") : ""
return formattedDate;
}} ></Column>
<Column
field="timeRange"
header="Consultation Time"
body={(rowData) => `${rowData.fromTime} - ${rowData.toTime}`}
></Column>
<Column field="consultationsType" header="Consultations Type" body={
(rowData) =>
  rowData.consultationsType === "V" ? "Video" :
    rowData.consultationsType === "H" ? "Home Visit" :
      rowData.consultationsType === "P" ? "Phone" :
        rowData.consultationsType === "E" ? "Eclinic" : ""

} ></Column>

<Column field="consultationsReason" header="Consultation Reason"
></Column>

              {/* <Column field="consultationType" header="Consultation Type" ></Column> */}
            </DataTable>
          </TabPanel>
          <TabPanel header="Lab Appointment">
            <div style={{display:"flex"}}>
<Dropdown
id="disease"
name="disease"
value={selectlabList}
options={dropdownOptionsLab}
onChange={(e) => setSelectlabList(e.value)}
placeholder="Select Lab"
className="searchbar custom-dropdown dropdowntd"
style={{  marginRight: "20px" ,border:"1px solid #ccc"}}
optionLabel="label"
filter
filterBy="label"
filterMatchMode="contains"
filterPlaceholder="Type to filter"
filterInputAutoFocus
onFilter={onFilterLab}
/>
<p style={{marginTop:"9px"}}> <b>Total Appointments: {labcareplanscheduledash?.length}</b></p>
</div>
            <DataTable
              value={labcareplanscheduledash}
              paginator
              rows={3}
              rowsPerPageOptions={[3, 6, 9, 12]}
              tableStyle={{ padding: "10px" }}
            >
              <Column field="orderId" header="Order Id"></Column>
              <Column field="patientName" header="Patient Name" sortable></Column>
              <Column field="planName" header="Plan Name" sortable></Column>

              <Column field="serviceName" header="Service Name" sortable></Column>
              <Column field="hospitalName" header="Service Provider Name" sortable ></Column>
              <Column field="labTestName" header="LabTest Name" sortable></Column>
              <Column field="confirmDateTime" header="Appointment Date"
                body={(rowData) => {

                  const formattedDate = rowData.confirmDateTime ? moment(rowData.confirmDateTime).format("DD-MM-YYYY") : ""
                  return formattedDate;
                }}
              ></Column>
              <Column
                field="preferTime"
                header="Appointment Time"

              ></Column>
            </DataTable>
          </TabPanel>
          <TabPanel header="Nurse Appointment">
            <div style={{display:"flex"}}>
            <Dropdown
                          id="disease"
                          name="disease"
                          value={serviceprovider}
                          options={dropdownOptionsNurse}
                          onChange={(e) => setServiceprovider(e.target.value)}
                          placeholder="Select Nurse"
                          className="dropdowntd"
                          optionLabel="label"
                        
                        />
<p style={{marginLeft:"20px"}}> <b>Total Appointments: {nurseappointment?.length}</b></p>
</div>
         <DataTable
              value={nurseappointment}
              paginator
              rows={3}
              rowsPerPageOptions={[3, 6, 9, 12]}
              tableStyle={{ padding: "10px" }}
            >
               <Column field="id" header="Id"></Column>
               <Column field="patientName" header="Patient Name" sortable></Column>
              {/* <Column field="planName" header="Plan Name" sortable></Column> */}
              <Column field="serviceName" header="Service Name" sortable></Column>
              <Column field="userName" header="Service Provider Name" sortable ></Column>
              <Column field="whenAppointment" header="Consultation Date" body={(rowData) => {

                const formattedDate = rowData.whenAppointment ? moment(rowData.whenAppointment).format("DD-MM-YYYY") : ""
                return formattedDate;
              }} ></Column>
              <Column
                field="timeRange"
                header="Consultation Time"
                body={(rowData) => `${rowData.fromTime} - ${rowData.toTime}`}
              ></Column>
              <Column field="consultationsType" header="Consultations Type" body={
                (rowData) =>
                  rowData.consultationsType === "V" ? "Video" :
                    rowData.consultationsType === "H" ? "Home Visit" :
                      rowData.consultationsType === "P" ? "Phone" :
                        rowData.consultationsType === "E" ? "Eclinic" : ""

              } ></Column>

              <Column field="consultationsReason" header="Consultation Reason"
              ></Column>
            </DataTable>
          </TabPanel>
        </TabView>

        <div className="boxcss" style={{ padding: "10px" }}>
          {/* <div style={{ display: "flex", justifyContent: "space-between", fontSize: "20px" }} >
            <div>
              <h4>Upcoming Appointments</h4>
            </div>
            <div className=""  >
              <Dropdown
                options={options}
                // value={selectedOption}
                optionLabel="label"
                optionValue="value"
                optionIcon="icon"
                placeholder={<CustomPlaceholder />}
                className="searchbar custom-dropdown"
                style={{ width: "160px", marginTop: "26px", marginRight: "20px" }}
              />
              <div className="custom-btn btn-2" style={{ fontWeight: "700", marginTop: "26px", fontSize: "16px" }} onClick={handleClick}>  + New Appointment</div>


            </div>
          </div> */}

          {/* <DataTable value={careplanscheduledash} paginator rows={3} rowsPerPageOptions={[3, 6, 9, 12]} tableStyle={{ padding: "10px" }}
          >
            <Column field="id" header="Id" ></Column>
            <Column field="hospitalId" header="Eclinic" ></Column>
            <Column field="patientName" header="Patient Name" ></Column>
            <Column field="serviceName" header="Service Name" style={{ width: "150px" }}  ></Column>
          
            <Column field="whenAppointment" header="Scheduled Date" body={(rowData) => {

              const formattedDate = rowData.whenAppointment ? moment(rowData.whenAppointment).format("DD-MM-YYYY") : ""
              return formattedDate;
            }}></Column>
            <Column field="fromTime" header="Schedule Time" ></Column>
            <Column field="userName" header="Service Provider Name"
            ></Column>
            <Column field="consultationsType" header="Consultation Type"
            ></Column>



            <Column field="status" header="Status" />


        


          </DataTable> */}
        </div>

        <div style={{ display: "flex", marginTop: "50px", gap: "10px" }}>
          <div
            style={{
              width: "100%",
              padding: "10px",
              backgroundColor: "white",
              borderRadius: "20px",
              boxShadow: " 0 2px 4px rgba(0, 0, 0, 0.1)",
            }}
            className=""
          >
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                fontSize: "20px",
              }}
            >
              <div>
                <h4>Emergency</h4>
              </div>
              <div className="">
                <Dropdown
                  options={options}
                  // value={selectedOption}
                  optionLabel="label"
                  optionValue="value"
                  optionIcon="icon"
                  placeholder={<CustomPlaceholder2 />}
                  className="searchbar custom-dropdown"
                  style={{
                    width: "160px",
                    marginTop: "26px",
                    marginRight: "20px",
                  }}
                />
                <div
                  className="custom-btn btn-2"
                  style={{
                    fontWeight: "700",
                    marginTop: "26px",
                    fontSize: "16px",
                    marginRight: "25px",
                  }}
                  onClick={handleClick}
                >
                  {" "}
                  + Add
                </div>
              </div>
            </div>
            <DataTable
              value={sosdetails}
              tableStyle={{ marginTop: "20px" }}
              paginator
              rows={3}
              rowsPerPageOptions={[3, 6, 9, 12]}
            >
              <Column
                field="patientName"
                header="Patient Name"
                sortable
              ></Column>
              <Column field="patientMobile" header="Patient Mobile"></Column>
              <Column field="severity" header="Severity"></Column>
              <Column field="status" header="Status"></Column>

              <Column field="remarks" header="Remarks"></Column>
            </DataTable>
          </div>

          {/* <div style={{ width: "40%", padding: "10px" }} className='boxcss'>
            <div style={{ display: "flex", justifyContent: "space-between", fontSize: "20px" }} >
              <div>
                <h4>Change For Request</h4>
              </div>
              <div className="">



              </div>
            </div>


            <DataTable value={changeforrequest} tableStyle={{ marginTop: "20px" }} paginator rows={3} rowsPerPageOptions={[3, 6, 9, 12]}
            >
              <Column field="patientName" header="Patient Name" sortable></Column>
              <Column field="originalServiceDate" header="Schedule Date" body={(rowData) => {

                const formattedDate = rowData.originalServiceDate ? moment(rowData.originalServiceDate).format("DD-MM-YYYY") : ""
                return formattedDate;
              }} ></Column>
              <Column field="requestedDate" header="Request Date" body={(rowData) => {

                const formattedDate = rowData.requestedDate ? moment(rowData.requestedDate).format("DD-MM-YYYY") : ""
                return formattedDate;
              }} ></Column>
              <Column header="Update"
                body={(rowData) => (


                  <>

                    <div className="custom-btn btn-2" style={{ fontWeight: "700", fontSize: "14px" }}> Update</div>

                  </>
                )}
              />
            </DataTable>
          </div> */}
        </div>

        <div className="boxcss" style={{ padding: "10px", marginTop: "50px" }}>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              fontSize: "20px",
            }}
          >
            <div>
              <h4>Pre Assessment</h4>
            </div>
           
          </div>

          <DataTable
            value={assesment}
            paginator
            rows={3}
            rowsPerPageOptions={[3, 6, 9, 12]}
            tableStyle={{ padding: "10px" }}
          >
            <Column field="giverName" header="Giver Name"></Column>
            <Column field="giverMobile" header="Giver Mobile"></Column>
            <Column field="giverEmail" header="Giver Email"></Column>
           
            <Column field="patientName" header="Patient Name"></Column>
            <Column field="receiverMobile" header="Patient Mobile"></Column>
           
            <Column field="relation" header="Relation"></Column>
            <Column field="zipCode" header="Patient Zipcode"></Column>
            <Column field="address" header="Patient Address"></Column>

            <Column
              field="createdDate"
              header="Created Date"
              body={(rowData) => {
                const formattedDate = rowData.createdDate
                  ? moment(rowData.createdDate).format("DD-MM-YYYY")
                  : "";
                return formattedDate;
              }}
            ></Column>
            <Column 
  body={(rowData) => {
    const status = rowData.parentStatus === 1 ? 'Yet to Start' : rowData.parentStatus === 2 ? 'Booked' : 'Completed';
    return status;
  }}
  header="Status"
/>
          </DataTable>
        </div>
      </div>

      <Dialog
            visible={openDialog}
            showHeader={true}
            resizable={false}
            draggable={false}
            blockScroll={false}
            style={{ width: "1000px" }}
            header={<div>Patient Details :</div>}
            className=""
           
            onHide={() => setDialog(false)}
          >
            <PopupDash 
              particularPatientDash={patientdetailsdash}
              giverdetailsDash={giverdetails}
             
            />
          </Dialog>
    </>
  );
}

export default MedicareDash;
