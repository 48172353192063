import DatePicker from "react-datepicker";
import { useState, React, useRef } from "react";
import { Dropdown } from "primereact/dropdown";
import { useEffect } from "react";
import http from "../../../src/Redux/services/http-common.jsx";
import "react-datepicker/dist/react-datepicker.css";
import "../CarePlan/index.css";
import moment from "moment";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import DoctorAppointmentViewPopup from "./DoctorAppointmentViewPopup.js";
import { Sidebar } from "primereact/sidebar";
import { AutoComplete } from "primereact/autocomplete";
import { TabView, TabPanel } from "primereact/tabview";
import { InputText } from "primereact/inputtext";
import infobutton from "../../Assets/Images/infobutton.svg";
import { Accordion, AccordionTab } from "primereact/accordion";
import { Tag } from "primereact/tag";
import AppointmentReschedule from "./AppointmentReschedule.jsx";
import { Backdrop, Button, CircularProgress } from "@mui/material";
import accept from "../../Assets/Images/accept.svg";
import reject from "../../Assets/Images/reject.svg";
import { login } from "../../Redux/Actions/dashboardaction.jsx";
import red from "../../Assets/Images/red.svg";
import green from "../../Assets/Images/green.svg";
import VerifyAccount from "../../Assets/Images/VerifyAccount.svg";

function EmpanelledDoctorsData({}) {
  const [doctorsData, setDoctorsData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [fromDate, setFromDate] = useState(new Date());
  const [remarks, setRemarks] = useState({});
  const [actualPayment, setActualPayment] = useState({});
  const [sideBarData, setSideBarData] = useState();
  const [sideDialog, setSideDialog] = useState(false);
  const loginData = JSON.parse(localStorage.getItem("loginData"));
  const options = [
    { name: "Pending" },
    { name: "Approved", code: "A" },
    { name: "Rejected", code: "R" },
  ];
  const [selectedItem, setSelectedItem] = useState(
    options.find((option) => option.name === "Pending")
  );

  console.log(loginData, "Logindata");

  const empanelledGetAPI = async () => {
    setDoctorsData([]);
    let url = `${
      process.env.REACT_APP_DOC_URL
    }backOfficeAutoPayment/getDoctorPayment?type=1&year=${moment(
      fromDate
    ).format("YYYY")}&month=${moment(fromDate).format("MM")}`;
    if (selectedItem && ["A", "R"].includes(selectedItem.code)) {
      url += `&status=${selectedItem.code}`;
    }

    try {
      setLoading(true);
      const response = await fetch(url);
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
      const data = await response.json();
      if (!data || data.data.length === 0) {
        setDoctorsData([]);
      } else {
        setDoctorsData(data?.data);
      }
    } catch (error) {
      console.error("Error fetching Empanelled data:", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (fromDate) {
      empanelledGetAPI();
    } else if (fromDate && selectedItem) {
      empanelledGetAPI();
    } else if (selectedItem) {
      empanelledGetAPI();
    }
  }, [fromDate, selectedItem]);

  const handleRemarkChange = (userId, serviceCode, value) => {
    const compositeKey = `${userId}:${serviceCode}`;
    setRemarks((prevState) => ({
      ...prevState,
      [compositeKey]: value,
    }));
  };

  const handleActualPaymentChange = (userId, serviceCode, value) => {
    const compositeKey = `${userId}:${serviceCode}`;

    setActualPayment((prevState) => ({
      ...prevState,
      [compositeKey]: value,
    }));
  };

  const accepted = async (
    userId,
    toBePaid,
    serviceCode,
    consultationAmount,
    fixedSalaryAmount,
    consultationCount
  ) => {
    const startOfMonth = moment()
      .startOf("month")
      .format("YYYY-MM-DD 00:00:00");
    const endOfMonth = moment().endOf("month").format("YYYY-MM-DD 00:00:00");
    const compositeKey = `${userId}:${serviceCode}`;

    let payload = {
      userid: userId,
      type: 1,
      serviceCode: serviceCode,
      totalAmount: fixedSalaryAmount,
      fromDate: startOfMonth,
      toDate: endOfMonth,
      remark: remarks[compositeKey] || "",
      status: "A",
      createdBy: loginData?.firstName + " " + loginData?.lastName,
      toBePaid: toBePaid,
      totalDeduction:
        fixedSalaryAmount -
        (actualPayment[compositeKey] && actualPayment[compositeKey] !== ""
          ? actualPayment[compositeKey]
          : toBePaid),
      actualAmount:
        actualPayment[compositeKey] && actualPayment[compositeKey] !== ""
          ? actualPayment[compositeKey]
          : toBePaid,
      consultationCount: consultationCount,
    };

    console.log(payload, "AcceptedPayload");
    try {
      const response = await http.post(
        `${process.env.REACT_APP_DOC_URL}doctorBilling/saveDoctorPayment`,
        payload
      );

      console.log(response.data, "Response from saveDoctorPayment");
      empanelledGetAPI();
    } catch (error) {
      console.error("Error in saveDoctorPayment", error);
    }
  };

  const rejected = async (
    userId,
    toBePaid,
    serviceCode,
    consultationAmount,
    fixedSalaryAmount,
    consultationCount
  ) => {
    console.log("Rejected");
    const compositeKey = `${userId}:${serviceCode}`;
    if (!remarks[compositeKey] || remarks[compositeKey].trim() === "") {
      toast("Please enter a remark.");
      return;
    }
    const startOfMonth = moment()
      .startOf("month")
      .format("YYYY-MM-DD 00:00:00");
    const endOfMonth = moment().endOf("month").format("YYYY-MM-DD 00:00:00");

    let payload = {
      userid: userId,
      type: 1,
      serviceCode: serviceCode,
      totalAmount: fixedSalaryAmount,
      fromDate: startOfMonth,
      toDate: endOfMonth,
      remark: remarks[compositeKey] || "",
      status: "R",
      createdBy: loginData?.firstName + " " + loginData?.lastName,
      toBePaid: toBePaid,
      totalDeduction:
        fixedSalaryAmount -
        (actualPayment[compositeKey] && actualPayment[compositeKey] !== ""
          ? actualPayment[compositeKey]
          : toBePaid),
      actualAmount:
        actualPayment[compositeKey] && actualPayment[compositeKey] !== ""
          ? actualPayment[compositeKey]
          : toBePaid,
      consultationCount: consultationCount,
    };

    console.log(payload, "AcceptedPayload");
    try {
      const response = await http.post(
        `${process.env.REACT_APP_DOC_URL}doctorBilling/saveDoctorPayment`,
        payload
      );

      console.log(response.data, "Response from saveDoctorPayment");
      empanelledGetAPI();
    } catch (error) {
      console.error("Error in saveDoctorPayment", error);
    }
  };
  const onClickInfo = async (columnData) => {
    setLoading(true);

    let url = `${process.env.REACT_APP_DOC_URL}backOfficeAutoPayment/getDoctorPaymentDetails?type=1&userId=${columnData.userId}`;
    if (fromDate) {
      url += `&year=${moment(fromDate).format("YYYY")}&month=${moment(
        fromDate
      ).format("MM")}`;
    }

    try {
      const response = await fetch(url);
      if (!response.ok) {
        setLoading(false);
        throw new Error("Network response was not ok");
      }
      const result = await response.json();
      console.log(result.data.empanelled, "DATAAAAAAAA");

      if (result.data.empanelled && result.data.empanelled.length > 0) {
        setSideBarData(result.data.empanelled);
      } else {
        setSideBarData([]);
        console.log("No data found");
      }
      setLoading(false);
      setSideDialog(true);
    } catch (error) {
      console.error("Error fetching or parsing data:", error);
      toast.error("Error fetching Emapnelled data");
    }
  };
  const verifyAccount = async (userId) => {
    console.log("Verify Account Function");
    // let s_token = secureStorage.getItem("token");
    try {
      setLoading(true);
      const response = await http.post(
        `${process.env.REACT_APP_ADMINURL}user/createRazorCustomerAccountId?userId=${userId}`

        // {
        //   headers: {
        //     Authorization: `Bearer ${s_token}`,
        //   },
        // }
      );

      console.log(response.data, "Response from Verify Account");

      empanelledGetAPI();
      toast.success("Account Verification Successful");
    } catch (error) {
      console.error("Error in Verifying Account", error);
      toast.error("Error in Verifying Account.");
    }
  };
  const accHeader = (tabContent) => {
    return (
      <>
        <div style={{ display: "flex", marginTop: "7px" }}>
          <div style={{ width: "270px", textAlign: "left" }}>
            <label>{tabContent.doctorName}</label>
          </div>

          <div style={{ width: "150px", textAlign: "right" }}></div>
        </div>
      </>
    );
  };
  const calculatePayableDaysUsingFromDate = (lopDays) => {
    const momentFromDate = moment(fromDate);
    const daysInMonth = momentFromDate.daysInMonth();
    const payableDays = daysInMonth - lopDays;
    return isNaN(payableDays) ? null : payableDays;
  };

  return (
    <>
      <ToastContainer />
      <Backdrop
        sx={{ color: "#5579C6", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={loading}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      {doctorsData === undefined ? (
        <div
          style={{
            height: "1000px",
            textAlign: "center",
            justifyItems: "center",
          }}
        >
          <div>
            <i
              class="pi pi-spin pi-spinner"
              style={{
                fontSize: " 2rem",
                marginTop: "200px",
                color: "blue",
                fontWeight: "700",
              }}
            ></i>
          </div>
        </div>
      ) : (
        <>
          <div style={{ display: "flex" }}>
            <div
              className="searchbar"
              style={{ marginBottom: "20px" }}
              title="Search by date"
            >
              <DatePicker
                showIcon
                selected={fromDate ? new Date(fromDate) : null}
                onChange={(date) => setFromDate(date)}
                showMonthYearPicker
                dateFormat="MM/yyyy"
                customInput={
                  <div
                    style={{
                      position: "relative",
                      display: "flex",
                      flexDirection: "column",
                    }}
                  >
                    <div>
                      <input
                        type="text"
                        className="form-control"
                        value={
                          fromDate
                            ? moment(fromDate).format("MM/yyyy")
                            : "Select month & year"
                        }
                        placeholder="Select month & year"
                        readOnly
                      />
                    </div>
                    <img
                      src="images/calander.svg"
                      alt="Calendar"
                      style={{
                        position: "absolute",
                        right: "30px",
                        top: "1px",
                        cursor: "pointer",
                      }}
                    />
                  </div>
                }
              ></DatePicker>
            </div>
            <div
              style={{
                marginBottom: "20px",
                marginLeft: "50px",
                width: "135px",
              }}
            >
              <Dropdown
                value={selectedItem}
                onChange={(e) => setSelectedItem(e.value)}
                options={options}
                optionLabel="name"
                placeholder="Select Status"
                className="w-96"
              />
            </div>
          </div>
          <DataTable
            value={doctorsData}
            paginator
            rows={10}
            rowsPerPageOptions={[10, 25, 50]}
            tableStyle={{ width: "100%" }}
          >
            {/* <Column field="giver_name" header="Name" sortable></Column> */}
            <Column
              // field="receiverName"
              header="Doctor Name"
              body={(rowbody) => (
                <>
                  <div style={{ display: "flex" }}>
                    <div style={{ display: "block", marginLeft: "15px" }}>
                      <div style={{ fontSize: "12px" }}>
                        {rowbody.doctorName}
                      </div>
                    </div>
                  </div>
                </>
              )}
            ></Column>
            <Column
              field="fixedSalaryAmount"
              header="Salary amount "
              body={(rowbody) => (
                <>
                  <div style={{ display: "flex" }}>
                    <div style={{ fontSize: "12px" }}>
                      {rowbody.fixedSalaryAmount}
                    </div>
                  </div>
                </>
              )}
            ></Column>
            <Column
              header="Loss of Pay"
              body={(rowData) => {
                const lossOfPay = Number(rowData.lopAmount);

                if (isNaN(lossOfPay)) {
                  return "";
                }

                return <span>{lossOfPay}</span>;
              }}
            />

            <Column field="defaultLeave" header="Total leave"></Column>

            <Column
              header="TDS"
              body={(rowData) => {
                return rowData.tds || rowData.tds === 0
                  ? `${rowData.tds}%`
                  : "";
              }}
            />
            <Column
              field="isPanVerified"
              header="PAN Confirm"
              // style={{ width: "200px" }}
            ></Column>
            <Column
              field="transportationAmount"
              header="Transportation Amount"
              // style={{ width: "200px" }}
            ></Column>
            {/* <Column
              field="camp"
              header="Camp"
              // style={{ width: "200px" }}
            ></Column> */}
            <Column
              header="To be paid"
              body={(rowbody) => {
                const compositeKey = `${rowbody.userId}:${rowbody.serviceCode}`;

                const actualPaymentEntered =
                  actualPayment[compositeKey] !== undefined &&
                  actualPayment[compositeKey] !== "";

                const isMatch =
                  Number(rowbody.toBePaid) ===
                  Number(actualPayment[compositeKey] || 0);

                console.log("Actual Payments:", actualPayment);
                console.log("Is Match for", compositeKey, ":", isMatch);

                return (
                  <div
                    style={{
                      position: "relative",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      height: "60px",
                      width: "100px",
                    }}
                  >
                    <span
                      style={{
                        zIndex: 2,
                        position: "absolute",
                        color: actualPaymentEntered ? "white" : "black",
                        fontWeight: "bold",
                      }}
                    >
                      {rowbody.toBePaid}
                    </span>
                    {actualPaymentEntered && (
                      <img
                        src={isMatch ? green : red}
                        alt={isMatch ? "Match" : "Mismatch"}
                        style={{
                          position: "absolute",
                          height: "100%",
                          width: "100%",
                          zIndex: 1,
                        }}
                      />
                    )}
                  </div>
                );
              }}
            />
            {!(selectedItem?.code === "A" || selectedItem?.code === "R") && (
              <Column
                field="actualPayment"
                header="Actual payment"
                body={(rowbody) => (
                  <>
                    <div style={{ display: "flex" }}>
                      <div style={{ fontSize: "12px" }}>
                        <InputText
                          placeholder="Enter Actual Amount"
                          type="number"
                          value={
                            actualPayment[
                              `${rowbody.userId}:${rowbody.serviceCode}`
                            ] || ""
                          }
                          onChange={(e) =>
                            handleActualPaymentChange(
                              rowbody.userId,
                              rowbody.serviceCode,
                              e.target.value
                            )
                          }
                          style={{ width: "140px" }}
                        />
                      </div>
                    </div>
                  </>
                )}
              />
            )}

            <Column
              field="status"
              header="Status"
              body={(rowData) => {
                if (selectedItem.code === "A") {
                  return "Approved";
                } else if (selectedItem.code === "R") {
                  return "Rejected";
                } else {
                  return "Pending";
                }
              }}
            />

            {!(selectedItem?.code === "A" || selectedItem?.code === "R") && (
              <Column
                header="Remarks"
                body={(rowbody) => (
                  <>
                    <div style={{ display: "flex" }}>
                      <div style={{ fontSize: "12px" }}>
                        <InputText
                          placeholder="Enter Remarks"
                          value={
                            remarks[
                              `${rowbody.userId}:${rowbody.serviceCode}`
                            ] || ""
                          }
                          onChange={(e) =>
                            handleRemarkChange(
                              rowbody.userId,
                              rowbody.serviceCode,
                              e.target.value
                            )
                          }
                          style={{ width: "140px" }}
                        />
                      </div>
                    </div>
                  </>
                )}
              />
            )}
            {!(selectedItem?.code === "A" || selectedItem?.code === "R") && (
              <Column
                field=""
                header="Action Button "
                body={(rowbody) => (
                  <>
                    {rowbody.isBankDetailsVerified === "Y" && (
                      <div style={{ display: "flex" }}>
                        <div style={{ fontSize: "12px", cursor: "pointer" }}>
                          <img
                            src={accept}
                            alt=""
                            onClick={(e) =>
                              accepted(
                                rowbody.userId,
                                rowbody.toBePaid,
                                rowbody.serviceCode,
                                rowbody.consultationAmount,
                                rowbody.fixedSalaryAmount,
                                rowbody.consultationCount
                              )
                            }
                          />
                          <img
                            src={reject}
                            alt=""
                            onClick={(e) =>
                              rejected(
                                rowbody.userId,
                                rowbody.toBePaid,
                                rowbody.serviceCode,
                                rowbody.consultationAmount,
                                rowbody.fixedSalaryAmount,
                                rowbody.consultationCount
                              )
                            }
                          />
                        </div>
                      </div>
                    )}
                    {rowbody.isBankDetailsVerified === "N" && (
                      <div style={{ display: "flex" }}>
                        <div style={{ fontSize: "10px", cursor: "pointer" }}>
                          <img
                            src={VerifyAccount}
                            alt=""
                            onClick={(e) => verifyAccount(rowbody.userId)}
                            style={{ width: "110px" }}
                          />
                        </div>
                      </div>
                    )}
                  </>
                )}
              />
            )}

            <Column
              field=""
              header="Details"
              body={(rowbody) => (
                <>
                  <div style={{ display: "flex" }}>
                    <div style={{ fontSize: "12px" }}>
                      <img
                        src={infobutton}
                        alt=""
                        title="Doctor Info"
                        onClick={(e) => onClickInfo(rowbody)}
                        style={{ cursor: "pointer" }}
                      />
                    </div>
                  </div>
                </>
              )}
            ></Column>
          </DataTable>
          <Sidebar
            visible={sideDialog}
            position="right"
            onHide={() => setSideDialog(false)}
            style={{ width: "580px" }}
          >
            <>
              {sideBarData && (
                <div className="">
                  <div>
                    <h2 style={{ color: "black", marginTop: "0px" }}>
                      {" "}
                      Payment Details{" "}
                    </h2>
                  </div>
                  <div className="mt-10 mb-10">
                    <h3
                      style={{
                        color: "black",
                        marginTop: "20px",
                        marginBottom: "20px",
                      }}
                    >
                      {" "}
                      {sideBarData[0].doctorName}
                    </h3>
                  </div>
                  <div className="mt-3">
                    <Accordion>
                      {sideBarData.map((tab, index) => (
                        <AccordionTab key={index} header={accHeader(tab)}>
                          <h3 className="mt-2">Payment details</h3>
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "space-between",
                              marginTop: "15px",
                            }}
                          ></div>
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "space-between",
                              marginTop: "15px",
                            }}
                          >
                            <div style={{ width: "50%", textAlign: "left" }}>
                              <label>Salary Amount</label>
                            </div>
                            <div style={{ width: "50%", textAlign: "right" }}>
                              {tab.fixedSalaryAmount}
                            </div>
                          </div>

                          <div
                            style={{
                              display: "flex",
                              justifyContent: "space-between",
                              marginTop: "15px",
                            }}
                          >
                            <div style={{ width: "50%", textAlign: "left" }}>
                              <label>Total Leaves</label>
                            </div>
                            <div style={{ width: "50%", textAlign: "right" }}>
                              {tab.defaultLeave}
                            </div>
                          </div>
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "space-between",
                              marginTop: "15px",
                            }}
                          >
                            <div style={{ width: "50%", textAlign: "left" }}>
                              <label>Loss of Pay</label>
                            </div>
                            <div style={{ width: "50%", textAlign: "right" }}>
                              {tab.lopDays}
                            </div>
                          </div>
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "space-between",
                              marginTop: "15px",
                            }}
                          >
                            <div style={{ width: "50%", textAlign: "left" }}>
                              <label>TDS</label>
                            </div>
                            <div style={{ width: "50%", textAlign: "right" }}>
                              {tab.tds} %
                            </div>
                          </div>
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "space-between",
                              marginTop: "15px",
                            }}
                          ></div>

                          <div
                            style={{
                              display: "flex",
                              justifyContent: "space-between",
                              marginTop: "15px",
                            }}
                          >
                            <div style={{ width: "50%", textAlign: "left" }}>
                              <label>Number of Consultation Done</label>
                            </div>
                            <div style={{ width: "50%", textAlign: "right" }}>
                              {tab.count}
                            </div>
                          </div>
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "space-between",
                              marginTop: "15px",
                            }}
                          >
                            <div style={{ width: "50%", textAlign: "left" }}>
                              <label>LOP Amount</label>
                            </div>
                            <div style={{ width: "50%", textAlign: "right" }}>
                              {tab.lopAmount}
                            </div>
                          </div>

                          <div
                            style={{
                              display: "flex",
                              justifyContent: "space-between",
                              marginTop: "15px",
                            }}
                          >
                            <div style={{ width: "50%", textAlign: "left" }}>
                              <label>No of Payable days</label>
                            </div>
                            <div style={{ width: "50%", textAlign: "right" }}>
                              {calculatePayableDaysUsingFromDate(tab.lopDays)}
                            </div>
                          </div>
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "space-between",
                              marginTop: "15px",
                            }}
                          >
                            <div style={{ width: "50%", textAlign: "left" }}>
                              <label>Total Amount</label>
                            </div>
                            <div style={{ width: "50%", textAlign: "right" }}>
                              {tab.toBePaid}
                            </div>
                          </div>
                        </AccordionTab>
                      ))}
                    </Accordion>
                  </div>
                </div>
              )}
            </>
          </Sidebar>
        </>
      )}
    </>
  );
}

export default EmpanelledDoctorsData;
