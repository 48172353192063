import React, { useState } from "react";
import "./index.css";
import moment from "moment";
import DatePicker from "react-datepicker";

function DialogRefundMoney({
  onClickYesBtn,
  onClickCancelBtn,

  onSelectChange,
  preferredLabTime,
  preferDateTime,
  onPreferTime,
  preferTime,
}) {
  const [isErrorMsg, setErrorMsg] = useState(false);
  const onClickYes = () => {
    onClickYesBtn();
  };

  const onClickCancel = () => {
    onClickCancelBtn();
  };

  const onChangeDate = (e) => {
    onSelectChange(e);
  };

  const handleChange = (event) => {
    onPreferTime(event.target.value);
  };

  return (
    <div className="panelist-overlay">
      <div className="panelist-statusUpdateBox">
        <div className="panelist-overlayLabTest">
          <div className="panelist-approveRejectIconContainer">
            <p className="panelist-overlayUpdateStatus">
              Select preferred Date and Time for Lab Test sample
            </p>

            <div className="flex flex-col justify-center md:mt-0 mt-6">
              <div className="btnContainerNew">
                <div className="btnContainerNew">
                  <label style={{ width: 70 }}>
                    Date: <span className="require">*</span>
                  </label>
                  <DatePicker
                    locale="es"
                    dropdownMode="select"
                    showMonthDropdown
                    showYearDropdown
                    dateFormat="dd/MM/yyyy"
                    minDate={new Date()}
                    selected={preferDateTime}
                    onSelect={onChangeDate}
                    autoFocus={false}
                    placeholderText="Select Date"
                    className="btnContainerNew"
                  />
                </div>

                <div className="btnContainerNew">
                  <label>
                    Time: <span className="require">*</span>
                  </label>
                  <select
                    value={preferTime}
                    id="procedureStatus"
                    name="procedureStatus"
                    onChange={handleChange}
                  >
                    <option value="">Select time </option>

                    {preferredLabTime.map((res, i) => (
                      <option
                        key={i}
                        className="py-1 text-sm text-green-600"
                        value={res.code}
                      >
                        {res.label}
                      </option>
                    ))}
                  </select>
                </div>
              </div>
            </div>

            <div className="btnContainerBtn">
              <div className="primaryBtn" onClick={onClickYes}>
                <div className="textYesRefund">Yes</div>
              </div>

              <div className="cancelBtn" onClick={onClickCancel}>
                <div className="textCancel">Cancel</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default DialogRefundMoney;
