import React from "react";
import { useState, useEffect } from "react";
import "./index.css";
import { Dialog } from "primereact/dialog";
import moment from "moment";
import Flag from "../../Assets/Images/Flag.svg";
import Popup from "./Popup.js";
import axios from "axios";
import { Paginator } from "primereact/paginator";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Button } from "primereact/button";
import NoDataFound from "../../components/NoDataFound/NoDataFound";
import Loader from "../../components/Loader/Loader";
import { useNavigate, useLocation } from "react-router-dom";
import { Dropdown } from "primereact/dropdown";
import { getLocalTime, getLocalOnlyDate } from "../../utils/LocalTimeFormat";
import { select } from "@redux-saga/core/effects";
import { TabView, TabPanel } from "primereact/tabview";
import searchImg from "../../Assets/Images/searchImg.svg";
import http from "../../../src/Redux/services/http-common.jsx"
import {
  fetchMapMyIndiaToken,
  fetchEclinicsByPincode,
} from "../../Redux/Actions/dashboardaction";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { jsonToExcelConvetercareplan } from "../../utils/jsonToExcelConvertCareSponse.js";

function CaregiverDetailspage({
  searchResults,
  selectedBox,
  patientList,
  careplanboxlist,
  isSelectedOption,
  onClickOrderIdNew,

  onClickAddCommentNew,
  onClickEditLabTestSample,
}) {
  const navigate = useNavigate();
  const location = useLocation();

  const selectedDash = location.state?.selectedDash;
  const datesearch = location.state?.datesearch;
  const monthSearch = location.state?.monthSearch;
  const yearSearch = location.state?.yearSearch;
  const formattedMonthYear = location.state?.formattedMonthYear;
  const formatstartDate = location.state?.formatstartDate;
  const formatendDate = location.state?.formatendDate;

  const selectedDash1 = location.state?.selectedDash1;
  const datesearch1 = location.state?.datesearch1;
  const monthSearch1 = location.state?.monthSearch1;
  const yearSearch1 = location.state?.yearSearch1;
  const formattedMonthYear1 = location.state?.formattedMonthYear1;
  const formatstartDate1 = location.state?.formatstartDate;
  const formatendDate1 = location.state?.formatendDate;

  const [patientdetails, setPatientdetails] = useState("");
  const [giverdetails, setGiverdetail] = useState("");
  const [isOpen, setIsOpen] = useState(false);
  const [open, setOpen] = useState(true);
  const [open1, setOpen1] = useState(false);
  const [openDialog, setDialog] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [transactionMonth, setTransactionMonth] = useState("");
  const [isLoader, setLoader] = useState(false);
  const [dialogEclinic, setDialogeclinic] = useState(false);
  const [selecteclinic, setSelecteclinic] = useState(null);
  const [alleclinic, setAllclinic] = useState();
  const [particularPatient, setParticularPatient] = useState();
  const [activeIndex, setActiveIndex] = useState(0);
  const [careGiversData, setCareGiversData] = useState([]);
  const [careReceiversData, setCareReceiversData] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const [selectedId, setSelectedId] = useState("0");
  const searchData = location.state?.searchData;
  const [allplan, setAllplan] = useState();
  const [planName1, setplanName1] = useState();
  const [planids, setplanids] = useState();
  const [value, setValue] = useState(searchData);
  const [message, setMessage] = useState("");
  const [allcliniceach, setAllecliniceach] = useState(null);
  const [selectType,setSelectType]=useState(null);

  // const pageLimit = 30;
  const [clinics, setClinics] = useState([]);
  const [clinicsdropdown, setClinicsdropdown] = useState(null);
  const [cliniceachcond, setcliniceachcond] = useState(null);
  const [careplanDataExcel, setCareplanDataExcel] = useState([]);
  const onClickAddComment = (item) => {
    onClickAddCommentNew(item);
  };

  const onClickEditLabTest = (item) => {
    onClickEditLabTestSample(item);
  };

  const onClickOrderId = (item) => {
    onClickOrderIdNew(item);
  };

  const dropdownOptionsType = [
    {label: "Subscribed",value: "Subscribed"},
    {label: "Non-Subscribed",value: "Non-Subscribed"},
    {label: "All",value: "All"}
  ]


  const fetchCareGiversData = async () => {
    try {
      let url = `${process.env.REACT_APP_BASEURL}medicare/patients/search/caregiver`;

      let payload = {};

      if (selectedDash === "Today") {
        payload = {
          type: "T",
          date: datesearch,
        };
      } else if (selectedDash === "Month") {
        payload = {
          type: "M",
          month: monthSearch,
          year: formattedMonthYear,
        };
      } else if (selectedDash === "Date") {
        payload = {
          type: "D",
          fromDate: formatstartDate,
          toDate: formatendDate,
        };
      } else if (selectedDash === "Year") {
        payload = {
          type: "Y",
          year: yearSearch,
        };
      } else if (selectedDash === "All") {
        payload = {};
      }

      if (searchQuery) {
        const mobileNumberPattern = /^[0-9]{10}$/;

        if (mobileNumberPattern.test(searchQuery)) {
          payload.mobile_number = searchQuery;
        } else {
          payload.giver_name = searchQuery;
        }
      }
      const response = await http.post(url, payload);
      // const response = await http.post(url,{});
      setCareGiversData(response.data);
      setCareplanDataExcel(response.data);
      console.log(response.data, "yes");
    } catch (error) {
      console.error("Error fetching Care Givers data:", error);
    }
  };

  const fetchCareReceiversData = async () => {
    try {
      let url = `${process.env.REACT_APP_BASEURL}medicare/patients/search/carereceiver`;

      let payload = {};

      if (selectedDash1 === "Today") {
        payload = {
          type: "T",
          date: datesearch1,
        };
      } else if (selectedDash1 === "Month") {
        payload = {
          type: "M",
          month: monthSearch1,
          year: formattedMonthYear1,
        };
      } else if (selectedDash1 === "Date") {
        payload = {
          type: "D",
          fromDate: formatstartDate1,
          toDate: formatendDate1,
        };
      } else if (selectedDash1 === "Year") {
        payload = {
          type: "Y",
          year: yearSearch1,
        };
      }
        else if (selectType ==="Subscribed") {
          payload = {
            
              "subscribed":"Y"
          
          };
      }
      
        else if (selectType ==="Non-Subscribed") {
          payload = {
            
              "subscribed":"N"
          
          };
        }
          else if (selectType ==="All") {
            payload = {
              
               
            
            };
        }
      else if (selectedDash1 === "All") {
        payload = {};
      }

      if (selectedId) {
        payload.planId = selectedId;
      }

      if (selecteclinic) {
        payload.cheid = selecteclinic.name;
      }

      if (searchQuery) {
        const mobileNumberPattern = /^[0-9]{10}$/;

        if (mobileNumberPattern.test(searchQuery)) {
          // url += `receiverMobile=${searchQuery}`;
          payload.receiverMobile = searchQuery;
        } else {
          // url += `receiverName=${searchQuery}`;
          payload.receiverName = searchQuery;
        }
      }

      http
        // .post(url,{})
        .post(url, payload)
        .then((res) => {
          setCareReceiversData(res.data);
          console.log(res.data, "yes");
        })
        .catch((error) => {
          console.error("Error fetching Care Receivers data:", error);
        });
    } catch (error) {
      console.error("Error fetching Care Receivers data:", error);
    }
  };

  useEffect(() => {
    if (activeIndex === 0) {
      fetchCareGiversData();
    } else if (activeIndex === 1) {
      fetchCareReceiversData();
    }
  }, [activeIndex, selectedId, selecteclinic,selectType]);

 

  const onClickOrderIdMain = (e, id) => {
    setDialog(true);

    console.log(e, "item_data");

    http
      .get(
        `${process.env.REACT_APP_BASEURL}medicare/patients/receiver?user_seq_id=${e}`
      )

      .then((res) => {
        console.log(res.data, "aaja");

        setPatientdetails(res.data);
        let apidate = res.data?.planDetailsDTO?.map((obj) => {
          return obj.purchasedDate;
        });
        setTransactionMonth(apidate);
      })
      .catch((err) => {
        console.log(err, "Something went wrong1");
      });
  };

  const onClickAnotherApi = (rowbody, id) => {
    setDialog(true);

    http
      .get(
        `${process.env.REACT_APP_BASEURL}medicare/patients/giver?user_seq_id=${rowbody?.userSeqId}&patientCode=${rowbody?.receiverCode}`
      )

      .then((res) => {
        setGiverdetail(res.data);
        let apidate = res.data?.planDetailsDTO?.map((obj) => {
          return obj.purchasedDate;
        });
        setTransactionMonth(apidate);
      })
      .catch((err) => {
        console.log(err, "Something went wrong1");
      });
  };

  const handleReceiver = (receiverName) => {
    navigate("/careplansSchedule", { state: { receiverName: receiverName } });
  };
  const handleSchedule = (receiverMobile) => {
    navigate("/Schedule", { state: { receiverMobile: receiverMobile } });
  };

  const handleEclinic = async (rowbody) => {
    setDialogeclinic(true);
    setParticularPatient(rowbody);
    const pincode = rowbody.pincode;
    console.log(pincode, "rowbody");

    const tokenResponse = await fetchMapMyIndiaToken();
    if (
      tokenResponse?.data?.responseCode === "200" &&
      tokenResponse.data?.succesObject
    ) {
      setClinics([]);
      const token = tokenResponse.data.succesObject.access_token;
      console.log(token, "token");
      try {
        const response = await http.get(
          `https://patient.curebay.com/dhp/places/geocode?address=${pincode}&itemCount=1`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        let eLoc = response?.data?.copResults?.eLoc;
        if (eLoc) {
          const latLongResponse = await http.get(
            `https://patient.curebay.com/dhp/O2O/entity/${eLoc}`,
            {
              headers: {
                Authorization: `Bearer ${token}`,
              },
            }
          );
          if (latLongResponse?.data) {
            let reqData = {
              lat: latLongResponse.data?.latitude,
              longt: latLongResponse.data?.longitude,
              pincode: pincode,
            };

            const eClinicsByPinCode = await http.post(
              `${process.env.REACT_APP_MEDICARE_BASEURL}location/eclinics`,
              reqData
            );
            console.log(eClinicsByPinCode, "okay");

            if (eClinicsByPinCode?.data) {
              setClinics(eClinicsByPinCode?.data?.succesObject);
              if (eClinicsByPinCode?.data?.responseCode === "500") {
                setMessage(
                  eClinicsByPinCode?.data?.responseCode === "500"
                    ? "Currently We do not have an eClinic at the provided zip code"
                    : ""
                );
                http
                  .post(
                    `${process.env.REACT_APP_MEDICARE_BASEURL}location/getAllEclinics`,
                    {}
                  )
                  .then((res) => {
                    setAllecliniceach(res.data.succesObject);

                    console.log(res.data.succesObject, "alleclinics123456789");
                  })
                  .catch((err) => {
                    console.log(err, "Something went wrong1");
                  });
              }
            }
          }
        }
      } catch (error) {
        console.error(error);
      }
    }
  };


  useEffect(() => {
    http
      .post(
        `${process.env.REACT_APP_MEDICARE_BASEURL}location/getAllEclinics`,
        {}
      )
      .then((res) => {
        setAllclinic(res.data.succesObject);

        console.log(res.data.succesObject, "alleclinics");
      })
      .catch((err) => {
        console.log(err, "Something went wrong1");
      });
  }, []);

  const dropdownParticularEclinic = (clinics ?? []).map((item) => ({
    label: item?.name,
    value: {
      name: item?.name,
      loc: item?.locationCode,
    },
  }));

  // const dropdownParticularEclinic = clinics?.map((item) => (
  //   {

  //   label: item?.name,
  //   value: {
  //     name: item?.name,
  //     loc: item?.locationCode,
  //   },

  // }));

  const dropdownEclinic = alleclinic?.map((item) => ({
    label: item?.hospitalCode,
    value: {
      name: item?.hospitalCode,
      loc: item?.locationCode,
    },
  }));

  const dropdownEcliniceach = allcliniceach?.map((item) => ({
    label: item?.hospitalCode,
    value: {
      name: item?.hospitalCode,
      loc: item?.locationCode,
    },
  }));
 
  const onFilter = (event) => {
    if (event.query) {
      const filteredOptions = dropdownEclinic.filter((option) =>
        option.label.toLowerCase().includes(event.query.toLowerCase())
      );
      setSelecteclinic(filteredOptions);
    } else {
      setSelecteclinic(dropdownEclinic);
    }
  };

  console.log(clinicsdropdown?.name, cliniceachcond?.name, "gsdbhbdhbfjd");

  const updatebuttonClick = (rowdata) => {
    http
      .post(`${process.env.REACT_APP_BASEURL}medicare/assignEclinic`, {
        patientId: rowdata.receiverCode,
        cheId: clinicsdropdown?.name
          ? clinicsdropdown?.name
          : cliniceachcond?.name,
        cheLocationId: clinicsdropdown?.loc
          ? clinicsdropdown?.loc
          : cliniceachcond?.loc,
      })
      .then((res) => {
        console.log(res, "dddddd");
        setDialogeclinic(false);
        toast(" Updated Eclinic assigned successfully");
      })
      .catch((error) => console.error(error));
  };
  useEffect(() => {
    setSelectedId(searchData);
    // const getPlan = () => {
    http
      .get(`${process.env.REACT_APP_BASEURL}medicare/careplandetails`)
      .then((res) => {
        setAllplan(res.data);

        let plans = res.data.map((obj) => {
          return obj.planName;
        });
        let planids = res.data.map((obj) => {
          return obj.planId;
        });
        setplanName1(plans);
        setplanids(planids);
        console.log(planids, "wed");
      })
      .catch((err) => {
        console.log(err, "Something went wrong1");
      });
    // };
    // return () => getPlan();
  }, []);

  const dropdownOptions = [
    { label: "All", value: 0 },
    ...(allplan || []).map((item) => ({
      label: item?.planName,
      value: item?.planId,
    })),
  ];

  const handleDropdownChange = (e) => {
    setSelectedId(e.value);
    setValue(e.target.value);
  };

  const handleImageClick = () => {
    if (activeIndex === 0) {
      fetchCareGiversData();
    } else if (activeIndex === 1) {
      fetchCareReceiversData();
    }
  };

  const handleInputChange = (e) => {
    const inputValue = e.target.value;

    setSearchQuery(inputValue);

    const mobileNumberPattern = /^[0-9]{0,10}$/;

    if (mobileNumberPattern.test(inputValue)) {
      setSearchQuery(inputValue.slice(0, 10));
    }
  };

  useEffect(() => {
    if (selectedDash1) {
      setActiveIndex(1);
    }
  }, [selectedDash1]);

  async function saveAsExcel() {
   
    console.log(
      "careplanDataExcel",
      JSON.stringify(careplanDataExcel)
    );
    

    const newArrOfObjects = careplanDataExcel.map((obj) =>
      Object.keys(obj)
        .filter((e) => obj[e] !== null)
        .reduce((o, e) => {
          o[e] = obj[e];
          return o;
        }, {})
    );


    setTimeout(() => {
      jsonToExcelConvetercareplan(newArrOfObjects, "Careplan");
    }, 4000);
  }

  return (
    <>
      <ToastContainer />
      <div style={{ padding: "20px" }}>
        <div className="">
          <div style={{ display: "flex" }}>
            <div
              className="searchbar"
              style={{ width: "240px" }}
              title="Search by Name/Mobile"
            >
              <input
                type="text"
                value={searchQuery}
                onChange={handleInputChange}
                placeholder="Search by Name / Mobile"
                className="placeholder"
                maxLength={10}
              />

              <img
                src={searchImg}
                alt={"Goto icon"}
                className="navImg"
                onClick={handleImageClick}
              />
            </div>
            <button
              className="custom-btn btn-2"
              style={{ marginLeft: "30px", width: "125px" }}
              onClick={handleImageClick}
            >
              Search
            </button>
          </div>
         { activeIndex === 0 ? <div style={{display:"flex",justifyContent:"end",paddingRight:"20px"}}>
        <button onClick={() => saveAsExcel()}  className="custom-btn btn-2" style={{width:"125px",height:"40px",fontSize:"16px",fontWeight:"700",backgroundColor:"green"}}><i class="pi pi-spin pi-file-excel" style={{marginRight:"10px",fontSize:"20px"}}></i>Export</button>
        </div> : ""}
          <TabView
            activeIndex={activeIndex}
            onTabChange={(e) => setActiveIndex(e.index)}
            style={{ marginTop: "20px" }}
          >
         
            <TabPanel header="Care Sponsor">
              <div
                style={{ float: "right", fontSize: "18px", fontWeight: "700" }}
              >
                Total : {careGiversData.length}
              </div>
              <DataTable
                value={careGiversData}
                paginator
                rows={10}
                rowsPerPageOptions={[10, 25, 50]}
                tableStyle={{ minWidth: "50rem" }}
              >
                 
                {/* <Column field="giver_name" header="Name" sortable></Column> */}
                <Column
                  // field="receiverName"
                  header="Name"
                  body={(rowbody) => (
                    <>
                      <div style={{ display: "flex" }}>
                        <div
                          style={{
                            height: "22px",
                            width: "22px",
                            border: "1px solid #dcd9d9",
                            textAlign: "center",
                            cursor: "pointer",
                            marginTop:"10px"
                          }}
                          onClick={() =>
                            onClickOrderIdMain(rowbody?.user_seq_id)
                          }
                        >
                          <i class="fa fa-caret-down" aria-hidden="true"></i>
                        </div>
                        <div style={{ display: "block", marginLeft: "15px" }}>
                          <div style={{ fontSize: "12px" }}>
                            {rowbody.giver_name}
                          </div>

                          <div style={{ fontSize: "12px", marginTop: "10px" }}>
                            {rowbody.userCode}
                          </div>
                          
                        </div>
                      </div>
                    </>
                  )}
                ></Column>
                <Column
                  field="mobile_number"
                  header="Mobile No."
                  body={(rowbody) => (
                    <>
                     
                        <div style={{ display: "flex" }}>
                          <div style={{ fontSize: "12px" }}>
                            {rowbody.countryCode}
                          </div>

                          <div style={{ fontSize: "12px",marginLeft:"5px" }}>
                            {rowbody.mobile_number}
                          </div>
                        </div>
                     
                    </>
                  )}
                ></Column>
                <Column field="email" header="Email"></Column>
                <Column
                  field="signUpDate"
                  header="SignUp Date"
                 
                 
                ></Column>
               
                <Column
                  field="address"
                  header="Address"
                  // style={{ width: "200px" }}
                 
                ></Column>
                 

                {/* <Column
                  body={(rowbody) => (
                    <>
                      <Button
                        icon="pi pi-eye"
                        rounded
                        text
                        severity="info"
                        aria-label="Search"
                        onClick={() => onClickOrderIdMain(rowbody?.user_seq_id)}
                      />
                     
                    </>
                  )}
                ></Column> */}
              </DataTable>
            </TabPanel>
            <TabPanel header="Care Recipient">
              <div style={{ marginBottom: "20px", display: "flex" }}>
                <div>
                  <Dropdown
                    onChange={handleDropdownChange}
                    options={dropdownOptions}
                    value={selectedId}
                    placeholder="Select Plan"
                    className="searchbarname"
                  />
                </div>
                <div>
                  <Dropdown
                    id="eclinic"
                    name="eclinic"
                    value={selecteclinic}
                    options={dropdownEclinic}
                    onChange={(e) => setSelecteclinic(e.value)}
                    optionLabel="label"
                    optionValue="value"
                    optionIcon="icon"
                    placeholder="Select Eclinic"
                    filter
                    filterBy="label"
                    filterMatchMode="contains"
                    filterPlaceholder="Type to filter"
                    filterInputAutoFocus
                    onFilter={onFilter}
                    className="searchbar custom-dropdown"
                    style={{ width: "160px", marginLeft: "20px" }}
                  />
                   
                </div>
                <div style={{marginLeft:"10px"}}>
                  <Dropdown
                    onChange={(e) => setSelectType(e.value)}
                    options={dropdownOptionsType}
                    value={selectType}
                    placeholder="Select Type"
                    className="searchbarname"
                  />
                </div>
                <h3
                  style={{
                    marginTop: "10px",
                    marginLeft: "10px",
                    justifyContent: "right",
                  }}
                >
                  Total : {careReceiversData.length}
                </h3>
              </div>
              <DataTable
                value={careReceiversData}
                paginator
                rows={10}
                rowsPerPageOptions={[10, 25, 50]}
                tableStyle={{ fontSize: "10px" }}
              >
                <Column
                  header="Eclinic"
                  body={(rowbody) => (
                    <>
                      <div
                        style={{ display: "flex", gap: "10px", width: "100px" }}
                      >
                        <div>
                          <i
                            class="pi pi-pencil"
                            style={{ marginLeft: "0px", cursor: "pointer" }}
                            onClick={() => handleEclinic(rowbody)}
                          ></i>
                        </div>
                        <div style={{ fontSize: "12px" }}>{rowbody.cheid}</div>
                      </div>
                    </>
                  )}
                ></Column>
                <Column
                  field="receiverName"
                  header="Name"
                  body={(rowbody) => (
                    <>
                      <div style={{ display: "flex", fontSize: "12px" }}>
                        <div
                          style={{
                            height: "22px",

                            width: "22px",

                            border: "1px solid #dcd9d9",

                            textAlign: "center",

                            cursor: "pointer",

                            marginTop: "10px",
                          }}
                          onClick={() => onClickAnotherApi(rowbody)}
                        >
                          <i class="fa fa-caret-down" aria-hidden="true"></i>
                        </div>

                        <div style={{ display: "block", marginLeft: "15px" }}>
                          <div style={{ fontSize: "12px", marginTop: "10px" }}>
                            {rowbody.receiverName}
                          </div>

                          <div style={{ fontSize: "12px", marginTop: "10px" }}>
                            {rowbody.receiverCode}
                          </div>
                        </div>
                      </div>
                    </>
                  )}
                ></Column>
                <Column
                  field="receiverMobile"
                  header="Mobile No."
                  style={{ fontSize: "12px" }}
                ></Column>
                <Column
                  field="email"
                  header="Email"
                  style={{ fontSize: "12px" }}
                ></Column>
                {/* <Column field="receiverAge" header="Age"  style={{fontSize:"12px"}} ></Column> */}

                <Column
                  field="planName"
                  header="Careplan Name"
                  style={{ fontSize: "12px" }}
                ></Column>
                <Column
                  field="smartphoneUser"
                  header="Smartphone"
                  body={(rowbody) => (
                    <>
                      {rowbody.smartphoneUser === "1" ? (
                        <div style={{ fontSize: "12px" }}>Yes</div>
                      ) : (
                        <div style={{ fontSize: "12px" }}>No</div>
                      )}
                    </>
                  )}
                ></Column>

                <Column
                  header="Appointment"
                  body={(rowbody) => (
                    <>
                      {rowbody.planName === null ? (
                        " "
                      ) : (
                        <div>
                          <div
                            style={{
                              color: "#005D8E",
                              fontSize: "12px",
                              fontWeight: "600",
                              cursor: "pointer",
                            }}
                            onClick={() => handleReceiver(rowbody.receiverName)}
                          >
                            View Appointment
                          </div>
                        </div>
                      )}
                    </>
                  )}
                ></Column>

                <Column
                  header="Schedule"
                  body={(rowbody) => (
                    <>
                      {rowbody.planName === null ? (
                        " "
                      ) : (
                        <div>
                          <div
                            style={{
                              color: "#005D8E",
                              fontSize: "12px",
                              fontWeight: "600",
                              cursor: "pointer",
                            }}
                            onClick={() =>
                              handleSchedule(rowbody.receiverMobile)
                            }
                          >
                            View Schedule
                          </div>
                        </div>
                      )}
                    </>
                  )}
                ></Column>
     <Column
                  field="subscriptionStatus"
                  header="Status"
                  style={{ fontSize: "12px",fontWeight:"700" }}
                ></Column>
                <Column
                  field="address"
                  header="Address"
                  // style={{ width: "300px" }}
                  style={{ fontSize: "12px" }}
                ></Column>
                
              </DataTable>
            </TabPanel>
          </TabView>

          {patientList.length === 0 ? (
            <></>
          ) : // <div>No Data Found</div>
          !selectedBox ? (
            <>
              {/* <h2 style={{ padding: "10px", color: "#004172" }}>
                {" "}
                No. of Signups: {patientList?.length}{" "}
              </h2>
              <DataTable
                value={patientList}
                paginator
                rows={5}
                rowsPerPageOptions={[5, 10, 25, 50]}
                tableStyle={{ minWidth: "50rem" }}
              >
                <Column field="giver_name" header="Name" sortable></Column>
                <Column
                  field="mobile_number"
                  header="Mobile No."
                  sortable
                ></Column>
                <Column field="email" header="Email" sortable></Column>
                <Column field="age" header="Age" sortable></Column>
                <Column field="address" header="Address" style={{width:"200px"}} sortable></Column>

                <Column
                  body={(rowbody) => (
                    <>
                      <Button
                        icon="pi pi-eye"
                        rounded
                        text
                        severity="info"
                        aria-label="Search"
                        onClick={() => onClickOrderIdMain(rowbody?.user_seq_id)}
                      />
                     
                    </>
                  )}
                ></Column>
              </DataTable> */}
            </>
          ) : selectedBox === "box1" ? (
            <>
              <h2 style={{ padding: "10px", color: "#004172" }}>
                {" "}
                No. of Care Givers: {searchResults?.length}{" "}
              </h2>
              <DataTable
                value={searchResults}
                paginator
                rows={10}
                rowsPerPageOptions={[10, 25, 50]}
                tableStyle={{ minWidth: "50rem" }}
              >
                <Column field="giver_name" header="Name" sortable></Column>
                <Column
                  field="mobile_number"
                  header="Mobile No."
                  sortable
                ></Column>
                <Column field="email" header="Email" sortable></Column>
                {/* <Column field="age" header="Age" sortable></Column> */}
                <Column
                  field="address"
                  header="Address"
                  style={{ width: "200px" }}
                  sortable
                ></Column>

                <Column
                  body={(rowbody) => (
                    <>
                      <Button
                        icon="pi pi-eye"
                        rounded
                        text
                        severity="info"
                        aria-label="Search"
                        onClick={() => onClickOrderIdMain(rowbody?.user_seq_id)}
                      />
                    </>
                  )}
                ></Column>
              </DataTable>
            </>
          ) : (
            <>
              {/* <h2 style={{ padding: "10px", color: "#004172" }}>
                {" "}
                No. of Care Receivers: {searchResults?.length}{" "}
              </h2>
              <DataTable
                value={searchResults}
                paginator
                rows={5}
                rowsPerPageOptions={[5, 10, 25, 50]}
                tableStyle={{ minWidth: "50rem",fontSize:"14px" }}
              >
                 <Column header="Eclinic"  body={(rowbody) => (
                    <>
                      <div style={{display:"flex"}}>
                      <i class="pi pi-pencil" style={{marginLeft:"10px",cursor:"pointer"}} onClick={()=>handleEclinic(rowbody)}></i>
                      </div>
                    </>
                  )} ></Column>
                <Column field="receiverName" header="Name"  body={(rowbody) => (
                    <>
                      <div style={{display:"flex"}}>
                        <div style={{height:"22px",width:"22px",border:"1px solid #dcd9d9",textAlign:"center",cursor:"pointer"}}  onClick={() => onClickAnotherApi(rowbody?.userSeqId)}>
                      <i class="fa fa-caret-down"  aria-hidden="true"></i>
                      </div>
                      <span style={{marginLeft:"10px"}}>{rowbody.receiverName}</span>
                      </div>
                    </>
                  )} sortable></Column>
                <Column
                  field="receiverMobile"
                  header="Mobile No."
                  sortable
                ></Column>
                <Column field="email" header="Email" sortable></Column>
                <Column field="receiverAge" header="Age" sortable></Column>
               
                <Column
                  header="Appointment"
                  body={(rowbody) => (
                    <>
                      <div>
                        <div style={{color:"#005D8E",fontSize:"14px",fontWeight:"600",cursor:"pointer"}}
                        
                          onClick={() => handleReceiver(rowbody.receiverName)}
                        >View Appointment Screen</div>
                      </div>
                    </>
                  )}
                ></Column>
                   <Column field="address" header="Address" style={{width:"300px"}} sortable></Column>
                <Column
                  body={(rowbody) => (
                    <>
                      <Button
                        icon="pi pi-eye"
                        rounded
                        text
                        severity="info"
                        aria-label="Search"
                        onClick={() => onClickAnotherApi(rowbody?.userSeqId)}
                      />
                     
                    </>
                  )}
                ></Column>
              </DataTable> */}
            </>
          )}

          <Dialog
            visible={openDialog}
            showHeader={true}
            resizable={false}
            draggable={false}
            blockScroll={false}
            style={{ width: "1000px" }}
            header={<div>Patient Details :</div>}
            className=""
            onHide={() => setDialog(false)}
          >
            <Popup
              particularPatient={patientdetails}
              giverdetails={giverdetails}
              activeIndex={activeIndex}
            />
          </Dialog>
          <Dialog
            visible={dialogEclinic}
            showHeader={true}
            resizable={false}
            draggable={false}
            blockScroll={false}
            header={<div>Select Eclinic :</div>}
            onHide={() => setDialogeclinic(false)}
          >
            <div>
              <DataTable
                value={[particularPatient]}
                tableStyle={{ minWidth: "50rem" }}
              >
                <Column field="receiverName" header="Name"></Column>
                <Column field="receiverMobile" header="Mobile No."></Column>
                <Column field="email" header="Email"></Column>
                {/* <Column field="receiverAge" header="Age"></Column> */}

                <Column
                  field="address"
                  header="Address"
                  style={{ width: "300px" }}
                ></Column>
                <Column
                  header="Eclinic"
                  body={(rowbody) => (
                    <>
                      {allcliniceach ? (
                        <Dropdown
                          id="eclinic"
                          name="eclinic"
                          value={cliniceachcond}
                          options={dropdownEcliniceach}
                          onChange={(e) => setcliniceachcond(e.value)}
                          optionLabel="label"
                          optionValue="value"
                          optionIcon="icon"
                          placeholder="Select Eclinic"
                          className="searchbar custom-dropdown"
                          style={{ width: "160px", marginLeft: "20px" }}
                        />
                      ) : (
                        <Dropdown
                          id="eclinic"
                          name="eclinic"
                          value={clinicsdropdown}
                          options={dropdownParticularEclinic}
                          onChange={(e) => setClinicsdropdown(e.value)}
                          optionLabel="label"
                          placeholder="Select Eclinic"
                          optionValue="value"
                          optionIcon="icon"
                          className="searchbar custom-dropdown"
                        />
                      )}
                    </>
                  )}
                ></Column>
                <Column
                  body={(rowData) => (
                    <div
                      style={{
                        display: "flex",
                        gap: "20px",
                        justifyContent: "end",
                      }}
                    >
                      <div></div>
                      <div>
                        <button
                          className="custom-btn btn-2"
                          style={{ marginLeft: "10px", width: "125px" }}
                          onClick={() => updatebuttonClick(rowData)}
                        >
                          Save
                        </button>
                      </div>
                    </div>
                  )}
                ></Column>
              </DataTable>
              {message && (
                <div style={{ color: "red", marginTop: "10px" }}>{message}</div>
              )}
            </div>
          </Dialog>
        </div>
      </div>
    </>
  );
}

export default CaregiverDetailspage;
