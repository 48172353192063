import "./index.css";
import { useDispatch } from "react-redux";
import React, { useState, useEffect } from "react";
import MedicineOrderList from "./MedicineOrderList";
import "react-toastify/dist/ReactToastify.css";
import DateView from "../../components/DateView/dateView";
import SearchView from "../../components/SearchView/searchView";
import { ToastContainer, toast } from "react-toastify";
import DoctorAppointmentDetails from "../../components/DoctorAppointmentUpdate/DoctorAppointmentUpdate";
import moment from "moment";

import DialogAddComment from "../../components/DialogAddComment/dialogAddComment";
import calender_img from "../../Assets/Images/calender_img.svg";
import {onConvertJsonToExcel} from '../../utils/JsonToExcelConverter'

import {
  getInventoryDoctor,
  getOrderDetails,
  onAddComment,

  onUpdateAppointment
} from "../../Redux/Actions/dashboardaction";

import InfiniteScroll from "react-infinite-scroll-component";

const BusinessEvent = () => {
  const dispatch = useDispatch();
  const [isSelectedOption] = useState("Doctor Consultation");

  const [isDialogComment, setDialogViewComment] = useState(false);

  const [isDialogAppointment, setDialogAppointment] = useState(false);

  const [businessEventData, setBusinessEventData] = useState([]);
  const [businessEventDataExcel, setBusinessEventDataExcel] = useState([]);
  const [orderDetails, setOrderDetails] = useState();
 

  const [hasMore, setHasMore] = useState(true);
  const [pageStart, setPageStart] = useState(1);

  const [isLoader,setLoader]=useState(false)


  const [selectedItem, setSelectedItem] = useState("");

  const [fromDate, setFromDate] = useState(
    moment().add(-1, "days").format("DD-MM-yyyy")
  );
  const [toDate, setToDate] = useState(
    moment().add("days").format("DD-MM-yyyy")
  );

  const [commentText, setCommentText] = useState("");
  const [filterKey, setFilterKey] = useState("");
  useEffect(() => {

    setLoader(true)
    onAppointmentUpdate(toDate)
    
  }, [dispatch]);


  const onAppointmentUpdate=(toDate)=>{
    let payload = {
      pageNo: "1",
      pageSize: "20",
      type: "3",
      status: "14",
      fromDate: moment(fromDate, "DD-MM-YYYY").format("YYYY-MM-DD"),
      toDate: moment(toDate, "DD-MM-YYYY").format("YYYY-MM-DD"),

    };

    dispatch(getInventoryDoctor(payload)).then((res) => {
      setBusinessEventData([...res]);
      onAppointmentUpdateExcel(toDate)
      setLoader(false)
    });

  }

  
  const onAppointmentUpdateExcel=(toDate)=>{
    let payload = {
      type: "3",
      status: "14",
      fromDate: moment(fromDate, "DD-MM-YYYY").format("YYYY-MM-DD"),
      toDate: moment(toDate, "DD-MM-YYYY").format("YYYY-MM-DD"),

    };

    dispatch(getInventoryDoctor(payload)).then((res) => {
      setBusinessEventDataExcel([...res]);
      setLoader(false)
    });

  }


  

  const onClickYesBtn = () => {
    setDialogAppointment(false);
    let payload={
      patientId:selectedItem?.patientId,
      patientOrderId:selectedItem?.patientOrderId,
      status:"15",
      customerCareId:"admin",
    }


    dispatch(onUpdateAppointment(payload)).then((res) => {

      setDialogAppointment(false)

      toast(res?.message);
      onAppointmentUpdate()
     
    });
  };


  const onClickCancelBtnCross=()=>{

    setDialogAppointment(false);

  }

  const onClickCancelBtn = () => {
    setDialogAppointment(false);

    let payload={
      patientId:selectedItem?.patientId,
      patientOrderId:selectedItem?.patientOrderId,
      status:"16",
      customerCareId:"admin",
    }


    dispatch(onUpdateAppointment(payload)).then((res) => {

      setDialogAppointment(false)

      toast(res?.message);
      onAppointmentUpdate()
     
    });
  };

  const [doctorConsultation] = useState([
    "OrderID",
    "Patient Details",
    "Doctor Details",
    "Appointment Time",
    "Consultation",
    "Status",
    "Consultations Type",
    "Order Time"
  ]);


  const onClickDate = (e) => {
    setFromDate(e);
    setToDate();
  };

  const onClickToDate = (toDate) => {
    setToDate(toDate);
    
    onAppointmentUpdate(toDate)
  };

  const onTextChange = (filterKey) => {
    setFilterKey(filterKey);
  };

  const onChangeTextDialog = (text) => {
    setCommentText(text);
  };


  const onClickSearch = () => {

    if(filterKey?.length==0){
      return
    }
    setFilterKey("");
    setHasMore(false);
    let payload;
    if (filterKey?.length < 10) {
      payload = {
        patientOrderId: filterKey,
        type: "3",
       // fromDate: moment(fromDate, "DD-MM-YYYY").format("YYYY-MM-DD"),
        //toDate: moment(toDate, "DD-MM-YYYY").format("YYYY-MM-DD"),
      };
    } else {
      payload = {
        patientMobileNo: filterKey,
        type: "3",
       // fromDate: moment(fromDate, "DD-MM-YYYY").format("YYYY-MM-DD"),
        //toDate: moment(toDate, "DD-MM-YYYY").format("YYYY-MM-DD"),
      };
    }
    dispatch(getInventoryDoctor(payload)).then((res) => {
      setBusinessEventData([...res]);
    });
  };

  const onKeyDownEnter = (e) => {
    if(filterKey?.length==0){
      return
    }

    if (e?.key === "Enter") {
      setFilterKey("");

      setHasMore(false);

      let payload;
      if (filterKey?.length < 10) {
        payload = {
          patientOrderId: filterKey,
          type: "3",
          //fromDate: moment(fromDate, "DD-MM-YYYY").format("YYYY-MM-DD"),
         // toDate: moment(toDate, "DD-MM-YYYY").format("YYYY-MM-DD"),
        };
      } else {
        payload = {
          patientMobileNo: filterKey,
         type: "3",
         // fromDate: moment(fromDate, "DD-MM-YYYY").format("YYYY-MM-DD"),
         // toDate: moment(toDate, "DD-MM-YYYY").format("YYYY-MM-DD"),
        };
      }
      dispatch(getInventoryDoctor(payload)).then((res) => {
        setBusinessEventData([...res]);
      });
    }
  };


  const onClickAddCommentMain = (item) => {
    setDialogViewComment(true);

    setSelectedItem(item)
  };

 

  const onClickOrderIdMain = (item) => {
    setOrderDetails();
    setDialogAppointment(true);
    setSelectedItem(item)

    let payload = {
      patientOrderId: item?.patientOrderId,
      type: "3",
    };
    dispatch(getOrderDetails(payload)).then((res) => {
      setOrderDetails(res);
    });
  };



  const loadFunc = async () => {
    let payload = {
      
      pageNo: pageStart+1,
      pageSize: "20",
      type: "3",
      status: "14",
      fromDate: moment(fromDate, "DD-MM-YYYY").format("YYYY-MM-DD"),
      toDate: moment(toDate, "DD-MM-YYYY").format("YYYY-MM-DD"),
    };

    dispatch(getInventoryDoctor(payload)).then((res) => {

      if (res.length === 0) {
        setHasMore(false);
      } else {
        setPageStart(pageStart + 1);
        if (businessEventData?.length) {
          setBusinessEventData([...businessEventData, ...res]);
        } else {
          setBusinessEventData([...res]);
        }

        setHasMore(true);
      }
    });
  };

  const onClickYesBtnDialog = () => {

    let userData = JSON.parse(localStorage.getItem("userData"));


   
    let payload = {
      orderId: selectedItem?.patientOrderId,
      serviceType:
        isSelectedOption === "Doctor Consultation",
      action: "Elite Doctor ",
      remarks: commentText,
      userId: userData?.firstName +" "+ userData?.lastName,

    };

    dispatch(onAddComment(payload)).then((res) => {

      setDialogViewComment(false)
     
    });


  };

  const onClickCancelBtnDialog = () => {
    setDialogViewComment(false);
  };

  async function saveAsExcel() {
    onConvertJsonToExcel(businessEventDataExcel,"Elite Appointment")
  }

  return (
    <>
     <ToastContainer />
      <div className="profilepage-breadcrumb"></div>



<div className="titleBusinessEvent"> Select Filters & Services:</div>

<div className="dateContainer">
  <div className="dateContainerSub">
    <DateView
      title="From Date"
      value={fromDate}
      placeholder="From Date"
      onClickDate={onClickDate}
    />

    <SearchView
      title="Search by Order Id/Mobile Number"
      onTextChange={onTextChange}
      onClickSearch={onClickSearch}
      onKeyDownEnter={onKeyDownEnter}
      filterKey={filterKey}
    />
  </div>

  <div className="dateContainerSub">
    <DateView
      title="To Date"
      value={toDate}
      placeholder="To Date"
      onClickDate={onClickToDate}
      fromDate={fromDate}
    />
     
   <div></div>
  </div>
</div>

<div className="dateContainerAlert">
  <div></div>

</div>

<p className="hospitalEnquiry">Service: {isSelectedOption}</p>

<div className="hospitalEnquiryBody">
  {fromDate && toDate ? (
    <div className="selectedDateContainer">
      <img src={calender_img} alt={"Goto icon"} className="calenderImg" />
      <p className="selectDate">
        Selected date results from: {fromDate} to {toDate}
      </p>
    </div>
  ) : (
    <div></div>
  )}
 
  <div className="hospitalEnquiryBody">
        <p className="response">
          {" "}
          Total there are {businessEventData?.length} responses :
        </p>

        {
          businessEventDataExcel?.length>0 &&
          <p className="downLoadFile" onClick={()=>saveAsExcel()}>
           Download File
        </p>
        }

        </div>
</div>


      <div  id="scrollableDiv"
        className="listNewContainer"
        style={{
          height: 800,
          overflow: "auto",
        }}>

      <InfiniteScroll
          dataLength={businessEventData.length}
          next={loadFunc}
          hasMore={hasMore}
          pullDownToRefreshThreshold={50}
          scrollableTarget="scrollableDiv"
        ></InfiniteScroll>

     
        <MedicineOrderList
          medicineList={businessEventData}
          doctorConsultation={doctorConsultation}
          isSelectedOption={isSelectedOption}
          onClickOrderIdMain={onClickOrderIdMain}
          isLoader={isLoader}
          onClickAddCommentMain={onClickAddCommentMain}
       
         

        />


      </div>

      {isDialogAppointment && (
        <DoctorAppointmentDetails
          onClickYesBtn={onClickYesBtn}
          onClickCancelBtn={onClickCancelBtn}
          isSelectedOption={isSelectedOption}
          isDialog={isDialogAppointment}
          orderDetails={orderDetails}
          selectedItem={selectedItem}
           formActivity="business"

           onClickCancelBtnCross={onClickCancelBtnCross}
        />
      )}

      {isDialogComment && (
        <DialogAddComment
          onClickYesBtn={onClickYesBtnDialog}
          onClickCancelBtn={onClickCancelBtnDialog}
          isDialog={isDialogComment}
          isApproveOrReject={true}
          onTextChange={onChangeTextDialog}
          remarks={commentText}
        />
      )}

     
    </>
  );
};

export default BusinessEvent;
