import React from 'react'
import "./index.css";
import searchImg from '../../Assets/Images/searchImg.svg'
function  dateView({
  title,
  onTextChange,
  onClickSearch,
  onKeyDownEnter,
  filterKey

}) {

  const onChangeNumber=(e)=>{
    onTextChange(e.target.value)
  }

  const onKeyDown=(e)=>{
    onKeyDownEnter(e)

  }
  return (
    <div className='border-container' style={{zIndex:0,marginTop:20}}>


    <input value={filterKey} className='placeholder' placeholder={title} onChange={(e)=>onChangeNumber(e)}  onKeyDown={(e)=>onKeyDown(e)}/>

    <img src={ searchImg}  alt={"Goto icon"}  className='navImg' onClick={onClickSearch}/>
      
    </div>
  )
}

export default dateView
