import React, { useEffect, useReducer, useState } from "react";
import moment from "moment";

import { jsPDF } from "jspdf";
import { useNavigate } from "react-router-dom";

import { useLocation } from "react-router-dom";
import { APP_ROUTES } from "../../application/Router/constants/AppRoutes";
import doctorService from "../../Redux/services/doctorService"

// import { APP_ROUTES } from '../constants/approutes';
// import { IMG_URL } from "../config/constant";
// import { jsPDF } from "jspdf";
// import { useHistory, useLocation } from "react-router";

function EprescriptionDoc(props) {
  // const history = useHistory();
  const location = useLocation();

  //const { postConsultation[0], isLoading } = useSelector(state => state.doctorconsultationlist);
  const [postConsultation, setpostConsultation] = useState(location.state);
  // const [locationdata, setLocationdata] = useState(location.state?.dashboard);
  // console.log(locationdata,"locationdata")

  //const { patientLabTestsList, patientDrugPrescriptionList } = postConsultation;
  const [patientLabTestsList, setpatientLabTestsList] = useState();
  const [patientDrugPrescriptionList, setpatientDrugPrescriptionList] =
    useState(postConsultation[0]?.patientDrugPrescriptionList);
  const [labTestList, setLabTestList] = useState(
    postConsultation[0]?.patientLabTestsList
  );
  const [stamp, setStamp] = useState([]);
  const [click, setClick] = useState(false);

  const [signature, setSignature] = useState([]);
  // const { state } = location;

  const navigate = useNavigate();

  useEffect(() => {
   
    console.log("prescriptions",postConsultation[0].patientName)
    let payload = {
        fileName: postConsultation[0].userSignature,
      };
    if (postConsultation[0].patientName) {

     
      doctorService.getBase64Img(postConsultation[0].userSignature).then(
            (res) => {
                setTimeout(() => {
                    setClick(true);
                }, 1000);
                if (res.data) {
                  console.log(res.data,"ereerrr")
                    setSignature("data:image/jpg;base64," + res.data.fileData);
                   
                }
                console.log(res,"response");
            },
            (err) => {
                console.log(err);
            }
        );
        let payload1 = {
            fileName: postConsultation[0].hospitalStamp,
          };

          doctorService.getBase64Img(postConsultation[0].hospitalStamp).then(
            (res) => {
                console.log(res);
                if (res.data) {
                    setStamp("data:image/jpg;base64," + res.data.fileData);
                }
            },
            (err) => {
                console.log(err);
            }
        );
    }
   
}, [postConsultation[0].patientName]);


  const download = () => {
    const input = document.getElementById("abc");
    const pdf = new jsPDF({
      orientation: "portrait",
      unit: "px",
      format: "A4",
      userUnit: "px",
    });
    pdf
      .html(input, {
        html2canvas: { scale: 0.32, allowTaint: false },
        margin: [1, 0, 1, 0],
        autoPaging: true,
      })
      .then(() => {
        const fileName =
          "e-Prescription-" + postConsultation[0]?.patientName + ".pdf";
        pdf.save(fileName);
      });
  };

  const calage = (dateString) => {
    var today = new Date();
    var birthDate = new Date(dateString);
    var age = today.getFullYear() - birthDate.getFullYear();
    var m = today.getMonth() - birthDate.getMonth();
    if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
      age--;
    }
    return age;
  };

  const Closedata = () => {
    //  if(locationdata == "dashboard")
    // {
    navigate("/careplansSchedule");
    // }
  };

  console.log(labTestList, "ygyjhjj");

  return (
    <>
      <div className="mainpart">
        <div id="abc" className="secondartpart">
          <div className="px-2">
            <div className="flex justify-between mb-4  ml-1">
              <div></div>
              <div></div>
            </div>

            <div className="prespart1">
              {
                <span style={{ fontWeight: "700", fontSize: "16px" }}>
                  {postConsultation[0]?.userSalutation
                    ? postConsultation[0]?.userSalutation
                    : "Dr."}{" "}
                  {postConsultation[0]?.userName}
                  {/* {postConsultation[0]?.doctorName ? postConsultation[0].doctorName : postConsultation[0].userName} */}
                </span>
              }

              <span style={{ fontWeight: "700", fontSize: "16px" }}>
                {postConsultation[0]?.userQualification}
              </span>

              <span style={{ fontWeight: "700", fontSize: "16px" }}>
                {postConsultation[0]?.userSpecialityDept}
              </span>

              <span style={{ fontWeight: "700", fontSize: "16px" }}>
                Regn No:{postConsultation[0]?.userMCIVerification}
              </span>
            </div>
            <hr className="hr-line" />

            <div
              style={{
                display: "grid",
                gridTemplateColumns: "repeat(6, 1fr)",
                marginBottom: "1.25rem",
              }}
            >
              <div
                className="border dark:border-slate-700 px-3 py-1 font-bold"
                style={{
                  border: "1px solid #c1c5ca",
                  padding: "0.75rem",
                  fontWeight: "bold",
                }}
              >
                Name:
              </div>
              <div
                className="border dark:border-slate-700 px-3 py-1"
                style={{ border: "1px solid #c1c5ca", padding: "0.75rem" }}
              >
                {postConsultation[0]?.patientsalutation +
                  " " +
                  postConsultation[0]?.patientName}
              </div>

              <div
                className="border dark:border-slate-700 px-3 py-1 font-bold"
                style={{
                  border: "1px solid #c1c5ca",
                  padding: "0.75rem",
                  fontWeight: "bold",
                }}
              >
                Age/Sex:
              </div>
              <div
                className="border dark:border-slate-700 px-3 py-1"
                style={{ border: "1px solid #c1c5ca", padding: "0.75rem" }}
              >
                {calage(postConsultation[0]?.patientDOB) +
                  " / " +
                  (postConsultation[0]?.patientgender === "M"
                    ? "Male"
                    : postConsultation[0]?.patientgender === "F"
                    ? "Female"
                    : "")}
              </div>

              <div
                className="border dark:border-slate-700 px-3 py-1 font-bold"
                style={{
                  border: "1px solid #c1c5ca",
                  padding: "0.75rem",
                  fontWeight: "bold",
                }}
              >
                Hospital:
              </div>
              <div
                className="border dark:border-slate-700 px-3 py-1"
                style={{
                  border: "1px solid #c1c5ca",
                  padding: "0.75rem",
                }}
              >
                {postConsultation[0]?.patientHospitalName}
              </div>

              <div
                className="border dark:border-slate-700 px-3 py-1 font-bold"
                style={{
                  border: "1px solid #c1c5ca",
                  padding: "0.75rem",
                  fontWeight: "bold",
                }}
              >
                Phone Number:
              </div>
              <div
                className="border dark:border-slate-700 px-3 py-1"
                style={{
                  border: "1px solid #c1c5ca",
                  padding: "0.75rem",
                  // fontWeight: "bold"
                }}
              >
                {postConsultation[0]?.patientMobileNo}
              </div>

              <div
                className="border dark:border-slate-700 px-3 py-1 font-bold"
                style={{
                  border: "1px solid #c1c5ca",
                  padding: "0.75rem",
                  fontWeight: "bold",
                }}
              >
                Visit Type:
              </div>
              <div
                className="border dark:border-slate-700 px-3 py-1"
                style={{
                  border: "1px solid #c1c5ca",
                  padding: "0.75rem",
                  // fontWeight: "bold"
                }}
              >
                {postConsultation[0]?.consultationType === "Q"
                  ? "Quick Consultation"
                  : postConsultation[0]?.consultationType === "V"
                  ? "Video Consultation"
                  : postConsultation[0]?.consultationType === "I"
                  ? "In-person Consultation"
                  : postConsultation[0]?.consultationType === "A"
                  ? "Audio Consultation"
                  : "Quick Consultation"}
              </div>
              <div
                className="border dark:border-slate-700 px-3 py-1 font-bold"
                style={{
                  border: "1px solid #c1c5ca",
                  padding: "0.75rem",
                  fontWeight: "bold",
                }}
              >
                Visit Date:
              </div>
              <div
                className="border dark:border-slate-700 px-3 py-1"
                style={{
                  border: "1px solid #c1c5ca",
                  padding: "0.75rem",
                  // fontWeight: "bold"
                }}
              >
                {moment(postConsultation[0]?.visitDate).format("DD/MM/yyyy")}
              </div>
            </div>

            {/* <img src={''} style={{ width: "25px" }} /> */}

            {/* <div className="grid grid-cols-1 my-2">
                            <p>
                                <b className="underline underline-offset-1">
                                    Chief Complaint:
                                </b>{" "}
                                {postConsultation[0]?.consultationsReason}
                            </p>
                        </div> */}

            {postConsultation[0]?.consultReason && (
              <div
                className="grid grid-cols-1 my-2"
                style={{
                  display: "grid",
                  gridTemplateColumns: "1fr",
                  margin: "0.5rem",
                }}
              >
                <p className="mb-2" style={{ marginBottom: "0.75rem" }}>
                  <b
                    className="underline underline-offset-4"
                    style={{
                      textDecoration: "underline",
                      textDecorationOffset: "1rem",
                    }}
                  >
                    Reason of Consultation:
                  </b>{" "}
                  {postConsultation[0]?.consultReason}
                </p>
              </div>
            )}

            {(postConsultation[0]?.primarySymptoms ||
              postConsultation[0]?.primaryDiagnosis) && (
              <div
                className="grid grid-cols-1 my-2 mb-4"
                style={{
                  display: "grid",
                  gridTemplateColumns: "1fr",
                  margin: "0.5rem 0",
                  marginBottom: "1rem",
                }}
              >
                <p className="">
                  <b
                    className="underline underline-offset-4"
                    style={{
                      textDecoration: "underline",
                      textDecorationOffset: "1rem",
                    }}
                  >
                    Primary Diagnosis :
                  </b>{" "}
                </p>
                <p
                  className="text-base font-montserrat  font-thin "
                  style={{
                    fontSize: "1rem",
                    fontFamily: "Montserrat, sans-serif",
                    fontWeight: 300,
                  }}
                >
                  ICD-10 : {postConsultation[0]?.primarySymptoms}{" "}
                </p>
                <p
                  className="text-base font-montserrat  font-thin "
                  style={{
                    fontSize: "1rem",
                    fontFamily: "Montserrat, sans-serif",
                    fontWeight: 300,
                  }}
                >
                  Description : {postConsultation[0]?.primaryDiagnosis}{" "}
                </p>
              </div>
            )}
            {(postConsultation[0]?.secondarySymptoms ||
              postConsultation[0]?.secondaryDiagnosis) && (
              <div
                className="grid grid-cols-1 my-2"
                style={{
                  display: "grid",
                  gridTemplateColumns: "1fr",
                  marginTop: "0.5rem",
                  marginBottom: "0.5rem",
                }}
              >
                <p className="">
                  <b
                    className="underline underline-offset-4"
                    style={{
                      textDecoration: "underline",
                      textDecorationOffset: "1rem",
                    }}
                  >
                    Secondary Diagnosis :
                  </b>{" "}
                </p>
                <p
                  className="text-base font-montserrat  font-thin "
                  style={{
                    fontSize: "1rem",
                    fontFamily: "Montserrat, sans-serif",
                    fontWeight: 300,
                  }}
                >
                  ICD-10 : {postConsultation[0]?.secondarySymptoms}{" "}
                </p>
                <p
                  className="text-base font-montserrat  font-thin "
                  style={{
                    fontSize: "1rem",
                    fontFamily: "Montserrat, sans-serif",
                    fontWeight: 300,
                  }}
                >
                  Description : {postConsultation[0]?.secondaryDiagnosis}{" "}
                </p>
              </div>
            )}
            {postConsultation[0]?.symptoms && (
              <div className="mb-5" style={{ marginBottom: "1.25rem" }}>
                <p
                  className="underline underline-offset-4"
                  style={{
                    textDecoration: "underline",
                    textDecorationOffset: "1rem",
                  }}
                >
                  <b
                    className="underline underline-offset-4"
                    style={{
                      textDecoration: "underline",
                      textDecorationOffset: "1rem",
                    }}
                  >
                    Symptoms :
                  </b>{" "}
                </p>
                <p
                  className="text-base font-montserrat  font-thin "
                  style={{
                    fontSize: "1rem",
                    fontFamily: "Montserrat, sans-serif",
                    fontWeight: 300,
                  }}
                >
                  {postConsultation[0]?.symptoms}
                </p>
              </div>
            )}
            {postConsultation[0]?.patientVitalList?.length == 0 ? (
              ""
            ) : (
              <>
                {postConsultation[0]?.patientVitalList &&
                  postConsultation[0]?.patientVitalList?.length && (
                    <>
                      <div
                        className="grid grid-cols-1 my-2"
                        style={{ gridColumn: "1", marginTop: "1rem" }}
                      >
                        <p>
                          <b
                            style={{
                              textDecoration: "underline",
                              textDecorationOffset: "1rem",
                            }}
                          >
                            Vitals:
                          </b>{" "}
                          {postConsultation[0]?.patientVitalList[0].height && (
                            <>
                              <span style={{ marginLeft: "1rem" }}>
                                Height -{" "}
                                {
                                  postConsultation[0]?.patientVitalList[0]
                                    .height
                                }{" "}
                                CM
                              </span>
                            </>
                          )}
                          {postConsultation[0]?.patientVitalList[0].weight && (
                            <>
                              <span style={{ marginLeft: "1rem" }}>
                                Weight -{" "}
                                {
                                  postConsultation[0]?.patientVitalList[0]
                                    .weight
                                }{" "}
                                KG
                              </span>
                            </>
                          )}
                          {postConsultation[0]?.patientVitalList[0].bmi && (
                            <>
                              <span style={{ marginLeft: "1rem" }}>
                                BMI -{" "}
                                {postConsultation[0]?.patientVitalList[0].bmi}{" "}
                                KG/M2
                              </span>
                            </>
                          )}
                          {postConsultation[0]?.patientVitalList[0]
                            .systolic && (
                            <>
                              <span style={{ marginLeft: "1rem" }}>
                                Blood Pressure (SYS) -{" "}
                                {
                                  postConsultation[0]?.patientVitalList[0]
                                    .systolic
                                }{" "}
                                mmHg
                              </span>
                            </>
                          )}
                          {postConsultation[0]?.patientVitalList[0]
                            .diastolic && (
                            <>
                              <span style={{ marginLeft: "1rem" }}>
                                Blood Pressure (DIA) -{" "}
                                {
                                  postConsultation[0]?.patientVitalList[0]
                                    .diastolic
                                }{" "}
                                mmHg
                              </span>
                            </>
                          )}
                          {postConsultation[0]?.patientVitalList[0].spo2 && (
                            <>
                              <span style={{ marginLeft: "1rem" }}>
                                Pulse Ox. -{" "}
                                {postConsultation[0]?.patientVitalList[0].spo2}{" "}
                                %
                              </span>
                            </>
                          )}
                          {postConsultation[0]?.patientVitalList[0]
                            .heartRate && (
                            <>
                              <span style={{ marginLeft: "1rem" }}>
                                Heart Rate -{" "}
                                {
                                  postConsultation[0]?.patientVitalList[0]
                                    .heartRate
                                }{" "}
                                Beats/min
                              </span>
                            </>
                          )}
                          {postConsultation[0]?.patientVitalList[0]
                            .respirationRate && (
                            <>
                              <span style={{ marginLeft: "1rem" }}>
                                Respiration Rate -{" "}
                                {
                                  postConsultation[0]?.patientVitalList[0]
                                    .respirationRate
                                }{" "}
                                Breaths/min
                              </span>
                            </>
                          )}
                          {postConsultation[0]?.patientVitalList[0]
                            .temperature && (
                            <>
                              <span style={{ marginLeft: "1rem" }}>
                                Temperature -{" "}
                                {
                                  postConsultation[0]?.patientVitalList[0]
                                    .temperature
                                }{" "}
                                °F
                              </span>
                            </>
                          )}
                        </p>
                      </div>
                    </>
                  )}
              </>
            )}
            <div style={{ marginBottom: "0.5rem" }}>
              {patientDrugPrescriptionList &&
                patientDrugPrescriptionList?.length > 0 && (
                  <p
                    style={{
                      marginBottom: "0.5rem",
                      textDecoration: "underline",
                      textDecorationOffset: "1rem",
                    }}
                  >
                    <b
                      style={{
                        textDecoration: "underline",
                        textDecorationOffset: "1rem",
                      }}
                    >
                      Medicines Prescribed :
                    </b>{" "}
                  </p>
                )}

              <table
                style={{
                  width: "100%",
                  marginBottom: "0.5rem",
                  borderCollapse: "collapse",
                }}
              >
                <thead>
                  <tr>
                    <th className="medicinebox"></th>
                    <th className="medicinebox">Medication</th>
                    <th className="medicinebox">Dosage</th>
                    <th className="medicinebox">Qty</th>
                    <th className="medicinebox">Notes</th>
                    <th className="medicinebox">Instruction</th>
                    <th className="medicinebox">Duration</th>
                  </tr>
                </thead>
                <tbody>
                  {patientDrugPrescriptionList &&
                    patientDrugPrescriptionList?.length > 0 &&
                    patientDrugPrescriptionList?.map((res, i) => (
                      <tr key={i}>
                        <td className="medicinebox">{i + 1}</td>
                        <td className="medicinebox">
                          {res?.drugName}
                          <br></br>
                          <span style={{ fontSize: "0.75rem" }}>
                            {res?.composition}
                          </span>
                        </td>
                        <td className="medicinebox">{res?.dosage}</td>
                        <td className="medicinebox">{res?.quantity}</td>
                        <td className="medicinebox">{res?.notes}</td>
                        <td className="medicinebox">
                          {res.instruction === "0"
                            ? "After Meal"
                            : "Before Meal"}
                        </td>
                        <td className="medicinebox">{res?.duration} days</td>
                      </tr>
                    ))}
                </tbody>
              </table>

              <div style={{ marginTop: "0.5rem", marginBottom: "0.5rem" }}>
                {labTestList && (
                  <p
                    style={{
                      marginBottom: "0.5rem",
                      textDecoration: "underline",
                      textDecorationOffset: "1rem",
                    }}
                  >
                    <b
                      style={{
                        textDecoration: "underline",
                        textDecorationOffset: "1rem",
                      }}
                    >
                      Lab Test Prescribed :
                    </b>{" "}
                  </p>
                )}

                <div style={{ marginTop: "10px" }}>
                  <div
                    style={{
                      display: "grid",
                      gridTemplateColumns: "1fr 1fr",
                      textAlign: "left",
                    }}
                  >
                    <div
                      style={{
                        fontSize: "1rem",
                        fontWeight: "bold",
                        border: "1px solid #c1c5ca",
                        padding: "0.5rem",
                      }}
                    >
                      Test Name
                    </div>
                    <div
                      style={{
                        fontSize: "1rem",
                        fontWeight: "bold",
                        border: "1px solid #c1c5ca",
                        padding: "0.5rem",
                      }}
                    >
                      Test Type
                    </div>
                  </div>
                  {labTestList &&
                    labTestList?.map((labres, i) => (
                      <div
                        key={i}
                        style={{
                          display: "grid",
                          gridTemplateColumns: "1fr 1fr",
                          justifyContent: "space-between",
                        }}
                      >
                        <div
                          style={{
                            fontSize: "0.875rem",
                            border: "1px solid #c1c5ca",
                            padding: "0.5rem",
                          }}
                        >
                          {labres?.labTestName}
                        </div>
                        <div
                          style={{
                            fontSize: "0.875rem",
                            border: "1px solid #c1c5ca",
                            padding: "0.5rem",
                          }}
                        >
                          {labres?.description}
                        </div>
                      </div>
                    ))}
                </div>
              </div>
            </div>

            <div style={{ my: "2rem" }}>
              <div style={{ width: "100%", my: "2rem" }}>
                {postConsultation[0]?.recommendation && (
                  <div style={{ marginBottom: "2rem" }}>
                    <p
                      style={{
                        textDecoration: "underline",
                        textDecorationOffset: "4px",
                      }}
                    >
                      <b
                        style={{
                          textDecoration: "underline",
                          textDecorationOffset: "4px",
                        }}
                      >
                        Recommendation :
                      </b>{" "}
                    </p>
                    <p
                      style={{
                        fontSize: "1rem",
                        fontFamily: "montserrat",
                        fontWeight: "thin",
                      }}
                    >
                      {postConsultation[0]?.recommendation}
                    </p>
                  </div>
                )}

                {postConsultation[0]?.followUpVisitDate && (
                  <div>
                    <p style={{ my: "2rem" }}>
                      <b
                        style={{
                          textDecoration: "underline",
                          textDecorationOffset: "4px",
                        }}
                      >
                        Scheduled follow up visit date :
                      </b>{" "}
                      {moment(postConsultation[0]?.followUpVisitDate).format(
                        "DD/MM/YYYY"
                      )}
                    </p>
                  </div>
                )}
              </div>
            </div>

            {props && props.data ? (
              ""
            ) : (
              <div style={{ display: "flex", justifyContent: "space-between" }}>
                <div style={{ marginTop: "6rem" }}>
                  {!postConsultation[0]?.hospitalStamp ? (
                    ""
                  ) : (
                    <>
                      {stamp && (
                        <img
                          // src={
                          //   process.env.REACT_APP_IMAGE_URL +
                          //   postConsultation[0]?.hospitalStamp
                          // }
                          src={stamp}
                          alt="Stamp"
                          width={150}
                        />
                      )}
                    </>
                  )}
                </div>

                <div style={{ marginTop: "6rem" }}>
                  {signature && (
                    <>
                      <img
                        // src={`${
                        //   process.env.REACT_APP_IMAGE_URL +
                        //   postConsultation[0]?.userSignature
                        // }`}
                        src={signature}
                        alt="Signature"
                        width={150}
                      />
                      <p
                        style={{
                          fontFamily: "rubik",
                          color: "gray",
                          fontSize: "1rem",
                        }}
                      >
                        Dr.
                        {postConsultation[0]?.doctorName
                          ? postConsultation[0].doctorName
                          : postConsultation[0].userName}
                      </p>
                      <p
                        style={{
                          fontFamily: "rubik",
                          color: "gray",
                          fontSize: "1rem",
                        }}
                      >
                        {postConsultation[0]?.userQualification}{" "}
                      </p>
                      <p
                        style={{
                          fontFamily: "rubik",
                          color: "gray",
                          fontSize: "1rem",
                        }}
                      >
                        Regn No. {postConsultation[0]?.userMCIVerification}
                      </p>
                    </>
                  )}
                </div>
              </div>
            )}
          </div>

          <div style={{ display: "grid", gridTemplateColumns: "1fr" }}>
            <div style={{ padding: "0.5rem" }}>
              <p style={{ color: "gray", fontSize: "0.875rem" }}>
                <b>Disclaimer :</b> This is an ONLINE consultation response. The
                patient has not been physically examined. The prescription or
                advice is based on the patient's description of the problem,
                which is given above and also explained over video consultation.
              </p>
            </div>
          </div>

          <div style={{ display: "grid", gridTemplateColumns: "1fr" }}>
            <div style={{ padding: "0.5rem" }}>
              {postConsultation[0]?.consultationType === "I" ? (
                <span></span>
              ) : (
                <p style={{ color: "gray", fontSize: "0.875rem" }}>
                  <b>Disclaimer:</b>
                  <ul style={{ listStyleType: "disc", marginLeft: "1rem" }}>
                    <li>
                      The information and advice provided here is provisional in
                      nature as it is based on the limited information made
                      available by the patient
                    </li>
                    <li>
                      The patient is advised to visit in person for a thorough
                      examination at the earliest
                    </li>
                    <li>
                      The information is confidential in nature and for the
                      recipient's use only
                    </li>
                    <li>The Prescription is generated on a Teleconsultation</li>
                    <li>Not valid for medico-legal purposes</li>
                  </ul>
                </p>
              )}
            </div>
          </div>
        </div>
      </div>

      <div
        style={{
          display: "flex",
          marginBottom: "1rem",
          justifyContent: "flex-end",
        }}
      >
        <div style={{ marginRight: "5px" }}>
          <button
            onClick={Closedata}
            style={{
              background: "transparent",
              fontSize: "1rem",
              borderRadius: "0.5rem",
              marginTop: "1rem",
              color: "#0073E7",
              fontWeight: "500",
              padding: "0.5rem 1.5rem",
              border: "1px solid #0073E7",
              cursor: "pointer",
            }}
          >
            Close
          </button>
        </div>
        <div>
          <button
            onClick={download}
            style={{
              background: "transparent",
              fontSize: "1rem",
              borderRadius: "0.5rem",
              marginTop: "1rem",
              color: "#0073E7",
              fontWeight: "500",
              padding: "0.5rem 1.5rem",
              border: "1px solid #0073E7",
              cursor: "pointer",
            }}
          >
            Download
          </button>
        </div>
      </div>
    </>
  );
}
export default EprescriptionDoc;
