import React from 'react'
import "./index.css";
function inputWithTitleView(props) {
  return (

    
    <div >
    <div className='title'> {props.title}</div>
    <input  className='borderInput' placeholder={props.placeholder}/>

    </div>
  )
}

export default inputWithTitleView
