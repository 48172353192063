export const USER = {
  REQUEST_USEREDIT: "REQUEST_USEREDIT",
  SUCCESS_USEREDIT: "SUCCESS_USEREDIT",
  FAILED_USEREDIT: "FAILED_USEREDIT",

  REQUEST_FEEDBACK: "REQUEST_FEEDBACK",
  SUCCESS_FEEDBACK: "SUCCESS_FEEDBACK",
  FAILED_FEEDBACK: "FAILED_FEEDBACK",

  REQUEST_CONTACTUS: "REQUEST_CONTACTUS",
  SUCCESS_CONTACTUS: "SUCCESS_CONTACTUS",
  FAILED_CONTACTUS: "FAILED_CONTACTUS",

  REQUEST_APPLYCOUPON: "REQUEST_APPLYCOUPON",
  SUCCESS_APPLYCOUPON: "SUCCESS_APPLYCOUPON",
  FAILED_APPLYCOUPON: "FAILED_APPLYCOUPON",


  REQUEST_COUPON_CART: "REQUEST_COUPON_CART",
  SUCCESS_COUPON_CART: "SUCCESS_COUPON_CART",
  FAILED_COUPON_CART: "FAILED_COUPON_CART",


  REQUEST_COUPON_DOCTOR: "REQUEST_COUPON_DOCTOR",
  SUCCESS_COUPON_DOCTOR: "SUCCESS_COUPON_DOCTOR",
  FAILED_COUPON_DOCTOR: "FAILED_COUPON_DOCTOR",



  REQUEST_COUPON_GEN: "REQUEST_COUPON_GEN",
  SUCCESS_COUPON_GEN: "SUCCESS_COUPON_GEN",
  FAILED_COUPON_GEN: "FAILED_COUPON_GEN",



  REQUEST_CUSTOMERLIST: "REQUEST_CUSTOMERLIST",
  SUCCESS_CUSTOMERLIST: "SUCCESS_CUSTOMERLIST",
  FAILED_CUSTOMERLIST: "FAILED_CUSTOMERLIST",

  REQUEST_APPOINTMENT: "REQUEST_APPOINTMENT",
  SUCCESS_APPOINTMENT: "SUCCESS_APPOINTMENT",
  FAILED_APPOINTMENT: "FAILED_APPOINTMENT",

  REQUEST_CHANGEPASSWORD: "REQUEST_CHANGEPASSWORD",
  SUCCESS_CHANGEPASSWORD: "SUCCESS_CHANGEPASSWORD",
  FAILED_CHANGEPASSWORD: "FAILED_CHANGEPASSWORD",


  REQUEST_MYPRISCRIPTION: "REQUEST_MYPRISCRIPTION",
  SUCCESS_MYPRISCRIPTION: "SUCCESS_MYPRISCRIPTION",
  FAILED_MYPRISCRIPTION: "FAILED_MYPRISCRIPTION",

  REQUEST_FAMILYMEMBERS: "REQUEST_FAMILYMEMBERS",
  SUCCESS_FAMILYMEMBERS: "SUCCESS_FAMILYMEMBERS",
  FAILED_FAMILYMEMBERS: "FAILED_FAMILYMEMBERS",


  REQUEST_FAMILYMEMBERS_DELETE: "REQUEST_FAMILYMEMBERS_DELETE",
  SUCCESS_FAMILYMEMBERS_DELETE: "SUCCESS_FAMILYMEMBERS_DELETE",
  FAILED_FAMILYMEMBERS_DELETE :"FAILED_FAMILYMEMBERS_DELETE",

  REQUEST_MYORDERS: "REQUEST_MYORDERS",
  SUCCESS_MYORDERS: "SUCCESS_MYORDERS",
  FAILED_MYORDERS: "FAILED_MYORDERS",

  REQUEST_NOTIFICATIONS: "REQUEST_NOTIFICATIONS",
  SUCCESS_NOTIFICATIONS: "SUCCESS_NOTIFICATIONS",
  FAILED_NOTIFICATIONS: "FAILED_NOTIFICATIONS",

 

  REQUEST_PATLABTESTORDERLIST: "REQUEST_PATLABTESTORDERLIST",
  SUCCESS_PATLABTESTORDERLIST: "SUCCESS_PATLABTESTORDERLIST",
  FAILED_PATLABTESTORDERLIST: "FAILED_PATLABTESTORDERLIST",

  REQUEST_PATLABTESTORDEREPORTLIST: "REQUEST_PATLABTESTORDEREPORTLIST",
  SUCCESS_PATLABTESTORDEREPORTLIST: "SUCCESS_PATLABTESTORDEREPORTLIST",
  FAILED_PATLABTESTORDEREPORTLIST: "FAILED_PATLABTESTORDEREPORTLIST",

  REQUEST_PATIENTADDRESSLIST: "REQUEST_PATIENTADDRESSLIST",
  SUCCESS_PATIENTADDRESSLIST: "SUCCESS_PATIENTADDRESSLIST",
  FAILED_PATIENTADDRESSLIST: "FAILED_PATIENTADDRESSLIST",

  REQUEST_ADDPATIENTADDRESS: "REQUEST_ADDPATIENTADDRESS",
  SUCCESS_ADDPATIENTADDRESS: "SUCCESS_ADDPATIENTADDRESS",
  FAILED_ADDPATIENTADDRESS: "FAILED_ADDPATIENTADDRESS",

  REQUEST_EDITPATIENTADDRESS: "REQUEST_EDITPATIENTADDRESS",
  REQUEST_DELETEPATIENTADDRESS: "REQUEST_DELETEPATIENTADDRESS",
  SUCCESS_DELETEPATIENTADDRESS: "SUCCESS_DELETEPATIENTADDRESS",
  FAILED_DELETEPATIENTADDRESS: "FAILED_DELETEPATIENTADDRESS",

  REQUEST_PATIENTPAYMENTHISTORY: "REQUEST_PATIENTPAYMENTHISTORY",
  SUCCESS_PATIENTPAYMENTHISTORY: "SUCCESS_PATIENTPAYMENTHISTORY",
  FAILED_PATIENTPAYMENTHISTORY: "FAILED_PATIENTPAYMENTHISTORY",

  REQUEST_WALLET: "REQUEST_WALLET",
  SUCCESS_WALLET: "SUCCESS_WALLET",
  SUCCESS_WALLET_TRANS:"SUCCESS_WALLET_TRANS",
  FAILED_WALLET: "FAILED_WALLET",

  REQUEST_ADDALLERGY: "REQUEST_ADDALLERGY",
  SUCCESS_ADDALLERGY: "SUCCESS_ADDALLERGY",
  FAILED_ADDALLERGY: "FAILED_ADDALLERGY",

  REQUEST_ADDMEDICATION: "REQUEST_ADDMEDICATION",
  SUCCESS_ADDMEDICATION: "SUCCESS_ADDMEDICATION",
  FAILED_ADDMEDICATION: "FAILED_ADDMEDICATION",

  REQUEST_ADDMEDICALHISTORY: "REQUEST_ADDMEDICALHISTORY",
  SUCCESS_ADDMEDICALHISTORY: "SUCCESS_ADDMEDICALHISTORY",
  FAILED_ADDMEDICALHISTORY: "FAILED_ADDMEDICALHISTORY",

  REQUEST_UPLOADPRESCRIPTION: "REQUEST_UPLOADPRESCRIPTION",
  SUCCESS_UPLOADPRESCRIPTION: "SUCCESS_UPLOADPRESCRIPTION",
  FAILED_UPLOADPRESCRIPTION: "FAILED_UPLOADPRESCRIPTION",

  REQUEST_E_MYPRISCRIPTION: "REQUEST_E_MYPRISCRIPTION",
  SUCCESS_E_MYPRISCRIPTION: "SUCCESS_E_MYPRISCRIPTION",
  FAILED_E_MYPRISCRIPTION: "FAILED_E_MYPRISCRIPTION",

  CLEAR_PRESCRIPTION: "CLEAR_PRESCRIPTION",

  //My Profile Details 

  REQUEST_ALLERGYLIST: "REQUEST_ALLERGYLIST",
  SUCCESS_ALLERGYLIST: "SUCCESS_ALLERGYLIST",
  FAILED_ALLERGYLIST: "FAILED_ALLERGYLIST",

  REQUEST_MEDICATIONLIST: "REQUEST_MEDICATIONLIST",
  SUCCESS_MEDICATIONLIST: "SUCCESS_MEDICATIONLIST",
  FAILED_MEDICATIONLIST: "FAILED_MEDICATIONLIST",

  REQUEST_MEDICALHISTORYLIST: "REQUEST_MEDICALHISTORYLIST",
  SUCCESS_MEDICALHISTORYLIST: "SUCCESS_MEDICALHISTORYLIST",
  FAILED_MEDICALHISTORYLIST: "FAILED_MEDICALHISTORYLIST",

  REQUEST_SURGICALHISTORYLIST: "REQUEST_SURGICALHISTORYLIST",
  SUCCESS_SURGICALHISTORYLIST: "SUCCESS_SURGICALHISTORYLIST",
  FAILED_SURGICALHISTORYLIST: "FAILED_SURGICALHISTORYLIST",


  REQUEST_FAMILYHISTORYLIST: "REQUEST_FAMILYHISTORYLIST",
  SUCCESS_FAMILYHISTORYLIST: "SUCCESS_FAMILYHISTORYLIST",
  FAILED_FAMILYHISTORYLIST: "FAILED_FAMILYHISTORYLIST",
  
  REQUEST_SOCIALHISTORYLIST: "REQUEST_SOCIALHISTORYLIST",
  SUCCESS_SOCIALHISTORYLIST: "SUCCESS_SOCIALHISTORYLIST",
  FAILED_SOCIALHISTORYLIST: "FAILED_SOCIALHISTORYLIST",

  REQUEST_MEDICATIONHISTORYLIST: "REQUEST_MEDICATIONHISTORYLIST",
  SUCCESS_MEDICATIONHISTORYLIST: "SUCCESS_MEDICATIONHISTORYLIST",
  FAILED_MEDICATIONHISTORYLIST: "FAILED_MEDICATIONHISTORYLIST",

  REQUEST_VITALSLIST: "REQUEST_VITALSLIST",
  SUCCESS_VITALSLIST: "SUCCESS_VITALSLIST",
  FAILED_VITALSLIST: "FAILED_VITALSLIST",

  REQUEST_CHRONICCONDITIONSLIST: "REQUEST_CHRONICCONDITIONSLIST",
  SUCCESS_CHRONICCONDITIONSLIST: "SUCCESS_CHRONICCONDITIONSLIST",
  FAILED_CHRONICCONDITIONSLIST: "FAILED_CHRONICCONDITIONSLIST",

  REQUEST_PATIENTCHRONICCONDITIONSLIST: "REQUEST_PATIENTCHRONICCONDITIONSLIST",
  SUCCESS_PATIENTCHRONICCONDITIONSLIST: "SUCCESS_PATIENTCHRONICCONDITIONSLIST",
  FAILED_PATIENTCHRONICCONDITIONSLIST: "FAILED_PATIENTCHRONICCONDITIONSLIST",

  REQUEST_MYREQUESTPATIENT: "REQUEST_MYREQUESTPATIENT",
  SUCCESS_MYREQUESTPATIENT: "SUCCESS_MYREQUESTPATIENT",
  FAILED_MYREQUESTPATIENT: "FAILED_MYREQUESTPATIENT",

  REQUEST_PATIENTMYORDERS: "REQUEST_PATIENTMYORDERS",
  SUCCESS_PATIENTMYORDERS: "SUCCESS_PATIENTMYORDERS",
  FAILED_PATIENTMYORDERS: "FAILED_PATIENTMYORDERS",

  CLEAR_POST_CONSULT: 'CLEAR_POST_CONSULT',

  REQUEST_RESETPASSWORD: "REQUEST_RESETPASSWORD",
  SUCCESS_RESETPASSWORD: "SUCCESS_RESETPASSWORD",
  FAILED_RESETPASSWORD: "FAILED_RESETPASSWORD",

  REQUEST_RESETPASSWORDOTP: "REQUEST_RESETPASSWORDOTP",
  SUCCESS_RESETPASSWORDOTP: "SUCCESS_RESETPASSWORDOTP",
  FAILED_RESETPASSWORDOTP: "FAILED_RESETPASSWORDOTP",

};
