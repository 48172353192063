import React from "react";
import RowBody from "./RowBody";
import Loader from '../../components/Loader/Loader'
import NoDataFound from "../../components/NoDataFound/NoDataFound";



function MedicineOrderList({onClickOrderIdMain,medicineList,doctorConsultation,isSelectedOption,onInFiniteScrollFinal,onClickAddCommentMain,isLoader}) {

  const onClickOrderIdChild=(item)=>{
    onClickOrderIdMain(item)
  }

  const onClickAddCommentChild=(item)=>{

    onClickAddCommentMain(item)

  }
const onInFiniteScroll=()=>{

  onInFiniteScrollFinal()

  
}


  return (
    <div className="listContainer">
      <div className="listBorder">
        {doctorConsultation.map((item,i) => (
          <div key={i}>


          <p  className="listTitle">{item} </p>
          </div>
          
        ))}
       
      </div>
 
        <RowBody medicineList={medicineList}  isSelectedOption={isSelectedOption} onClickOrderIdNew={onClickOrderIdChild} onInFiniteScroll={onInFiniteScroll} onClickAddCommentNew={onClickAddCommentChild}/>

        {isLoader != false  && <Loader />}
      {medicineList.length == 0 && !isLoader && <NoDataFound />}
      
    </div>
  );
}

export default MedicineOrderList;
