import React from "react";
import { useState, useEffect } from "react";
import axios from "axios";
import moment from "moment";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import DatePicker from "react-datepicker";
import { Dropdown } from "primereact/dropdown";
import "./event.css";
import { debounce } from "lodash";
import { TabView, TabPanel } from "primereact/tabview";
import { Button } from "primereact/button";
import { ToastContainer, toast } from "react-toastify";
import { useNavigate, useLocation } from "react-router-dom";
import http from "../../../Redux/services/http-common"

function Event() {

  const location = useLocation();

  const selectedDashBe = location.state?.selectedDashBe;
  const datesearchBe = location.state?.datesearchBe;
  const monthSearchBe = location.state?.monthSearchBe;
  const yearSearchBe = location.state?.yearSearchBe;
  const formattedMonthYearBe = location.state?.formattedMonthYearBe;
  const formatstartDateBe = location.state?.formatstartDateBe;
  const formatendDateBe = location.state?.formatendDateBe;

  const [eventdata, setEventdata] = useState();
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());
  // const [endDate, setEndDate] = useState(new Date(new Date().getTime() + 24 * 60 * 60 * 1000));
  const [selecteDate, setSelectedDate] = useState();
  const [subscriptionapi, setSubscriptionapi] = useState();
  const [planapi, setPlanapi] = useState();
  const [preassment, setPreassment] = useState();
  const [searchName, setSearchName] = useState();
  const [searchNames, setSearchNames] = useState();
  const [searchMobile, setSearchMobile] = useState();
  const [searchResults, setSearchResults] = useState([]);
  const [activeIndex, setActiveIndex] = useState(0);
  const [doctorapp, setdoctorapp] = useState();
  const [labapp, setLabapp] = useState();
  const [nurseapp, setNurseapp] = useState();
  const [selectdocList, setSelectdocList] = useState(null);
  const [alleclinic, setAllclinic] = useState();
  const [selecteclinic, setSelecteclinic] = useState(null);
  const [doctortlistApi, setDoctortlistApi] = useState();
  const [doclist, setDocList] = useState("");
  const [seoid, setSeoid] = useState();
  const [selectlabList, setSelectlabList] = useState(null);
  const [lablistApi, setlablistApi] = useState();
  const [lablocationId, setlablocationId] = useState();
  const [selectStatus, setSelectStatus] = useState("Yet to Start");
 
  const [serviceprovider, setServiceprovider] = useState();
  const [nurselistApi, setnurselistApi] = useState();

  const handleStatusChange = (rowId, status) => {
    setSelectStatus((prevStatus) => ({
      ...prevStatus,
      [rowId]: status,
    }));
  };

  const dropdownOptions = [
    { label: "Appointment Booked", value: "Appointment Booked" },
    // { label: 'Subscription', value: 'Subscription' },
    { label: "Plan Purchased", value: "Plan Purchased" },
    { label: "Pre Assessment", value: "Pre Assessment" },
    { label: "Incomplete", value: "Incomplete" },
  ];
  const [selectedOption, setSelectedOption] = useState(dropdownOptions[0]);

  const handleStartDateChange = (date) => {
    setStartDate(date);
  };
  const handleEndDateChange = (date) => {
    setEndDate(date);
  };
  
 
  useEffect(() => {
    if (selectedDashBe) {
      setSelectedOption( dropdownOptions[3]);
    }
  }, [selectedDashBe]);






  

  useEffect(() => {
    http
      .post(
        `${process.env.REACT_APP_MEDICARE_BASEURL}location/getAllEclinics`,
        {}
      )
      .then((res) => {
        setAllclinic(res.data.succesObject);

        console.log(res.data.succesObject, "alleclinics");
      })
      .catch((err) => {
        console.log(err, "Something went wrong1");
      });
  }, []);
  const formatstartDate = startDate
    ? moment(startDate).format("YYYY-MM-DD")
    : "";
  const formatendDate = endDate ? moment(endDate).format("YYYY-MM-DD") : "";
  useEffect(() => {
    if (selectedOption) {
      switch (selectedOption.value) {
        case "Appointment Booked":
          callAPI1();
          break;
        case "Subscription":
          callAPI2();
          break;
        case "Plan Purchased":
          callAPI3();
          break;
        case "Pre Assessment":
          callAPI4();
          break;
        case "Incomplete":
          callAPI1();
          break;

        default:
          break;
      }
    }
  }, [selectedOption, formatstartDate, formatendDate]);

  const handleOptionChange = (e) => {
    const selectedValue = e.value;
    const selectedOption = dropdownOptions.find(
      (dropdownOptions) => dropdownOptions.value === selectedValue
    );
    setSelectedOption(selectedOption);
  };

  const callAPI2 = () => {
    let requestBody = {};
    requestBody = {
      fromDate: formatstartDate,
      toDate: formatendDate,
    };
    http
      .get(`${process.env.REACT_APP_BASEURL}medicare/businessSubscription`, {
        params: requestBody,
      })

      .then((res) => {
        console.log(res.data, "event");

        setSubscriptionapi(res.data);
      })
      .catch((err) => {
        console.log(err, "Something went wrong1");
      });

    console.log("Calling API for Option 1");
  };

  const callAPI3 = () => {
    let requestBody = {};
    requestBody = {
      fromDate: formatstartDate,
      toDate: formatendDate,
    };
    http
      .post(
        `${process.env.REACT_APP_BASEURL}medicare/businessPlanDetails`,
        requestBody
      )

      .then((res) => {
        console.log(res.data, "event123");
        setPlanapi(res.data);

        // setEventdata(res.data);
      })
      .catch((err) => {
        console.log(err, "Something went wrong1");
      });
    console.log("Calling API for Option 2");
  };

  const callAPI4 = () => {
    let requestBody = {};
    requestBody = {
      fromDate: formatstartDate,
      toDate: formatendDate,
    };
    http
      .post(
        `${process.env.REACT_APP_BASEURL}medicare/getPreassessmentForChildAndParent`,
        requestBody
      )
      .then((res) => {
        console.log(res.data, "event123");
        setPreassment(res.data);

        // setEventdata(res.data);
      })
      .catch((err) => {
        console.log(err, "Something went wrong1");
      });
    console.log("Calling API for Option 2");
  };

  const callAPI1 = () => {
    switch (activeIndex) {
      case 0: // Doctor Appointment Tab
        callDoctorAppointmentAPI();
        break;
      case 1: // Lab Appointment Tab
        callLabAppointmentAPI();

        break;
      case 2: // Lab Appointment Tab
        callNurseAppointmentAPI();

        break;
      default:
        break;
    }
  };

  useEffect(() => {
    if (activeIndex === 1) {
      callLabAppointmentAPI();
    }
  }, [activeIndex, selectlabList]);

  useEffect(() => {
    if (activeIndex === 2) {
      callNurseAppointmentAPI();
    }
  }, [activeIndex,serviceprovider]);

  useEffect(() => {
    if (activeIndex === 0) {
      callDoctorAppointmentAPI();
    }
  }, [activeIndex, selectdocList]);
  const doctsearch = searchName === "" ? "" : searchName;
  console.log(doctsearch, "fffgghgh");

  const formattedMonth = selecteDate
    ? moment(selecteDate).format("YYYY-MM-DD")
    : "";

  const callDoctorAppointmentAPI = () => {
    let requestBodynew = {};
    if (selectedOption.value === "Appointment Booked") {
      // if (formatstartDate === "") {
      // requestBodynew = {
      //   patientName: doctsearch,
      // userId:selectdocList,
      // serviceStatus:"2"
      // };
      // }
      // else{
      requestBodynew = {
        patientName: doctsearch,
        fromDate: formatstartDate,
        toDate: formatendDate,
        userId: selectdocList,
        serviceStatus: "2",
        
      };
    
      // }
    } else if (selectedOption.value === "Incomplete") {
      // if (formattedMonth === "") {
      //   requestBodynew = {
      //     patientName: doctsearch,
      //   userId:selectdocList,
      //   serviceStatus:"0"
      //   };
      // }
      // else{
     // Assuming requestBodynew is declared somewhere in your code

// Common properties
const commonProperties = {
  patientName: doctsearch,
  userId: selectdocList,
  serviceStatus: "0",
  fromDate: selectedDashBe ? "" : formatstartDate,
  toDate:selectedDashBe ? "" :  formatendDate,
};

// Payload properties
const payloadProperties = {};

if (selectedDashBe === "Today") {
  payloadProperties.type = "T";
  payloadProperties.date = datesearchBe;
} else if (selectedDashBe === "Month") {
  payloadProperties.type = "M";
  payloadProperties.month = monthSearchBe;
  payloadProperties.year = formattedMonthYearBe;
} else if (selectedDashBe === "Date") {
  payloadProperties.type = "D";
  payloadProperties.fromDate = formatstartDateBe;
  payloadProperties.toDate = formatendDateBe;
} else if (selectedDashBe === "Year") {
  payloadProperties.type = "Y";
  payloadProperties.year = yearSearchBe;
}

// Combine common and payload properties
requestBodynew = {
  ...commonProperties,
  ...payloadProperties,
};

// Now, requestBodynew contains all properties at the top level

        
      // }
    }

    http
      .post(
        `${process.env.REACT_APP_BASEURL}medicare/getDoctorAppointments`,
        requestBodynew
      )
      .then((res) => {
        console.log(res.data, "event123");
        setdoctorapp(res.data);

        // setEventdata(res.data);
      })
      .catch((err) => {
        console.log(err, "Something went wrong1");
      });
  };

  const callLabAppointmentAPI = () => {
    let requestBodynew = {};
    if (selectedOption.value === "Appointment Booked") {
      requestBodynew = {
        patientName: doctsearch,
        fromDate: formatstartDate,
        toDate: formatendDate,
        hospitalName: selectlabList,
        serviceStatus: "2",
      };
    } else if (selectedOption.value === "Incomplete") {
      requestBodynew = {
        patientName: doctsearch,
        fromDate: formatstartDate,
        toDate: formatendDate,
        hospitalName: selectlabList,
        serviceStatus: "0",
      };
    }
    http
      .post(
        `${process.env.REACT_APP_BASEURL}medicare/getLabAppointments`,
        requestBodynew
        // {
        //   patientName: doctsearch,
        //   whenAppointment: formattedMonth
        // }
      )
      .then((res) => {
        console.log(res.data, "mnbvcx");
        setLabapp(res.data);
      })
      .catch((err) => {
        console.log(err, "Something went wrong1");
      });
  };

  const callNurseAppointmentAPI = () => {
    let requestBodynew = {};
    if (selectedOption.value === "Appointment Booked") {
      requestBodynew = {
        patientName: doctsearch,
        fromDate: formatstartDate,
        toDate: formatendDate,
        hospitalName: selectlabList,
        userId:serviceprovider?.id,
        serviceStatus: "2",
      };
    }
    if (selectedOption.value === "Incomplete") {
      requestBodynew = {
        patientName: doctsearch,
        fromDate: formatstartDate,
        toDate: formatendDate,

        hospitalName: selectlabList,
        userId:serviceprovider?.id,
        serviceStatus: "0",
      };
    }
    http
      .post(
        `${process.env.REACT_APP_BASEURL}medicare/getNurseAppointments`,
        requestBodynew
      )
      .then((res) => {
        console.log(res.data, "mnbvcx");
        setNurseapp(res.data);
      })
      .catch((err) => {
        console.log(err, "Something went wrong1");
      });
  };

  console.log(activeIndex, "uytiop");

  const handleSearchChange = (e) => {
    if (/^(0|[1-9][0-9]*)$/.test(e.target.value)) {
      setSearchMobile(e.target.value);
      setSearchName("");
    } else {
      // setSearchName(value);
      setSearchName(e.target.value);
      setSearchMobile("");
      callDoctorAppointmentAPI();
      callLabAppointmentAPI();
    }
  };

  useEffect(() => {
    if (startDate && endDate) {
      callDoctorAppointmentAPI();
      callLabAppointmentAPI();
      callNurseAppointmentAPI();
    }
  }, [startDate, endDate]);

  const dateSearchApi = (date) => {
    setSelectedDate(date);
  };

  const handleSearchPlanpurchased = () => {
    // e.prveventDefult();
    // alert("hii");
    console.log(searchName, "name");

    http
      .get(
        `${process.env.REACT_APP_BASEURL}medicare/searchPlanPurchasedByNameAndMobile?receiverName=${searchName}&receiverMobile=${searchMobile}`
      )

      .then((res) => {
        console.log(res.data, "search");
        setPlanapi(res.data);
      })

      .catch((err) => {
        console.log(err, "Something went wrong");
      });
  };

  const handleSearchAppointment = (e) => {
    e.prveventDefult();
    // alert("hii");
    if (activeIndex === 0) {
      callDoctorAppointmentAPI();
    } else if (activeIndex === 1) {
      callLabAppointmentAPI();
    } else if (activeIndex === 2) {
      callNurseAppointmentAPI();
    }
  };

  console.log(searchName, searchMobile, "search");
  // useEffect(() => {
  //   // Call the API for the first option by default
  //   if (dropdownOptions.length > 0) {
  //     setSelectedOption(dropdownOptions[0]);
  //   }
  // }, []);
  const dropdownEclinic = alleclinic?.map((item) => ({
    label: item?.hospitalCode,
    value: {
      name: item?.hospitalCode,
      loc: item?.locationCode,
    },
  }));

  const onFilter = (event) => {
    if (event.query) {
      const filteredOptions = dropdownEclinic.filter((option) =>
        option.label.toLowerCase().includes(event.query.toLowerCase())
      );
      setSelecteclinic(filteredOptions);
    } else {
      setSelecteclinic(dropdownEclinic);
    }
  };
  useEffect(() => {
    http
      .get(
        `${process.env.REACT_APP_ELASTIC_BASEURL}doctors?freeTextSearch=&pageNo=1&pageSize=100`
      )
      .then((res) => {
        setDoctortlistApi(res.data);

        let docList = res.data.map((obj) => {
          return obj.userName;
        });
        // let planids = res.data.map((obj) => {

        //   return (obj.planId)

        // })
        setDocList(docList);

        let seoId = res.data.map((obj) => {
          return obj.seoNameId;
        });
        setSeoid(seoId);

        console.log(res.data, "qwertymnbvcx");
      })
      .catch((err) => {
        console.log(err, "Something went wrong1");
      });
  }, []);

  const dropdownOptionStatus = [
    // { name: "Yet to Start", value: "1" },
    { name: "Booked", value: "2" },
    { name: "Completed", value: "3" },
  ];
  const dropdownOptionsnew = doctortlistApi?.map((item) => ({
    label: item?.userName,
    value: item?.userId,
    // value: {
    //     id: item?.seoNameId,
    //     name: item?.userName,

    //     email: item?.email,
    //     code: item?.userId,
    //     number: item?.contactNo,

    // }
  }));
  useEffect(() => {
    http
      .get(
        `${process.env.REACT_APP_ELASTIC_BASEURL}labs?latitude=20.2960587&longitude=85.8245398&source=b2c`
      )
      .then((res) => {
        setlablistApi(res.data);

        let locationIdLab = res.data.map((obj) => {
          return obj.locationId;
        });

        setlablocationId(locationIdLab);

        // let seoId = res.data.map((obj) => {

        //     return (obj.seoNameId)

        // })
        // setSeoid(seoId)

        console.log(res.data, "qwertymnbvcx");
      })
      .catch((err) => {
        console.log(err, "Something went wrong1");
      });
  }, []);
  const dropdownOptionsLab = lablistApi?.map((item) => ({
    label: item?.labName,
    value: item?.labName,
    // value: {
    //     id: item?.locationId,

    // email: item?.email,
    // code: item?.userId,
    // number: item?.contactNo,

    // }
  }));
  const onFilterLab = (event) => {
    if (event.query) {
      const filteredOptionsLab = dropdownOptionsLab.filter((option) =>
        option.label.toLowerCase().includes(event.query.toLowerCase())
      );
      setSelectlabList(filteredOptionsLab);
    } else {
      setSelectlabList(dropdownOptionsLab);
    }
  };

  const handleSubmit = (rowData) => {
    const updatedStatus = selectStatus[rowData.prepaSeqId];
    console.log(updatedStatus, "updatedStatus");
    http
      .post(`${process.env.REACT_APP_BASEURL}medicare/updatePreAssesment`, {
        parentStatus: updatedStatus,
        prepaSeqId: rowData.prepaSeqId,
        prechSeqId: rowData.prechSeqId,
        giverEmail: rowData.giverEmail,
        giverName: rowData.giverName,
      })
      .then((res) => {
        console.log(res, "yes");
        toast(" Updated successfully");
        callAPI4();
      })
      .catch((err) => {
        console.log(err, "Something went wrong2");
      });
  };

  const handleStatus = (e) => {
    setSelectStatus(e.target.value);
  };
  console.log(selectlabList, "selectdocList");
  const handleSearchChanges = (e) => {
    setSearchNames(e.target.value);
  };

  useEffect(() => {
    http
      .get(
        `${process.env.REACT_APP_BASEURL}user/list/filter?roleCode=AH&status=1`
      )
      .then((res) => {
        setnurselistApi(res.data);
      })
      .catch((err) => {
        console.log(err, "Something went wrong1");
      });
  }, []);
  
  const dropdownOptionsNurse = nurselistApi?.map((item) => ({
    label: item?.userName,
    value: {
      id: item?.code,
      name: item?.userName,
  
      email: item?.email,
      code: item?.userId,
      number: item?.mobile,
    },
  }));

  return (
    <>
      <ToastContainer />
      {/* <div style={{ display: "flex ", backgroundColor: "white" }}>
        <div className='headingevent' style={{ marginLeft: "10px" }}>BUSINESS EVENT :</div>
      </div> */}

      <div
        style={{
          display: "flex ",
          gap: "20px",
          marginLeft: "20px",
          paddingTop: "15px",
        }}
      >
        {selectedOption.value === "Plan Purchased" && (
          <form>
            <div style={{ display: "flex", gap: "20px" }}>
              <div
                className="searchbar"
                style={{ marginTop: "10px" }}
                title="Search by Name and number"
                onChange={(e) => handleSearchPlanpurchased(e)}
              >
                <input
                  type="text"
                  className="placeholder"
                  value={searchName || searchMobile}
                  onChange={(e) => handleSearchChange(e)}
                  placeholder="Search by Name"
                  name="search"
                ></input>

                <i
                  class="fa fa-search"
                  aria-hidden="true"
                  className="navImg"
                ></i>
              </div>

              <div title="Search by date">
                <DatePicker
                  selected={startDate}
                  onChange={handleStartDateChange}
                  selectsStart
                  startDate={startDate}
                  endDate={endDate}
                  placeholderText="Start Date"
                  className="datepicker1"
                  customInput={
                    <div
                      style={{
                        position: "relative",
                        display: "flex",
                        flexDirection: "column",
                        marginRight: "20px",
                        border: "1px solid #ced4da",
                        borderRadius: "6px",
                        paddingBottom: "3px",
                        paddingTop: "3px",
                      }}
                    >
                      <div style={{ marginTop: "10px" }}>
                        <input
                          type="text"
                          className="formcontroldash"
                          style={{ color: "#6c757d", border: "none" }}
                          value={
                            startDate
                              ? moment(startDate).format("DD-MM-YYYY")
                              : "Start Date" // Placeholder text with default values
                          }
                          readOnly
                        />
                      </div>

                      <img
                        src="images/calander.svg"
                        alt="Calendar"
                        style={{
                          position: "absolute",
                          right: "21px",
                          top: "10px",
                          cursor: "pointer",
                        }}
                      />
                    </div>
                  }
                />
              </div>
              <div title="Search by date">
                <DatePicker
                  selected={endDate}
                  onChange={handleEndDateChange}
                  selectsEnd
                  startDate={startDate}
                  endDate={endDate}
                  placeholderText="End Date"
                  minDate={startDate}
                  className="datepicker1"
                  customInput={
                    <div
                      style={{
                        position: "relative",
                        display: "flex",
                        flexDirection: "column",
                        border: "1px solid #ced4da",
                        borderRadius: "6px",
                        paddingBottom: "3px",
                        paddingTop: "3px",
                      }}
                    >
                      <div style={{ marginTop: "10px" }}>
                        <input
                          type="text"
                          className="formcontroldash"
                          style={{ color: "#6c757d", border: "none" }}
                          value={
                            endDate ? moment(endDate).format("DD-MM-YYYY") : ""
                          }
                          readOnly
                        />
                      </div>

                      <img
                        src="images/calander.svg"
                        alt="Calendar"
                        style={{
                          position: "absolute",
                          right: "21px",
                          top: "10px",
                          cursor: "pointer",
                        }}
                      />
                    </div>
                  }
                />
              </div>
              {/* <button className="buttn" type="submit" >Search</button> */}
            </div>
          </form>
        )}

        {selectedOption.value === "Subscription" && (
          <form>
            <div style={{ display: "flex", gap: "20px" }}>
              <div
                className="searchbar"
                style={{ marginTop: "10px" }}
                title="Search by Name and number"
                onChange={(e) => handleSearchPlanpurchased(e)}
              >
                <input
                  type="text"
                  className="placeholder"
                  value={searchName || searchMobile}
                  onChange={(e) => handleSearchChange(e)}
                  placeholder="Search by Name"
                  name="search"
                ></input>

                <i
                  class="fa fa-search"
                  aria-hidden="true"
                  className="navImg"
                ></i>
              </div>

              <div title="Search by date">
                <DatePicker
                  selected={startDate}
                  onChange={handleStartDateChange}
                  selectsStart
                  startDate={startDate}
                  endDate={endDate}
                  placeholderText="Start Date"
                  className="datepicker1"
                  customInput={
                    <div
                      style={{
                        position: "relative",
                        display: "flex",
                        flexDirection: "column",
                        marginRight: "20px",
                        border: "1px solid #ced4da",
                        borderRadius: "6px",
                        paddingBottom: "3px",
                        paddingTop: "3px",
                      }}
                    >
                      <div style={{ marginTop: "10px" }}>
                        <input
                          type="text"
                          className="formcontroldash"
                          style={{ color: "#6c757d", border: "none" }}
                          value={
                            startDate
                              ? moment(startDate).format("DD-MM-YYYY")
                              : "Start Date" // Placeholder text with default values
                          }
                          readOnly
                        />
                      </div>

                      <img
                        src="images/calander.svg"
                        alt="Calendar"
                        style={{
                          position: "absolute",
                          right: "21px",
                          top: "10px",
                          cursor: "pointer",
                        }}
                      />
                    </div>
                  }
                />
              </div>
              <div title="Search by date">
                <DatePicker
                  selected={endDate}
                  onChange={handleEndDateChange}
                  selectsEnd
                  startDate={startDate}
                  endDate={endDate}
                  placeholderText="End Date"
                  minDate={startDate}
                  className="datepicker1"
                  customInput={
                    <div
                      style={{
                        position: "relative",
                        display: "flex",
                        flexDirection: "column",
                        border: "1px solid #ced4da",
                        borderRadius: "6px",
                        paddingBottom: "3px",
                        paddingTop: "3px",
                      }}
                    >
                      <div style={{ marginTop: "10px" }}>
                        <input
                          type="text"
                          className="formcontroldash"
                          style={{ color: "#6c757d", border: "none" }}
                          value={
                            endDate ? moment(endDate).format("DD-MM-YYYY") : ""
                          }
                          readOnly
                        />
                      </div>

                      <img
                        src="images/calander.svg"
                        alt="Calendar"
                        style={{
                          position: "absolute",
                          right: "21px",
                          top: "10px",
                          cursor: "pointer",
                        }}
                      />
                    </div>
                  }
                />
              </div>
              {/* <button className="buttn" type="submit" >Search</button> */}
            </div>
          </form>
        )}

        {selectedOption.value === "Pre Assessment" && (
          <form>
            <div style={{ display: "flex", gap: "20px" }}>
              <div
                className="searchbar"
                style={{ marginTop: "10px" }}
                title="Search by Name and number"
                onChange={(e) => handleSearchPlanpurchased(e)}
              >
                <input
                  type="text"
                  className="placeholder"
                  value={searchName || searchMobile}
                  onChange={(e) => handleSearchChange(e)}
                  placeholder="Search by Name"
                  name="search"
                ></input>

                <i
                  class="fa fa-search"
                  aria-hidden="true"
                  className="navImg"
                ></i>
              </div>

              <div title="Search by date">
                <DatePicker
                  selected={startDate}
                  onChange={handleStartDateChange}
                  selectsStart
                  startDate={startDate}
                  endDate={endDate}
                  placeholderText="Start Date"
                  className="datepicker1"
                  customInput={
                    <div
                      style={{
                        position: "relative",
                        display: "flex",
                        flexDirection: "column",
                        marginRight: "20px",
                        border: "1px solid #ced4da",
                        borderRadius: "6px",
                        paddingBottom: "3px",
                        paddingTop: "3px",
                      }}
                    >
                      <div style={{ marginTop: "10px" }}>
                        <input
                          type="text"
                          className="formcontroldash"
                          style={{ color: "#6c757d", border: "none" }}
                          value={
                            startDate
                              ? moment(startDate).format("DD-MM-YYYY")
                              : "Start Date" // Placeholder text with default values
                          }
                          readOnly
                        />
                      </div>

                      <img
                        src="images/calander.svg"
                        alt="Calendar"
                        style={{
                          position: "absolute",
                          right: "21px",
                          top: "10px",
                          cursor: "pointer",
                        }}
                      />
                    </div>
                  }
                />
              </div>
              <div title="Search by date">
                <DatePicker
                  selected={endDate}
                  onChange={handleEndDateChange}
                  selectsEnd
                  startDate={startDate}
                  endDate={endDate}
                  placeholderText="End Date"
                  minDate={startDate}
                  className="datepicker1"
                  customInput={
                    <div
                      style={{
                        position: "relative",
                        display: "flex",
                        flexDirection: "column",
                        border: "1px solid #ced4da",
                        borderRadius: "6px",
                        paddingBottom: "3px",
                        paddingTop: "3px",
                      }}
                    >
                      <div style={{ marginTop: "10px" }}>
                        <input
                          type="text"
                          className="formcontroldash"
                          style={{ color: "#6c757d", border: "none" }}
                          value={
                            endDate ? moment(endDate).format("DD-MM-YYYY") : ""
                          }
                          readOnly
                        />
                      </div>

                      <img
                        src="images/calander.svg"
                        alt="Calendar"
                        style={{
                          position: "absolute",
                          right: "21px",
                          top: "10px",
                          cursor: "pointer",
                        }}
                      />
                    </div>
                  }
                />
              </div>
              {/* <button className="buttn" type="submit" >Search</button> */}
            </div>
          </form>
        )}

        {selectedOption.value === "Appointment Booked" && (
          <>
            <div
              className="searchbar"
              title="Search by name"
              style={{ marginTop: "10px" }}
              onChange={(e) => handleSearchAppointment(e)}
            >
              <input
                type="text"
                className="placeholder"
                value={searchName}
                onChange={(e) => handleSearchChange(e)}
                placeholder=" Search by Patient Name"
                name="search"
              ></input>
            </div>

            <div title="Search by date">
              <DatePicker
                selected={startDate}
                onChange={handleStartDateChange}
                selectsStart
                startDate={startDate}
                endDate={endDate}
                placeholderText="Start Date"
                className="datepicker1"
                customInput={
                  <div
                    style={{
                      position: "relative",
                      display: "flex",
                      flexDirection: "column",
                      marginRight: "20px",
                      border: "1px solid #ced4da",
                      borderRadius: "6px",
                      paddingBottom: "3px",
                      paddingTop: "3px",
                    }}
                  >
                    <div style={{ marginTop: "10px" }}>
                      <input
                        type="text"
                        className="formcontroldash"
                        style={{ color: "#6c757d", border: "none" }}
                        value={
                          startDate
                            ? moment(startDate).format("DD-MM-YYYY")
                            : "Start Date" // Placeholder text with default values
                        }
                        readOnly
                      />
                    </div>

                    <img
                      src="images/calander.svg"
                      alt="Calendar"
                      style={{
                        position: "absolute",
                        right: "21px",
                        top: "10px",
                        cursor: "pointer",
                      }}
                    />
                  </div>
                }
              />
            </div>
            <div title="Search by date">
              <DatePicker
                selected={endDate}
                onChange={handleEndDateChange}
                selectsEnd
                startDate={startDate}
                endDate={endDate}
                placeholderText="End Date"
                minDate={startDate}
                className="datepicker1"
                customInput={
                  <div
                    style={{
                      position: "relative",
                      display: "flex",
                      flexDirection: "column",
                      border: "1px solid #ced4da",
                      borderRadius: "6px",
                      paddingBottom: "3px",
                      paddingTop: "3px",
                    }}
                  >
                    <div style={{ marginTop: "10px" }}>
                      <input
                        type="text"
                        className="formcontroldash"
                        style={{ color: "#6c757d", border: "none" }}
                        value={
                          endDate ? moment(endDate).format("DD-MM-YYYY") : ""
                        }
                        readOnly
                      />
                    </div>

                    <img
                      src="images/calander.svg"
                      alt="Calendar"
                      style={{
                        position: "absolute",
                        right: "21px",
                        top: "10px",
                        cursor: "pointer",
                      }}
                    />
                  </div>
                }
              />
            </div>
          </>
        )}

        {selectedOption.value === "Incomplete" && (
          <form>
            <div style={{ display: "flex", gap: "20px" }}>
              <div
                className="searchbar"
                style={{ marginTop: "10px" }}
                title="Search by Name and number"
                onChange={(e) => handleSearchPlanpurchased(e)}
              >
                <input
                  type="text"
                  className="placeholder"
                  value={searchName || searchMobile}
                  onChange={(e) => handleSearchChange(e)}
                  placeholder="Search by Name"
                  name="search"
                ></input>

                <i
                  class="fa fa-search"
                  aria-hidden="true"
                  className="navImg"
                ></i>
              </div>

              <div title="Search by date">
                <DatePicker
                  selected={startDate}
                  onChange={handleStartDateChange}
                  selectsStart
                  startDate={startDate}
                  endDate={endDate}
                  placeholderText="Start Date"
                  className="datepicker1"
                  customInput={
                    <div
                      style={{
                        position: "relative",
                        display: "flex",
                        flexDirection: "column",
                        marginRight: "20px",
                        border: "1px solid #ced4da",
                        borderRadius: "6px",
                        paddingBottom: "3px",
                        paddingTop: "3px",
                      }}
                    >
                      <div style={{ marginTop: "10px" }}>
                        <input
                          type="text"
                          className="formcontroldash"
                          style={{ color: "#6c757d", border: "none" }}
                          value={
                            startDate
                              ? moment(startDate).format("DD-MM-YYYY")
                              : "Start Date" // Placeholder text with default values
                          }
                          readOnly
                        />
                      </div>

                      <img
                        src="images/calander.svg"
                        alt="Calendar"
                        style={{
                          position: "absolute",
                          right: "21px",
                          top: "10px",
                          cursor: "pointer",
                        }}
                      />
                    </div>
                  }
                />
              </div>
              <div title="Search by date">
                <DatePicker
                  selected={endDate}
                  onChange={handleEndDateChange}
                  selectsEnd
                  startDate={startDate}
                  endDate={endDate}
                  placeholderText="End Date"
                  minDate={startDate}
                  className="datepicker1"
                  customInput={
                    <div
                      style={{
                        position: "relative",
                        display: "flex",
                        flexDirection: "column",
                        border: "1px solid #ced4da",
                        borderRadius: "6px",
                        paddingBottom: "3px",
                        paddingTop: "3px",
                      }}
                    >
                      <div style={{ marginTop: "10px" }}>
                        <input
                          type="text"
                          className="formcontroldash"
                          style={{ color: "#6c757d", border: "none" }}
                          value={
                            endDate ? moment(endDate).format("DD-MM-YYYY") : ""
                          }
                          readOnly
                        />
                      </div>

                      <img
                        src="images/calander.svg"
                        alt="Calendar"
                        style={{
                          position: "absolute",
                          right: "21px",
                          top: "10px",
                          cursor: "pointer",
                        }}
                      />
                    </div>
                  }
                />
              </div>
              {/* <button className="buttn" type="submit" >Search</button> */}
            </div>
          </form>
        )}

        {/* <div className='searchbar' style={{ marginTop: "10px" }} title="Search by date">

          <DatePicker
            showIcon
            className="datepicker1"

            dateFormat="MM/yyyy"
            selected={selecteDate}
            //  selected={selectedDate1}
            onChange={(date) => setSelectedDate(date)}



            customInput={
              <div style={{ position: 'relative', display: "flex", flexDirection: "column" }}>
                <div>
                  <input
                    type="text"
                    className="form-control"

                    value={
                      selecteDate
                        ? moment(selecteDate).format('DD-MM-YYYY')
                        : "Select Date" // Placeholder text with default values
                    }


                    readOnly


                  /></div>

                <img
                  src="images/calander.svg"
                  alt="Calendar"
                  style={{
                    position: 'absolute',
                    right: '10px',
                    top: '1px',
                    cursor: 'pointer',
                  }} />
              </div>}
          >

          </DatePicker>



        </div> */}

        <div className="" style={{ marginTop: "10px" }}>
          <Dropdown
            value={selectedOption}
            options={dropdownOptions}
            onChange={handleOptionChange}
            optionLabel="label"
            optionValue="value"
            placeholder={selectedOption.label}
            // placeholder={dropdownOptions[0].label || selectedOption.label }
          />

          {/* <Dropdown onChange={(e) => setSelectedId(e.value)} options={dropdownOptions} optionLabel="name" name="Plans" value={selectedId}
            placeholder="Select Events" className="searchbarname" /> */}
        </div>
      </div>

      {selectedOption.value === "Appointment Booked" && (
        <TabView
          activeIndex={activeIndex}
          onTabChange={(e) => setActiveIndex(e.index)}
          style={{ marginTop: "20px" }}
        >
          <TabPanel header="Doctor Appointment">
            <div style={{ display: "flex" }}>
              <Dropdown
                id="disease"
                name="disease"
                value={selectdocList}
                options={dropdownOptionsnew}
                onChange={(e) => setSelectdocList(e.value)}
                placeholder="Select Doctor"
                optionLabel="label"
                filter
                filterBy="label"
                filterMatchMode="contains"
                filterPlaceholder="Type to filter"
                filterInputAutoFocus
                // onFilter={onFilter}
                className="searchbar custom-dropdown dropdowntd"
                style={{
                  width: "160px",
                  marginRight: "20px",
                  border: "1px solid #ccc",
                  marginBottom: "15px",
                }}
              />
              <p style={{ marginTop: "9px" }}>
                <b> Total Appointments: {doctorapp?.length} </b>
              </p>
            </div>

            <DataTable
              value={doctorapp}
              paginator
              rows={5}
              rowsPerPageOptions={[5, 10, 15, 15]}
            >
              <Column
                field="patientName"
                header="Patient Name"
                sortable
              ></Column>
              <Column field="planName" header="Plan Name" sortable></Column>
              <Column
                field="serviceName"
                header="Service Name"
                sortable
              ></Column>
              <Column
                field="userName"
                header="Service Provider Name"
                sortable
              ></Column>
              <Column
                field="whenAppointment"
                header="Consultation Date"
                body={(rowData) => {
                  const formattedDate = rowData.whenAppointment
                    ? moment(rowData.whenAppointment).format("DD-MM-YYYY")
                    : "";
                  return formattedDate;
                }}
              ></Column>
              <Column
                field="timeRange"
                header="Consultation Time"
                body={(rowData) => `${rowData.fromTime} - ${rowData.toTime}`}
              ></Column>
              <Column
                field="consultationsType"
                header="Consultations Type"
                body={(rowData) =>
                  rowData.consultationsType === "V"
                    ? "Video"
                    : rowData.consultationsType === "H"
                    ? "Home Visit"
                    : rowData.consultationsType === "P"
                    ? "Phone"
                    : rowData.consultationsType === "E"
                    ? "Eclinic"
                    : ""
                }
              ></Column>

              <Column
                field="consultationsReason"
                header="Consultation Reason"
              ></Column>
            </DataTable>
          </TabPanel>
          <TabPanel header="Lab Appointment">
            <div style={{ display: "flex" }}>
              <Dropdown
                id="disease"
                name="disease"
                value={selectlabList}
                options={dropdownOptionsLab}
                onChange={(e) => setSelectlabList(e.value)}
                placeholder="Select Lab"
                className="searchbar custom-dropdown dropdowntd"
                style={{
                  marginRight: "20px",
                  border: "1px solid #ccc",
                  marginBottom: "15px",
                }}
                optionLabel="label"
                filter
                filterBy="label"
                filterMatchMode="contains"
                filterPlaceholder="Type to filter"
                filterInputAutoFocus
                onFilter={onFilterLab}
              />
              <p style={{ marginTop: "9px" }}>
                <b> Total Appointments: {labapp?.length} </b>
              </p>
            </div>

            <DataTable
              value={labapp}
              paginator
              rows={5}
              rowsPerPageOptions={[5, 10, 15, 15]}
            >
              <Column
                field="patientName"
                header="Patient Name"
                sortable
              ></Column>
              <Column field="planName" header="Plan Name" sortable></Column>

              <Column
                field="serviceName"
                header="Service Name"
                sortable
              ></Column>
              <Column
                field="hospitalName"
                header="Service Provider Name"
                sortable
              ></Column>
              <Column
                field="labTestName"
                header="LabTest Name"
                sortable
              ></Column>
              <Column
                field="confirmDateTime"
                header="Appointment Date"
                body={(rowData) => {
                  const formattedDate = rowData.preferDateTime
                    ? moment(rowData.preferDateTime).format("DD-MM-YYYY")
                    : "";
                  return formattedDate;
                }}
              ></Column>
              <Column field="preferTime" header="Appointment Time"></Column>
            </DataTable>
          </TabPanel>
          <TabPanel header="Nurse Appointment">
            <div style={{ display: "flex" }}>
            <Dropdown
                          id="disease"
                          name="disease"
                          value={serviceprovider}
                          options={dropdownOptionsNurse}
                          onChange={(e) => setServiceprovider(e.target.value)}
                          placeholder="Select Nurse"
                          className="dropdowntd"
                          optionLabel="label"
                        
                        />
              <p style={{ marginLeft: "20px" }}>
                {" "}
                <b>Total Appointments: {nurseapp?.length}</b>
              </p>
            </div>

            <DataTable
              value={nurseapp}
              paginator
              rows={5}
              rowsPerPageOptions={[5, 10, 15, 15]}
            >
              <Column
                field="patientName"
                header="Patient Name"
                sortable
              ></Column>
              {/* <Column field="planName" header="Plan Name" sortable></Column> */}
              <Column
                field="serviceName"
                header="Service Name"
                sortable
              ></Column>
              <Column
                field="userName"
                header="Service Provider Name"
                sortable
              ></Column>
              <Column
                field="whenAppointment"
                header="Consultation Date"
                body={(rowData) => {
                  const formattedDate = rowData.whenAppointment
                    ? moment(rowData.whenAppointment).format("DD-MM-YYYY")
                    : "";
                  return formattedDate;
                }}
              ></Column>
              <Column
                field="timeRange"
                header="Consultation Time"
                body={(rowData) => `${rowData.fromTime} - ${rowData.toTime}`}
              ></Column>
              <Column
                field="consultationsType"
                header="Consultations Type"
                body={(rowData) =>
                  rowData.consultationsType === "V"
                    ? "Video"
                    : rowData.consultationsType === "H"
                    ? "Home Visit"
                    : rowData.consultationsType === "P"
                    ? "Phone"
                    : rowData.consultationsType === "E"
                    ? "Eclinic"
                    : ""
                }
              ></Column>

              <Column
                field="consultationsReason"
                header="Consultation Reason"
              ></Column>
            </DataTable>
          </TabPanel>
        </TabView>
      )}

      {selectedOption.value === "Subscription" && (
        <DataTable
          value={subscriptionapi}
          paginator
          rows={5}
          rowsPerPageOptions={[5, 10, 15, 15]}
          tableStyle={{ minWidth: "50rem", padding: "10px", marginTop: "20px" }}
        >
          <Column field="patientName" header="Patient Name" sortable></Column>
          <Column field="mobile" header="Mobile No." sortable></Column>
          <Column field="email" header="Email" sortable></Column>
          <Column field="planName" header="Plan Name" sortable></Column>
          <Column field="subsPeriod" header="Subs Period" sortable></Column>
          <Column
            field="expiryDate"
            header="Expiry Date"
            body={(rowData) => {
              const formattedDate = rowData.expiryDate
                ? moment(rowData.expiryDate).format("DD-MM-YYYY")
                : "";
              return formattedDate;
            }}
            sortable
          ></Column>
          <Column
            field="renewalStatus"
            header="Renewal Status"
            sortable
          ></Column>
          <Column field="expiredDays" header="Expired Days" sortable></Column>
          <Column field="totalAmount" header="Total Amount" sortable></Column>
        </DataTable>
      )}

      {selectedOption.value === "Plan Purchased" && (
        <DataTable
          value={planapi}
          paginator
          rows={5}
          rowsPerPageOptions={[5, 10, 15, 15]}
          tableStyle={{ minWidth: "50rem", padding: "10px", marginTop: "20px" }}
        >
          <Column field="patientName" header="Patient Name" sortable></Column>
          <Column field="mobile" header="Mobile No." sortable></Column>
          {/* <Column field="email" header="Email" sortable></Column> */}
          <Column field="planName" header="Plan Name" sortable></Column>
          <Column field="subsPeriod" header="Subs Period" sortable></Column>
          <Column
            field="createdDate"
            header="Start Date"
            body={(rowData) => {
              const formattedDate = rowData.createdDate
                ? moment(rowData.createdDate).format("DD-MM-YYYY")
                : "";
              return formattedDate;
            }}
            sortable
          ></Column>
          {/* <Column field="expiryDate" header="Expiry Date" body={(rowData) => {

          const formattedDate = rowData.expiryDate ? moment(rowData.expiryDate).format("DD-MM-YYYY") : ""
          return formattedDate;
        }} sortable></Column>
        {/* <Column field="renewalStatus" header="Renewal Status" sortable></Column> */}
          {/* <Column field="expiredDays" header="Expired Days" sortable></Column>  */}
          <Column field="totalAmount" header="Total Amount" sortable></Column>
        </DataTable>
      )}

      {selectedOption.value === "Pre Assessment" && (
        <DataTable
          value={preassment}
          paginator
          rows={5}
          rowsPerPageOptions={[5, 10, 15, 15]}
          tableStyle={{ minWidth: "50rem", padding: "10px", marginTop: "20px" }}
        >
          <Column field="giverName" header="Giver Name"></Column>
          <Column field="giverMobile" header="Giver Mobile"></Column>
          <Column field="giverEmail" header="Giver Email"></Column>
          {/* <Column field="address" header="address"  ></Column> */}
          <Column field="patientName" header="Patient Name"></Column>
          <Column field="receiverMobile" header="Patient Mobile"></Column>
          <Column field="relation" header="Relation"></Column>
          <Column field="zipCode" header="Patient Zipcode"></Column>
            <Column field="address" header="Patient Address"></Column>

          <Column
            field="createdDate"
            header="Created Date"
            body={(rowData) => {
              const formattedDate = rowData.createdDate
                ? moment(rowData.createdDate).format("DD-MM-YYYY")
                : "";
              return formattedDate;
            }}
          ></Column>
          <Column
            header="Status"
            style={{ width: "200px" }}
            body={(rowData) => (
              <>
                {rowData?.parentStatus === 3 ? (
                  <div>Completed</div>
                ) : (
                  <>
                    <div style={{ display: "flex", gap: "10px" }}>
                      <Dropdown
                        onChange={(e) =>
                          handleStatusChange(rowData.prepaSeqId, e.value)
                        }
                        name={`status-${rowData.prepaSeqId}`}
                        options={dropdownOptionStatus}
                        optionLabel="name"
                        value={selectStatus?.[rowData.prepaSeqId] || ""}
                        // value={statusMap[rowData.prepaSeqId] || ''}
                        placeholder={
                          rowData?.parentStatus === 2
                            ? "Booked"
                            : "Yet to start"
                        }
                        className="dropdowntd"
                        style={{ width: "150px" }}
                      />
                      <button
                        type="submit"
                        className="custom-btn btn-2"
                        onClick={() => handleSubmit(rowData)}
                      >
                        Save
                      </button>
                    </div>
                  </>
                )}
              </>
            )}
          />
          {/* <Column 
             body={(rowData) => (
              <>
                  
              </>
            )}
            
              header="Save"></Column> */}
        </DataTable>
      )}

      {/* {selectedOption.value === 'Incomplete' && (



    <DataTable  paginator rows={5} rowsPerPageOptions={[5, 10, 15, 15]} style={{marginTop:"20px"}}
    >
      <Column  header="Patient Name" sortable></Column>
      <Column header="Plan Name" sortable></Column>
      <Column field="serviceName" header="Service Name" sortable></Column>
      <Column field="userName" header="Service Provider Name" sortable ></Column>
      <Column field="whenAppointment" header="Consultation Date" body={(rowData) => {

        const formattedDate = rowData.whenAppointment ? moment(rowData.whenAppointment).format("DD-MM-YYYY") : ""
        return formattedDate;
      }} ></Column>
      <Column
        field="timeRange"
        header="Consultation Time"
        body={(rowData) => `${rowData.fromTime} - ${rowData.toTime}`}
      ></Column>
      <Column field="consultationsType" header="Consultations Type" body={
        (rowData) =>
          rowData.consultationsType === "V" ? "Video" :
            rowData.consultationsType === "H" ? "Home Visit" :
              rowData.consultationsType === "P" ? "Phonic" :
                rowData.consultationsType === "E" ? "Eclinic" : ""

      } ></Column>

      <Column field="consultationsReason" header="Reason"
      ></Column>



    </DataTable>


)
} */}

      {selectedOption.value === "Incomplete" && (
        <TabView
          activeIndex={activeIndex}
          onTabChange={(e) => setActiveIndex(e.index)}
          style={{ marginTop: "20px" }}
        >
          <TabPanel header="Doctor Appointment">
            <div style={{ display: "flex" }}>
              <Dropdown
                id="disease"
                name="disease"
                value={selectdocList}
                options={dropdownOptionsnew}
                onChange={(e) => setSelectdocList(e.value)}
                placeholder="Select Doctor"
                optionLabel="label"
                filter
                filterBy="label"
                filterMatchMode="contains"
                filterPlaceholder="Type to filter"
                filterInputAutoFocus
                // onFilter={onFilter}
                className="searchbar custom-dropdown dropdowntd"
                style={{
                  width: "160px",
                  marginRight: "20px",
                  border: "1px solid #ccc",
                  marginBottom: "15px",
                }}
              />
              <p style={{ marginTop: "9px" }}>
                <b> Total Appointments: {doctorapp?.length} </b>
              </p>
            </div>

            <DataTable
              value={doctorapp}
              paginator
              rows={5}
              rowsPerPageOptions={[5, 10, 15, 15]}
            >
              <Column
                field="patientName"
                header="Patient Name"
                sortable
              ></Column>
              <Column field="planName" header="Plan Name" sortable></Column>
              <Column
                field="serviceName"
                header="Service Name"
                sortable
              ></Column>
              <Column
                field="userName"
                header="Service Provider Name"
                sortable
              ></Column>
              <Column
                field="whenAppointment"
                header="Consultation Date"
                body={(rowData) => {
                  const formattedDate = rowData.whenAppointment
                    ? moment(rowData.whenAppointment).format("DD-MM-YYYY")
                    : "";
                  return formattedDate;
                }}
              ></Column>
              <Column
                field="timeRange"
                header="Consultation Time"
                body={(rowData) => `${rowData.fromTime} - ${rowData.toTime}`}
              ></Column>
              <Column
                field="consultationsType"
                header="Consultations Type"
                body={(rowData) =>
                  rowData.consultationsType === "V"
                    ? "Video"
                    : rowData.consultationsType === "H"
                    ? "Home Visit"
                    : rowData.consultationsType === "P"
                    ? "Phone"
                    : rowData.consultationsType === "E"
                    ? "Eclinic"
                    : ""
                }
              ></Column>

              <Column
                field="consultationsReason"
                header="Consultation Reason"
              ></Column>
            </DataTable>
          </TabPanel>
          <TabPanel header="Lab Appointment">
            <div style={{ display: "flex" }}>
              <Dropdown
                id="disease"
                name="disease"
                value={selectlabList}
                options={dropdownOptionsLab}
                onChange={(e) => setSelectlabList(e.value)}
                placeholder="Select Lab"
                className="searchbar custom-dropdown dropdowntd"
                style={{
                  marginRight: "20px",
                  border: "1px solid #ccc",
                  marginBottom: "15px",
                }}
                optionLabel="label"
                filter
                filterBy="label"
                filterMatchMode="contains"
                filterPlaceholder="Type to filter"
                filterInputAutoFocus
                onFilter={onFilterLab}
              />
              <p style={{ marginTop: "9px" }}>
                <b> Total Appointments: {labapp?.length} </b>
              </p>
            </div>
            <DataTable
              value={labapp}
              paginator
              rows={5}
              rowsPerPageOptions={[5, 10, 15, 15]}
            >
              <Column
                field="patientName"
                header="Patient Name"
                sortable
              ></Column>
              <Column field="planName" header="Plan Name" sortable></Column>

              <Column
                field="serviceName"
                header="Service Name"
                sortable
              ></Column>
              <Column
                field="hospitalName"
                header="Service Provider Name"
                sortable
              ></Column>
              <Column
                field="labTestName"
                header="LabTest Name"
                sortable
              ></Column>
              <Column
                field="confirmDateTime"
                header="Appointment Date"
                body={(rowData) => {
                  const formattedDate = rowData.confirmDateTime
                    ? moment(rowData.confirmDateTime).format("DD-MM-YYYY")
                    : "";
                  return formattedDate;
                }}
              ></Column>
              <Column field="preferTime" header="Appointment Time"></Column>
            </DataTable>
          </TabPanel>
          <TabPanel header="Nurse Appointment">
            <div style={{ display: "flex" }}>
            <Dropdown
                          id="disease"
                          name="disease"
                          value={serviceprovider}
                          options={dropdownOptionsNurse}
                          onChange={(e) => setServiceprovider(e.target.value)}
                          placeholder="Select Nurse"
                          className="dropdowntd"
                          optionLabel="label"
                        
                        />
              <p style={{ marginLeft: "20px" }}>
                {" "}
                <b>Total Appointments: {nurseapp?.length}</b>
              </p>
            </div>

            <DataTable
              value={nurseapp}
              paginator
              rows={5}
              rowsPerPageOptions={[5, 10, 15, 15]}
            >
              <Column
                field="patientName"
                header="Patient Name"
                sortable
              ></Column>
              {/* <Column field="planName" header="Plan Name" sortable></Column> */}
              <Column
                field="serviceName"
                header="Service Name"
                sortable
              ></Column>
              <Column
                field="userName"
                header="Service Provider Name"
                sortable
              ></Column>
              <Column
                field="whenAppointment"
                header="Consultation Date"
                body={(rowData) => {
                  const formattedDate = rowData.whenAppointment
                    ? moment(rowData.whenAppointment).format("DD-MM-YYYY")
                    : "";
                  return formattedDate;
                }}
              ></Column>
              <Column
                field="timeRange"
                header="Consultation Time"
                body={(rowData) => `${rowData.fromTime} - ${rowData.toTime}`}
              ></Column>
              <Column
                field="consultationsType"
                header="Consultations Type"
                body={(rowData) =>
                  rowData.consultationsType === "V"
                    ? "Video"
                    : rowData.consultationsType === "H"
                    ? "Home Visit"
                    : rowData.consultationsType === "P"
                    ? "Phone"
                    : rowData.consultationsType === "E"
                    ? "Eclinic"
                    : ""
                }
              ></Column>

              <Column
                field="consultationsReason"
                header="Consultation Reason"
              ></Column>
            </DataTable>
          </TabPanel>
        </TabView>
      )}
    </>
  );
}

export default Event;
