import {React,useState,useEffect} from 'react';
import { TabView, TabPanel } from 'primereact/tabview';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import axios from "axios";
import http from "../../../src/Redux/services/http-common.jsx"

function CareplansDetail() {
    const [planList, setPlanList] = useState([]);


    useEffect(() => {

        http.get(`${process.env.REACT_APP_BASEURL}medicare/carePlans`)
            .then((res) => {


                setPlanList(res.data);

               

                console.log(res.data, "qwertymnbvcx");


            })
            .catch((err) => {

                console.log(err, "Something went wrong1");

            })


    }, [])

  return (

   

    <div>


<TabView>
    {planList?.map((item, index) => (
      <TabPanel header={item.planname} key={index}>
          <DataTable value={item.carePlanFeatures} paginator rows={10}>
      <Column field="serviceName" header="Service Name" />
      <Column field="frequency" header="Frequency" />
      <Column field="personnel" header="Service Provider" />
      <Column field="serviceMode" header="Service Mode" />
    
    </DataTable>
      
      </TabPanel>
    ))}
  </TabView>

        {/* <TabView>
    <TabPanel header="General Health">
    <DataTable tableStyle={{ minWidth: '50rem' }}>
    <Column  header="Services"></Column>
    <Column header="Frequency"></Column>
  
</DataTable>
    </TabPanel>
    <TabPanel header="Cardiac Care">
    <DataTable tableStyle={{ minWidth: '50rem' }}>
    <Column  header="Services"></Column>
    <Column header="Frequency"></Column>
  
</DataTable>
    </TabPanel>
    <TabPanel header="Ortho Care">
    <DataTable tableStyle={{ minWidth: '50rem' }}>
    <Column  header="Services"></Column>
    <Column header="Frequency"></Column>
  
</DataTable>
    </TabPanel>

    <TabPanel header="Womens Health">
    <DataTable tableStyle={{ minWidth: '50rem' }}>
    <Column  header="Services"></Column>
    <Column header="Frequency"></Column>
  
</DataTable>
    </TabPanel>

    <TabPanel header="Diabetes Care">
    <DataTable tableStyle={{ minWidth: '50rem' }}>
    <Column  header="Services"></Column>
    <Column header="Frequency"></Column>
  
</DataTable>
    </TabPanel>
</TabView> */}


    </div>
  )
}

export default CareplansDetail