import React from 'react'
import './index.css'

function Loader() {
  return (
    <div className='container-main'>
  <div className='container-loader'></div>
    </div>
  
  )
}

export default Loader