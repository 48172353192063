import React from "react";
import { useState, useEffect } from "react";
import axios from "axios";
import moment from "moment";
import { DataTable } from "primereact/datatable";
import { TabView, TabPanel } from "primereact/tabview";
import { Dropdown } from "primereact/dropdown";
import { Column } from "primereact/column";
import DatePicker from "react-datepicker";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import AssessmentScheduleSidebar from "./AssessmentScheduleSidebar";
import { Sidebar } from "primereact/sidebar";
import { Tag } from "primereact/tag";
import AssessmentScheduleSidebarUpdate from "./AssessmentScheduleSidebarUpdate";
import http from "../../../Redux/services/http-common"

function Assessment() {
  const [preassment, setPreassment] = useState();
  const [preassmentSchedule, setPreassmentSchedule] = useState();
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());
  const [selectStatus, setSelectStatus] = useState("Yet to Start");
  const [activeIndex, setActiveIndex] = useState(0);
  const [selectedRowData, setSelectedRowData] = useState(null);
  const [sideDialog, setSideDialog] = useState(false);
  const [assessmentShedulerowdata, setAssessmentSchedule] = useState(null);
  const [sideDialogAssessmentSchedule, setSideDialogAssessmentSchedule] =
    useState(false);

  const dropdownOptionStatus = [
    // { name: "Yet to Start", value: "1" },
    { name: "Booked", value: "2" },
    { name: "Completed", value: "3" },
  ];
  const handleStartDateChange = (date) => {
    setStartDate(date);
  };
  const handleEndDateChange = (date) => {
    setEndDate(date);
  };

  const formatstartDate = startDate
    ? moment(startDate).format("YYYY-MM-DD")
    : "";
  const formatendDate = endDate ? moment(endDate).format("YYYY-MM-DD") : "";

  const fetchpreassessmentdetail = () => {
    let requestBody = {};
    requestBody = {
      // fromDate: formatstartDate,
      // toDate: formatendDate,
    };
    http
      .post(
        `${process.env.REACT_APP_BASEURL}medicare/getPreassessmentForChildAndParent`,
        requestBody
      )
      .then((res) => {
        console.log(res.data, "event123");
        setPreassment(res.data);

        // setEventdata(res.data);
      })
      .catch((err) => {
        console.log(err, "Something went wrong1");
      });
    console.log("Calling API for Option 2");
  };
  const fetchpreassessmentSchedule = () => {
    let requestBody = {};

    http
      .post(
        `${process.env.REACT_APP_BASEURL}preassesment/getAllPreassessmentAppointments`,
        requestBody
      )
      .then((res) => {
        console.log(res.data, "assessment");
        setPreassmentSchedule(res.data);

        // setEventdata(res.data);
      })
      .catch((err) => {
        console.log(err, "Something went wrong1");
      });
    console.log("Calling API for Option 2");
  };

  useEffect(() => {
    if (activeIndex === 0) {
      fetchpreassessmentdetail();
    } else if (activeIndex === 1) {
      fetchpreassessmentSchedule();
    }
  }, [activeIndex, formatstartDate, formatendDate]);

  const handleStatusChange = (rowId, status) => {
    setSelectStatus((prevStatus) => ({
      ...prevStatus,
      [rowId]: status,
    }));
  };

  const handleSubmit = (rowData) => {
    const updatedStatus = selectStatus[rowData.prepaSeqId];
    console.log(updatedStatus, "updatedStatus");
    http
      .post(`${process.env.REACT_APP_BASEURL}medicare/updatePreAssesment`, {
        parentStatus: updatedStatus,
        prepaSeqId: rowData.prepaSeqId,
        prechSeqId: rowData.prechSeqId,
        giverEmail: rowData.giverEmail,
        giverName: rowData.giverName,
      })
      .then((res) => {
        console.log(res, "yes");
        toast(" Updated successfully");
        fetchpreassessmentdetail();
      })
      .catch((err) => {
        console.log(err, "Something went wrong2");
      });
  };

  const handleRowClick = (rowData) => {
    setSelectedRowData(rowData);
    setSideDialog(true);
  };

  const handleUpdate = (rowData) => {
    setAssessmentSchedule(rowData);
    setSideDialogAssessmentSchedule(true);
  };

  const closeSidebar = () => {
    setSideDialog(false);
    toast("Appointment Booked");
  };
  const closeSidebarUpdate = () => {
    setSideDialogAssessmentSchedule(false);
    toast("Appointment Updated Successfully");
  };

  const statusBodyTemplate = (rowData) => {
    console.log(rowData?.serviceStatus);
    let statusLabel, statusSeverity;
    switch (rowData?.serviceStatus) {
      case 0:
        statusLabel = "Auto Cancelled";
        statusSeverity = "danger";
        break;
      case 1:
        statusLabel = "Yet to Start";
        statusSeverity = "warning";
        break;
      case 2:
        statusLabel = "Appointment Booked";
        statusSeverity = "info";
        break;
      case 3:
        statusLabel = "Completed";
        statusSeverity = "success";
        break;
      case 4:
        statusLabel = "Request For Update";
        statusSeverity = "warning";
        break;
      case 5:
        statusLabel = "Cancelled";
        statusSeverity = "danger";
        break;
      case 6:
        statusLabel = "Reschedule";
        statusSeverity = "warning";
        break;

      default:
        statusLabel = "";
        statusSeverity = "";
        break;
    }
    return <Tag value={statusLabel} severity={statusSeverity}></Tag>;
  };

  return (
    <>
      <ToastContainer />
      <TabView
        activeIndex={activeIndex}
        onTabChange={(e) => setActiveIndex(e.index)}
       
      >
        <TabPanel header="Patient Details">
          <div style={{ display: "flex" }}>
            <div title="Search by date">
              {/* <DatePicker
                  selected={startDate}
                  onChange={handleStartDateChange}
                  selectsStart
                  startDate={startDate}
                  endDate={endDate}
                  placeholderText="Start Date"
                  className="datepicker1"
                  customInput={
                    <div
                      style={{
                        position: "relative",
                        display: "flex",
                        flexDirection: "column",
                        marginRight: "20px",
                        border: "1px solid #ced4da",
                        borderRadius: "6px",
                        paddingBottom: "3px",
                        paddingTop: "3px",
                      }}
                    >
                      <div style={{ marginTop: "10px" }}>
                        <input
                          type="text"
                          className="formcontroldash"
                          style={{ color: "#6c757d", border: "none" }}
                          value={
                            startDate
                              ? moment(startDate).format("DD-MM-YYYY")
                              : "Start Date" // Placeholder text with default values
                          }
                          readOnly
                        />
                      </div>

                      <img
                        src="images/calander.svg"
                        alt="Calendar"
                        style={{
                          position: "absolute",
                          right: "21px",
                          top: "10px",
                          cursor: "pointer",
                        }}
                      />
                    </div>
                  }
                /> */}
            </div>
            <div title="Search by date">
              {/* <DatePicker
                  selected={endDate}
                  onChange={handleEndDateChange}
                  selectsEnd
                  startDate={startDate}
                  endDate={endDate}
                  placeholderText="End Date"
                  minDate={startDate}
                  className="datepicker1"
                  customInput={
                    <div
                      style={{
                        position: "relative",
                        display: "flex",
                        flexDirection: "column",
                        border: "1px solid #ced4da",
                        borderRadius: "6px",
                        paddingBottom: "3px",
                        paddingTop: "3px",
                      }}
                    >
                      <div style={{ marginTop: "10px" }}>
                        <input
                          type="text"
                          className="formcontroldash"
                          style={{ color: "#6c757d", border: "none" }}
                          value={
                            endDate ? moment(endDate).format("DD-MM-YYYY") : ""
                          }
                          readOnly
                        />
                      </div>

                      <img
                        src="images/calander.svg"
                        alt="Calendar"
                        style={{
                          position: "absolute",
                          right: "21px",
                          top: "10px",
                          cursor: "pointer",
                        }}
                      />
                    </div>
                  }
                /> */}
            </div>
          </div>
          <DataTable
            value={preassment}
            paginator
            rows={10}
            rowsPerPageOptions={[5, 10, 15, 15]}
           
          > 
          <Column field="patientName" header="Patient Name"></Column>
          <Column field="receiverMobile" header="Patient Mobile"></Column>
          <Column field="relation" header="Relation"></Column>
            <Column field="giverName" header="Giver Name"></Column>
            <Column field="giverMobile" header="Giver Mobile"></Column>
            <Column field="giverEmail" header="Giver Email"></Column>
            {/* <Column field="address" header="address"  ></Column> */}
           
            <Column field="zipCode" header="Patient Zipcode"></Column>
            <Column field="address" header="Patient Address"></Column>

            <Column
              field="createdDate"
              header="Created Date"
              body={(rowData) => {
                const formattedDate = rowData.createdDate
                  ? moment(rowData.createdDate).format("DD-MM-YYYY")
                  : "";
                return formattedDate;
              }}
            ></Column>
            <Column
              header="Action"
              body={(rowData) => (
                <>
                  <span>
                    {/* <i class="fa fa-pencil-square-o " aria-hidden="true" onClick={() => show(rowData)} style={{ cursor: "pointer" }}></i> */}
                    {rowData.serviceStatus === "2" &&
                    rowData.personnelCode === "NUR" ? (
                      <i
                        className="fa fa-pencil-square-o"
                        aria-hidden="true"
                        onClick={() => handleRowClick(rowData)}
                        style={{ cursor: "pointer" }}
                      ></i>
                    ) : rowData?.serviceStatus === "2" ||
                      rowData?.serviceStatus === "3" ||
                      rowData?.serviceStatus === "0" ||
                      rowData?.serviceStatus === "5" ? (
                      <i
                        class="fa fa-eye"
                        aria-hidden="true"
                        onClick={() => handleRowClick(rowData)}
                        style={{ cursor: "pointer", color: "#005D8E" }}
                      ></i>
                    ) : (
                      <i
                        className="fa fa-pencil-square-o"
                        aria-hidden="true"
                        onClick={() => handleRowClick(rowData)}
                        style={{ cursor: "pointer" }}
                      ></i>
                    )}
                  </span>
                </>
              )}
            />
          </DataTable>
        </TabPanel>
        <TabPanel header="Assessment Schedule">
          <DataTable
            value={preassmentSchedule}
            paginator
            rows={10}
            rowsPerPageOptions={[5, 10, 15, 15]}
           
          >
            <Column
              header="Update"
              body={(rowData) => (
                <>
                  <span>
                    {/* <i class="fa fa-pencil-square-o " aria-hidden="true" onClick={() => show(rowData)} style={{ cursor: "pointer" }}></i> */}

                    <i
                      className="fa fa-pencil-square-o"
                      aria-hidden="true"
                      onClick={() => handleUpdate(rowData)}
                      style={{ cursor: "pointer" }}
                    ></i>
                  </span>
                </>
              )}
            />
            <Column field="firstName" header="Patient Name"></Column>
            <Column field="patientCode" header="Patient code"></Column>

            <Column field="appointmentCode" header="Appointment Type"></Column>
            <Column
  field="serviceProviderName"
  header="Provider Name"
  body={(rowData) => {
    if (rowData.appointmentCode === 'LAB') {
      return (
        <div>
          <div>{rowData.serviceProviderName}</div>
          <div style={{marginTop:"15px"}}>Labtest - {rowData.labTestName}</div>
        </div>
      );
    } else {
      return <div>{rowData.serviceProviderName}</div>;
    }
  }}
></Column>

            <Column
              header="Consultation Type"
              body={(rowData) => {
                if ( rowData.consultationType === "L"){
                  return "Lab"
                } else
                if (rowData.consultationType === "V") {
                  return "Video";
                } else if (rowData.consultationType === "H") {
                  return "Home Visit";
                } else if (rowData.consultationType === "P") {
                  return "Phone";
                } else {
                  return "";
                }
              }}
            ></Column>

            <Column
              header="Scheduled Date and Time"
              style={{ width: "14rem" }}
              body={(rowData) => {
                if (rowData.serviceStatus === 4) {
                  return null; // Return null to hide the column content
                }

                const formattedDate = rowData.modifiedServiceDate
                  ? moment(rowData.modifiedServiceDate).format("DD-MM-YYYY")
                  : "";

                let fromTime = "";

                if (rowData.fromTime !== null) {
                  fromTime = moment(rowData.fromTime, "HH:mm").format(
                    "hh:mm A"
                  );

                  if (
                    rowData.personnelCode === "LAB" &&
                    rowData.toTime !== null
                  ) {
                    fromTime +=
                      " - " + moment(rowData.toTime, "HH:mm").format("hh:mm A");
                  }
                }

                return (
                  <div>
                    <div>{formattedDate}</div>
                    <div>{fromTime}</div>
                  </div>
                );
              }}
            />
            <Column
              field="status"
              header="Status"
              filterMenuStyle={{ width: "14rem" }}
              body={statusBodyTemplate}
              style={{ width: "200px" }}
            />
          </DataTable>
        </TabPanel>
      </TabView>

      <Sidebar
        visible={sideDialog}
        position="right"
        onHide={() => setSideDialog(false)}
        style={{ width: "480px" }}
      >
        <>
          <AssessmentScheduleSidebar
            scheduledata={selectedRowData}
            handleSearch={fetchpreassessmentdetail}
            closeSidebar={closeSidebar}
          />
          {/* ... */}
        </>
      </Sidebar>

      <Sidebar
        visible={sideDialogAssessmentSchedule}
        position="right"
        onHide={() => setSideDialogAssessmentSchedule(false)}
        style={{ width: "480px" }}
      >
        <>
          <AssessmentScheduleSidebarUpdate
            scheduledata={assessmentShedulerowdata}
            handleSearch={fetchpreassessmentSchedule}
            closeSidebar={closeSidebarUpdate}
          />
          {/* ... */}
        </>
      </Sidebar>
    </>
  );
}

export default Assessment;
