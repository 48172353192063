import { saveAs } from "file-saver";
import XlsxPopulate from "xlsx-populate";

export function jsonToExcelConvetercareplan(careplandata, fileName) {

console.log(careplandata, fileName,"careplandata")
let headerOfExcel = careplandata[0]
console.log('careplanDatahai \n', JSON.stringify(careplandata), "Type: \n", fileName)
let exportArr = []
let exportHeading

for (let i = 0; i < careplandata.length; i++) {
  let obj
    exportHeading = ["patient Name", "Plan Name","serviceName","Eclinic","Scheduled Date","Scheduled_From_Time","Scheduled_To_Time","ServiceProviderName","Status"]

    // if (careplandata[i].serviceStatus === 0) {
        obj = {
          patientName: careplandata[i].patientName,
          planName: careplandata[i].planName,
          serviceName: careplandata[i].serviceName,
          Eclinic: careplandata[i].cheid,
          modifiedServiceDate: careplandata[i].modifiedServiceDate,
          Scheduled_From_Time: careplandata[i].fromTime,
          Scheduled_To_Time: careplandata[i].toTime,
          ServiceProviderName: careplandata[i].serviceProviderName,
          serviceStatus: careplandata[i].serviceStatus === "0" ? "Expired" : careplandata[i].serviceStatus === "1" ? "Yet to Start":careplandata[i].serviceStatus === "2" ? "Appointment Booked" : careplandata[i].serviceStatus === "3" ? "Completed" :careplandata[i].serviceStatus === "4" ?"Request For Update" :careplandata[i].serviceStatus === "5" ? "Cancelled" : careplandata[i].serviceStatus === "6" ? "Re-Scheduled" : "" ,
        };
   
    

 
  exportArr.push(obj)

}
console.log('EXPORT ARRAY DATAaa: \n', JSON.stringify(exportArr))

const keys = Object.keys(headerOfExcel)
let header = [...keys];

XlsxPopulate.fromBlankAsync().then(async (workbook) => {
  const sheet1 = workbook?.sheet(0);
  const sheetData = getSheetData(exportArr, exportHeading);
  const totalColumns = sheetData[0]?.length;
  sheet1.cell("A1").value(sheetData);
  const range = sheet1.usedRange();
  const endColumn = String.fromCharCode(64 + totalColumns);
  sheet1.row(1).style("bold", true);
  sheet1.range("A1:" + endColumn + "1").style("fill", "BFBFBF");
  range.style("border", true);
  return workbook.outputAsync().then((res) => {
    saveAs(res, fileName + new Date()?.getTime() + "file.xlsx");
  });
});
}

function getSheetData(data, header) {
    var fields = Object?.keys(data[0]);
    var sheetData = data?.map(function (row) {
      return fields.map(function (fieldName) {
        if (fieldName)
          return row[fieldName] ? row[fieldName] : "";
      });
    });
    sheetData.unshift(header);
    return sheetData;
  }
