import React from 'react'
import { useState } from 'react';
import "./index.css";
import { NavLink } from "react-router-dom";
import { Tooltip } from 'primereact/tooltip';
import { Dropdown } from 'primereact/dropdown';

function Index({ Menus,setMenus,handleMenuSelect, handleSubmenuSelect } ) {
  const [open, setOpen] = useState(true);
  const [open1, setOpen1] = useState(false);
  const [isHovering, setIsHovering] = useState(false);
  const [selectedMenu, setSelectedMenu] = useState(null);
  const [selectedItemId, setSelectedItemId] = useState(null);
  const [hoveredItem, setHoveredItem] = useState(null);



 

  // const [Menus, setMenus] = useState([
  //   { title: "Dashboard", src: "dashboard", id: 0,path:"/dashboard" },
  //   { title: "E-clinic", src: "eclinic", id: 1 },
  //   {
  //     title: "B2C", src: "B2C",
  //     id: 2,
  //     childrens: [
  //       {
  //         title: "Refund",
  //         nevLink: "/inbox",
  //         isSelected: true,
  //         subid: 1,
  //       },
  //       {
  //         title: "Business Events",
  //         nevLink: "/businessEvent",
  //         isSelected: false,
  //         subid: 2,
  //       },
  //       {
  //         title: "Elite appointments",
  //         nevLink: "/inventoryDoctorAppointment",
  //         isSelected: false,
  //         subid: 3,
  //       },
  //       {
  //         title: "Booked Lab Test",
  //         nevLink: "/bookLabTest",
  //         isSelected: false,
  //         subid: 4,
  //       },
  //       {
  //         title: "Translated Prescription",
  //         nevLink: "/translatedPrescription",
  //         isSelected: false,
  //         subid: 5,
  //       },
  //       {
  //         title: "Packages",
  //         nevLink: "/packages",
  //         isSelected: false,
  //         subid: 6,
  //       },
  //       {
  //         title: "Membership",
  //         nevLink: "/membership",
  //         isSelected: false,
  //         subid: 7,
  //       },
  //     ]
  //   },
  //   {
  //     title: "MediCare ", src: "careplan",
  //     id: 3,
  //     childrens: [

  //       {
  //         "title": "Patient Details",
  //         nevLink: "/bookCarePlan",
  //         subid: 1,
  //       },
  //       {
  //         "title": "Careplan Schedule",
  //         nevLink: "/careplansSchedule",
  //         subid: 2,
  //       },
  //       {
  //         "title": "Careplan SOS",

  //         nevLink: "/careplanSos",
  //         subid: 3,
  //       },
  //       {
  //         "title": "Business Event",

  //         nevLink: "/careplanbusinessevent",
  //         subid: 4,
  //       },

  //     ]
  //   },


  // ]);
  // // const handleItemClick = (menuId, submenuId,id) => {
  //   console.log(menuId, submenuId,"submenu")
  //   setSelectedItemId(menuId);
  //   setOpen1(!open1);
  //   if (submenuId) {
  //     handleMenuClick( submenuId); // Pass both menuId and submenuId to handleMenuClick
  //   } else {
  //     handleMenuClick(menuId); // Pass only menuId to handleMenuClick
  //   }
  // };

  const atClickItem = (index) => {
    let list = [...Menus];

    list.map((item) =>
      item.isSelected = false
    );

    list[index].isSelected = true;
    setMenus(list);
  };

  const handleOpen = (itemId) => {
    setHoveredItem(itemId);
    setOpen(!open);
    // setOpen1(!open1);
  };


  const handleItemClick = (id) => {
    setSelectedItemId(id);
    setOpen1(!open1);
  };


  return (

    <div className="maindiv sidebarContainer">
      <div
        className={` ${open ? "scrn1" : "scrn2 "
          } sidebar`}
      >



        {!open && <i aria-hidden="true" className={` fa fa-long-arrow-right img1 `} onClick={() => setOpen(!open)} />}
        {/* <i aria-hidden="true" className={` fa fa-long-arrow-left img1  ${!open && " imgr"}`}
          onClick={() => setOpen(!open)}></i> */}

        <div className=" div2 ">
          {open ? <img src='./images/logowithname.svg' className='logoname' /> : <img
            src="./images/logo.svg" className='logo' />}

        </div>

        <ul className="menu">
          {Menus.map((Menu, index) => (
            <li
              key={Menu.id}
              id={Menu.id}
              className={`list
            ${Menu.gap ? "top1" : "top2"} ${index === 0 && "bgclr"
                } `}
            >


              {/* {!open ? <div class="tooltip">
                <img src={`./images/${Menu.src}.svg`} className="icons " onClick={handleOpen} />
                <span class="tooltiptext">{Menu.title}</span>
              </div> : <img src={`./images/${Menu.src}.svg`} onClick={handleOpen} className="icons " />
              } */}

              <div >
                <div style={{ display: "flex" }} onClick={() => handleItemClick(Menu.id)}>
                  <div onClick={() => handleItemClick(Menu.id)}>
                    {!open ? <div class="tooltip">
                      <img src={`./images/${Menu.src}.svg`} className="icons " onClick={handleOpen} />
                      <span class="tooltiptext">{Menu.title}</span>
                    </div> : <img src={`./images/${Menu.src}.svg`} onClick={handleOpen} className="icons " />
                    }
                  </div>

                  <div >

                    <NavLink exact to={Menu?.path} activeClassName="active" style={{ textDecoration: "none" }} onClick={() => handleMenuSelect(Menu.id)} className={`${!open && "disp"}  disp2`
                    }>
                      {Menu.title}
                    </NavLink>



                    {/* {Menu?.childrens ? <i onClick={() => handleItemClick(Menu.id)} className={`${!open && "disp"}  disp2 fa fa-chevron-down dropdown`} aria-hidden="true"
                    ></i> : null} */}

                  </div>
                </div>
                
                <div style={{ display: "block" }} onClick={() => handleItemClick(Menu.id)}>
                  {selectedItemId === Menu.id && (
                    <>
                      {open1 && open && Menu?.childrens?.map(item => (
                        <NavLink

                          to={item.nevLink}
                          // onClick={() => atClickItem(index)}
                          onClick={() => handleSubmenuSelect(index, item.subid)}
                          // onClick={() => handleItemClick(item.id, item.subid)}
                          className={({ isActive }) =>
                            isActive ? "nevBlock-active nevBlock" : "nevBlock"
                          }
                        >
                          <li key={item.subid} className='submenu' onClick={handleOpen}>{item.title}</li>
                        </NavLink>
                      ))}
                    </>
                  )}
                </div>
              </div>


            </li>

          ))}

        </ul>


      </div>

    </div >
  )
}

export default Index