import React from 'react'
import { useState, useEffect } from 'react';
import axios from 'axios';
import "./sos.css";
import { Dropdown } from 'primereact/dropdown';
import { Calendar } from 'primereact/calendar';
import { Dialog } from "primereact/dialog";
import PopupSos from './PopupSos';
import DatePicker from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";
import moment from "moment";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Button } from 'primereact/button';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import http from "../../../Redux/services/http-common"


function CarePlanSos() {
  const [openDialog, setDialog] = useState(false);
  const [date, setDate] = useState(null);
  const [selectedStatus, setSelectedStatus] = useState(null);
  const [getsos, setGetsos] = useState();
  const [selectStatus, setSelectStatus] = useState();
  const [tableData, setTableData] = useState([]);
  const status = [
    { name: 'Pending', code: 'NY' },
    { name: 'Completed', code: 'RM' },

  ];

  const dropdownOptionStatus = [

    // { name: 'In progress'},
    { name: 'Completed' },

  ];
  // const [statusArray, setStatusArray] = useState([]);


  // const initialStatusArray = initialData.map(row => row.status);
  // setStatusArray(initialStatusArray);


  const prop = getsos
  useEffect(() => {

    http.get(`${process.env.REACT_APP_BASEURL}medicare/sos`)
      .then((res) => {
        console.log(res.data, "sos")

        setGetsos(res.data);
      })
      .catch((err) => {

        console.log(err, "Something went wrong1");

      })


  }, [])

    const onRowEditComplete = (e) => {
      // let _products = [...products];
      // let { newData, index } = e;

      // _products[index] = newData;

      // setProducts(_products);
  };



  function closebtn() {
    setDialog(!openDialog);
  }

  const onHide = () => {
    setDialog(false);
    window.location.reload();
  };

  const handleColumnEdit = (newValue, rowIndex) => {
    const updatedData = [...tableData];
    updatedData[rowIndex].columnName = newValue; // Replace "columnName" with the actual column name you want to edit
    setTableData(updatedData);
  };

  // const renderStatusColumn = (e, rowIndex) => {
  //   const updatedStatusArray = [...statusArray];
  //   updatedStatusArray[rowIndex] = e.value;
  //   setStatusArray(updatedStatusArray);
  
  //   // Optionally, you can make an API request here to update the status value in the backend
  // };

  //   const renderIssueresolveColumn = (options) => { 
  // return (
  //   <>
  //     <div >

  // <span> {item.item.modifyDate === null ? "" : !issueResovedate && <div>{moment(item.item.modifyDate).format('DD/MM/YYYY')}</div>}</span>


  // <DatePicker

  //   showIcon

  //   selected={issueResovedate}

  //   // onChange={(e) => setIssueResovedate(e)}
  //   onChange={(e) => onClickdate(e)}

  //   placeholderText="select"
  //   customInput={
  //     <div style={{ position: 'relative', display: "flex", flexDirection: "column" }}>
  //       <div>
  //         <input
  //           type="text"
  //           className=""
  //           style={{ border: "none" }}
  //           value={issueResovedate ? issueResovedate.toLocaleString("en-GB", { day: "numeric", month: "numeric", year: "numeric" }) : ""}

  //           readOnly

  //         />
  //       </div>
  //       <div>
  //         {item.item.modifyDate ? "" : <i className={`${edit}  fa fa-calendar fontsomenew `} aria-hidden="true"></i>}
  //       </div>
  //     </div>}
  // />
  // {/* </div> */}
  // </div>

  //   </>
  // );
  //   };





  return (
    <>
      <div>
        {/*heading with searchbar */}
        <div style={{ display: "flex ", justifyContent: "space-between", padding: "24px" }}>
          <div className='heading'>
            {/* CarePlan SOS : */}
            </div>

          <div className='' style={{ display: "flex ", gap: "10px" }}>
            <button className='addbtn' onClick={() => setDialog(true)}> Add <i class="fa fa-plus" style={{ marginLeft: "10px", fontSize: "14px" }} aria-hidden="true"></i></button>
            <div className='searchbarsos' style={{ marginBottom: "20px" }} title="Search by date">
              <i className="fa fa-search navImg" aria-hidden="true" ></i>
              <input type="text" className='placeholder' placeholder=" Quick Search" name="search"
              />
              {/* <img src="./Assets/Images/searchImg.svg" alt={"Goto icon"} className='navImg' /> */}

            </div>
          </div>
        </div>

        {/* table details */}
        {/* <DataTable value={getsos} editMode="row" dataKey="id"

          onRowEditComplete={onRowEditComplete} 
          tableStyle={{ minWidth: '50rem' }}>
          <Column field="patientName" header="Patient Name"></Column>
          <Column field="age" header="Age"></Column>
          <Column field="patientMobile" header="Mobile No."></Column>
          <Column field="patientAddress" header="Address" style={{width:"100px"}}></Column>
          <Column field="issue" header="Issue Type"></Column>
          <Column field="remarks" header="Remarks"></Column>
          <Column
        
        header="Status"
        body={(rowData, rowIndex) => (
          <Dropdown onChange={(e) => setSelectStatus(e.value)} name="status" options={dropdownOptionStatus} optionLabel="name" value={selectStatus}
          placeholder={"Select Status"} className="dropdowntdsos" />
        
        )}
      />
         
              
          
          <Column header="Issue resolve Date"></Column>
        </DataTable > */}

        <div style={{ padding: "20px" }}>

          <div className='main-table-container1' >
            <table class="my-table">
              <tr className='tableheading'>

                <th className=''>Patient Name</th>
                <th className=''>Age</th>
                <th className=''>Mobile No.</th>
                <th className=''>Address</th>
                <th className=''>Issue Type</th>
                <th className=''>Severity</th>
                <th className=''>Remarks</th>
                <th className=''>Issue Date</th>

                <th className=''>Status</th>
                <th className=''>Issue resolve Date</th>
                <th>Save</th>

              </tr>



              {getsos?.map((item, i) => {

                console.log(item, "helli")
                return (
                  <SosTabledata key={i} item={item} />





                );
              })}





            </table>

            <Dialog
              visible={openDialog}
              showHeader={true}
              resizable={false}
              draggable={false}
              blockScroll={false}
              style={{ height: "550px", width: "1000px" }}
              className=""
              header={<div><p style={{ color: "black", marginTop: "0px" }}> Add Patient Details</p></div>}
              // onHide={() => setDialog(false)}
              onHide={onHide}
            // footer={dialogFooter}
            >
              <PopupSos onHide={onHide}
              //  openDialog={openDialog}
              />
            </Dialog>



          </div>
        </div>
        {/* table end */}
      </div >


    </>
  )
}

function SosTabledata(item, key) {
  const [status, setStatus] = useState(item.item?.status);
  const [issueResovedate, setIssueResovedate] = useState();
  const [readOnly, setReadOnly] = useState(true);
  const [style, setStyle] = useState("cont1");
  const [edit, setEdit] = useState("edit");
  const [selectStatus, setSelectStatus] = useState(item.item?.status);

  const dropdownOptionStatus = [

    // { name: 'In progress'},
    { name: 'Completed' },

  ];
  console.log(item, "status");

  const handleSubmit = (item, id) => {

    const actualDate = moment(issueResovedate).format("YY/MM/DD")
    // const actualDate = item.originalServiceDate === null ? updateddate : item.originalServiceDate
    if (issueResovedate && selectStatus) {

      http.put(`${process.env.REACT_APP_BASEURL}medicare/sos/update?status=${selectStatus.name}&modify_date=${actualDate}&id=${id}`)
        .then(res => {
          // setSearchall(res.data)
          // setMyArray(res.data)
          console.log(res, "yes")
          toast(" Updated successfully");
        })
        .catch(err => {
          console.log(err, "Something went wrong2");
        });
    }

  }

  useEffect(() => {
    handleSubmit()

  }, [])

  const handelClick = () => {
    setReadOnly(!readOnly);
    setStyle("cont2")
  }
  const onClickdate = (e) => {
    // alert(e);
    setIssueResovedate(e);
    setEdit("editicon")

  }
  console.log(issueResovedate, "issue")
  return (

    <>
      <ToastContainer />
      <tr className='tr border_bottom1' key={item.item.id} id={item.item.id} >


        <td >{item.item.patientName}</td>

        <td>{item.item.age} </td>
        <td>{item.item.patientMobile}</td>
        <td>{item.item.patientAddress}</td>
        <td>{item.item.issue}</td>
        <td>{item.item.severity}</td>
        <td>

          {item.item.remarks}</td>
        <td>{item.item.createdDate === null ? "" : moment(item.item.createdDate).format("DD/MM/YYYY")}</td>
        <td>
          {item.item.status === "In progress" ? (



            <Dropdown onChange={(e) => setSelectStatus(e.value)} options={dropdownOptionStatus} optionLabel="name" value={selectStatus}
              placeholder={item.item.status} className="dropdowntdsos" />
          ) : item.item.status === "Completed" ? (
            <>{item.item.status}</>
          ) : (
            <Dropdown onChange={(e) => setSelectStatus(e.value)} options={dropdownOptionStatus} optionLabel="name" value={selectStatus}
              placeholder="In Progress" className="dropdowntdsos" />
          )}


          {/* <input type="text" name="status" onChange={(e) => setStatus(e.target.value)} value={status} className={`${style}  comntinpt `}  readOnly={readOnly} />
          <i className="fa fa-pencil-square-o fa-pencil-square-o1 fontsome" aria-hidden="true" style={{marginTop:"-40px",paddingRight:"5px"}} onClick={handelClick}></i> */}
        </td>
        <td >

          <div >

            <span> {item.item.modifyDate === null ? "" : !issueResovedate && <div>{moment(item.item.modifyDate).format('DD/MM/YYYY')}</div>}</span>


            <DatePicker

              showIcon

              selected={issueResovedate}

              // onChange={(e) => setIssueResovedate(e)}
              onChange={(e) => onClickdate(e)}

              placeholderText="select"
              customInput={
                <div style={{ position: 'relative', display: "flex", flexDirection: "column" }}>
                  <div>
                    <input
                      type="text"
                      className=""
                      style={{ border: "none" }}
                      value={issueResovedate ? issueResovedate.toLocaleString("en-GB", { day: "numeric", month: "numeric", year: "numeric" }) : ""}

                      readOnly

                    />
                  </div>
                  <div>
                    {item.item.modifyDate ? "" : <i className={`${edit}  fa fa-calendar fontsomenew `} aria-hidden="true"></i>}
                  </div>
                </div>}
            />
            {/* </div> */}
          </div>
        </td>
        <td>
          {item.item.status === "In progress" && issueResovedate ? (
            <Button icon="pi pi-check" type="submit"
              onClick={() => handleSubmit(item.item, item.item.id)} ></Button>
          ) : item.item.status === "Completed" ? (
            <Button icon="pi pi-check" type="submit"
              style={{ backgroundColor: "green" }} ></Button>
          ) : (
            <Button icon="pi pi-check" type="submit"
              onClick={() => handleSubmit(item.item, item.item.id)} ></Button>
          )}
        </td>
        {/* <td> <i className="fa fa-pencil-square-o fa-pencil-square-o1 edit" aria-hidden="true"onClick={() =>handleSubmit(item.item,item.item.id)}></i></td> */}

      </tr>




    </>
  )
}

export default CarePlanSos
