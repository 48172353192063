import React, { useEffect, useState } from "react";
// import { XIcon } from "@heroicons/react/outline";
import { useDispatch, useSelector } from "react-redux";
import patientService from "../../Redux/services/patientService";
import { APP_ROUTES } from "../../application/Router/constants/AppRoutes";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

export default function Share(props) {
  console.log(props, "props");
  const dispatch = useDispatch();
  const [name, setName] = useState(props.data.mobile);
  const [err, setErr] = useState("");
  const { userData } = useSelector((state) => state.authReducer);
  const save = (e) => {
    e.preventDefault();
  
    if (name.trim() === "") {
      setErr("Please enter the value");
    } else {
      var validation =
        /^([a-zA-Z0-9_\.\-])+\@(([a-zA-Z0-9\-])+\.)+([a-zA-Z0-9]{2,4})|([0-9]{10})+$/;
      var number = /^\d+$/;
      var data = {};
      let url = window.location.href.substring(
        0,
        window.location.href.lastIndexOf("/")
      );
      let dataURL = "";
      if (url === "https://backoffice-stage.curebay.in") {
        dataURL = "https://doctor-stage.curebay.in";
      } else if (url === "https://backoffice.curebay.com") {
        dataURL = "https://doctor.curebay.com";
      } else {
        dataURL = "https://doctor-dev.curebay.in";
      }
  
      if (validation.test(name)) {
        if (number.test(name)) {
          data = {
            patientMobileNo: name ,
            url:
              dataURL +
              "/" +
              APP_ROUTES.SHARECALL.split("/")[1] +
              "/" +
              props.data.appointmentid +
              "/" +
              props.data.consultationType,
            patientId: props.data.patientCode,
            patientName: props.data.patientName,
            userId: props.data.userid,
          };
        } else {
          data = {
            patientEmail: name,
            url:
              dataURL +
              "/" +
              APP_ROUTES.SHARECALL.split("/")[1] +
              "/" +
              props.data.appointmentid +
              "/" +
              props.data.consultationType,
            patientId: props.data.patientCode,
            userId: props.data.userid,
          };
        }
        patientService.share(data).then(
          (res) => {
            toast("Link sent to patient successfully");
            props.closeFunnew();
            // setTimeout(() => {
            //   // props.onClose();
            //   props.closeFunnew();
            // }, 1000);
          },
          (err) => {
            setErr("Something went wrong");
          }
        );
      }
       else {
        setErr("Please enter a valid email or phone");
      }
    }
  };
  
  const cancel = (e) => {
    // props.closePopup(e);
    props.onHide(e);
    e.preventDefault();
  };

  const redirectTo = (event) => {
    event.preventDefault();
    props.onClose();
  };

  useEffect(() => {}, []);

  const onChangeHandler = (fieldName, value) => {
    if (fieldName === "name") {
      setName(value);
      setErr("");
    }
  };

  return (
    <>
      <ToastContainer />
      <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
        <div className="relative w-5/6 md:w-3/6 lg:w-2/6 my-6 mx-auto max-w-3xl">
          {/*content*/}
          <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
            {/*body*/}
            <div className="rounded-lg shadow-lg bg-white-600 w-full h-112 p-5 antialiased justify-between border border-gray-200">
              <div className="flex justify-between">
                {/* <h1 className="text-medium font-medium text-2xl text-brand-secondary font-rubik">Add New Address</h1> */}
                <div onClick={redirectTo} className="h-5 cursor-pointer" />
              </div>

              <h3 style={{paddingBottom:"25px"}}>Patient Name: {props.data.patientName} </h3>

              <div  >
               
                  <form
                    onSubmit={(e) => {
                      save(e);
                    }}
                  >
                     <label> Enter Email or Mobile
                    <div  style={{display:"flex", justifyContent:"space-between",paddingBottom:"40px"}}>
                   
                    <div className={"searchbar " + (err ? "py-1" : "py-4")} style={{marginTop:"10px"}}>
                       
                      <input
                        type="text"
                        placeholder={props.data.mobile || "Enter Email/Mobile"}
                        value={name}
                        onChange={(e) => {
                          onChangeHandler("name", e.target.value);
                        }}
                        className="placeholder"
                      />
                     
                    </div>
                   
                    <div
                      
                      style={{ display: "flex",marginTop:"10px"}}
                    >
                      <input
                        type="submit"
                        value="Send"
                        className="custom-btn btn-2"
                      />
                      <button
                        type="submit"
                        onClick={cancel}
                        className="cancel-button"
                        style={{ marginLeft: "20px" }}
                        // style={{backgroundColor:"red"}}
                      >
                        Cancel
                      </button>
                    </div>
                    </div>
                    </label>
                    {err ? <span style={{ color: "red" }}>{err}</span> : null}
                  </form>
               
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
