import "./index.css";
import axios from "axios";
import searchImg from '../../Assets/Images/searchImg.svg';

import { useDispatch } from "react-redux";
import React, { useState, useEffect } from "react";
import DateView from "../../components/DateView/dateView";
import SearchView from "../../components/SearchView/searchView";
import calender_img from "../../Assets/Images/calender_img.svg";
import CaregiverDetailspage from "./CaregiverDetailspage";
import DialogAddComment from "./Popup.js";
import DialogLabTestReschedule from "../../components/DialogLabTestReschedule/dialogRefundMoney";
import DoctorAppointmentDetails from "../../components/DoctorAppointmentDetails/DoctorAppointmentDetails";
import moment from "moment";
import DropDownCancelReson from "../../components/DropDownCancelResonBusiness";
import { ToastContainer, toast } from "react-toastify";
import http from "../../../src/Redux/services/http-common.jsx"
import {
    // getCarePlandetails,
    getOrderDetails,
    onAddComment,
    updatePreferTime
} from "../../Redux/Actions/dashboardaction";

import InfiniteScroll from "react-infinite-scroll-component";

import { onConvertJsonToExcel } from "../../utils/JsonToExcelConverter";
import { debounce } from 'lodash';
import { Dropdown } from 'primereact/dropdown';
import { PrimeIcons } from 'primereact/api';
import { useLocation } from 'react-router-dom';

const BusinessEvent = () => {
    const dispatch = useDispatch();
    const location = useLocation();
    // const selectedDash = location.state?.selectedDash;
    // const datesearch = location.state?.datesearch;
    // const monthSearch = location.state?.monthSearch;
    // const yearSearch = location.state?.yearSearch;
    // const formattedMonthYear = location.state?.formattedMonthYear;

    // const selectedDash1 = location.state?.selectedDash;
    // const datesearch1 = location.state?.datesearch;
    // const monthSearch1 = location.state?.monthSearch;
    // const yearSearch1 = location.state?.yearSearch;
    // const formattedMonthYear1 = location.state?.formattedMonthYear;
   

    const [isSelectedOption] = useState("Care Plan1");
    const [preferDateTime, setPreferDateTime] = useState("");
    const [preferTime, setPreferTime] = useState("");
    const [orderId, setOrderId] = useState("");

    const [fromDate, setFromDate] = useState(
        moment().add(-1, "days").format("DD-MM-yyyy")
    );
    const [toDate, setToDate] = useState(
        moment().add("days").format("DD-MM-yyyy")
    );
    const [isDialog, setDialogView] = useState(false);
    const [isDialogLabTest, setDialogLabTest] = useState(false);
    const [hasMore, setHasMore] = useState(true);
    const [pageStart, setPageStart] = useState(1);
    const [isDialogAppointment, setDialogAppointment] = useState(false);
    const [isSelectedService, setSelectedService] = useState("2");
    const [businessEventData, setBusinessEventData] = useState([]);
    const [businessEventDataExcel, setBusinessEventDataExcel] = useState([]);
    const [orderDetails, setOrderDetails] = useState();

    const [commentText, setCommentText] = useState("");
    const [filterKey, setFilterKey] = useState("");
    const [isLoader, setLoader] = useState(false);

    const [selectedItem, setSelectedItem] = useState("");
    const [isStatus, setStatus] = useState(1);
    const [isPalaceHolder, setPalaceHolder] = useState("Select Plan");
    const [search, setSearch] = useState("");

    const [statusOfBusiness] = useState([


        {
            status: 1,
            statusText: "General Health",
        },
        {
            status: 1,
            statusText: "Diabetics Care",
        }
    ]);
    const [selectedBox, setSelectedBox] = useState('');
    const [searchValue, setSearchValue] = useState('');
    const [searchResults, setSearchResults] = useState([]);
    const [caregivername, setCaregivername] = useState()
    const [filterValue, setFilterValue] = useState('');
    const [filteredData, setFilteredData] = useState([]);
    const [showDropdown, setShowDropdown] = useState(false);

    const handleDropdownToggle = () => {
        setShowDropdown((prevState) => !prevState);
    };

    // const handleRadioChange = (event) => {
    //     setSelectedBox(event.target.value);
    // };



    // useEffect(() => {
    //     let requestBody = {}; 
 
    //     if (selectedDash === "Today") {
    //       requestBody = {
    //         type :"T",
    //         date: datesearch,
    //       };
    //     } else
    //      if (selectedDash === "Month") {
    //       requestBody = {
    //         type :"M",
    //         month: monthSearch,
    //         year :formattedMonthYear
    //       };
    //     } 
    //     else if (selectedDash === "Year") {
    //       requestBody = {
    //         type :"Y",
    //         year: yearSearch,
    //       };
          
    //     }
    //     else if (selectedDash === "All") {
    //       requestBody = {
           
    //       };
          
    //     }
    //     http.post(`${process.env.REACT_APP_BASEURL}medicare/patients`,requestBody
    //     )

    //         .then((res) => {
    //             console.log(res.data, "1234")
    //             setBusinessEventData(res.data);
    //         })


    //         .catch((err) => {

    //             console.log(err, "Something went wrong");

    //         })

    // }, [])

  
  
    useEffect(() => {
        setLoader(true);
        let payload = {
            fromDate: moment(fromDate, "DD-MM-YYYY").format("YYYY-MM-DD"),
            toDate: moment(toDate, "DD-MM-YYYY").format("YYYY-MM-DD"),
            pageNo: "1",
            pageSize: "20",
            type: "2",
            status: 1,
        };
        let filterData = businessEventData.filter((item) => item.procedureStatus == 1 && moment().isBefore(
            businessEventData.patientPlannedDate,
            "YYYY-MM-DD"
        ))
        setBusinessEventData([...filterData]);
        setLoader(false);

    }, []);





    const [careplan] = useState([


        "Name",
        "Mobile No.",
        "Email Id",
        "Age",
        "Address",

    ]);



    const onClickDate = (e) => {
        setFromDate(e);
        setToDate();
    };




    const handleDropDownClick = () => { };



    const onTextChange = (filterKey) => {
        setFilterKey(filterKey);
    };

    const onInFiniteScrollFinal = () => { };

    const onClickOrderIdMain = (item) => {
        setOrderDetails();
        setDialogAppointment(true);
        setSelectedItem(item);
        let payload = {
            patientOrderId: item?.patientOrderId,
            type: isSelectedService,
        };
        dispatch(getOrderDetails(payload)).then((res) => {
            setOrderDetails(res);
        });
    };

    const onClickAddCommentMain = (item) => {
        setDialogView(true);

        setSelectedItem(item);
    };

    const onClickEditLabTestSampleMain = (item) => {
        setPreferDateTime(new Date(item?.patientPlannedDate))
        setPreferTime(item?.preferTime)
        setOrderId(item?.patientOrderId)
        setDialogLabTest(true)
    }

    const onChangeTextRemarks = (text) => {
        setCommentText(text);
    };

    const onClickYesBtn1Dialog = () => {
        let userData = JSON.parse(localStorage.getItem("userData"));
        let payload = {
            orderId: selectedItem?.patientOrderId,
            serviceType:
                isSelectedOption === "Doctor Consultation"
                    ? "Doctor"
                    : isSelectedOption === "Hospital Enquiry"
                        ? "Hospital"
                        : isSelectedOption === "Medicine Order"
                            ? "Medicine"
                            : "LabTest",
            action: "comment",
            remarks: commentText,
            userId: userData?.firstName + " " + userData?.lastName,
        };

        dispatch(onAddComment(payload)).then((res) => {
            setDialogView(false);
        });
    };

    const onClickCancelBtnDialog = () => {
        setDialogView(false);
    };

    const loadFunc = async () => {
        let payload = {
            fromDate: moment(fromDate, "DD-MM-YYYY").format("YYYY-MM-DD"),
            toDate: moment(toDate, "DD-MM-YYYY").format("YYYY-MM-DD"),
            pageNo: pageStart + 1,
            pageSize: "20",
            type: isSelectedService,
        };

        let filter = businessEventData.filter((item) => item.procedureStatus == 1)

        if (filter.length === 0) {
            setHasMore(false);
        } else {
            setPageStart(pageStart + 1);
            if (businessEventData?.length) {



                setBusinessEventData([...businessEventData, ...filter]);
            } else {
                setBusinessEventData([...filter]);
            }

            setHasMore(true);
        }

    };



    const onClickSearch = () => {
        if (filterKey?.length == 0) {
            return;
        }
        setFilterKey("");
        setHasMore(false);
        let payload;
        if (filterKey?.length < 10) {
            payload = {
                patientOrderId: filterKey,
                type: isSelectedService,
                // fromDate: moment(fromDate, "DD-MM-YYYY").format("YYYY-MM-DD"),
                // toDate: moment(toDate, "DD-MM-YYYY").format("YYYY-MM-DD"),
            };
        } else {
            payload = {
                patientMobileNo: filterKey,
                type: isSelectedService,
                //fromDate: moment(fromDate, "DD-MM-YYYY").format("YYYY-MM-DD"),
                //toDate: moment(toDate, "DD-MM-YYYY").format("YYYY-MM-DD"),
            };
        }


        let filterData = businessEventData.filter((item) => item.procedureStatus == 1 && moment().isBefore(
            businessEventData.patientPlannedDate,
            "YYYY-MM-DD"
        ))
        setBusinessEventData([...filterData]);

    };

    const onKeyDownEnter = (e) => {
        if (e?.key === "Enter") {
            setFilterKey("");

            setHasMore(false);

            let payload;
            if (filterKey?.length < 10) {
                payload = {
                    patientOrderId: filterKey,
                    type: isSelectedService,
                    //fromDate: moment(fromDate, "DD-MM-YYYY").format("YYYY-MM-DD"),
                    //toDate: moment(toDate, "DD-MM-YYYY").format("YYYY-MM-DD"),
                };
            } else {
                payload = {
                    patientMobileNo: filterKey,
                    type: isSelectedService,
                    //fromDate: moment(fromDate, "DD-MM-YYYY").format("YYYY-MM-DD"),
                    //toDate: moment(toDate, "DD-MM-YYYY").format("YYYY-MM-DD"),
                };
            }

            let filterData = businessEventData.filter((item) => item.procedureStatus == 1 && moment().isBefore(
                businessEventData.patientPlannedDate,
                "YYYY-MM-DD"
            ))
            setBusinessEventData([...filterData]);

        }
    };






    const handleButtonClick = async () => {
        try {
          let response;
    
          if (selectedBox === 'box1') {
            if (/^\d/.test(searchValue)) {
              response = await http.get(`${process.env.REACT_APP_BASEURL}medicare/patients/search/caregiver?mobile_number=${searchValue}`);
            } else {
              response = await http.get(`${process.env.REACT_APP_BASEURL}medicare/patients/search/caregiver?giver_name=${searchValue}`);
            }
          } else if (selectedBox === 'box2') {
            if (/^\d/.test(searchValue)) {
              response = await http.get(`${process.env.REACT_APP_BASEURL}medicare/patients/search/carereceiver?receiverMobile==${searchValue}`);
            } else {
              response = await http.get(`${process.env.REACT_APP_BASEURL}medicare/patients/search/carereceiver?receiverName=${searchValue}`);
            }
          }
    
          setSearchResults(response.data); // Assuming the response is an object with a "results" property containing the array of results
        } catch (error) {
          console.error('Error fetching data:', error);
        }
      };
    
      const handleSearchChange = (value) => {
        setSearchValue(value);
      };
    
      const handleRadioChange = (e) => {
        setSelectedBox(e.target.value);
      };




    const onChangeNumber = (e) => {
        onTextChange(e.target.value)
    }

    const onKeyDown = (e) => {
        onKeyDownEnter(e)

    }

    const options = [
        { label: 'Today', value: 'Today'},
        { label: 'This Week', value: 'This Week' },
        { label: 'Other', value: 'Other' },
       
    ];

    const CustomPlaceholder = () => (
        <div>
          Select an option   <i className="pi pi-filter-fill" />
        </div>
      );


    return (
        <>
         

            <div style={{ paddingLeft: "26px" }}>

                {/* <div style={{ display: "flex", gap: "30px" }}>

                    <div style={{ display: "block" }}>
                        <div style={{ display: "flex" }}>
                            <div>

                                <input
                                    type="radio"
                                    value="box1"
                                    checked={selectedBox === 'box1'}
                                    onChange={handleRadioChange}
                                />
                            </div>
                            <div>
                                <label>Care Giver</label>
                            </div>
                        </div>

                    </div>
                    <div style={{ display: "block" }}>
                        <div style={{ display: "flex" }}>
                            <div>
                                <input
                                    type="radio"
                                    value="box2"
                                    checked={selectedBox === 'box2'}
                                    onChange={handleRadioChange}
                                />
                            </div>
                            <div>
                                <label>Care Receiver</label>
                            </div>
                        </div>



                    </div>
                </div> */}

                {/* <div style={{ display: "flex", gap: "10px", marginTop: "20px" }} onClick={handleButtonClick}> */}
                    {/* <div className="searchbar" style={{ display: "block", width: "60%" }}>

                        <input type="text" className='placeholder' value={searchValue} style={{ marginTop: "5px" }} placeholder="Search By Name / Mobile No."
                            onChange={(e) => handleSearchChange(e.target.value)} />
                    </div> */}


                    {/* <div className="">

                        <Dropdown
                            options={options}
                           
                            optionLabel="label"
                            optionValue="value"
                            optionIcon="icon"
                            placeholder={<CustomPlaceholder />}
                            className = "searchbar custom-dropdown"
                            style={{width:"160px"}}
                        />
            </div> */}

                  


                </div>





            {/* </div> */}
            {/* <ToastContainer />
            <div className="profilepage-breadcrumb"></div> */}

            {/* <div className="titleBusinessEvent"> Select Filters & Services:</div> */}

            {/* <div className="dateContainer">
                <div className="dateContainerSub">
                    <DateView
                        title="From Date"
                        value={fromDate}
                        placeholder="From Date"
                        onClickDate={onClickDate}
                    />

                    <SearchView
                        className="searchbar"
                        title="Search by Order Id/Mobile Number"
                        onTextChange={onTextChange}
                        onClickSearch={onClickSearch}
                        onKeyDownEnter={onKeyDownEnter}
                        filterKey={filterKey}
                    />
                </div>

                <div className="dateContainerSub">
                    <DateView
                        title="To Date"
                        value={toDate}
                        placeholder="To Date"
                        onClickDate={onClickToDate}
                        fromDate={fromDate}
                    />

                    <DropDownCancelReson
                        style={{ marginTop: 20 }}
                        onArrowClick={handleDropDownClick}
                        onOptionClick={handleDropDownSelectStatus}
                        options={statusOfBusiness}
                        placeholder="Select Service"
                        isPalaceHolder={isPalaceHolder}
                    />

                </div>
            </div> */}

            {/* selected date from to date */}
            {/* <div className="hospitalEnquiryBody">
                {fromDate && toDate ? (
                    <div className="selectedDateContainer">
                        <img src={calender_img} alt={"Goto icon"} className="calenderImg" />
                        <p className="selectDate">
                            Selected date results from: {fromDate} to {toDate}
                        </p>
                    </div>
                ) : (
                    <div></div>
                )}

                <div className="hospitalEnquiryBody">
                    <p className="response">
                        {" "}
                        Total there are {businessEventData?.length} responses
                    </p>

                    {businessEventDataExcel?.length > 0 && (
            <p className="downLoadFile" onClick={() => saveAsExcel()}>
              Download File
            </p>
          )}
                </div>
            </div> */}

            <div
                id="scrollableDiv"
                // className="listNewContainer"
                // style={{
                //     height: 800,
                //     overflow: "auto",
                // }}
            >
                <InfiniteScroll
                    dataLength={businessEventData.length}
                    next={loadFunc}
                    hasMore={hasMore}
                    pullDownToRefreshThreshold={50}
                    scrollableTarget="scrollableDiv"
                ></InfiniteScroll>

                <CaregiverDetailspage
                    selectedBox={selectedBox}
                    searchResults={searchResults}
                    patientList={businessEventData}
                    careplanboxlist={careplan}
                    isSelectedOption={isSelectedOption}
                    onClickOrderIdMain={onClickOrderIdMain}
                    onClickAddCommentMain={onClickAddCommentMain}
                    onInFiniteScrollFinal={onInFiniteScrollFinal}
                    onClickEditLabTestSampleChild={onClickEditLabTestSampleMain}
                    isLoader={isLoader}
                    hasMore={hasMore}
                    
                />
            </div>

            {/* {isDialogAppointment && (
                <DoctorAppointmentDetails
                    onClickYesBtn1={onClickYesBtn1}
                    onClickCancelBtn={onClickCancelBtn}
                    // isSelectedOption={isSelectedOption}
                    isDialog={isDialogAppointment}
                    orderDetails={orderDetails}
                    selectedItem={selectedItem}
                    formActivity="business"
                />
            )} */}

            {/* {isDialog && (
                <DialogAddComment
                    onClickYesBtn1={onClickYesBtn1Dialog}
                    onClickCancelBtn={onClickCancelBtnDialog}
                    isDialog={isDialog}
                    isApproveOrReject={true}
                    onTextChange={onChangeTextRemarks}
                    remarks={commentText}
                />
            )} */}

            {isDialog && (
                <DialogAddComment
                    //   onClickYesBtn={onClickYesBtnDialog}
                    //   onClickCancelBtn={onClickCancelBtnDialog}
                    isDialog={isDialog}
                    isApproveOrReject={true}
                    onTextChange={onChangeTextRemarks}
                    remarks={commentText}
                />
            )}


            {/* {isDialogLabTest && (
        <DialogLabTestReschedule
          onClickYesBtn={onClickYesBtnLabTest}
          onClickCancelBtn={onClickCancelBtnLabTest}
          isDialog={isDialogLabTest}
          preferredLabTime={preferredLabTime}
          onSelectChange={onSelectChange}
          preferDateTime={preferDateTime}
          onPreferTime={onPreferTime}
          preferTime={preferTime}
         
        />
      )} */}
        </>
    );
};

export default BusinessEvent;
