import React, { useState } from "react";
import "./index.css";
function DialogAddComment({
  onClickYesBtn,
  onClickCancelBtn,
  isApproveOrReject,
  onTextChange,
  remarks
}) {


  const [isErrorMsg, setErrorMsg] = useState(false);
  const onClickYes = () => {

    if (remarks?.length === 0) {
      setErrorMsg(true)
      return
    }
    onClickYesBtn();
  };

  const onClickCancel = () => {


    onClickCancelBtn();
  };

  const onChangeText = (e) => {
    onTextChange(e.target.value);

    if (e.target.value.length > 0) {
      setErrorMsg(false)

    }


  };

  return (
    <div className="panelist-overlay_new">
      <div className="panelist-statusUpdateBox">
        <div className="panelist-overlayUpdateStatusContainer" style={{ height: 300 }}>
          <div className="panelist-approveRejectIconContainer">


            <p className="panelist-overlayUpdateStatus">
              Remark
            </p>

            <div className="">
              <textarea className="typeReasonbisuness typeReasonNew"

                type="text"

                placeholder={

                  "Please Enter Remarks..."



                }

                onChange={(e) => onChangeText(e)}></textarea>
            </div>

            <div className="statusRejectDiv">



              {
                isApproveOrReject !== "Approve" && isErrorMsg ?
                  <div className="statusMsg">Please Enter comment</div> : <div className="statusMsg"></div>

              }
            </div>



            <div className="btnContainer">
              <div className="primaryBtn" onClick={onClickYes}>
                <div className="textYesRefund">
                  {" "}
                  Save

                </div>
              </div>

              <div className="cancelBtn" onClick={onClickCancel}>
                <div className="textCancel">Cancel</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default DialogAddComment;
