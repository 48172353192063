import React from 'react';
import './header.css';
import { useState, useEffect, useRef } from 'react';
import { NavLink, useNavigate,useLocation } from "react-router-dom";
import CureBayLogo from "../../Assets/Images/CureBayLogo.svg";
import userIcon from "../../Assets/Images/nav_user_img.svg";
import logoutIcon from "../../Assets/Images/logout.svg";
import arr from "../../Assets/Images/arr.svg";
import axios from 'axios';
import http from "../../../src/Redux/services/http-common.jsx"
import {
  clearDataFromStore,
  getDataFromStore,
} from "../../CommonUtils/commonUtils";
import { Button } from 'primereact/button';
import { Toast } from 'primereact/toast';
import { OverlayPanel } from 'primereact/overlaypanel';
import { Navigate } from 'react-router-dom';

function Header({ text, items }) {
  console.log(items,"hwllo")
  const location = useLocation();
  const navigate =useNavigate();
  const [currentSubmenu, setCurrentSubmenu] = useState('');
  const [getNotific, setgetNotific] = useState([]);
  const op = useRef(null);
  const toast = useRef(null);

  const [selectedMenuTitle, setSelectedMenuTitle] = useState("");
  const [selectedSubMenuTitle, setSelectedSubMenuTitle] = useState("");


  // Effect to update the selected menu item's title based on the route
  useEffect(() => {
    // Get the current route path
    const currentPath = window.location.pathname;

    // Find the selected menu and submenu items based on the route
    const selectedMenuItem = items.find(
      (menuItem) =>
        menuItem.childrens?.some((child) => child.nevLink === currentPath) ||
        menuItem.path === currentPath
    );

    let selectedMenu = "";
    let selectedSubMenu = "";

    if (selectedMenuItem) {
      selectedMenu = selectedMenuItem.title;

      // If the selected menu item has submenus, find the selected submenu
      if (selectedMenuItem.childrens) {
        const selectedSubMenuItem = selectedMenuItem.childrens.find(
          (child) => child.nevLink === currentPath
        );
        if (selectedSubMenuItem) {
          selectedSubMenu = selectedSubMenuItem.title;
        }
      }
    }

    // Update the selected menu and submenu titles
    setSelectedMenuTitle(selectedMenu);
    setSelectedSubMenuTitle(selectedSubMenu);
  }, [items]);

useEffect(() => {
  http.get(`${process.env.REACT_APP_BASEURL}medicare/getAllNotificationRequest`)
  .then((res) => {

    setgetNotific(res.data);
    console.log(res.data, "asdfghQWERTTYYUUUII")
  })
  .catch((err) => {

    console.log(err, "Something went wrong1");

  })
}, []);
const getAllNot =(e) =>{
  op.current.toggle(e);
  // toast.current.show({severity:'info', summary: 'Info', detail:'Message Content', life: 3000});
  http.get(`${process.env.REACT_APP_BASEURL}medicare/getAllNotificationRequest`)
      .then((res) => {

        setgetNotific(res.data);
        console.log(res.data, "asdfghQWERTTYYUUUII")
      })
      .catch((err) => {

        console.log(err, "Something went wrong1");

      })
}


const [userrole, setUserlore] = useState("");

  useEffect(() => {
     
      let userData = JSON.parse(localStorage.getItem("userData"));
      setUserlore(userData?.role);
  }, []);

console.log(getNotific,"getNotific")
  return (
    <>

      <div className='headercss row' >
      <Toast ref={toast} />
        <div style={{ width: "50%" }}>
          <h6 style={{ marginTop: '0px' }} className='subheaderCss'>
          {selectedMenuTitle || "Default Menu"}
          {selectedSubMenuTitle && ` : ${selectedSubMenuTitle}`} </h6>
        </div>
      
        <div className='loginflowcss'> <UserDropDown />
        {/* <div style={{marginRight:"20px",float:"right",marginTop:"5px"}}>
      
        {userrole == "ADMIN" ?
        <button type="button" className="icon-button" onClick={(e) => navigate('./useradmin')}
>

        <span className="material-icons" style={{fontSize:"20px"}}> <i class="fa fa-user-plus" aria-hidden="true"></i></span>
      
      </button>
:""}
      
 
    </div> */}
        <div style={{marginRight:"20px",float:"right",marginTop:"5px"}}>
      
          <button type="button" className="icon-button" onClick={(e)=>getAllNot(e)}>

            <span className="material-icons"> <i class="pi pi-bell bellcss" ></i></span>
            <span className="icon-button__badge">{getNotific.length}</span>
          </button>
        {/* </Button> */}
        </div>
        </div>
      </div>
      <OverlayPanel ref={op} className="custom-overlay">
      {getNotific?.map((item, i) => {
        return(
          <>
     <div key={i}>
          {item?.remarks}
          </div>
          <hr></hr>
          </>
        )
      })}
   {/* <div>hello</div> */}
</OverlayPanel>

    </>
  )
}

const UserDropDown = () => {
  const navigate = useNavigate();
  useEffect(() => {
    if (!getDataFromStore("loggedIn")) {
      navigate("/login", { replace: true });
    }
  }, [navigate]);
  const [isClicked, setClicked] = useState(false);

  const logout = () => {
    clearDataFromStore("loggedIn");
    clearDataFromStore("token");
    clearDataFromStore("userData");
    navigate("/login", { replace: true });
  };
  const handleDropDown = () => {
    setClicked(!isClicked);
  };
  const userDropDownRef = useRef(null);
  useEffect(() => {
    const handleOutSideClick = (e) => {
      if (!userDropDownRef?.current?.contains(e.target)) {
        setClicked(false);
      }
    };
    if (isClicked) {
      window.addEventListener("mousedown", handleOutSideClick);
    } else {
      window.removeEventListener("mousedown", handleOutSideClick);
    }
  }, [isClicked]);
  const [fullName, setFullName] = useState(null);
  const [timeStatus, setTimeStatus] = useState("");

  useEffect(() => {
    try {
     
      let userData = JSON.parse(localStorage.getItem("userData"));
      setFullName(userData?.firstName + " " + userData?.lastName);

      var today = new Date();
      var curHr = today.getHours();

      if (curHr < 12) {
        setTimeStatus("Good Morning !");
      } else if (curHr < 18) {
        setTimeStatus("Good Afternoon !");
      } else {
        setTimeStatus("Good Evening !");
      }
    } catch (e) {
      setFullName(null);
    }
  }, []);
 
  return (
    <div ref={userDropDownRef} className="userDropDown-iconContainer" style={{ float: "right" }}>
      <div style={{ display: "flex", marginLeft: "10px" }}>
        <div className="userDropDown-icon" style={{marginTop:"3px"}} onClick={handleDropDown}>
          <img src={userIcon} alt={"user icon"} style={{height:"26px"}} />
        </div>
        <div style={{
          display: "block", marginTop: "5px",
          marginLeft: "10px"
        }}>
          {/* <div>Prakash Shingadiya</div> */}
          {fullName && <div style={{ marginRight: "20px" }}> {fullName}</div>}
          <span style={{ fontSize: "14px" }}>Customer Service</span>
        </div>
      </div>
      {isClicked && (
        <div className="userDropDown-container">

          <div style={{ color: "rgba(255, 255, 255, 0.6)" }}>{timeStatus}</div>
          <div className="userDropDown-blockContainer">
            <NavLink
              to="/profile"
              // onClick={handleDropDown}
              className="userDropDown-block"
            >
              Profile{" "}
              <img src={arr} style={{ marginLeft: "auto" }} alt={"Goto icon"} />
            </NavLink>
          </div>
          <div className="userDropDown-logout" onClick={logout}>
            Logout{" "}
            <img
              style={{ marginLeft: "auto" }}
              src={logoutIcon}
              alt="logout icon"
            />
          </div>
        </div>
      )}
    </div>
  );
};

export default Header