import React, { useState, useEffect } from 'react'
import { useDispatch } from "react-redux";
import { useNavigate } from 'react-router-dom';
import floral from "../../Assets/Images/floral.svg"
import eyeIcon from "../../Assets/Images/eye.svg"
import doctorBg from "../../Assets/Images/docterSection.png"
import "./index.css"
import axios from 'axios';
import { encryptData, decryptData } from "../../helper/encryptData";
import { Base64 } from "js-base64";


import {
    getBearerToken,
    login
} from "../../Redux/Actions/dashboardaction";

import { getDataFromStore, setDataInStore } from "../../CommonUtils/commonUtils";

const CureBayLogin = () => {
    const [emailId, setEmailId] = useState("")
    const [password, setPassword] = useState("");
    const [errorMsg, setErrorMsg] = useState("");
    const [passwordType, setPasswordType] = useState(false)


    const dispatch = useDispatch();
    const navigate = useNavigate();
    // const loginData = useSelector((state) => state.loginData);

    //const { isTokenSet, loggedIn, isUserValid } = loginData;



    useEffect(() => {
        let token = localStorage.getItem('token');
        let storageLoggedIn = localStorage.getItem('loggedIn');
        if (token && storageLoggedIn === true) {
            navigate("/", { replace: true })
        }
        return () => {
            // clanup code
        };
    }, [navigate])


    // useEffect(() => {
    //     // console.log('isTokenSet', isTokenSet);
    //     if (isTokenSet) {
    //         // let token = localStorage.getItem('token');
    //         let token = getDataFromStore("token")
    //         dispatch(login({ emailId, password, token, channel: loginData.channel }))
    //     }

    // }, [isTokenSet, dispatch, emailId, loginData.channel, password])

    useEffect(() => {
        let token = localStorage.getItem('token');
        if (token) {
            setLoginFlag();
            navigate("/", { replace: true })
        }
    }, [navigate])

    // useEffect(() => {

    //     if (loginData.isUserValid === false) {
    //         setErrorMsg('Invalid user id or password');
    //     }
    //     return () => {
    //         // cleanup code
    //     };
    // }, [isUserValid, loginData.isUserValid])

    const setLoginFlag = async () => {
        await setDataInStore("loggedIn", true)
    }

    const handleSubmit = async (e) => {
        e.preventDefault();
        setErrorMsg('');
        if (emailId === "" || password === "") {
            setErrorMsg("Please fill required fields");
            return
        } else {
            let load = {
                email: emailId,
                password: password
            }
            const encryptedData = encryptData(load);
            dispatch(getBearerToken(encryptedData)).then((resToken) => {
                let load2 = {
                    userCode: emailId,
                    passWord: Base64.encode(password)
                }
                const encryptedData2 = encryptData(load2);
                dispatch(login(encryptedData2)).then((res) => {
                    if (res.user.userRolesList == null) {
                        setErrorMsg("Invalid Login Details");
                        return;
                    }
                    const userLog =
                        res.user.userRolesList &&
                        res.user.userRolesList.filter(
                            (val) => val.roleCode === "BO"
                        );
                    if (!userLog || userLog.length === 0) {
                        setErrorMsg("Invalid Login Details");
                        return;
                    } else {
                        localStorage.setItem('token', resToken?.access_token);
                        navigate("/", { replace: true })
                        localStorage.setItem('userData', JSON.stringify(res?.user));
                    }
                }).catch((err) => {
                    setErrorMsg(err?.response?.data?.details[0]);
                });

            });
        }
    }




    return (
        <div style={{ display: "flex", height: "100vh", overflowY: "hidden" }}>
            <div className='login-bgSection'>
                {/* <img style={{ marginLeft: "auto", display: "block", padding: 30 }} src={cureBayLogo} /> */}
                <img style={{ width: "100%", height: "100%", transform: "scale(1.02) translateX(-1rem)" }} src={doctorBg} alt={"Doctor Bg"} />
            </div>
            <div className='login-formSection'>
                <div className='login-formSectionContainer'>
                    <h1 className='login-heading'>CureBay</h1>
                    <h3 className='login-tagline'>Improving Lives Together.</h3>
                    <img src={floral} width="200px" style={{ display: "block", margin: "auto" }} alt={"Floral"} />
                    <p className='login-subheading'>Please Log In</p>
                    <form className='login-formContainer' onSubmit={handleSubmit}>
                        <label htmlFor='emailId'>Enter Your Email Id</label>
                        <input type="text" id='emailId' placeholder='Enter Email Id' name='emailId' value={emailId}
                            onChange={(e) => setEmailId(e.target.value)} pattern="^[a-zA-Z0-9+_.-]+@[a-zA-Z0-9.-]+[.][a-zA-Z0-9.-]{2,}$" />

                        <label htmlFor='password'>Enter Your Password</label>
                        <div style={{ position: "relative" }}>
                            <input placeholder='Enter Password' id="password"
                                type={passwordType ? "text" : "password"}
                                name='password'
                                value={password}
                                onChange={(e) => setPassword(e.target.value)} />

                            {password &&
                                <img
                                    onPointerDown={() => {
                                        setPasswordType(true)
                                    }}
                                    onPointerUp={() => {
                                        setPasswordType(false)
                                    }}
                                    style={{ cursor: "pointer", position: "absolute", right: 6, top: 5, borderRadius: 5, padding: 5, background: passwordType && "#004171" }} src={eyeIcon}
                                    alt="View Password"
                                />

                            }
                        </div>
                        <p style={{ color: "#004171", fontSize: "26px", fontWeight: 900, cursor: "pointer" }} onClick={() => navigate('/changePassword')}>
                            Change your password?
                        </p>
                        <p className='login-ErrorMsg'>{errorMsg}</p>

                        <button type='submit' className='login-formBtn'>Log In</button>
                    </form>
                </div>
            </div>
        </div>
    )
}

export default CureBayLogin