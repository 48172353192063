import React, { useEffect } from "react";
import { useState } from "react";
import status_approved from "../../Assets/Images/status_approved.svg";
import { ReactDialogBox } from "react-js-dialog-box";
import "./index.css";
import "react-js-dialog-box/dist/index.css";
import Loader from "../../components/Loader/Loader";
import { getLocalTime, getLocalOnlyDate } from "../../utils/LocalTimeFormat";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useDispatch } from "react-redux";
import moment from "moment";
import {
    onAddpackagestatus,
} from "../../Redux/Actions/dashboardaction";

function PackagePopup({
    particularpackageData,


    onClickCancelBtn,
    isSelectedOption,
    orderDetails,
    selectedItem,
    formActivity,
    onTextChange,
}) {
    const dispatch = useDispatch();
    const [isErrorMsg, setErrorMsg] = useState(false);
    const [commentTextnew, setCommentTextnew] = useState("");
    const [remarks, setRemarks] = useState(selectedItem?.boRemarks);

    // const onChangeTextRemarksnew = (text) => {
    //   setCommentTextnew(text);
    // };

    console.log(selectedItem, "packageData")

    const onChangeText = (e) => {
        setCommentTextnew(e.target.value);

        if (e.target.value.length > 0) {
            setErrorMsg(false)

        }


    };
    const onClickCancel = () => {
        onClickCancelBtn();
    };

    console.log("dlssm", JSON.stringify(selectedItem));
    const [submitData, setSubmitData] = useState({})
    const handleChange = (e) => {
        setSubmitData({
            ...submitData,
            [e.target.name]: e.target.value
        })
    }





    const handleSubmit = (e) => {
        e.preventDefault();

        console.log(submitData, "status data");
        let userData = JSON.parse(localStorage.getItem("userData"));

        // let payload = {
        //     patientPackageId: selectedItem?.patientPackageId,
        // //   status: commentTextnew,
        // //   boRemarks: remarks,
        // //   patientId: selectedItem?.patientId,
        // //   createdBy: userData?.firstName + " " + userData?.lastName,

        // };

        // console.log(payload, "payloadio");
        // dispatch(onAddpackagestatus(payload)).then((res) => {

        //   toast("Status Update Sucessfully")
        //   updateComplete();
        // });

    }
    console.log(selectedItem, "mon")
    return (

        <div className="dialogContainer" style={{ overflowY: "scroll" }}>
            <>
                <ToastContainer />
                <ReactDialogBox
                    closeBox={onClickCancel}
                    modalWidth="80%"
                    // modalHeight="60%"
                    headerBackgroundColor="#004171"
                    headerTextColor="white"
                    closeButtonColor="white"
                    bodyBackgroundColor="white"
                    bodyTextColor="black"
                    //   headerText={` - Order ID :`}
                    headerText={`Order ID :  ${selectedItem.patientPackageId !== undefined
                        ? selectedItem.patientPackageId
                        : ""
                        }`}
                >
                    <div className="dialogContainer" style={{ marginTop: "2px" }}>
                        <div >

                            <div className="titleOfNew">Patient Details</div>
                            <div className="subContainer" style={{marginTop:"20px"}}>

                                <div className="main">
                                    <div className="titleMain">Order ID</div>

                                    <div className="titleOfDialog" style={{marginTop:"20px"}}>
                                        {selectedItem?.patientPackageId}
                                    </div>
                                </div>

                                <div className="main">
                                    <div className="titleMain">Patient Details</div>

                                    <div className="titleOfDialog" style={{marginTop:"20px"}}>
                                        {selectedItem?.patientName}
                                    </div>
                                    <div className="titleOfDialog" style={{marginTop:"20px"}}>
                                        {selectedItem?.mobile}
                                    </div>
                                    <div className="titleOfDialog" style={{marginTop:"20px"}}>
                                        {selectedItem?.patientCode}
                                    </div>
                                </div>

                                <div className="main">
                                    <div className="titleMain">Package Details</div>

                                    <div className="titleOfDialog" style={{marginTop:"20px"}}>
                                        {selectedItem?.packageName}
                                    </div>

                                    <div className="titleOfDialog" style={{marginTop:"20px"}}>
                                        {selectedItem?.packageCode}
                                    </div>
                                </div>


                                <div className="main">
                                    <div className="titleMain">Period</div>

                                    <div className="titleOfDialog" style={{marginTop:"20px"}}>
                                        {moment(selectedItem?.fromDate).format("DD-MM-YYYY")} - {moment(selectedItem?.toDate).format("DD-MM-YYYY")}
                                    </div>
                                </div>

                                <div className="main">
                                    <div className="titleMain">Payment</div>

                                    <div className="titleOfDialog" >
                                        <p className="patientId" >

                                            Total - ₹ {selectedItem?.totalAmount}
                                        </p>

                                        <p className="patientId" >

                                            Paid - ₹ {selectedItem?.discountedAmount}
                                        </p>
                                       
                                    </div>
                                </div>

                                <div className="main">
                                    <div className="titleMain">Status</div>

                                    <div className="titleOfDialog" style={{marginTop:"20px"}}>
                                      

                                           {selectedItem?.status}
                                       
                                       
                                    </div>
                                </div>



                            </div>
                        </div>

                        <div className="main">
                            <div className="titleOfNew">Package Details</div>
                            <div className="mainTestCode" style={{ marginTop: "15px" }}>
                                <div className="titleMain">Service Name</div>
                                <div className="titleMain">Service Provider</div>
                                <div className="titleMain">Amount</div>
                                <div className="titleMain">Paid</div>
                                <div className="titleMain">Total Quantity</div>
                                <div className="titleMain">Pending Quantity</div>
                            </div>
                            {
                                <div className="medicineList">
                                    {particularpackageData?.map((item) => (
                                        <div className="subContainer">
                                            {/* <div className="main">
                            <div className="titleOfDialog">
                              {item?.description}
                            </div>
                          </div> */}
                                            <div className="main">
                                                <div className="titleOfDialog">
                                                    {item?.packageServiceName}

                                                </div>
                                            </div>


                                            <div className="main">
                                                <div className="titleOfDialog">
                                                    {item?.serviceProvider}
                                                </div>
                                            </div>

                                            <div className="main">
                                                <div className="titleOfDialog">
                                                ₹ {item?.actualAmount}
                                                </div>
                                            </div>

                                            <div className="main">
                                                <div className="titleOfDialog">
                                                ₹ {item?.discountedAmount}
                                                </div>
                                            </div>

                                            <div className="main">
                                                <div className="titleOfDialog">
                                                    {item?.quantity}
                                                </div>
                                            </div>

                                            <div className="main">
                                                <div className="titleOfDialog">
                                                    {item?.remaining}
                                                </div>
                                            </div>


                                        </div>
                                    ))}
                                </div>
                            }
                        </div>

                        <div>

                        </div>
                    </div>
                    {/* </div> */}
                </ReactDialogBox>
            </>
        </div>
    );
}

export default PackagePopup;
