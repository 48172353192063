import DatePicker from "react-datepicker";
import { useState, React } from "react";
import { useEffect } from "react";

import { useRef } from "react";
import axios from "axios";
import http from "../../../src/Redux/services/http-common.jsx"
import "react-datepicker/dist/react-datepicker.css";
import "./index.css";
import moment from "moment";
import searchImg from "../../Assets/Images/searchImg.svg";
import { Button } from "primereact/button";
import { useLocation } from "react-router-dom";

import { Calendar } from "primereact/calendar";
import { Dropdown } from "primereact/dropdown";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import MyDatePickerComponent from "./MyDatePickerComponent";
import { Dialog } from "primereact/dialog";
import { useNavigate } from "react-router-dom";

import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Tag } from "primereact/tag";
import { InputText } from "primereact/inputtext";
import { OverlayPanel } from "primereact/overlaypanel";
import { ColumnGroup } from "primereact/columngroup";
import { Row } from "primereact/row";
import CareplanSchedulepopup from "./CareplanSchedulepopup";
// import Loader from '../../components/Loader/Loader'
// import { Tooltip } from 'react-tooltip';

import { Sidebar } from "primereact/sidebar";

import { debounce } from "lodash";
import handleSave from "../../Screens/CarePlan/CareplanSchedulepopup";

import { Rating } from "primereact/rating";
import { useParams } from "react-router-dom";
import Share from "./Share";
import { APP_ROUTES } from '../../application/Router/constants/AppRoutes';
// import { ToastContainer, toast } from "react-toastify";
import { jsonToExcelConvetercareplan } from "../../utils/jsonToExcelConvetercareplan";
function CareplansSchedule({}) {
  const location = useLocation();
  const receiverName = location.state?.receiverName;
  console.log(receiverName, "receiverName");
  const data = location.state?.data;
  const searchData = location.state?.searchData;
  const receiverdata = location.state?.receiverdata;
  // const year = location.state?.year;
  // const month = location.state?.month;
  const locationState = location.state || {};
  const year = locationState.year || new Date().getFullYear();
  const month = locationState.month || new Date().getMonth() + 1;
  //   const [editedDates, setEditedDates] = useState({});
  const navigate = useNavigate();
  const [modifiedServiceDate, setModifiedServiceDate] = useState();
  const [selectedDate1, setSelectedDate1] = useState(null);
  const [rowData, setRowdate] = useState(data);
  const [selectedPlan, setSelectedPlan] = useState(null);
  const [selecteDate, setSelectedDate] = useState("");
  const [allplan, setAllplan] = useState();
  const [planName1, setplanName1] = useState();
  const [planids, setplanids] = useState();
  const [searchMonth, setSearchMonth] = useState("");
  const [searchName, setSearchName] = useState("");
  const [searchPlan, setSearchPlan] = useState("");
  const [searchall, setSearchall] = useState(data);
  const [selectedOptionId, setSelectedOptionId] = useState(null);
  const [id, setId] = useState("");
  const [selectedId, setSelectedId] = useState(null);
  const [value, setValue] = useState(searchData);
  const [myArray, setMyArray] = useState(searchall);

  const [updatedlist, setUpdatedlist] = useState(null);
  const [expandedRows, setExpandedRows] = useState(null);
  const [statuses] = useState(["Yet To Start", "In Progress", "Complete"]);
  const [consultationTypes] = useState(["In Person", "Home Visit", "Video"]);

  const [frommtime, setFromtime] = useState();
  const [totime, setTotime] = useState();
  const [serviceprovider, setServiceprovider] = useState();
  const [serviceproviderNo, setServiceproviderNo] = useState();

  // const [selectStatus, setSelectStatus] = useState(null);

  const op1 = useRef(null);
  const op2 = useRef(null);
  const [comment, setComment] = useState(" ");
  const [scheduledata, setScheduledata] = useState(rowData);

  const [isEditing, setIsEditing] = useState(false);

  const [getApi, setGetapi] = useState();

  const [rowid, setRowid] = useState();
  const [visible, setVisible] = useState(false);
  const [sharelinkvisible, setSharelinkvisible] = useState(false);
  const [position, setPosition] = useState("center");
  const [selectedFile, setSelectedFile] = useState(null);
  const [rowData1, setRowData1] = useState(null);

  const [selectedRowData, setSelectedRowData] = useState(null);
  const [sideDialog, setSideDialog] = useState(false);
  const [filters, setFilters] = useState({});
  const [selectedStatus, setSelectedStatus] = useState("");
  const [share, setShare] = useState(false);
  const [appID, setAppointmentId] = useState();
  const [careplanDataExcel, setCareplanDataExcel] = useState([]);

  const handleRowClick = (rowData) => {
    setSelectedRowData(rowData);
    setSideDialog(true);
  };
  const closeSidebar = () => {
    setSideDialog(false);
    toast("Appointment Booked");
  };
  //   const showSuccess = () => {
  //     toast.current.show({severity:'success', summary: 'Success', detail:'Appointment Booked', life: 3000});

  // }

  const showDialog = (rowData) => {
    setRowData1(rowData);
    setVisible(true);
  };

  const hideDialog = () => {
    setVisible(false);
  };

  const hideDialogSharelink = () => {
    setSharelinkvisible(false);
  };

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    setSelectedFile(file);
    const reader = new FileReader();

    reader.onload = () => {
      const base64String = reader.result;
      console.log("File converted to base64:", base64String);

      // Display the document
      const documentContainer = document.getElementById("document-container");
      documentContainer.innerHTML = "";

      // Handle different file types
      if (file.type.startsWith("image/")) {
        // Display image
        const imgContainer = document.createElement("div");
        imgContainer.style.display = "flex";
        imgContainer.style.alignItems = "center";

        const imgElement = document.createElement("img");
        imgElement.src = base64String;
        imgElement.classList.add("imagecss");
        imgElement.style.height = "200px"; // Set the desired height here
        imgElement.style.marginRight = "10px";
        imgContainer.appendChild(imgElement);

        const imageSize = document.createElement("p");
        imageSize.textContent = `Size: ${file.size} bytes`;
        imgContainer.appendChild(imageSize);

        const cancelButton = document.createElement("button");
        cancelButton.textContent = "Cancel";
        cancelButton.classList.add("cancel-button");
        cancelButton.addEventListener("click", () => {
          documentContainer.innerHTML = ""; // Remove the image
        });
        imgContainer.appendChild(cancelButton);

        documentContainer.appendChild(imgContainer);
      } else {
        // Display file name as a link
        const documentLink = document.createElement("a");
        documentLink.href = base64String;
        documentLink.textContent = file.name;
        documentContainer.appendChild(documentLink);
      }
    };

    reader.readAsDataURL(file);
  };

  const handleUpload = () => {
    if (selectedFile && rowData1) {
      const reader = new FileReader();
      console.log(selectedFile, rowData1, "assddff");

      reader.onload = () => {
        const base64String = reader.result;
        const key = base64String.split(",")[1];
        console.log("File converted to base64:", base64String);
        console.log(rowData1.patientCode, "dcfgvbhjnmkkktgh");
        const visitDate = rowData1.modifiedServiceDate ? moment(rowData1.modifiedServiceDate).format("YYYY-MM-DD"):"";
        const visitTime = rowData1.fromTime
        const concatenatedDateTime = `${visitDate} ${visitTime}:00`;
        console.log(concatenatedDateTime,visitDate,visitTime,"concatenate")

        http
          .post(
            `${process.env.REACT_APP_BASEURL}patientDocument/uploadPrescription`,
            {
              appointmentId: rowData1.appointmentid,
              createdBy: rowData1.patientCode,
              modifiedBy: rowData1.patientCode,
              documentType: selectedFile.type.split("/")[1],
              status: 1,
              patientId: rowData1.patientCode,
              visitDate : concatenatedDateTime,
              prescription: {
                createdBy: rowData1.patientCode,
                diagnositicNotes: "string",
                modifiedBy: "AEJYVSSK080322",
                patientId: rowData1.patientCode,
                status: 1,
                userId: rowData1.userid,
              },
              patientName: rowData1.patientName,
              patientMobileNo: rowData1.mobile,
              patientDocument: key,
            }
          )
          .then((response) => {
            console.log("File uploaded successfully:", response.data);
            toast("Prescription uploaded successfully");
            hideDialog();
          })
          .catch((error) => {
            console.error("Error uploading file:", error);
            // Handle any errors
          });
      };

      reader.readAsDataURL(selectedFile);
    }
  };

  // const initialDate = new Date(year, month - 1, 1);
  const initialDate =
    locationState.year && locationState.month
      ? new Date(year, month - 1, 1)
      : null;

  console.log([data].comments, "help");

  // const transMonth = month || formattedMonth;
  // const transYear = year || formattedMonth;

  const moment = require("moment");

  const yearGet = moment().format("YYYY");
  const monthGet = moment().format("MM");

  const showSide = (position, rowData) => {
    console.log("Button clicked with rowData:", rowData);
    setPosition(position);
    setVisible(true);
  };

  const currentYear = moment().year();
  const currentMonth = moment().month() + 1;

 

 
    const formattedYear = receiverName && selectedDate1
  ? moment(selectedDate1).format("YYYY")
  : receiverName
  ? ""
  : selectedDate1? moment(selectedDate1).format("YYYY") : currentYear;

  const formattedMonth = receiverName && selectedDate1
  ? moment(selectedDate1).format("MM")
  : receiverName
  ? ""
  : selectedDate1? moment(selectedDate1).format("MM") : currentMonth;

  console.log(currentMonth,"hello")
 


  const handleSearchSubmit = () => {
    let url = `${process.env.REACT_APP_BASEURL}medicare/careplanservice?type=2`;

    if (selectedId) {
      url += `&planId=${selectedId}`;
    }

    if (searchName || receiverName) {
      url += `&patientName=${searchName || receiverName}`;
    }
    if (formattedYear) {
      url += `&transactionYear=${formattedYear}`;
    }
    if (formattedMonth) {
      url += `&transactionMonth=${formattedMonth}`;
    }
    if (selectedStatus) {
      url += `&serviceStatus=${selectedStatus}`;
    }

    http
      .get(url)
      .then((res) => {
        setSearchall(res.data);
        setCareplanDataExcel(res.data)
        console.log(res.data, "yes");
        // dataFetched = true;
      })
      .catch((error) => {
        console.log(error, "went wrong");
      });
  };

  useEffect(() => {
    handleSearchSubmit();
  }, [selectedId, selectedStatus, formattedYear, formattedMonth,selectedDate1]);

  console.log(formattedMonth, formattedYear, "formattedMonth");

  const handleOptionChange = (e) => {
    setSelectedOptionId(e.target.value);
  };

  console.log(selectedOptionId, "id is coming");
  useEffect(() => {
    setSelectedId(searchData);
    // const getPlan = () => {
    http
      .get(`${process.env.REACT_APP_BASEURL}medicare/careplandetails`)
      .then((res) => {
        setAllplan(res.data);

        let plans = res.data.map((obj) => {
          return obj.planName;
        });
        let planids = res.data.map((obj) => {
          return obj.planId;
        });
        setplanName1(plans);
        setplanids(planids);
        console.log(planids, "wed");
      })
      .catch((err) => {
        console.log(err, "Something went wrong1");
      });
    // };
    // return () => getPlan();
  }, []);

  console.log(allplan, "aaja2");

  const dropdownOptions = allplan?.map((item) => ({
    label: item?.planName,
    value: item?.planId,
  }));

  const handleDropdownChange = (e) => {
    setSelectedId(e.value);
    setValue(e.target.value);
  };

  const handleDropdownStatus = (e) => {
    setSelectedStatus(e.value);
  };

  const handleChange = (e) => {
    setSelectedPlan(e.target.value);
  };

  console.log(planName1, "service");

  const handleDateChange1 = (e) => {
    setSelectedDate1(e);
  };

  const header = (
    <div className="flex flex-wrap justify-content-end gap-2"></div>
  );

 

  const updateFromtime = moment(frommtime).format("hh:mm A");

  const updateTotime = moment(totime).format("hh:mm A");



  const provderEditor = (text) => {
    return (
      <InputText
        type="text"
        value={text.value}
        onChange={(e) => {
          text.editorCallback(e.target.value);
          setServiceprovider(e.target.value);
        }}
        placeholder="Type the service provider"
      />
    );
  };
 
  const statusBodyTemplate = (rowData) => {
    let statusLabel, statusSeverity;
    switch (rowData.serviceStatus) {
      case "0":
        statusLabel = "Expired";
        statusSeverity = "danger";
        break;
      case "1":
        statusLabel = "Yet to Start";
        statusSeverity = "warning";
        break;
      case "2":
        statusLabel = "Appointment Booked";
        statusSeverity = "info";
        break;
      case "3":
        statusLabel = "Completed";
        statusSeverity = "success";
        break;
      case "4":
        statusLabel = "Request For Update";
        statusSeverity = "warning";
        break;
      case "5":
        statusLabel = "Cancelled";
        statusSeverity = "danger";
        break;
      case "6":
        statusLabel = "Re-Scheduled";
        statusSeverity = "warning";
        break;
    
      default:
        statusLabel = "";
        statusSeverity = "";
        break;
    }
    return <Tag value={statusLabel} severity={statusSeverity}></Tag>;
  };


  const filterOptions = [
    { label: "All", value: "", severity: "danger" },

    { label: "Yet to Start", value: "1", severity: "warning" },
    { label: "Appointment Booked", value: "2", severity: "info" },
    { label: "Completed", value: "3", severity: "success" },
    { label: "Request For Update", value: "4", severity: "warning" },
    { label: "Cancelled", value: "5", severity: "danger" },
    { label: "Expired", value: "0", severity: "danger" },
    { label: "Re-Scheduled", value: "6", severity: "danger" },

  ];

 

  const handleCancel = () => {
    setComment("");
    setServiceprovider(" ");
    op1.current.hide();
    op2.current.hide();
  };

  const viewPres = (rowData) => {
    http
      .post(
        `${process.env.REACT_APP_BASEURL}patientDocument/viewPrescription`,
        {
          id: rowData.prescriptionid,
          //  id : "2466"
        }
      )
      .then((response) => {
        console.log("File uploaded successfully:", response.data.document);

        const uploadpres = response.data.docName;
        const uploadpresUrl = `${process.env.REACT_APP_IMAGE_URL}${uploadpres} `;
        const win = window.open(uploadpresUrl, '_blank');
       
        win.focus();
    
      })
     
      .catch((error) => {
        console.error("Error uploading file:", error);
      
      });
  };

  const closeShare = () => {
    setShare(false);
  };

  const openShare = (rowData) => {
    setSharelinkvisible(true);
    setShare(true);
    setAppointmentId(rowData);
  };

  async function saveAsExcel() {
   
    console.log(
      "careplanDataExcel",
      JSON.stringify(careplanDataExcel)
    );
    

    const newArrOfObjects = careplanDataExcel.map((obj) =>
      Object.keys(obj)
        .filter((e) => obj[e] !== null)
        .reduce((o, e) => {
          o[e] = obj[e];
          return o;
        }, {})
    );


    setTimeout(() => {
      jsonToExcelConvetercareplan(newArrOfObjects, "Careplan");
    }, 4000);
  }

  console.log(searchall, "appids");

 
  const viewLabreport = (rowData) => {
    const labReportUrl =  `${process.env.REACT_APP_IMAGE_URL}${rowData.labOrderDoc}`;
    const win = window.open(labReportUrl, '_blank');
   
    win.focus();
    // window.location.href = labReportUrl;
  };

  const viewPrescription = (rowdata) => {
    let payload = {
      appointmentId: parseInt(rowdata.appointmentid),

      visitSummary: "Y",
      status: "1",

      stampRequired: "Y",

      documentRequired: "Y",

      vitalRequired: "Y",
    };
   
    http.post(
      `${process.env.REACT_APP_BASEURL}medicare/viewPrescription`,payload)
      
      .then((res) => {
        // if (res.data.succesObject[0]?.patientDrugPrescriptionList[0]?.docName) {
        //     let docName = res.data.succesObject[0].patientDrugPrescriptionList[0].docName
        //     window.open(filePath + docName, '_blank');
        // }
        // else {
         
        if (res?.data) {
          let epres = res?.data;
          console.log(epres,"jnjxnjd")
          navigate(
            APP_ROUTES.EPRECRIPTION,
            { state: epres },
            { replace: true }
          );
        } else {
          toast.error(
            "Prescription is not available at the moment. Please try after sometime",
            {
              position: toast.POSITION.TOP_CENTER,
            }
          );
        }
        // }
      })
      .catch((error) => {
        console.error("Failed to fetch prescription data:", error);
      });
  };

  return (
    <>
      <ToastContainer />
      <div style={{ padding: "10px" }}>
        <div className="maincareplan">
          {/* <div className="">Careplan Schedule : </div> */}

          {/* <div className='searchbar' style={{ marginBottom: "20px" }} title="Search by date">
            <input type="text" className='placeholder' placeholder=" Quick Search" name="search"
            />
            <img src={searchImg} alt={"Goto icon"} className='navImg' />

          </div> */}
        </div>
      </div>

      {/* search bar details */}
      <div style={{ marginTop: "5px" }}>
        <form
        // onSubmit={handleSearch}
        >
          <div className="maindivsearchbar">
            <div
              className="searchbar"
              style={{ marginBottom: "20px" }}
              title="Search by Name"
            >
              {receiverdata ? (
                <input
                  type="text"
                  className="placeholder"
                  value={searchName}
                  onChange={(e) => setSearchName(e.target.value)}
                  placeholder={receiverdata || receiverName}
                  name="search"
                ></input>
              ) : (
                ""
              )}
              {!receiverdata && (
                <input
                  type="text"
                  className="placeholder"
                  value={receiverdata}
                  onChange={(e) => setSearchName(e.target.value)}
                  placeholder={receiverName ? receiverName : "Name"}
                  name="search"
                ></input>
              )}

              <img
                src={searchImg}
                alt={"Goto icon"}
                className="navImg"
                onClick={handleSearchSubmit}
              />
            </div>

            <div>
              <div className="">
                <Dropdown
                  onChange={handleDropdownChange}
                  options={dropdownOptions}
                  value={selectedId}
                  placeholder="Select Plan"
                  className="searchbarname"
                />
              </div>
            </div>
            <div
              className="searchbar"
              style={{ marginBottom: "20px" }}
              title="Search by date"
            >
              <DatePicker
                showIcon
                className="datepicker1"
                dateFormat="MM/yyyy"
                selected={selectedDate1 || initialDate}
                //  selected={selectedDate1}
                onChange={(date) => handleDateChange1(date)}
                showMonthYearPicker
                customInput={
                  <div
                    style={{
                      position: "relative",
                      display: "flex",
                      flexDirection: "column",
                    }}
                  >
                    <div>
                      <input
                        type="text"
                        className="form-control"
                        value={
                          receiverName
                            ? selectedDate1
                              ? moment(selectedDate1).format("MM/YYYY")
                              : "Select Month"
                            : selectedDate1
                            ? moment(selectedDate1).format("MM/YYYY")
                            : `${currentMonth}/${currentYear}`
                        }
                        readOnly
                      />
                    </div>

                    <img
                      src="images/calander.svg"
                      alt="Calendar"
                      style={{
                        position: "absolute",
                        right: "10px",
                        top: "1px",
                        cursor: "pointer",
                      }}
                    />
                  </div>
                }
              ></DatePicker>
            </div>

            <Dropdown
              onChange={(e) => setSelectedStatus(e.target.value)}
              options={filterOptions}
              value={selectedStatus}
              placeholder="Status"
              className="searchbarname"
            />

            {/* <button className="buttn" type="submit">Search</button> */}
          </div>
        </form>
      </div>

      {searchall === undefined ? (
        <div
          style={{
            height: "1000px",
            textAlign: "center",
            justifyItems: "center",
          }}
        >
          <div>
            <i
              class="pi pi-spin pi-spinner"
              style={{
                fontSize: " 2rem",
                marginTop: "200px",
                color: "blue",
                fontWeight: "700",
              }}
            ></i>
          </div>
        </div>
      ) : (
        <>
        <div style={{display:"flex",justifyContent:"end",paddingRight:"20px"}}>
        <button onClick={() => saveAsExcel()} className="custom-btn btn-2" style={{width:"125px",height:"40px",fontSize:"16px",fontWeight:"700",backgroundColor:"green"}}><i class="pi pi-spin pi-file-excel" style={{marginRight:"10px",fontSize:"20px"}}></i>Export</button>
        </div>
          <DataTable
            value={searchall}
            paginator
            rows={50}
            rowsPerPageOptions={[5, 10, 25, 50]}
            filters={filters}
            globalFilterFields={["Status"]}
            header={header}
            tableStyle={{ padding: "10px" }}
            rowClassName={(rowData) =>
              rowData.serviceStatus === 2 ? "rowcolor" : ""
            }
          >
            <Column field="patientName" header="Patient Name" sortable />
            {selectedId ? null : (
              <Column field="planName" header="Plan Name" sortable />
            )}
            <Column
              field="serviceName"
              header="Service Name"
              style={{ width: "200px" }}
              sortable
              body={(rowData, column) => (
                <>
                  <div class="  tooltip">
                    {rowData.serviceName}
                    <span class="tooltiptext careplan">
                      {rowData.descriptions}
                    </span>
                  </div>
                </>
              )}
            />
            {/* <Column field="frequencyName" header="Frequency" sortable /> */}
            <Column field="hospitalId" header="Eclinic" />
            {/* <Column field="cheCenterId" header="cheCenterId" />
            <Column field="cheBranchId" header="cheBranchId" /> */}
            {/* <Column field="" header="Scheduled Month" sortable /> */}
            <Column
              field="timeRange"
              header="Scheduled Month"
              body={(rowData) => {
                const formattedDate = moment(rowData.transactionMonth).format(
                  "MMMM"
                );

                const formattedYear = rowData.transactionYear;
                return `${formattedDate} ${formattedYear}`;
              }}
            />

            <Column
              header="Scheduled Date"
              style={{ width: "14rem" }}
              body={(rowData) => {
                if (rowData.serviceStatus === "4") {
                  return null; // Return null to hide the column content
                }

                const formattedDate = rowData.modifiedServiceDate
                  ? moment(rowData.modifiedServiceDate).format("DD-MM-YYYY")
                  : "";

                let fromTime = "";

                if (rowData.fromTime !== null) {
                  fromTime = moment(rowData.fromTime, "HH:mm").format(
                    "hh:mm A"
                  );

                  if (
                    rowData.personnelCode === "LAB" &&
                    rowData.toTime !== null
                  ) {
                    fromTime +=
                      " - " + moment(rowData.toTime, "HH:mm").format("hh:mm A");
                  }
                }

                return (
                  <div>
                    <div>{formattedDate}</div>
                    <div>{fromTime}</div>
                  </div>
                );
              }}
            />
             <Column
                 
                  header="Appointment Details"
                  body={(rowbody) => (
                    <>
                     
                        <div style={{ display: "block" }}>
                          {/* <div className="icon-container">
                            <svg xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 448 512"><path d="M224 256A128 128 0 1 0 224 0a128 128 0 1 0 0 256zm-96 55.2C54 332.9 0 401.3 0 482.3C0 498.7 13.3 512 29.7 512H418.3c16.4 0 29.7-13.3 29.7-29.7c0-81-54-149.4-128-171.1V362c27.6 7.1 48 32.2 48 62v40c0 8.8-7.2 16-16 16H336c-8.8 0-16-7.2-16-16s7.2-16 16-16V424c0-17.7-14.3-32-32-32s-32 14.3-32 32v24c8.8 0 16 7.2 16 16s-7.2 16-16 16H256c-8.8 0-16-7.2-16-16V424c0-29.8 20.4-54.9 48-62V304.9c-6-.6-12.1-.9-18.3-.9H178.3c-6.2 0-12.3 .3-18.3 .9v65.4c23.1 6.9 40 28.3 40 53.7c0 30.9-25.1 56-56 56s-56-25.1-56-56c0-25.4 16.9-46.8 40-53.7V311.2zM144 448a24 24 0 1 0 0-48 24 24 0 1 0 0 48z" style={{ fill: "green" }}/></svg>
                            </div> */}
                          <div style={{ fontSize: "12px" }}>
                            {rowbody.serviceProviderName}
                          </div>

                          <div style={{ fontSize: "12px",marginTop:"10px" }}>
                            {rowbody.consultationType ==="L" ? "Lab" : rowbody.consultationType ==="H"? "Home Visit": rowbody.consultationType ==="P"? "Phone": rowbody.consultationType ==="V"? "Video" :""}
                          </div>
                        </div>
                     
                    </>
                  )}
                ></Column>

            {/* <Column field="fromTime" header="Scheduled Time" /> */}
            <Column
              field="status"
              header="Status"
              filterMenuStyle={{ width: "14rem" }}
              body={statusBodyTemplate}
              style={{ width: "200px" }}
            />

            <Column
              // field="Share"
              header="Share"
              filterMenuStyle={{ width: "14rem" }}
              body={(rowData) => (
                <>
                  <span>
                    {/* <i class="fa fa-pencil-square-o " aria-hidden="true" onClick={() => show(rowData)} style={{ cursor: "pointer" }}></i> */}
                    {rowData.consultationType === "V" &&
                    rowData.serviceStatus === "2" ? (
                      <div
                        // class="fa fa-eye"
                        aria-hidden="true"
                        onClick={() => openShare(rowData)}
                        style={{ cursor: "pointer", color: "#005D8E" }}
                      >
                        {" "}
                        Share Link
                      </div>
                    ) : (
                      ""
                    )}
                  </span>
                </>
              )}
              style={{ width: "200px" }}
            />
            <Column
              header="Action"
              body={(rowData) => (
                <>
                  <span>
                    {/* <i class="fa fa-pencil-square-o " aria-hidden="true" onClick={() => show(rowData)} style={{ cursor: "pointer" }}></i> */}
                    {
                    rowData.serviceStatus === "2" &&
                    rowData.personnelCode === "NUR" ? (
                      <i
                        className="fa fa-pencil-square-o"
                        aria-hidden="true"
                        onClick={() => handleRowClick(rowData)}
                        style={{ cursor: "pointer" }}
                      ></i>
                    ) :
                    //  rowData?.serviceStatus === "2" ||
                      rowData?.serviceStatus === "3" ||
                      // rowData?.serviceStatus === "0" ||
                      rowData?.serviceStatus === "5" ? (
                      <i
                        class="fa fa-eye"
                        aria-hidden="true"
                        onClick={() => handleRowClick(rowData)}
                        style={{ cursor: "pointer", color: "#005D8E" }}
                      ></i>
                    ) : (
                      <i
                        className="fa fa-pencil-square-o"
                        aria-hidden="true"
                        onClick={() => handleRowClick(rowData)}
                        style={{ cursor: "pointer" }}
                      ></i>
                    )}
                  </span>
                </>
              )}
            />

            {/* {rowData1 && rowData1.consultationType === "H"  ( */}
            <Column
  header="Prescription/Lab Report"
  body={(rowData) => {
    if (
      rowData.personnelCode === "DR" ||
      (rowData.personnelCode === "NUR" && rowData.serviceStatus === "3")
    ) {
      return rowData.prescriptionid === null ? ( ""
        // <Button
        //   icon="pi pi-upload"
        //   onClick={() => showDialog(rowData)}
        //   className="p-button-success"
        //   style={{
        //     backgroundColor: "white",
        //     color: "blue",
        //     border: "white",
        //   }}
        // />
      ) : (
        rowData.documentType ==="pdf" || rowData.documentType ==="jpg" || rowData.documentType === "jpeg" ? (
          <Button
            icon="pi pi-file"
            onClick={() => viewPres(rowData)}
            className="p-button-success"
            style={{
              backgroundColor: "white",
              color: "blue",
              border: "white",
            }}
          />
        ) : (
          <Button
            icon="pi pi-file"
            onClick={() => viewPrescription(rowData)}
            className="p-button-success"
            style={{
              backgroundColor: "white",
              color: "blue",
              border: "white",
            }}
          />
        )
      );
    } else {
      if (rowData?.labOrderDoc) {
        return (
          <Button
            icon="pi pi-file"
            onClick={() => viewLabreport(rowData)}
            className="p-button-success"
            style={{
              backgroundColor: "white",
              color: "blue",
              border: "white",
            }}
          />
        );
      } else if (typeof rowData.prescriptionDoc === "string") {
        return (
          <Button
            icon="pi pi-file"
            onClick={() => viewPrescription(rowData)}
            className="p-button-success"
            style={{
              backgroundColor: "white",
              color: "blue",
              border: "white",
            }}
          />
        );
      } else {
        return null;
      }
    }
  }}
/>





          </DataTable>

          {/* <Sidebar visible={sideDialog} position="right" onHide={onHide1} style={{ width: "480px" }}>
              <>
              <CareplanSchedulepopup 
                 scheduledata={scheduledata} 
                 refreshData={handleSearchSubmit} 
                 closeFun={onHide1}
               
              />
            
              </>
            </Sidebar> */}

          <Sidebar
            visible={sideDialog}
            position="right"
            onHide={() => setSideDialog(false)}
            style={{ width: "480px" }}
          >
            <>
              <CareplanSchedulepopup
                scheduledata={selectedRowData}
                // refreshData={handleSearchSubmit}
                handleSearch={handleSearchSubmit}
                closeFun={() => setSideDialog(false)}
                closeSidebar={closeSidebar}
              />
              {/* ... */}
            </>
          </Sidebar>

          <Dialog
            header="Upload Prescription"
            visible={visible}
            // position="bottom-right"
            style={{ width: "50vw" }}
            onHide={hideDialog}
            draggable={false}
            resizable={false}
          >
            <div>
              <div style={{ display: "flex" }}>
                <div>
                  <div class="file-input-wrapper ">
                    <button class="custom-btn btn-2"  style={{cursor:"pointer"}}>
                      <i
                        class="pi pi-cloud-upload"
                        style={{ marginRight: "10px" }}
                      ></i>
                      Choose File
                    </button>{" "}
                    <input
                      type="file"
                      class="actual-file-input"
                      onChange={handleFileChange}
                    />{" "}
                  </div>
                  {/* <label for="input-id">
                  <input type="file" onChange={handleFileChange} id="input-id" />
                
                </label> */}
                </div>
                <div>
                  {" "}
                  <button
                    className="custom-btn btn-2"
                    style={{ marginLeft: "10px" }}
                    onClick={handleUpload}
                  >
                    Upload
                  </button>
                </div>
              </div>
              <div id="document-container"></div>
            </div>
          </Dialog>
          <Dialog
            header={
              <>
                <div className="" style={{ display: "flex" }}>
                  <span
                    className=""
                    style={{ color: "#005D8E", fontSize: "28px" }}
                  >
                    Share Link
                  </span>
                  <i
                    class="fa fa-whatsapp"
                    style={{
                      fontSize: "24px",
                      marginLeft: "40px",
                      color: "green",
                      marginTop: "10px",
                    }}
                    aria-hidden="true"
                  ></i>

                  <i
                    class="fa fa-comments"
                    style={{
                      fontSize: "24px",
                      marginLeft: "15px",
                      color: "#005D8E",
                      marginTop: "10px",
                    }}
                    aria-hidden="true"
                  ></i>

                  <i
                    class="fa fa-envelope"
                    style={{
                      fontSize: "24px",
                      marginLeft: "15px",
                      color: "red",
                      marginTop: "10px",
                    }}
                    aria-hidden="true"
                  ></i>
                </div>
                <hr></hr>
              </>
            }
            visible={sharelinkvisible}
            // position="bottom-right"
            style={{ width: "30vw", paddingBottom: "0px" }}
            onHide={hideDialogSharelink}
            draggable={false}
            resizable={false}
          >
            {share && (
              <Share
                closeFunnew={() => hideDialogSharelink(false)}
                onClose={closeShare}
                closePopup={closeShare}
                data={appID}
                onHide={hideDialogSharelink}
              ></Share>
            )}
          </Dialog>
        </>
      )}
    </>
  );
}

export default CareplansSchedule;
