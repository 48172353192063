import {React,useState,useEffect} from 'react';
import searchImg from "../../Assets/Images/searchImg.svg";
import axios from "axios";
import { Dropdown } from "primereact/dropdown";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import DatePicker from "react-datepicker";
import moment from "moment";
import { Button } from "primereact/button";
import { TabView, TabPanel } from 'primereact/tabview';
import { useLocation } from "react-router-dom";
// import 'primereact/resources/primereact.min.css';
// import 'primereact/resources/themes/saga-blue/theme.css';
// import 'primeicons/primeicons.css';

function Schedule() {
  const location = useLocation();
  const receiverMobile = location.state?.receiverMobile ;

    const [searchName, setSearchName] = useState("");
    const [selectedId, setSelectedId] = useState(null);
    const [selectedDate1, setSelectedDate1] = useState(null);
    const [patients, setPatients] = useState([]);
    const [selectedPatient, setSelectedPatient] = useState(null);
    const [planList, setPlanList] = useState([]);

    const handleDropdownChange = (e) => {
        setSelectedId(e.value);
       
      };
      const handleDateChange1 = (e) => {
        setSelectedDate1(e);
      };

      useEffect(() => {
        if (receiverMobile || searchName.trim() !== '') {
          const combinedMobileNo = `${receiverMobile || ''} ${searchName || ''}`.trim();
          fetchPatients(combinedMobileNo);
        }
      }, [receiverMobile, searchName]);

      useEffect(() => {
        if (selectedPatient) {
          setPlanList(selectedPatient.planList);
        }
      }, [selectedPatient]);
    
      // Assuming fetchData returns a Promise that resolves to an array of plan objects
      const fetchPatients = async (mobileNo, search) => {
        try {
          const response = await fetch(`${process.env.REACT_APP_BASEURL}medicare/schedule`, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json'
            },
            body: JSON.stringify({ mobileNo, search })
          });
    
          const data = await response.json();
          setPatients(data);
          setSelectedPatient(data[0] || null);
        } catch (error) {
          console.error('Error fetching patient data:', error);
        }
      };
    
      // const dropdownOptions = allplan?.map((item) => ({
      //   label: item?.patientName,
      //   value: item?.patientCode,
      // }));
      // console.log(allplan[0]?.planList,"searchName")
  return (
    <div>

<div style={{ marginTop: "5px" }}>
        <form
        // onSubmit={handleSearch}
        >
          <div className="maindivsearchbar">
            <div
              className="searchbar"
              style={{ marginBottom: "20px" }}
              title="Search by No."
            >
            
                <input
                  type="text"
                  className="placeholder"
                  value={searchName || receiverMobile }
                  onChange={(e) => setSearchName(e.target.value)}
                  placeholder="Mobile Number"
                  name="search"
                ></input>
            
            
            

              <img
                src={searchImg}
                alt={"Goto icon"}
                className="navImg"
                // onClick={handleSearchSubmit}
              />
            </div>

              
            <div>
              <div className="">
              <Dropdown
        value={selectedPatient}
        options={ patients}
        optionLabel="patientName"
        onChange={(e) => setSelectedPatient(e.value)}
        
        placeholder="Select a patient"
        disabled={!patients.length} 
      />
              </div>
            </div>
            {/* <div
              className="searchbar"
              style={{ marginBottom: "20px" }}
              title="Search by No."
            >
              <DatePicker
                showIcon
                className="datepicker1"
                dateFormat="MM/yyyy"
                selected={selectedDate1}
                //  selected={selectedDate1}
                onChange={(date) => handleDateChange1(date)}
                showMonthYearPicker
                customInput={
                  <div
                    style={{
                      position: "relative",
                      display: "flex",
                      flexDirection: "column",
                    }}
                  >
                    <div>
                      <input
                        type="text"
                        className="form-control"
                        value={
                          selectedDate1
                            ? moment(selectedDate1).format("MM/YYYY")
                            :
                             "Select Date"
                          // `${currentMonth}/${currentYear}`
                        }
                        readOnly
                      />
                    </div>

                    <img
                      src="images/calander.svg"
                      alt="Calendar"
                      style={{
                        position: "absolute",
                        right: "10px",
                        top: "1px",
                        cursor: "pointer",
                      }}
                    />
                  </div>
                }
              ></DatePicker>
            </div> */}

          

            {/* <button className="buttn" type="submit">Search</button> */}
          </div>
        </form>
      </div>

      <TabView>
      {planList?.map((item, index) => (
        <TabPanel header={item.planname} key={index}>
            <DataTable value={item.upcomingScheduleList} paginator rows={10}>
        <Column field="serviceName" header="Service Name" />
        <Column field="appointmentMonthYear" header="Frequency" />
        <Column field="appointmentType" header="Appointment Type" />
        <Column field="appointmentDate" header="Appointment Date" />
       
        <Column field="appointmentTime" header="Appointment Time" />
        <Column field="serviceProviderName" header="Service Provider Name" />
        <Column field="consultationType" header="Consultation Type" />
        <Column field="appointmentStatus" header="Status" />
      </DataTable>
        
        </TabPanel>
      ))}
    </TabView>



    </div>
  )
}

export default Schedule