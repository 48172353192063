import React from "react";
import axios from "axios";
import { useState, useEffect, useRef } from "react";
import DatePicker from "react-datepicker";
import moment from "moment";
import { Calendar } from "primereact/calendar";
import { Dropdown } from "primereact/dropdown";
import { classNames } from "primereact/utils";
// import { ToastContainer, toast } from "react-toastify";
// import "react-toastify/dist/ReactToastify.css";
import { Dialog } from "primereact/dialog";
import { Toast } from "primereact/toast";
import { Button } from "primereact/button";
import { ToastContainer, toast } from "react-toastify";
import http from "../../../Redux/services/http-common"

function AssessmentScheduleSidebarUpdate({
  scheduledata,
  refreshData,
  closeFun,
  handleSearch,
  closeSidebar,
}) {
  const props = scheduledata;
  const toast = useRef(null);

  const [doclist, setDocList] = useState("");
  const [modifiedServiceDate, setModifiedServiceDate] = useState();
  const [frommtime, setFromtime] = useState();
  const [serviceprovider, setServiceprovider] = useState();
  const [serviceproviderNo, setServiceproviderNo] = useState();
  const [comment, setComment] = useState();
  const [selectConsult, setSelectConsult] = useState(null);
  const [selectdocList, setSelectdocList] = useState(null);
  const [selectlabList, setSelectlabList] = useState(null);
  const [selectStatus, setSelectStatus] = useState(null);
  const [selectStatus1, setSelectStatus1] = useState(null);
  const [isEditing, setIsEditing] = useState(false);
  const [openDialog, setDialog] = useState(false);
  const [seoid, setSeoid] = useState();
  const [doctortlistApi, setDoctortlistApi] = useState();
  const [lablistApi, setlablistApi] = useState();
  const [nurselistApi, setnurselistApi] = useState();
  const [locationId, setLocationId] = useState(null);
  const [hospitslId, sethospitalId] = useState();
  const [locationIdNurse, setLocationIdNurse] = useState();
  const [hospitslIdNurse, sethospitalIdNurse] = useState();
  const [lablocationId, setlablocationId] = useState();
  const [labtestName, setlabtestName] = useState();
  const [selectlabtestName, setselectlabtestName] = useState(null);
  const [preferTime, setPreferTime] = useState("");
  const [preferDateTime, setPreferredDate] = useState();
  const [isset, isIsset] = useState(false);
  const [preferDate, setPreferDate] = useState();
  const [tickDialog, setTickDialog] = useState(false);
  const [ErrorDialog, setErrorDialog] = useState(false);
  const [ErrorMsg, setErrorMsg] = useState(false);
  const [timeMessage, setTimeMessage] = useState("");
  const [errorMessages, setErrorMessages] = useState({
    appointmentDate: "",
    scheduleTime: "",
    serviceProviderName: "",
    consultationReason: "",
    consultationType: "",
  });

  const [preferredLabTime, setPreferredLabTime] = useState([
    {
      label: "07 AM - 08 AM",

      value: "07 AM - 08 AM",
    },

    {
      label: "08 AM - 09 AM",

      value: "08 AM - 09 AM",
    },

    {
      label: "09 AM - 10 AM",

      value: "09 AM - 10 AM",
    },

    {
      label: "10 AM - 11 AM",

      value: "10 AM - 11 AM",
    },

    {
      label: "11 AM - 12 PM",

      value: "11 AM - 12 PM",
    },

    {
      label: "12 PM - 01 PM",

      value: "12 PM - 01 PM",
    },

    {
      label: "01 PM - 02 PM",

      value: "01 PM - 02 PM",
    },

    {
      label: "02 PM - 03 PM",

      value: "02 PM - 03 PM",
    },

    {
      label: "03 PM - 04 PM",

      value: "03 PM - 04 PM",
    },

    {
      label: "04 PM - 05 PM",

      value: "04 PM - 05 PM",
    },

    {
      label: "05 PM - 06 PM",

      value: "05 PM - 06 PM",
    },

    {
      label: "07 PM - 08 PM",

      value: "19.00 - 20.00",
    },
  ]);

  // const [fromTime, setFromTime] = useState("");
  // const [toTime, setToTime] = useState("");

  // Custom function to generate time slots at 15-minute intervals
  const generateTimeSlots = () => {
    const timeSlots = [];
    const startTime = new Date();
    startTime.setHours(0, 0, 0, 0); // Set the start time to midnight

    while (startTime.getHours() < 12) {
      const formattedTime = startTime.toLocaleTimeString([], {
        hour: "2-digit",
        minute: "2-digit",
        hour12: true,
      });
      timeSlots.push(formattedTime);
      startTime.setMinutes(startTime.getMinutes() + 15);
    }

    return timeSlots;
  };

  const limitTimeForLab = (timeParam) => {
    console.log(timeParam, "sdpfdibdhpisdhpb");

    if (moment(preferDate).isSame(moment().format("YYYY-MM-DD"))) {
      let current_time_in_hour = moment().hour();

      let am_or_pm = timeParam.label.substring(0, 5).split(" ")[1];

      let time_in_hours = Number(timeParam.label.substring(0, 5).split(" ")[0]);

      if (am_or_pm == "PM" && time_in_hours != 12) {
        time_in_hours += 12;
      }

      if (current_time_in_hour + 3 >= time_in_hours) {
        return true;
      } else {
        return false;
      }
    }

    return false;
  };

  const convertTime = (timeStr) => {
    const [time, modifier] = timeStr.split(" ");

    let [hours] = time.split(":");

    if (hours === "12") {
      hours = "00";
    }

    if (modifier === "PM") {
      hours = parseInt(hours, 10) + 12;
    }

    return `${hours}`;
  };

  const handleChange = (e) => {
    let value = e?.target?.value;

    setPreferTime(value);

    let time = convertTime(value.substring(0, 5));

    localStorage.setItem("preferTime", JSON.stringify(time));

    localStorage.setItem("preferValue", JSON.stringify(value));

    localStorage.setItem("preferDate", JSON.stringify(preferDate));
  };

  const changeDate = (date) => {
    setPreferDate(moment(date).format("yyyy-MM-DD"));
    date = moment(date).format("yyyy-MM-DD HH:mm:ss");

    setPreferredDate(date);
  };

  function handleEditClick() {
    setIsEditing(true);
  }

  useEffect(() => {
    let freeTextSearchValue = "";

    if (props.personnel === "Nutritionist") {
      freeTextSearchValue = "Nutrition and Dietetics";
    }
    http
      .get(
        `${process.env.REACT_APP_ELASTIC_BASEURL}doctors?freeTextSearch=${freeTextSearchValue}&pageNo=1&pageSize=100`
      )
      .then((res) => {
        setDoctortlistApi(res.data);

        let docList = res.data.map((obj) => {
          return obj.userName;
        });
        // let planids = res.data.map((obj) => {

        //   return (obj.planId)

        // })
        setDocList(docList);

        let seoId = res.data.map((obj) => {
          return obj.seoNameId;
        });
        setSeoid(seoId);

        console.log(res.data, "qwertymnbvcx");
      })
      .catch((err) => {
        console.log(err, "Something went wrong1");
      });
  }, []);
  console.log(seoid, "doctortlist");

  useEffect(() => {
    http
      .get(
        `${process.env.REACT_APP_ELASTIC_BASEURL}labs?latitude=20.2960587&longitude=85.8245398&source=b2c`
      )
      .then((res) => {
        setlablistApi(res.data);

        let locationIdLab = res.data.map((obj) => {
          return obj.locationId;
        });

        setlablocationId(locationIdLab);

        // let seoId = res.data.map((obj) => {

        //     return (obj.seoNameId)

        // })
        // setSeoid(seoId)

       
      })
      .catch((err) => {
        console.log(err, "Something went wrong1");
      });
  }, []);

  useEffect(() => {
    http
      .get(
        `${process.env.REACT_APP_BASEURL}user/list/filter?roleCode=NURSE&status=1`
      )
      .then((res) => {
        setnurselistApi(res.data);
      })
      .catch((err) => {
        console.log(err, "Something went wrong1");
      });
  }, []);

  console.log(lablocationId, "qwertymnbvcx");

  const dropdownOptionConsult = [
    // { name: 'Eclinic', value: 'E' },
    { name: "Home Visit", value: "H" },
    { name: "Video", value: "V" },
    { name: "Phone", value: "P" },
  ];
  const dropdownOptionConsultnurse = [{ name: "Home Visit", value: "H" }];

  const dropdownOptionStatus = [
    // { name: "Yet to Start", value: "Yet to Start" },
    // { name: "In progress", value: "In progress" },
    { name: "Completed", value: "3" },
    { name: "Cancelled", value: "5" },
  ];
  const dropdownOptionStatusPhonic = [
    { name: "Completed", value: "3" },
    { name: "Cancelled", value: "5" },
  ];

  const dropdownOptions = doctortlistApi?.map((item) => ({
    label: item?.userName,
    value: {
      id: item?.seoNameId,
      name: item?.userName,

      email: item?.email,
      code: item?.userId,
      number: item?.contactNo,
    },
  }));

  const dropdownOptionsLab = lablistApi?.map((item) => ({
    label: item?.labName,
    value: {
      id: item?.locationId,

      // email: item?.email,
      // code: item?.userId,
      // number: item?.contactNo,
    },
  }));

  const dropdownOptionsParticularLab = labtestName?.map((item) => ({
    label: item?.labTestName,
    value: {
      name: item?.labTestName,
      labId: item?.labId,
      locationId: item?.locationId,
      labTestCode: item?.labTestCode,
      labName: item?.labName,
      locationName: item?.locationName,
      address1: item?.address1,
      address2: item?.address2,
      city: item?.city,
      state: item?.state,
      pinCode: item?.pinCode,
      labName: item?.labName,
    },
  }));

  const dropdownOptionsNurse = nurselistApi?.map((item) => ({
    label: item?.userName,
    value: {
      id: item?.code,
      name: item?.userName,

      email: item?.email,
      code: item?.userId,
      number: item?.mobile,
    },
  }));

  useEffect(() => {
    if (selectdocList) {
      fetchLocationId(selectdocList.id);
    }
  }, [selectdocList]);

  const fetchLocationId = (doctorId) => {
    // Make the GET request to fetch the location ID
    http
      .get(
        `${process.env.REACT_APP_BASEURL}User_Hospital_Location/list?userId=${doctorId}&status=1&source=b2c`
      )
      .then((response) => {
        console.log(response.data[0], "apidata");

        let locationData = response.data[0].locationId;

        setLocationId(locationData);
        let hospitalIdData = response.data[0].hospitalId;
        sethospitalId(hospitalIdData);
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };

  useEffect(() => {
    if (serviceprovider) {
      fetchLocationIdNurse(serviceprovider.id);
    }
  }, [serviceprovider]);

  console.log(serviceprovider, "serviceprovider");
  const fetchLocationIdNurse = (nurseId) => {
    // Make the GET request to fetch the location ID
    http
      .get(
        `${process.env.REACT_APP_BASEURL}User_Hospital_Location/list?userId=${nurseId}&status=1&source=Advertisement`
      )
      .then((response) => {
        console.log(response.data[0], "apidata");

        let locationDataNurse = response.data[0].locationId;

        setLocationIdNurse(locationDataNurse);
        let hospitalIdDataNurse = response.data[0].hospitalId;
        sethospitalIdNurse(hospitalIdDataNurse);
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };

  const iconClassName = classNames("pi", "pi-calendar");

  const formatFromtime = frommtime ? moment(frommtime).format("HH:mm") : "";
  const updatedTime = moment(formatFromtime, "HH:mm")
    .add(15, "minutes")
    .format("HH:mm");
  // console.log(updateddate, updatedTime, "time")

  var stringNumber = props.cpscSeqId;
  var number = parseInt(stringNumber);

  var appid = props.appointmentid;
  var appidnumber = parseInt(appid);

  const handleSave = (e) => {
    e.preventDefault();
    http 
      .post(
        `${process.env.REACT_APP_BASEURL}preassesment/updateAppointment`,
        {
          patientId: props.patientCode,
          userId:selectdocList.code || serviceprovider?.id,
          cpscSeqId: number,
          whenAppointment: bookedScheduledate,
          fromTime: formatFromtime,
          toTime: updatedTime,
          status: 1,
          modifiedby: props.patientCode,
          id: appidnumber,
          consultationsType:props.consultationType,
          userName:selectdocList.name ||serviceprovider?.name,
          userMobile:selectdocList.number || serviceprovider?.number,
         
          serviceStatus:6,
          consultationsReason:comment
        }
      )

      .then((response) => {
        console.log("File uploaded successfully:", response.data);
        setDialog(false);
        closeFun();

        toast("Appointmentment updated successfully");
        handleSearch();
      })
      .catch((error) => {
        console.error("Error uploading file:", error);
        // Handle any errors
      });
  };

  const formatLabAppo = moment(preferDate).format("YYYY-MM-DD HH:mm:ss");
  
  const handleSaveLab = (e) => {
    e.preventDefault();
   
    http 
      .post(
        `${process.env.REACT_APP_BASEURL}preassesment/updateLabAppointment`,
        {
            paSeqId: props.paSeqId,
            //"cpscSeqId": 6058,
            orderId: props.laborderId,
            patientId: props.patientCode,
            hospitalId:  selectlabtestName.labId,
            locationId: selectlabtestName.locationId,
            preferDateTime: formatLabAppo,
            status: 1,
            hospitalName: selectlabtestName.labName,
            serviceStatus: 6,
            fromTime: startTimeFormat,
            toTime: endTimeFormat,
            patientLabTestsOrderDetailsList: [
                {
                    patientId:  props.patientCode,
                    patientLabTestOrderId: props.laborderId,
                    labTestName: selectlabtestName.name,
                    hospitalId: selectlabtestName.labId,
                    locationId:  selectlabtestName.locationId,
                    status: 1,
                    hospitalName: selectlabtestName.labName,
                    id:props.labTestId
                }
            ]
        }
       
      )

      .then((response) => {
       
        setDialog(false);
        closeFun();

        toast("Appointmentment updated successfully");
        handleSearch();
      })
      .catch((error) => {
        console.error("Error uploading file:", error);
        // Handle any errors
      });
  };

  const userCode = selectdocList ? selectdocList?.code : "";

  const bookedScheduledate = modifiedServiceDate
    ? moment(modifiedServiceDate).format("MM/DD/YYYY")
    : "";

  console.log(selectConsult, "qwerty");

  const handleValidation = () => {
    let hasError = false;
    const newErrorMessages = {
      appointmentDate: "",
      scheduleTime: "",
      serviceProviderName: "",
      consultationReason: "",
      consultationType: "",
    };

    // Validate appointment date
    if (isEditing) {
      if (props.appointmentCode === "LAB" && !preferDate) {
        newErrorMessages.appointmentDate = " * Appointment date is required";
        hasError = true;
      }
      // Add any additional validation for preferDate specific to "Lab" personnel code here
      else if (props.appointmentCode !== "LAB" && !modifiedServiceDate) {
        newErrorMessages.appointmentDate = "* Appointment date is required";
        hasError = true;
      }
    }

    // Validate schedule time
    if (isEditing) {
      if (props.appointmentCode === "LAB" && !preferTime) {
        newErrorMessages.scheduleTime = " * Select a time for the appointment";
        hasError = true;
      } else if (
        props.appointmentCode !== "LAB" &&
        (!frommtime || timeMessage !== "")
      ) {
        newErrorMessages.scheduleTime = "* Select a valid schedule time";
        hasError = true;
      }
    }

    if (isEditing && props.appointmentCode !== "LAB" && !comment) {
      newErrorMessages.consultationReason = "* Consultation reason is required";
      hasError = true;
    }

    // Validate consultation type (skip for "LAB" personnel code)
    if (isEditing && props.appointmentCode !== "LAB" && !selectConsult) {
      newErrorMessages.consultationType = "* Select a consultation type";
      hasError = true;
    }

    if (isEditing) {
      if (
        props.appointmentCode === "DR" &&
        props.serviceStatus === "4" &&
        !props.serviceProviderName
      ) {
        newErrorMessages.serviceProviderName =
          "* Service Provider Name is required";
        hasError = true;
      } else if (props.appointmentCode === "DR" && !selectdocList) {
        newErrorMessages.serviceProviderName = "* Select a Doctor";
        hasError = true;
      } else if (props.appointmentCode === "LAB" && !selectlabList) {
        newErrorMessages.serviceProviderName = "* Select a Lab";
        hasError = true;
      } else if (props.appointmentCode === "NUR" && !serviceprovider) {
        newErrorMessages.serviceProviderName = "* Select a Nurse";
        hasError = true;
      }
    }

    setErrorMessages({ ...errorMessages, ...newErrorMessages });

    if (!hasError) {
      setDialog(true); // Open the edit box if there are no errors
    } else {
      setIsEditing(true); // Open the "Edit Information" section if there are errors
    }

    return hasError;

  
  };


 

  const [startTime, endTime] = preferTime.split(" - ");
  const startTimeFormat = moment(startTime, ["h A"]).format("HH:mm");
  const endTimeFormat = moment(endTime, ["h A"]).format("HH:mm");


 


  useEffect(() => {
    if (selectlabList) {
      fetchlabLocationId(selectlabList.id);
    }
  }, [selectlabList]);
  // console.log(selectlabList.id,"location")

  const fetchlabLocationId = (labId) => {
    // Make the GET request to fetch the location ID
    http
      .get(`${process.env.REACT_APP_ELASTIC_BASEURL}labs/${labId}/labtest`)
      .then((response) => {
        console.log(response.data, "apidata122");

        setlabtestName(response.data);
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };

  const onFilter = (event) => {
    if (event.query) {
      const filteredOptions = dropdownOptions.filter((option) =>
        option.label.toLowerCase().includes(event.query.toLowerCase())
      );
      setSelectdocList(filteredOptions);
    } else {
      setSelectdocList(dropdownOptions);
    }
  };
  const onFilterLab = (event) => {
    if (event.query) {
      const filteredOptionsLab = dropdownOptionsLab.filter((option) =>
        option.label.toLowerCase().includes(event.query.toLowerCase())
      );
      setSelectlabList(filteredOptionsLab);
    } else {
      setSelectlabList(dropdownOptionsLab);
    }
  };
  const onFilterLabTest = (event) => {
    if (event.query) {
      const filteredOptionsLabTest = dropdownOptionsParticularLab.filter(
        (option) =>
          option.label.toLowerCase().includes(event.query.toLowerCase())
      );
      setselectlabtestName(filteredOptionsLabTest);
    } else {
      setselectlabtestName(dropdownOptionsParticularLab);
    }
  };

  const toastBC = useRef(null);

 
 
  const closeBothFunct = () => {
    setDialog(false);
    closeFun();
  };

  const currentDate = moment().startOf("day"); // Get the current date at the start of the day
 

 
  const currentTime = moment();

 
  const modifiedServiceDateMoment = moment(modifiedServiceDate);

 
  const isToday = modifiedServiceDateMoment.isSame(currentTime, "day");

//   const minTime = isToday ? currentTime.toDate() : undefined;
//   const maxTime = isToday ? currentTime.endOf("day").toDate() : undefined;

//   const modifiedServiceDateMoment = moment(modifiedServiceDate);
// const isToday = modifiedServiceDateMoment.isSame(currentTime, "day");

// Set the min and max times based on your conditions
let minTime = moment().startOf("day").hour(7); // 7:00 AM
let maxTime = moment().startOf("day").hour(21); // 9:00 PM

if (isToday) {
  // If the modified service date is today, update minTime to be the current time
  minTime = moment().max(currentTime);
}



  return (
    <>
      <ToastContainer />
      <Toast ref={toast} />
      <Toast ref={toastBC} position="bottom-center" />
      {/* <button label="Success" className="p-button-success" onClick={showSuccess} >gvbnjmk,</button> */}

      <div className="">
        <form>
          <div>
            <h2 style={{ color: "black", marginTop: "0px" }}>
              {" "}
              Add Appointment Details
            </h2>
          
              <button
                type="button"
                onClick={handleEditClick}
                className="editinfor"
              >
                Edit information
              </button>
          
          </div>
          {/* <button>Edit </button> */}
          <div className="row ">
           

          
              <div
                style={{
                  display: "flex",
                  marginBottom: "15px",
                  marginTop: "20px",
                }}
              >
                <div style={{ width: "40%" }}>
                  <label>Requested Date :</label>
                </div>
                <div style={{ width: "60%" }}>
                  {props.modifiedServiceDate
                    ? moment(props.modifiedServiceDate).format("DD-MM-YYYY")
                    : ""}
                </div>
              </div>
          

            <div
              style={{
                display: "flex",
                marginBottom: "15px",
                marginTop: "20px",
              }}
            >
              <div style={{ width: "40%" }}>
                <label>Appointment date : </label>
              </div>
              <div>
                {isEditing ? (
                  <>
                    {props.appointmentCode === "LAB" ? (
                      <DatePicker
                        id="fromDate"
                        name="fromDate"
                        dropdownMode="select"
                        showMonthDropdown
                        showYearDropdown
                       
                        // className="pt-2 text-gray-900 "

                        dateFormat="dd-MM-yyyy"
                        // value={moment(preferDate).format("DD-MM-YYYY")}
                        onSelect={changeDate}
                        disabledKeyboardNavigation={true}
                        autoFocus={false}
                        placeholderText="Select Date"
                        customInput={
                          <div
                            style={{
                              position: "relative",
                              display: "flex",
                              flexDirection: "column",
                            }}
                          >
                            <input
                              type="text"
                              className="inputfield"
                              // className="form-control modifieddate"
                              name="whenAppointment"
                              value={
                                preferDate
                                  ? moment(preferDate).format("DD-MM-YYYY")
                                  : ""
                              }
                              readOnly
                            />
                            <i
                              className="fa fa-calendar  fa-pencil-square-o1 fontsome123"
                              aria-hidden="true"
                            ></i>
                          </div>
                        }
                      />
                    ) : (
                      <DatePicker
                        showIcon
                        selected={modifiedServiceDate}
                      
                        onChange={(e) => setModifiedServiceDate(e)}
                        placeholderText="select"
                        customInput={
                          <div
                            style={{
                              position: "relative",
                              display: "flex",
                              flexDirection: "column",
                            }}
                          >
                            <input
                              type="text"
                              className="inputfield"
                              // className="form-control modifieddate"
                              name="whenAppointment"
                              value={
                                modifiedServiceDate
                                  ? modifiedServiceDate.toLocaleString(
                                      "en-GB",
                                      {
                                        day: "numeric",
                                        month: "numeric",
                                        year: "numeric",
                                      }
                                    )
                                  : ""
                              }
                              readOnly
                            />
                            <i
                              className="fa fa-calendar  fa-pencil-square-o1 fontsome123"
                              aria-hidden="true"
                            ></i>
                          </div>
                        }
                      />
                    )}
                  </>
                ) : props.serviceStatus === "4" ? (
                  ""
                ) : props.modifiedServiceDate ? (
                  moment(props.modifiedServiceDate).format("DD-MM-YYYY")
                ) : (
                  ""
                )}
                <div style={{ color: "red", marginTop: "12px",fontSize:"14px"}}>
                  {errorMessages.appointmentDate}
                </div>
              </div>
            </div>

            <div
              style={{
                display: "flex",
                marginBottom: "15px",
                marginTop: "20px",
              }}
            >
              <div style={{ width: "40%" }}>
                <label>Schedule Time :</label>
              </div>
              <div style={{ width: "60%" }}>
                {isEditing ? (
                  <>
                    <div>
                      {props.appointmentCode === "LAB" ? (
                        <>
                          <select
                            // class=" text-xs h-10 bg-white border-gray-300 text-gray-900 focus:outline-none focus:borer-rose-600 w-full"
                            className="dropdowntd"
                            id="procedureStatus"
                            style={{ marginTop: "20px" }}
                            name="procedureStatus"
                            value={preferTime}
                            onChange={handleChange}
                          >
                            <option value="">Select time </option>

                            {preferredLabTime.map((res, i) => (
                              <option
                                key={i}
                                // className="py-1 text-sm text-green-600 font-semibold"

                                value={res.value}
                                className={
                                  limitTimeForLab(res)
                                    ? "disabled-option calander"
                                    : "enabled-option calander"
                                }
                                disabled={limitTimeForLab(res)}
                              >
                                {res.label}
                              </option>
                            ))}
                          </select>
                          <span
                            style={{ color: "red", fontSize: "12px" }}
                          ></span>
                        </>
                      ) : (
                        <DatePicker
                          selected={frommtime}
                          className="inputfield"
                          onChange={(date) => setFromtime(date)}
                          showTimeSelect
                          showTimeSelectOnly
                          dateFormat="h:mm aa"
                          timeIntervals={15}
                          timeCaption="Time"
                          minTime={minTime.toDate()} // Convert moment object to Date
                          maxTime={maxTime.toDate()}
                          customTimeInput={<input readOnly />}
                          placeholder="Add Time"
                        />
                      )}
                      <div style={{ color: "red", marginTop: "10px",fontSize:"14px" }}>
                        {errorMessages.scheduleTime}
                      </div>
                    </div>
                    <div style={{ color: "red", fontSize: "12px" }}>
                      {timeMessage}
                    </div>
                  </>
                ) : (
                  props.fromTime
                )}
              </div>
            </div>

         

            <div
              style={{
                display: "flex",
                marginBottom: "15px",
                marginTop: "20px",
              }}
            >
              <div style={{ width: "40%" }}>
                <label>Service Provider Name :</label>
              </div>
              <div style={{ width: "60%" }}>
                {isEditing ? (
                  <>
                    {
                      props.appointmentCode === "DR" &&
                      props.serviceStatus === "4" ? (
                        props.serviceProviderName
                      ) : props.appointmentCode === "DR" ? (
                        <Dropdown
                          id="disease"
                          name="disease"
                          value={selectdocList}
                          options={dropdownOptions}
                          onChange={(e) => setSelectdocList(e.value)}
                          placeholder="Select Doctor"
                          className="dropdowntd"
                          optionLabel="label"
                          filter
                          filterBy="label"
                          filterMatchMode="contains"
                          filterPlaceholder="Type to filter"
                          filterInputAutoFocus
                          onFilter={onFilter}
                        />
                      ) : props.appointmentCode === "LAB" ? (
                        <Dropdown
                          id="disease"
                          name="disease"
                          value={selectlabList}
                          options={dropdownOptionsLab}
                          onChange={(e) => setSelectlabList(e.value)}
                          placeholder="Select Lab"
                          className="dropdowntd"
                          optionLabel="label"
                          filter
                          filterBy="label"
                          filterMatchMode="contains"
                          filterPlaceholder="Type to filter"
                          filterInputAutoFocus
                          onFilter={onFilterLab}
                        />
                      ) : props.appointmentCode === "NUR" ? (
                        <Dropdown
                          id="disease"
                          name="disease"
                          value={serviceprovider}
                          options={dropdownOptionsNurse}
                          onChange={(e) => setServiceprovider(e.target.value)}
                          placeholder="Select Nurse"
                          className="dropdowntd"
                          optionLabel="label"
                          // filter
                          // filterBy="label"
                          // filterMatchMode="contains"
                          // filterPlaceholder="Type to filter"
                          // filterInputAutoFocus
                          // onFilter={onFilterLab}
                        />
                      ) : (
                        ""
                      )

                      // <input type="text" name="userName" onChange={(e) => setServiceprovider(e.target.value)}
                      //     value={serviceprovider}

                      //     placeholder=" Service provider Name" className="inputfield" />
                    }
                  </>
                ) : props.serviceProviderName === null ? (
                  ""
                ) : (
                  props.serviceProviderName
                )}

                <div style={{ color: "red", marginTop: "5px",fontSize:"14px" }}>
                  {errorMessages.serviceProviderName}
                </div>

                {/* <input type="text" placeholder="service Provider Name" className="inputfield" value={props.serviceProviderName}/> */}
              </div>
            </div>

            {props.appointmentCode === "LAB" ? (
              <div
                style={{
                  display: "flex",
                  marginBottom: "15px",
                  marginTop: "20px",
                }}
              >
                <div style={{ width: "40%" }}>
                  <label>Lab Test Name :</label>
                </div>
                <div style={{ width: "60%" }}>
                  {isEditing ? (
                    <Dropdown
                      id="disease"
                      name="disease"
                      value={selectlabtestName}
                      options={dropdownOptionsParticularLab}
                      onChange={(e) => setselectlabtestName(e.value)}
                      placeholder="Select Lab Test Name"
                      className="dropdowntd"
                      optionLabel="label"
                      filter
                      filterBy="label"
                      filterMatchMode="contains"
                      filterPlaceholder="Type to filter"
                      filterInputAutoFocus
                      onFilter={onFilterLabTest}
                    />
                  ) : (
                    props.labTestName
                  )}
                </div>
              </div>
            ) : (
              ""
            )}

            {props.appointmentCode === "LAB" ? (
              ""
            ) : (
              <div
                style={{
                  display: "flex",
                  marginBottom: "15px",
                  marginTop: "20px",
                }}
              >
                <div style={{ width: "40%" }}>
                  <label>Service Provider No :</label>
                </div>
                <div style={{ width: "60%" }}>
                  {isEditing ? (
                    <>
                      {props.appointmentCode === "DR" ? (
                        <>{selectdocList?.number}</>
                      ) : // <p>{selectdocList.number}</p>
                      props.appointmentCode === "NUR" ? (
                        <>{serviceprovider?.number}</>
                      ) : (
                        <input
                          type="tel"
                          name="userName"
                          onChange={(e) => setServiceproviderNo(e.target.value)}
                          value={serviceproviderNo}
                          placeholder=" Service provider No."
                          className="inputfield"
                        />
                      )}

                      {/* 
                                            <Dropdown onChange={(e) => setSelectdocList(e.value)} options={dropdownOptiondoc} optionLabel="name" name="Service Provider Name" value={selectConsult}
                                                placeholder={"Select Doclist"} className="dropdowntd" /> */}
                    </>
                  ) : props.serviceProviderMobile === null ? (
                    ""
                  ) : (
                    props.serviceProviderMobile
                  )}

                  {/* <input type="text" placeholder="Service Provider Number" className="inputfield" value={props.serviceProviderMobile}/> */}
                </div>
              </div>
            )}

            {props.appointmentCode === "LAB" ? (
              ""
            ) : (
              <div
                style={{
                  display: "flex",
                  marginBottom: "15px",
                  marginTop: "20px",
                }}
              >
                <div style={{ width: "40%" }}>
                  <label>Consultation Reason :</label>
                </div>
                <div style={{ width: "60%" }}>
                  {isEditing ? (
                    <>
                      <textarea
                        cols={32}
                        type="text"
                        name="comment"
                        onChange={(e) => setComment(e.target.value)}
                        value={comment}
                        className="inputfield"
                      />
                    </>
                  ) : (
                    props.comments
                  )}

                  <div style={{ color: "red", marginTop: "5px",fontSize:"14px" }}>
                    {errorMessages.consultationReason}
                  </div>

                  {/* <i className="fa fa-pencil-square-o fa-pencil-square-o1 fontsome" aria-hidden="true" style={{ marginTop: "-40px", paddingRight: "5px" }} onClick={handelClick}> */}

                  {/* <textarea cols={32} placeholder="Comments" className="inputfield"  value={props.comments}></textarea> */}
                </div>
              </div>
            )}

            {props.appointmentCode === "LAB" ? (
              ""
            ) : (
              <div
                style={{
                  display: "flex",
                  marginBottom: "15px",
                  marginTop: "20px",
                }}
              >
                <div style={{ width: "40%" }}>
                  <label>Consultation Type :</label>
                </div>
                <div style={{ width: "60%" }}>
                  {isEditing ? (
                    <>
                      {props.appointmentCode === "NUR" ? (
                        <Dropdown
                          onChange={(e) => setSelectConsult(e.value)}
                          options={dropdownOptionConsultnurse}
                          optionLabel="name"
                          name="consultationsType"
                          value={selectConsult}
                          placeholder={"Select Consultation"}
                          className="dropdowntd"
                        />
                      ) : (
                        <Dropdown
                          onChange={(e) => setSelectConsult(e.value)}
                          options={dropdownOptionConsult}
                          optionLabel="name"
                          name="consultationsType"
                          value={selectConsult}
                          placeholder={"Select Consultation"}
                          className="dropdowntd"
                        />
                      )}
                    </>
                  ) : props.consultationType === "V" ? (
                    "Video"
                  ) : props.consultationType === "H" ? (
                    "Home Visit"
                  ) : props.consultationType === "P" ? (
                    "Phone"
                  ) : props.consultationType === "E" ? (
                    "Eclinic"
                  ) : (
                    ""
                  )}

                  <div style={{ color: "red", marginTop: "5px",fontSize:"14px" }}>
                    {errorMessages.consultationType}
                  </div>

                  {/* <input type="text" placeholder="Consultation Type" className="inputfield" value={props.consultationType}/> */}
                </div>
              </div>
            )}
            {/* {props.appointmentCode === "LAB"  ? "" :
                            <div style={{ display: "flex", marginBottom: "15px", marginTop: "20px" }}>
                                <div style={{ width: "40%" }}>
                                    <label>Status :</label>
                                </div>
                                <div style={{ width: "60%" }}>

                                    {isEditing ? (
                                        (

                                            <>
                                                <Dropdown onChange={(e) => setSelectStatus(e.value)} name="status" options={dropdownOptionStatus} optionLabel="name" value={selectStatus}
                                                    placeholder={"Select Status"} className="dropdowntd" />

                                            </>



                                        )
                                    ) :


                                        (props.status)
                                    }

                                   
                                </div>
                            </div>
                        } */}

            {props.serviceStatus === "2" && props.appointmentCode === "NUR" ? (
              <div
                style={{
                  display: "flex",
                  marginBottom: "15px",
                  marginTop: "20px",
                }}
              >
                <div style={{ width: "40%" }}>
                  <label>Status :</label>
                </div>
                <Dropdown
                  onChange={(e) => setSelectStatus(e.value)}
                  name="status"
                  options={dropdownOptionStatus}
                  optionLabel="name"
                  value={selectStatus}
                  placeholder={"Select Status"}
                  className="dropdowntd"
                />
              </div>
            ) : props.serviceStatus === "2" &&
              (props.consultationType === "P" ||
                props.consultationType === "H") ? (
              <>
                <div
                  style={{
                    display: "flex",
                    marginBottom: "15px",
                    marginTop: "20px",
                  }}
                >
                  <div style={{ width: "40%" }}>
                    <label>Status :</label>
                  </div>
                  <Dropdown
                    onChange={(e) => setSelectStatus1(e.value)}
                    name="status"
                    options={dropdownOptionStatusPhonic}
                    optionLabel="name"
                    value={selectStatus1}
                    placeholder={"Select Status"}
                    className="dropdowntd"
                  />
                </div>
              </>
            ) : (
              ""
            )}
            <div
              style={{
                display: "flex",
                marginBottom: "15px",
                marginTop: "20px",
                float: "right",
              }}
            >
            
             { props.appointmentCode === "DR" ||  props.appointmentCode === "NUR" ? 
              <button
                  type="submit"
                  className="addbutton"
                  onClick={(e) => handleSave(e)}
                >
                  Update
                </button> : props.appointmentCode === "LAB" ? <button
                  type="submit"
                  className="addbutton"
                  onClick={(e) => handleSaveLab(e)}
                >
                  Update
                </button> : ""} 
            

            

        
            
            </div>
          </div>
        </form>

        <Dialog
          visible={ErrorDialog}
          showHeader={true}
          resizable={false}
          draggable={false}
          blockScroll={false}
          // style={{ width: "1000px" }}
          className=""
          style={{
            width: "390px",
          }}
          //  className="p-20"
          onHide={() => setErrorDialog(false)}
        >
          <div
            style={{
              // width: "100px",
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              textAlign: "center",
            }}
          >
            <i
              class="pi pi-times-circle"
              style={{ color: "green", fontWeight: "700", fontSize: "75px" }}
            ></i>

            <h4 className="text-xl mt-8" style={{}}>
              {ErrorMsg}
            </h4>
          </div>
        </Dialog>
      </div>

    
    
    </>
  );
}
export default AssessmentScheduleSidebarUpdate;
