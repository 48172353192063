

import { DASH } from "../Constants/Dashboardconstants";
import Dashboardservice from "../services/Dashboardservice";

export const getBusinessEvent = (data) => async (dispatch) => {
    request();
    const res = await Dashboardservice.getBusinessEventList(data)
    try {
        success(res);
        return Promise.resolve(res.data);
    } catch (err) {
        failure(err)
        return Promise.reject(err);
    }

    function request() {
        dispatch({ type: DASH.REQUEST_BUSINESS_EVENT });
    };

    function success(res) {
        dispatch({ type: DASH.SUCCESS_BUSINESS_EVENT, payload: res.data });
    };
    function failure(err) {
        dispatch({
            type: DASH.FAILED_BUSINESS_EVENT,
            payload: err
        });
    };
};
export const fetchMapMyIndiaToken = async () => {
    const res = await Dashboardservice.fetchMapMyIndiaToken();
    return res;
};
export const fetchEclinicsByPincode = async (payload) => {
    const res = await Dashboardservice.getEclinicsByPincode(payload);
    return res;
};

export const getSurgerylist = (data) => async (dispatch) => {
    request();
    const res = await Dashboardservice.getSurgeryAppointmentsList(data)
    try {
        success(res);
        return Promise.resolve(res.data);
    } catch (err) {
        failure(err)
        return Promise.reject(err);
    }

    function request() {
        dispatch({ type: DASH.REQUEST_BUSINESS_EVENT });
    };

    function success(res) {
        dispatch({ type: DASH.SUCCESS_BUSINESS_EVENT, payload: res.data });
    };
    function failure(err) {
        dispatch({
            type: DASH.FAILED_BUSINESS_EVENT,
            payload: err
        });
    };
};

export const getFranchiseEnquiry = () => async (dispatch) => {
    request();
    const res = await Dashboardservice.getFranchiseEnquiryList()
    try {
        success(res);
        return Promise.resolve(res.data);
    } catch (err) {
        failure(err)
        return Promise.reject(err);
    }

    function request() {
        dispatch({ type: DASH.REQUEST_BUSINESS_EVENT });
    };

    function success(res) {
        dispatch({ type: DASH.SUCCESS_BUSINESS_EVENT, payload: res.data });
    };
    function failure(err) {
        dispatch({
            type: DASH.FAILED_BUSINESS_EVENT,
            payload: err
        });
    };
};

// export const  getCarePlandetails= () => async (dispatch) => {
//     request();
//     const res = await Dashboardservice.getCarePlandetails()
//     try {
//         success(res);
//         return Promise.resolve(res.data);
//     } catch (err) {
//         failure(err)
//         return Promise.reject(err);
//     }

//     function request() {
//         dispatch({ type: DASH.REQUEST_BUSINESS_EVENT });
//     };

//     function success(res) {
//         dispatch({ type: DASH.SUCCESS_BUSINESS_EVENT, payload: res.data });
//     };
//     function failure(err) {
//         dispatch({
//             type: DASH.FAILED_BUSINESS_EVENT,
//             payload: err
//         });
//     };
// };

export const getInputEvent = (data) => async (dispatch) => {
    request();
    const res = await Dashboardservice.getInputEventList(data)
    try {
        success(res);
        return Promise.resolve(res.data);
    } catch (err) {
        failure(err)
        return Promise.reject(err);
    }

    function request() {
        dispatch({ type: DASH.REQUEST_BUSINESS_EVENT });
    };

    function success(res) {
        dispatch({ type: DASH.SUCCESS_BUSINESS_EVENT, payload: res.data });
    };
    function failure(err) {
        dispatch({
            type: DASH.FAILED_BUSINESS_EVENT,
            payload: err
        });
    };
};

export const onRefundProcess = (data) => async (dispatch) => {
    request();
    const res = await Dashboardservice.onRefundProcess(data)
    try {
        success(res);
        return Promise.resolve(res.data);
    } catch (err) {
        failure(err)
        return Promise.reject(err);
    }

    function request() {
        dispatch({ type: DASH.REQUEST_REFUND_PROCESS });
    };

    function success(res) {
        dispatch({ type: DASH.SUCCESS_REFUND_PROCESS, payload: res.data });
    };
    function failure(err) {
        dispatch({
            type: DASH.FAILED_REFUND_PROCESS,
            payload: err
        });
    };
};



export const updatePreferTime = (data) => async (dispatch) => {
    request();
    const res = await Dashboardservice.updatePreferTime(data)
    try {
        success(res);
        return Promise.resolve(res.data);
    } catch (err) {
        failure(err)
        return Promise.reject(err);
    }

    function request() {
        dispatch({ type: DASH.REQUEST_UPDATE_LAB });
    };

    function success(res) {
        dispatch({ type: DASH.SUCCESS_UPDATE_LAB, payload: res.data });
    };
    function failure(err) {
        dispatch({
            type: DASH.FAILED_UPDATE_LAB,
            payload: err
        });
    };
};



export const getOrderDetails = (data) => async (dispatch) => {
    request();
    const res = await Dashboardservice.getOrderDetails(data)
    try {
        success(res);
        return Promise.resolve(res.data);
    } catch (err) {
        failure(err)
        return Promise.reject(err);
    }

    function request() {
        dispatch({ type: DASH.REQUEST_ORDER_DETAILS });
    };

    function success(res) {
        dispatch({ type: DASH.SUCCESS__ORDER_DETAILS, payload: res.data });
    };
    function failure(err) {
        dispatch({
            type: DASH.FAILED_ORDER_DETAILS,
            payload: err
        });
    };
};

export const getsurgeryOrderDetails = (data) => async (dispatch) => {
    request();
    const res = await Dashboardservice.getsurgeryOrderDetails(data)
    try {
        success(res);
        return Promise.resolve(res.data);
    } catch (err) {
        failure(err)
        return Promise.reject(err);
    }

    function request() {
        dispatch({ type: DASH.REQUEST_ORDER_DETAILS });
    };

    function success(res) {
        dispatch({ type: DASH.SUCCESS__ORDER_DETAILS, payload: res.data });
    };
    function failure(err) {
        dispatch({
            type: DASH.FAILED_ORDER_DETAILS,
            payload: err
        });
    };
};


export const getInventoryDoctor = (data) => async (dispatch) => {
    request();
    const res = await Dashboardservice.getInventoryDoctor(data)
    try {
        success(res);
        return Promise.resolve(res.data);
    } catch (err) {
        failure(err)
        return Promise.reject(err);
    }

    function request() {
        dispatch({ type: DASH.REQUEST_BUSINESS_EVENT });
    };

    function success(res) {
        dispatch({ type: DASH.SUCCESS_BUSINESS_EVENT, payload: res.data });
    };
    function failure(err) {
        dispatch({
            type: DASH.FAILED_BUSINESS_EVENT,
            payload: err
        });
    };
};

export const onAddComment = (data) => async (dispatch) => {
    request();
    const res = await Dashboardservice.onAddComment(data)
    try {
        success(res);
        return Promise.resolve(res.data);
    } catch (err) {
        failure(err)
        return Promise.reject(err);
    }

    function request() {
        dispatch({ type: DASH.REQUEST_ADD_COMMENT });
    };

    function success(res) {
        dispatch({ type: DASH.SUCCESS_ADD_COMMENT, payload: res.data });
    };
    function failure(err) {
        dispatch({
            type: DASH.FAILED_ADD_COMMENT,
            payload: err
        });
    };
};

export const onAddstatus = (data) => async (dispatch) => {
    request();
    const res = await Dashboardservice.onAddstatus(data)
    try {
        success(res);
        return Promise.resolve(res.data);
    } catch (err) {
        failure(err)
        return Promise.reject(err);
    }

    function request() {
        dispatch({ type: DASH.REQUEST_ADD_COMMENT });
    };

    function success(res) {
        dispatch({ type: DASH.SUCCESS_ADD_COMMENT, payload: res.data });
    };
    function failure(err) {
        dispatch({
            type: DASH.FAILED_ADD_COMMENT,
            payload: err
        });
    };
};

export const onAddpackagestatus = (data) => async (dispatch) => {
    request();
    const res = await Dashboardservice.onAddpackagestatus(data)
    try {
        success(res);
        return Promise.resolve(res.data);
    } catch (err) {
        failure(err)
        return Promise.reject(err);
    }

    function request() {
        dispatch({ type: DASH.REQUEST_ADD_COMMENT });
    };

    function success(res) {
        dispatch({ type: DASH.SUCCESS_ADD_COMMENT, payload: res.data });
    };
    function failure(err) {
        dispatch({
            type: DASH.FAILED_ADD_COMMENT,
            payload: err
        });
    };
};


export const onUpdateAppointment = (data) => async (dispatch) => {
    request();
    const res = await Dashboardservice.onUpdateAppointment(data)
    try {
        success(res);
        return Promise.resolve(res.data);
    } catch (err) {
        failure(err)
        return Promise.reject(err);
    }

    function request() {
        dispatch({ type: DASH.REQUEST_ADD_COMMENT });
    };

    function success(res) {
        dispatch({ type: DASH.SUCCESS_ADD_COMMENT, payload: res.data });
    };
    function failure(err) {
        dispatch({
            type: DASH.FAILED_ADD_COMMENT,
            payload: err
        });
    };
};



export const login = (data) => async (dispatch) => {
    request();
    const res = await Dashboardservice.onLogin(data)
    try {
        success(res);
        localStorage.setItem('loginData', JSON.stringify(res.data));
        return Promise.resolve(res.data);
       
    } catch (err) {
        failure(err)
        return Promise.reject(err);
    }

    function request() {
        dispatch({ type: DASH.REQUEST_LOGIN });
    };

    function success(res) {
        dispatch({ type: DASH.SUCCESS_LOGIN, payload: res.data });
    };
    function failure(err) {
        dispatch({
            type: DASH.FAILED_LOGIN,
            payload: err
        });
    };
};



export const getBearerToken = (data) => async (dispatch) => {
    request();

    const res = await Dashboardservice.callToken(data);
    try {
        success(res);

        console.log('isssssss',JSON.stringify(res))
        
        return Promise.resolve(res.data);
    } catch (err) {
        console.log('issssssseeee',JSON.stringify(err))
        failure(err)
        return Promise.reject(err);
    }

    function request() {
        dispatch({ type: DASH.REQUEST_TOKEN });
    };

    function success(res) {
        dispatch({ type: DASH.SUCCESS_TOKEN, payload: res.data });
    };
    function failure(err) {
        dispatch({
            type: DASH.FAILED_TOKEN,
            payload: err
        });
    };
};








