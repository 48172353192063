import React, { useState } from "react";
import "./index.css";

import status_approved from "../../Assets/Images/status_approved.svg";
import RejectIcon from "../../Assets/Images/RejectIcon.svg";

function DialogLabTestReschedule({
  onClickYesBtn,
  onClickCancelBtn,
  isApproveOrReject,
  onTextChange,
  remarks
}) {

  const [isErrorMsg, setErrorMsg] = useState(false);
  const onClickYes = () => {

    if(remarks?.length===0 && isApproveOrReject !=="Approve"){
      setErrorMsg(true)
      return
    }
    onClickYesBtn();
  };

  const onClickCancel = () => {

   
    onClickCancelBtn();
  };

  const onChangeText = (e) => {
    onTextChange(e.target.value);

    if(e.target.value.length>0){
      setErrorMsg(false)

    }


  };

  return (
    <div className="panelist-overlay">
      <div className="panelist-statusUpdateBox">
        <div className="panelist-overlayUpdateStatusContainer">
          <div className="panelist-approveRejectIconContainer">
            <img
              src={
                isApproveOrReject === "Approve" ? status_approved : RejectIcon
              }
              alt="Approve"
              className="imageLogo"
            />

            <div
              className={
                isApproveOrReject === "Approve" ? "status" : "statusReject"
              }
            >

            {
              isApproveOrReject === "Approve" ?" Status: Approved" :"Status: Rejected"
            }
             
            </div>

            <p className="panelist-overlayUpdateStatus">
              {" "}
              {isApproveOrReject === "Approve"
                ? " Do you want to refund the money ?"
                : "Are you sure you want to reject refund money ?"}
            </p>

            <div className="typeReason">
              <input
                className="typeReasonNew"
                type="text"
                placeholder={
                  isApproveOrReject === "Approve"
                    ? "Please Enter Remark..."
                    : "Please Enter Reason..."
                }
                onChange={(e) => onChangeText(e)}
              />
            </div>

            <div className="statusRejectDiv">

          

            {
              isApproveOrReject !== "Approve" && isErrorMsg ?
              <div className="statusMsg">Please Enter Reason</div> : <div className="statusMsg"></div>

            }
            </div>

            

            <div className="btnContainer">
              <div className="primaryBtn" onClick={onClickYes}>
                <div className="textYesRefund">
                  {" "}
                  {isApproveOrReject === "Approve"
                    ? "Yes, Refund Money"
                    : "Yes, Reject Money"}{" "}
                </div>
              </div>

              <div className="cancelBtn" onClick={onClickCancel}>
                <div className="textCancel">Cancel</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default DialogLabTestReschedule;
