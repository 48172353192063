import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import Dashboard from "../src/application/Router/Dashboard";
import Profile from "../src/Screens/Profile";
import BusinessEvent from "../src/Screens/BusinessEvent";
import InventoryDoctorAppointment from "../src/Screens/InventoryDoctorAppointment";
import BookLabTest from "../src/Screens/BookLabTest";
import FranchiseEnquiry from "../src/Screens/FranchiseEnquiry";
import TranslatedPrescription from "../src/Screens/TranslatedPrescription";
import Packages from "../src/Screens/Packages";
import Membership from "../src/Screens/Membership";
import CarePlan from "../src/Screens/CarePlan";
import CareplansSchedule from "../src/Screens/CarePlan/CareplansSchedule";
import CarePlanSos from "./Screens/CarePlan/SOS/CarePlanSos";
import Dash from "./Screens/CarePlan/Dash/Dash";
import Event from "./Screens/CarePlan/Business Event/Event"


import CureBayLogin from './Screens/CureBayLogin';
import ChangePassword from './Screens/ChangePassword';
import Inbox from '../src/Screens/Inbox'
import React from "react";

import store from "./Redux/store/store";
import { Provider } from "react-redux";
import CareplansDetail from "./Screens/CarePlan/CareplansDetail";
import Schedule from "./Screens/CarePlan/Schedule";
import Assessment from "./Screens/CarePlan/PreAssessment/Assessment";
import { APP_ROUTES } from '../src/application/Router/constants/AppRoutes';
import EPRECRIPTION from "./Screens/CarePlan/EprescriptionDoc";
import Marketing from "./Screens/CarePlan/Marketing";
import UserAdmin from "./Screens/CarePlan/Useradmin";
import Doctor from './Screens/Doctor/Doctor';

function App() {
  return (
    <Provider store={store}>
    <BrowserRouter>
      <Routes>
        <Route path="/"  element={<Navigate to="/dashboard" />} />
        
        <Route path='/login' element={<CureBayLogin />} />
        <Route path='/changePassword' element={<ChangePassword />} />
        <Route path="/" element={<Dashboard />} >

        <Route path='/dashboard' element={<Dash/>} />
        <Route path='/doctor' element={<Doctor/>} />
        <Route path='/inbox' element={<Inbox />} />
        <Route path='/profile' element={<Profile />} />
        <Route path='/businessEvent' element={<BusinessEvent />} />
        <Route path='/inventoryDoctorAppointment' element={<InventoryDoctorAppointment />} />
        <Route path='/bookLabTest' element={<BookLabTest />} />
        <Route path='/franchiseenquiry' element={<FranchiseEnquiry/>} />
        <Route path='/translatedPrescription' element={<TranslatedPrescription/>} />
        <Route path='/packages' element={<Packages/>} />
        <Route path='/membership' element={<Membership/>} />
        <Route path='/bookCarePlan' element={<CarePlan/>} />
        <Route path='/carePlan' element={<CareplansDetail/>} />
        <Route path='/careplansSchedule' element={<CareplansSchedule/>} />
        <Route path='/Schedule' element={<Schedule/>} />
        <Route path='/careplanSos' element={<CarePlanSos/>} />
        <Route path='/preassesment' element={<Assessment/>} />
        <Route path='/careplanbusinessevent' element={<Event/>} />
        <Route path='/marketing' element={<Marketing/>} />
        <Route path='/packages' element={<Packages/>} />
        <Route path='/membership' element={<Membership/>} />
        <Route path='/useradmin' element={<UserAdmin/>} />
        <Route exact path={APP_ROUTES.EPRECRIPTION} element={<EPRECRIPTION/>} ></Route>
       

        </Route>
      </Routes>
    </BrowserRouter>
    </Provider>
  );
}


export default App;
