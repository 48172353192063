import axios from "axios";
import { secureStorage } from "../Reducers/authReducer";

var axiosInstance = axios.create({
    baseURL: process.env.REACT_APP_MEDICARE_BASEURL,
    headers: {
        "Content-type": "application/json",
    }
});


axiosInstance.interceptors.request.use(
    config => {
        let s_token =  secureStorage.getItem('token');

        if (!config.headers["Authorization"]) {
            config.headers["Authorization"] = `Bearer eyJhbGciOiJSUzI1NiIsImtpZCI6IjkxMWUzOWUyNzkyOGFlOWYxZTlkMWUyMTY0NmRlOTJkMTkzNTFiNDQiLCJ0eXAiOiJKV1QifQ.eyJhdWQiOiJodHRwczovL21lZGljYXJlLmN1cmViYXkuY29tLyIsImF6cCI6IjEwMTY3Mzg5NTIzNTMtY29tcHV0ZUBkZXZlbG9wZXIuZ3NlcnZpY2VhY2NvdW50LmNvbSIsImVtYWlsIjoiMTAxNjczODk1MjM1My1jb21wdXRlQGRldmVsb3Blci5nc2VydmljZWFjY291bnQuY29tIiwiZW1haWxfdmVyaWZpZWQiOnRydWUsImV4cCI6MTY5MTY4NDY5MiwiaWF0IjoxNjkxNjgxMDkyLCJpc3MiOiJodHRwczovL2FjY291bnRzLmdvb2dsZS5jb20iLCJzdWIiOiIxMDg3MzcxOTQ1NjI4OTY3MjU5ODQifQ.4aj5or6X9le0Oh7EJ_4uk6oAmt6RgA6uDJz-_cBzmX5dTcw6D-w7cK6zRwFDQvl4hA9XSoMw3vwNmC8sfzL-AJzAaKIzCo8Qb-9Z5rW6gH0G_s8IfAyt9VrrtrYzmNBpFDq9C0-c2vQa7oyBhiSzH2AX6rrSWvo-gO2wfpxA3gbMQCl39EUQY3qJrXEUbo1jWFyfeyHuQVPsaaZXIL8yYg0Uzx6xG12yp4pjl_jh5dozEtMBh9QS8gzyVqUyx8vZsGsEq1SbmMovqvpH5XTostruQbSj8NW-kpTA4M6syKXly72DxNfT7XcE1Nh7UaKjVhNMP5XhE1g_pefglnFofg`;
        }
        return config;
    },
    error => {
        Promise.reject(error);
    }
);

axiosInstance.interceptors.response.use(
    (response) => {
        return response
    },
    error => {
        Promise.reject(error);
    }
)



export default axiosInstance;
