import React, { useEffect } from "react";
import { useState } from "react";
import status_approved from "../../Assets/Images/status_approved.svg";
import { ReactDialogBox } from "react-js-dialog-box";
import "./index.css";
import "react-js-dialog-box/dist/index.css";
import Loader from "../../components/Loader/Loader";
import { getLocalTime, getLocalOnlyDate } from "../../utils/LocalTimeFormat";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useDispatch } from "react-redux";
import moment from "moment";
import {
  onAddstatus,
} from "../../Redux/Actions/dashboardaction";

function SurgeryAppointmentDetails({
  updateComplete,
  surgeryList,
  onClickCancelBtn,
  isSelectedOption,
  orderDetails,
  selectedItem,
  formActivity,
  onTextChange,
}) {
  const dispatch = useDispatch();
  const [isErrorMsg, setErrorMsg] = useState(false);
  const [commentTextnew, setCommentTextnew] = useState("");
  const [remarks, setRemarks] = useState(selectedItem?.boRemarks);

  // const onChangeTextRemarksnew = (text) => {
  //   setCommentTextnew(text);
  // };

  const onChangeText = (e) => {
    setCommentTextnew(e.target.value);

    if (e.target.value.length > 0) {
      setErrorMsg(false)

    }


  };
  const onClickCancel = () => {
    onClickCancelBtn();
  };
  console.log(surgeryList, "hiii");
  console.log("dlssm", JSON.stringify(selectedItem));
  const [submitData, setSubmitData] = useState({})
  const handleChange = (e) => {
    setSubmitData({
      ...submitData,
      [e.target.name]: e.target.value
    })
  }

  const status = {
    5: "OPD Booked",
    6: "OPD Complete",
    7: "Medicine prescribed",
    8: "Investigations",
    9: "Surgery prescribed",
    10: "Ipd booked",
    11: "Pateint admitted",
    12: "In OT",
    13: "Pateint discharge",
    14: "Surgery completd",
    15: "Bills received",
    16: "Others."
  }



  const handleSubmit = (e) => {
    e.preventDefault();

    console.log(submitData, "status data");
    let userData = JSON.parse(localStorage.getItem("userData"));

    let payload = {
      orderId: selectedItem?.id,
      status: commentTextnew,
      boRemarks: remarks,
      patientId: selectedItem?.patientId,
      createdBy: userData?.firstName + " " + userData?.lastName,

    };

    console.log(payload, "payloadio");
    dispatch(onAddstatus(payload)).then((res) => {
      toast("Status Update Sucessfully")
      updateComplete();
    });

  }
  console.log(selectedItem, "mon")
  return (

    <div className="dialogContainer" style={{overflowY: "scroll"}}>
      <>
        <ToastContainer />
        <ReactDialogBox
          closeBox={onClickCancel}
          modalWidth="80%"
          // modalHeight="60%"
          headerBackgroundColor="#004171"
          headerTextColor="white"
          closeButtonColor="white"
          bodyBackgroundColor="white"
          bodyTextColor="black"
          headerText={`${isSelectedOption} - Order ID :  ${selectedItem.patientOrderId !== undefined
            ? selectedItem.patientOrderId
            : selectedItem.id
            }`}
        >
             <div className="dialogContainer" style={{marginTop:"2px"}}>
          <div >
            <div>
              <form onChange={handleChange} onSubmit={handleSubmit}>
                <div style={{ display: "flex" }}>
                  <label className="text-base font-bold" style={{ fontWeight: "600", fontSize: "15px", marginTop: "8px" }}>Change Status</label>&nbsp;&nbsp;

                  <select style={{ fontSize: "14px", padding: "8px 5px" }} onChange={(e) => onChangeText(e)} >
                 
                    <option selected hidden>
                      {status[selectedItem?.status] ? status[selectedItem?.status] : "Select Status option"}</option>
                

                    <option value="5">OPD Booked</option>
                    <option value="6">OPD Complete</option>
                    <option value="7">Medicine prescribed</option>
                    <option value="8">Investigations</option>
                    <option value="9">Surgery prescribed</option>
                    <option value="10">Ipd booked</option>
                    <option value="11">Pateint admitted</option>
                    <option value="12">In OT</option>
                    <option value="13">Pateint discharge</option>
                    <option value="14">Surgery completd</option>
                    <option value="15">Bills received</option>
                    <option value="16">Others.</option>
                  </select>&nbsp;&nbsp;
                  {/* <div > */}
                  <label style={{ fontWeight: "600", fontSize: "15px", marginTop: "8px" }}>

                    Comments</label>&nbsp;&nbsp;

                  <textarea rows="2" cols="50" onChange={(e) => setRemarks(e.target.value)} value={remarks} ></textarea>

                  {/* {surgeryList?.map((item, i) => {
                    <>
                    {item.boRemarks?  <textarea  rows="2" cols="50"value={item.boRemarks}  ></textarea> :
                     }
                    </>
                 })} */}

                  {/* </div> */}
                  <br />
                  <button type="submit" style={{ backgroundColor: "rgb(0, 65, 113)", color: "white", fontSize: "15px", padding: "7px 22px", cursor: "pointer", marginLeft: "15px" }}>Submit</button>
                </div>
              </form>
            </div>
            <div className="titleOfNew" style={{ marginTop: "35px" }}>Patient Details</div>
            <div className="subContainer">
              <div className="main">
                <div className="titleMain">Name</div>

                <div className="titleOfDialog">
                  {selectedItem?.patientName}
                </div>
              </div>

              <div className="main">
                <div className="titleMain">Mobile</div>

                <div className="titleOfDialog">
                  {selectedItem.patientOrderId !== undefined
                    ? selectedItem?.patientNumber
                    : selectedItem?.mobileNo}
                </div>
              </div>


              <div className="main">
                <div className="titleMain">Patient Id</div>

                <div className="titleOfDialog">
                  {selectedItem?.patientId}
                </div>
              </div>
            </div>
          </div>

          <div className="main">
                  <div className="titleOfNew">Order Event Log</div>
                  <div className="mainTestCode" style={{marginTop:"15px"}}>
                    <div className="titleMain">Activity Status</div>
                    <div className="titleMain">Comments</div>
                    <div className="titleMain">Time</div>
                  </div>
                  {
                    <div className="medicineList">
                      {orderDetails?.eventLogList.map((item) => (
                        <div className="subContainer">
                          {/* <div className="main">
                            <div className="titleOfDialog">
                              {item?.description}
                            </div>
                          </div> */}
                          <div className="main">
                            <div className="titleOfDialog">
                           
                              {item?.status  ===  5 ? "OPD Booked" :
                              item?.status  ===  6 ? "OPD Complete" :
                              item?.status  ===  7 ? "Medicine prescribed" :
                              item?.status  ===  8 ? "Investigations" :
                              item?.status  ===  9 ? "Surgery prescribed" :
                              item?.status  ===  10 ? "Ipd booked" :
                              item?.status  ===  11 ? "Pateint admitted" :
                              item?.status  ===  12 ? "In OT" :
                              item?.status  ===  13 ? "Pateint discharge" :
                              item?.status  ===  14 ? "Surgery completd" :
                              item?.status  ===  15 ? "Bills received" :
                              item?.status  ===  16  ? "Others" :
                              item?.status  ===  1  ? "Pending" :""
}
                            </div>
                          </div>

                         
                          <div className="main">
                            <div className="titleOfDialog">
                              {item?.description}
                            </div>
                          </div>

                          <div className="main">
                            <div className="titleOfDialog">
                              {moment(item?.createdDate).format("DD-MM-YYYY hh:mm A")}
                            </div>
                          </div>
                        </div>
                      ))}
                    </div>
                  }
                </div>

          <div>
            <div className="main">
              <div className="titleOfNew">Back Office Log</div>
              <div className="mainTestCode">
                <div className="titleMain">Action</div>
                <div className="titleMain">CreatedBy</div>
                <div className="titleMain">Remarks</div>

                <div className="titleMain">Time</div>
              </div>
              {
                <div className="medicineList">
                  {orderDetails?.backOfficeEventLog.map((item) => (
                    <div className="subContainer">
                      <div className="main">
                        <div className="titleOfDialog">
                          {item?.action}
                        </div>
                      </div>

                      <div className="main">
                        <div className="titleOfDialog">
                          {item?.createdBy}
                        </div>
                      </div>

                      <div className="main">
                        <div className="titleOfDialog">
                          {item?.remarks}
                        </div>
                      </div>



                      <div className="main">
                        <div className="titleOfDialog">
                          {getLocalTime(item?.defaultDateTime)}
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              }
            </div>
          </div>
          </div>
          {/* </div> */}
        </ReactDialogBox>
      </>
    </div>
  );
}

export default SurgeryAppointmentDetails;
