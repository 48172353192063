import React from "react";

import "./index.css";
import moment from "moment";
import Flag from "../../Assets/Images/Flag.svg";

import { getLocalTime, getLocalOnlyDate } from "../../utils/LocalTimeFormat";

function RowBody({
  medicineList,
  isSelectedOption,
  onClickOrderIdNew,

  onClickAddCommentNew,
  onClickEditLabTestSample,
}) {
  const onClickOrderId = (item) => {
    onClickOrderIdNew(item);
  };

  const onClickAddComment = (item) => {
    onClickAddCommentNew(item);
  };

  const onClickEditLabTest = (item) => {
    onClickEditLabTestSample(item);
  };

  return (
    <div className="boxMain">
      {medicineList.map((item, i) => {
        return (
          <>
            <div className="rowList" key={i}>
              <div className="boxSub" style={{ width: 120 }}>
                <div>
                  <div className="flagView">
                    {item?.redAllert === 1 && (
                      <img
                        src={Flag}
                        alt={"Goto icon"}
                        className="calenderImg"
                      />
                    )}

                    <p
                      className="patientIdNew"
                      onClick={() => onClickOrderId(item)}
                    >
                      {item?.patientOrderId}
                    </p>
                  </div>
                  <p
                    className="patientComment"
                    onClick={() => onClickAddComment(item)}
                  >
                    Add Remark
                  </p>

                  {item.procedureStatus == 1 &&
                    moment().isBefore(
                      item.patientPlannedDate,
                      "YYYY-MM-DD"
                    ) && (
                      <p
                        className="patientComment"
                        onClick={() => onClickEditLabTest(item)}
                      >
                        Edit Date and Time for Lab Test sample
                      </p>
                    )}
                </div>
              </div>

              <div className="boxSub">
                {isSelectedOption === "Doctor Consultation" ? (
                  <div>
                    <p className="patientId">{item?.patientName}</p>
                    <p className="patientId">{item?.patientNumber}</p>

                    <p className="patientId">{item?.patientId}</p>
                  </div>
                ) : isSelectedOption === "Hospital Enquiry" ? (
                  <div>
                    <p className="patientId">{item?.patientName}</p>
                    <p className="patientId">{item?.patientNumber}</p>

                    <p className="patientId">{item?.patientId}</p>
                  </div>
                ) : isSelectedOption === "Medicine Order" ? (
                  <div>
                    <p className="patientId">{item?.patientName}</p>
                    <p className="patientId">{item?.patientNumber}</p>

                    <p className="patientId">{item?.patientId}</p>
                  </div>
                ) : (
                  <div>
                    <p className="patientId">{item?.patientName}</p>
                    <p className="patientId">{item?.patientNumber}</p>
                    <p className="patientId">{item?.patientId}</p>
                  </div>
                )}
              </div>

              <div className="boxSub">
                {isSelectedOption === "Doctor Consultation" ? (
                  <div>
                    <p className="patientId">{item?.doctorName}</p>
                    <p className="patientId">{item?.userId}</p>
                  </div>
                ) : isSelectedOption === "Hospital Enquiry" ? (
                  <div>
                    <p className="patientId">{item?.hospitalName}</p>
                    <p className="patientId">{item?.hospitalId}</p>
                  </div>
                ) : isSelectedOption === "Medicine Order" ? (
                  item?.eventPatientSubOrderDTOList != null ? (
                    <div>
                      <p className="patientId">
                        <ul>
                          {" "}
                          {item?.eventPatientSubOrderDTOList[0]?.hospitalName}
                        </ul>
                      </p>

                      <p className="patientId">
                        {item?.eventPatientSubOrderDTOList[0]?.hospitalId}
                      </p>
                    </div>
                  ) : (
                    <div></div>
                  )
                ) : (
                  <div>
                    <p className="patientId">{item?.hospitalName}</p>
                    <p className="patientId">{item?.hospitalId}</p>
                  </div>
                )}
              </div>

              <div className="boxSub">
                {isSelectedOption === "Doctor Consultation" ? (
                  <div>
                    <p className="patientId">
                      {getLocalOnlyDate(item?.appointmentDate)}
                    </p>
                    <p className="patientId">
                      {item?.fromTime} to {item?.toTime}
                    </p>
                  </div>
                ) : isSelectedOption === "Hospital Enquiry" ? (
                  <div>
                    <p className="patientId">{item?.locationName}</p>
                    <p className="patientId">{item?.locationId}</p>
                  </div>
                ) : isSelectedOption === "Medicine Order" ? (
                  <div>
                    <p className="patientId">{item?.procedureStatusName}</p>
                  </div>
                ) : (
                  <div>
                    <p className="patientId">{item?.locationName}</p>
                    <p className="patientId">{item?.locationId}</p>
                  </div>
                )}
              </div>

              {isSelectedOption !== "Hospital Enquiry" &&
                isSelectedOption !== "Lab Test" && (
                  <div className="boxSub">
                    {isSelectedOption === "Doctor Consultation" ? (
                      <p className="patientId">{item?.consultationsReason}</p>
                    ) : isSelectedOption === "Hospital Enquiry" ? (
                      <p className="patientId">{item?.procedureStatusName}</p>
                    ) : isSelectedOption === "Medicine Order" ? (
                      <p className="patientId">{item?.statusName}</p>
                    ) : (
                      <p className="patientId">{item?.statusName}</p>
                    )}
                  </div>
                )}

              {isSelectedOption !== "Medicine Order" && (
                <div className="boxSub">
                  {isSelectedOption === "Doctor Consultation" ? (
                    <p className="patientId">{item?.statusName}</p>
                  ) : isSelectedOption === "Hospital Enquiry" ? (
                    <p className="patientId">{item?.paymentDateTime}</p>
                  ) : isSelectedOption === "Medicine Order" ? (
                    <p className="patientId">{item?.paymentDateTime}</p>
                  ) : (
                    <p className="patientId">{item?.paymentDateTime}</p>
                  )}
                </div>
              )}

              {isSelectedOption !== "Medicine Order" && (
                <div className="boxSub">
                  {isSelectedOption === "Doctor Consultation" ? (
                    <p className="transactionDate">
                      {item?.consultationsType === "V"
                        ? "Video consultation"
                        : "In-Person consultation"}
                    </p>
                  ) : isSelectedOption === "Hospital Enquiry" ? (
                    <p className="patientId">{item?.statusName}</p>
                  ) : isSelectedOption === "Medicine Order" ? (
                    <p className="patientId">{item?.paymentDateTime}</p>
                  ) : (
                    <p className="patientId">{item?.statusName}</p>
                  )}
                </div>
              )}

              <div className="boxSub">
                <p className="transactionDate">
                  {getLocalTime(item?.paymentDateTime)}
                </p>
              </div>

              <div className="boxSub">
                <p className="transactionDate">
                  {item?.paidAmount}
                </p>
              </div>
            </div>
          </>
        );
      })}
    </div>
  );
}

export default RowBody;
