import React, { useEffect } from "react";
import { useState } from "react";
import DatePicker from "react-datepicker";
import moment from "moment";
import { Dropdown } from "primereact/dropdown";
import { ToastContainer, toast } from "react-toastify";
import http from "../../../src/Redux/services/http-common.jsx"
import { InputSwitch } from 'primereact/inputswitch';

function CareplanSchedulepopup(props) {
    const [actionValue, setActionValue] = useState();
    const [reason, setReason] = useState();
    const [resheduleDate, setResheduleDate] = useState();
    const [resheduleTime, setResheduleTime] = useState();
    const [resheduleTimeOtDoctor, setResheduleTimeOtDoctor] = useState()
    const [options, setOptions] = useState([]);
    const [optionsOtDoctor, setOptionsOtDoctor] = useState([]);
    const [doctorSelect, setdoctorSelect] = useState(true)
    const [consulatationType, setApptType] = useState(props?.scheduledata?.consultationsType)
    const [doctersList, setDoctorsList] = useState()
    const [doctorLocationList, setDoctorsLocationList] = useState();
    const [changedDoctor, setDocterChanged] = useState()
    const [changedDoctorLocation, setDocterLocationChanged] = useState();
    const getDoctorsList = (date) => {
        let url = `${process.env.REACT_APP_BASEURL}PatientAppointment/userListByTypeandFee?consultationType=${consulatationType}&consultationFee=${props?.scheduledata?.totalAmount}`
        http.get(url).then((res) => {
            if (res.data && res.data.length) {
                let filterData = res.data.filter((user, i) => user.userId.toLowerCase() != props?.scheduledata?.userId.toLowerCase())
                let docURL = `${process.env.REACT_APP_COMMON_BASEURL}DoctorListAsPerLeave/list?leaveDate=${moment(date).format("yyyy/MM/DD")}`
                http.get(docURL).then((res) => {
                    if (res.data && res.data.length) {
                        let withoutLeave = filterData.filter(item1 => !res.data.some(item2 => item1.userId.toLowerCase() === item2.userId.toLowerCase()));
                        setDoctorsList(withoutLeave)
                    } else {
                        setDoctorsList(filterData)
                    }
                }).catch((error) => {
                    console.log(error, "went wrong");
                });
            } else {
                return toast("No any other doctor is available for this consultation. please reshedule for same doctor or book new appointment")
            }
        }).catch((error) => {
            console.log(error, "went wrong");
        });
    }
    const handleSave = (e) => {
        e.preventDefault();
        if (!actionValue) {
            return toast("Please slect the options")
        } else {
            if (actionValue === 1) {
                if (!resheduleDate) {
                    toast("Please select reshedule Date")
                } else if (!resheduleTime) {
                    toast("Please select reshedule time")
                } else if (!reason) {
                    toast("Please Enter reason")
                } else {
                    let Timings = resheduleTime.split(" - ")
                    let payload = {
                        "whenAppointment": moment(resheduleDate).format("yyyy-MM-DD"),
                        "fromTime": Timings[0],
                        "toTime": Timings[1],
                        "cancelledreason": reason,
                        "status": 1
                    }
                    updateAppointment(payload)
                }
            } else if (actionValue === 2) {
                let load = {
                    "status": 2
                }
                updateAppointment(load)
            }
        }
    }
    const updateAppointment = (payload) => {
        let url = `${process.env.REACT_APP_BASEURL}backOfficePatientAppointment/updateDoctorAppointment/${props?.scheduledata?.id}`
        http.put(url, payload).then((res) => {
            toast("Appointment updated successfully")
            setTimeout(() => {
                props.refreshData()
                props.closeFun()
            }, 250);
        })
    }

    const onchangeReshDate = (e) => {
        setResheduleDate(e);
        setResheduleTime()
        getDoctorsList(e)
        let load = {
            "userId": props?.scheduledata?.userId,
            "fromDate": moment(e).format("MM/DD/yyyy")
        }
        let url = `${process.env.REACT_APP_BASEURL}medicare/getDoctorAvailability`;
        http.post(url, load).then((res) => {
            if (res.data) {
                const filteredOptions = Object.entries(res.data)
                    .filter(([time, value]) => value === 'Y')
                    .map(([time]) => time);
                const timeRangeOptions = filteredOptions.map((time, index) => {
                    const nextTime = filteredOptions[index + 1];
                    return {
                        label: `${time} - ${nextTime}`,
                        value: `${time} - ${nextTime}`
                    };
                });
                setOptions(timeRangeOptions.slice(0, -1));
            } else {
                toast("No slots available for selected date")
            }
        }).catch((error) => {
            console.log(error, "went wrong");
        });
    }
    const dropdownOptionConsult = [
        { name: "Video", value: "V" },
        { name: "Audio", value: "A" },
        { name: "Inperson", value: "I" },
        { name: "Quick", value: "Q" },
    ];
    const onChangeDoc = (e) => {
        setDocterChanged(e.value)
        let url = `${process.env.REACT_APP_BASEURL}User_Hospital_Location/list?userId=${e.value.userId}&status=1&source=Eclinic`
        http
            .get(url)
            .then((res) => {
                setDoctorsLocationList(res.data)
            })
            .catch((error) => {
                console.log(error, "went wrong");
            });
        setResheduleTime()
        let load = {
            "userId": e.value.userId,
            "fromDate": moment(resheduleDate).format("MM/DD/yyyy")
        }
        let url2 = `${process.env.REACT_APP_BASEURL}medicare/getDoctorAvailability`;
        http.post(url2, load).then((res) => {
            if (res.data) {
                const filteredOptions = Object.entries(res.data)
                    .filter(([time, value]) => value === 'Y')
                    .map(([time]) => time);
                const timeRangeOptions = filteredOptions.map((time, index) => {
                    const nextTime = filteredOptions[index + 1];
                    return {
                        label: `${time} - ${nextTime}`,
                        value: `${time} - ${nextTime}`
                    };
                });
                setOptionsOtDoctor(timeRangeOptions.slice(0, -1));
            } else {
                toast("No slots available for selected date")
            }
        }).catch((error) => {
            console.log(error, "went wrong");
        });

    }
    const onNewreschedule = () => {
        if (!resheduleDate) {
            toast("Please select reschedule Date")
        } else if (!changedDoctor) {
            toast("Please select Doctor")
        } else if (!changedDoctorLocation) {
            toast("Please select location")
        } else if (!resheduleTimeOtDoctor) {
            toast("Please select reschedule time")
        } else {
            let Timings = resheduleTimeOtDoctor.split(" - ")
            let payload = {
                "whenAppointment": moment(resheduleDate).format("yyyy-MM-DD"),
                "fromTime": Timings[0],
                "toTime": Timings[1],
                "cancelledreason": props?.scheduledata.consultationsReason,
                "status": 3,
                "userId": changedDoctor.userId,
                "hospitalId": changedDoctorLocation.hospitalId,
                "locationId": changedDoctorLocation.locationId,
                "consultationsType": props?.scheduledata?.consultationsType
            }
            updateAppointment(payload)
        }
    }
    const ontoggleChange = (e) => {
        setdoctorSelect(e.value)
        setOptions()
        setActionValue()
        setOptionsOtDoctor()
        setResheduleDate()
        setResheduleTime()
        setResheduleTimeOtDoctor()
    }
    const optionLabelTemplate = (option) => {
        return option.hospitalName + ' - ' + option.locationName;
    };
    return (
        <>
            <ToastContainer />
            <div className="">
                <form>
                    <div>
                        <h2 style={{ color: "black", marginTop: "0px" }}>
                            {" "}
                            Appointment Details {" "} ({props?.scheduledata?.id})
                        </h2>
                    </div>
                    <div style={{ display: "flex", marginBottom: "15px", marginTop: "20px", }}>
                        <div style={{}}>
                            <label htmlFor="doctors" className="">Reschedule for Same Doctor</label>
                        </div>
                        <div style={{ marginLeft: 10 }}>
                            <InputSwitch checked={doctorSelect} onChange={(e) => ontoggleChange(e)} />
                        </div>
                    </div>
                    {doctorSelect ?
                        <>
                            <div className="row ">

                                <div style={{ display: "flex", marginBottom: "15px", marginTop: "20px", }}>
                                    <div style={{ width: "40%" }}>
                                        <label>Patient Name:</label>
                                    </div>
                                    <div style={{ width: "60%" }}>
                                        {props?.scheduledata?.patientName} - {props?.scheduledata?.patientMobileNo}
                                    </div>
                                </div>
                                <div style={{ display: "flex", marginBottom: "15px", marginTop: "20px", }}>
                                    <div style={{ width: "40%" }}>
                                        <label>Appoitment Date :</label>
                                    </div>
                                    <div style={{ width: "60%" }}>
                                        {moment(props?.scheduledata?.whenAppointment).format("DD-MM-YYYY")}
                                    </div>
                                </div>
                                <div style={{ display: "flex", marginBottom: "15px", marginTop: "20px", }}>
                                    <div style={{ width: "40%" }}>
                                        <label>Appoitment Time :</label>
                                    </div>
                                    <div style={{ width: "60%" }}>
                                        {props?.scheduledata?.fromTime} - {props?.scheduledata?.toTime}
                                    </div>
                                </div>
                                <div style={{ display: "flex", marginBottom: "15px", marginTop: "20px", }}>
                                    <div style={{ width: "40%" }}>
                                        <label>User Name :</label>
                                    </div>
                                    <div style={{ width: "60%" }}>
                                        {props?.scheduledata?.userName}
                                    </div>
                                </div>
                                <div style={{ display: "flex", marginBottom: "15px", marginTop: "20px", }}>
                                    <div style={{ width: "40%" }}>
                                        <label>Consultation type :</label>
                                    </div>
                                    <div style={{ width: "60%" }}>
                                        {props?.scheduledata?.consultationsType === "I" ? "Inperson" :
                                            props?.scheduledata?.consultationsType === "A" ? "Audio" :
                                                props?.scheduledata?.consultationsType === "V" ? "Video" : "Quick"}
                                    </div>
                                </div>
                                <div style={{ display: "flex", marginBottom: "15px", marginTop: "20px", }}>
                                    <div style={{ width: "40%" }}>
                                        <label>Status :</label>
                                    </div>
                                    <div style={{ width: "60%" }}>
                                        {"Appointment on Hold"}
                                    </div>
                                </div>
                            </div>
                            <div>
                                <h2 style={{ color: "black", marginTop: "0px" }}>
                                    {" "}
                                    Action
                                </h2>
                            </div>
                            <div className="row ">
                                <div style={{ display: "flex" }}>
                                    <div style={{ width: "40%", marginTop: 4 }}>
                                        <label>Select Action:</label>
                                    </div>
                                    <div style={{ width: "60%" }}>
                                        <Dropdown
                                            onChange={(e) => setActionValue(e.target.value)}
                                            options={[
                                                { label: "Reschedule", value: 1 },
                                                { label: "Cancel", value: 2 }
                                            ]}
                                            value={actionValue}
                                            placeholder="Select option"
                                            className="searchbarname"
                                        />
                                    </div>
                                </div>
                                {actionValue && actionValue === 1 &&
                                    <>
                                        <div style={{ display: "flex", marginTop: 14 }}>
                                            <div style={{ width: "40%", marginTop: 4 }}>
                                                <label>Reschedule date:</label>
                                            </div>
                                            <div style={{ width: "60%" }}>
                                                <div
                                                    className="searchbar"
                                                    style={{ marginBottom: "20px" }}
                                                    title="Search by date"
                                                >
                                                    <DatePicker
                                                        showIcon
                                                        className="datepicker1"
                                                        minDate={new Date()}
                                                        selected={resheduleDate}
                                                        onChange={(date) => onchangeReshDate(date)}
                                                        customInput={
                                                            <div style={{ position: "relative", display: "flex", flexDirection: "column" }} >
                                                                <div>
                                                                    <input
                                                                        type="text"
                                                                        className="form-control"
                                                                        value={resheduleDate ? moment(resheduleDate).format("MM/DD/yyyy") : "Select Date"}
                                                                        readOnly />
                                                                </div>
                                                                <img
                                                                    src="images/calander.svg"
                                                                    alt="Calendar"
                                                                    style={{ position: "absolute", right: "10px", top: "1px", cursor: "pointer", }} />
                                                            </div>
                                                        }
                                                    ></DatePicker>
                                                </div>
                                            </div>
                                        </div>
                                        {options && options.length ?
                                            <div style={{ display: "flex", }}>
                                                <div style={{ width: "40%", marginTop: 4 }}>
                                                    <label>Reschedule time:</label>
                                                </div>
                                                <div style={{ width: "60%" }}>
                                                    <Dropdown
                                                        onChange={(e) => setResheduleTime(e.target.value)}
                                                        options={options}
                                                        value={resheduleTime}
                                                        placeholder="Select option"
                                                        className="searchbarname"
                                                    />
                                                </div>
                                            </div>
                                            : null
                                        }
                                    </>
                                }
                                <div style={{ display: "flex", marginTop: 10 }}>
                                    <div style={{ width: "40%", marginTop: 4 }}>
                                        <label>Reason:</label>
                                    </div>
                                    <div style={{ width: "60%" }}>
                                        <div
                                            className="searchbar"
                                            style={{ marginBottom: "20px" }}
                                            title="Search by Name"
                                        >
                                            <input
                                                type="text"
                                                className="placeholder"
                                                value={reason}
                                                onChange={(e) => setReason(e.target.value)}
                                                placeholder={"Enter reason"}
                                                name="reason"
                                            ></input>
                                        </div>
                                    </div>
                                </div>

                                <div
                                    style={{
                                        display: "flex",
                                        marginBottom: "15px",
                                        marginTop: "20px",
                                        float: "right",
                                    }}
                                >
                                    <button
                                        type="submit"
                                        className="addbutton"
                                        onClick={(e) => handleSave(e)}
                                    >
                                        Update
                                    </button>
                                </div>
                            </div>
                        </>
                        :
                        <div className="row ">
                            <div
                                style={{
                                    display: "flex",
                                    marginBottom: "15px",
                                    marginTop: "20px",
                                }}
                            >
                                <div style={{ width: "40%" }}>
                                    <label>Effective Start Date :</label>
                                </div>
                                <div style={{ width: "60%" }}>
                                    {props?.scheduledata
                                        ? moment(props?.scheduledata?.whenAppointment).format("DD-MM-YYYY")
                                        : ""}
                                </div>
                            </div>


                            <div
                                style={{
                                    display: "flex",
                                    marginBottom: "5px",
                                    marginTop: "20px",
                                }}
                            >
                                <div style={{ width: "40%", marginTop: 6 }}>
                                    <label>Reschedule date : </label>
                                </div>
                                <div style={{ width: "60%" }}>
                                    <div
                                        className="searchbar"
                                        style={{ marginBottom: "20px" }}
                                        title="Search by date"
                                    >
                                        <DatePicker
                                            showIcon
                                            className="datepicker1"
                                            minDate={new Date()}
                                            selected={resheduleDate}
                                            onChange={(date) => onchangeReshDate(date)}
                                            customInput={
                                                <div style={{ position: "relative", display: "flex", flexDirection: "column" }} >
                                                    <div>
                                                        <input
                                                            type="text"
                                                            className="form-control"
                                                            value={resheduleDate ? moment(resheduleDate).format("MM/DD/yyyy") : "Select Date"}
                                                            readOnly />
                                                    </div>
                                                    <img
                                                        src="images/calander.svg"
                                                        alt="Calendar"
                                                        style={{ position: "absolute", right: "10px", cursor: "pointer", }} />
                                                </div>
                                            }
                                        ></DatePicker>
                                    </div>
                                </div>
                            </div>

                            <div
                                style={{
                                    display: "flex",
                                    marginBottom: "15px",
                                    marginTop: "5px",
                                }}
                            >
                                <div style={{ width: "40%", marginTop: 10 }}>
                                    <label>Service Provider Name :</label>
                                </div>
                                <div style={{ width: "60%" }}>
                                    <Dropdown
                                        id="userId"
                                        name="userId"
                                        value={changedDoctor ? changedDoctor : ""}
                                        options={doctersList ? doctersList : null}
                                        onChange={(e) => onChangeDoc(e)}
                                        placeholder="Select Doctor"
                                        className="dropdowntd"
                                        optionLabel="doctorName"
                                        filter
                                        filterBy="doctorName"
                                        filterMatchMode="contains"
                                        filterPlaceholder="Type to filter"
                                        filterInputAutoFocus
                                    />
                                </div>
                            </div>
                            <div
                                style={{
                                    display: "flex",
                                    marginBottom: "15px",
                                    marginTop: "5px",
                                }}
                            >
                                <div style={{ width: "40%", marginTop: 10 }}>
                                    <label>Service Provider Location :</label>
                                </div>
                                <div style={{ width: "60%" }}>
                                    <Dropdown
                                        id="userId"
                                        name="userId"
                                        value={changedDoctorLocation ? changedDoctorLocation : ""}
                                        options={doctorLocationList ? doctorLocationList : null}
                                        onChange={(e) => setDocterLocationChanged(e.value)}
                                        placeholder="Select Location"
                                        className="dropdowntd"
                                        optionLabel={optionLabelTemplate}
                                        filter
                                        filterBy="hospitalName"
                                        filterMatchMode="contains"
                                        filterPlaceholder="Type to filter"
                                        filterInputAutoFocus
                                    />
                                </div>
                            </div>
                            <div
                                style={{
                                    display: "flex",
                                    marginBottom: "15px",
                                    marginTop: "20px",
                                }}
                            >
                                <div style={{ width: "40%" }}>
                                    <label>Consultation Type :</label>
                                </div>
                                <div style={{ width: "60%" }}>
                                    <Dropdown
                                        onChange={(e) => setApptType(e.value)}
                                        options={dropdownOptionConsult}
                                        optionLabel="name"
                                        disabled={true}
                                        name="consultationsType"
                                        value={consulatationType ? consulatationType : ""}
                                        placeholder="Select Consultation"
                                        className="dropdowntd"
                                    />
                                </div>
                            </div>
                            <div
                                style={{
                                    display: "flex",
                                    marginBottom: "15px",
                                    marginTop: "20px",
                                }}
                            >
                                <div style={{ width: "40%", marginTop: 10 }}>
                                    <label>Schedule Time :</label>
                                </div>
                                <div style={{ width: "60%" }}>
                                    <Dropdown
                                        onChange={(e) => setResheduleTimeOtDoctor(e.target.value)}
                                        options={optionsOtDoctor}
                                        value={resheduleTimeOtDoctor}
                                        placeholder="Select option"
                                        className="searchbarname"
                                    />
                                </div>
                            </div>
                            <div
                                style={{
                                    display: "flex",
                                    marginBottom: "15px",
                                    marginTop: "20px",
                                    float: "right",
                                }}
                            >
                                <button
                                    type="button"
                                    className="cancelbutton"
                                    onClick={() => onNewreschedule()}
                                >
                                    Book To Confirm
                                </button>

                            </div>
                        </div>
                    }
                </form>
            </div>
        </>
    );
}
export default CareplanSchedulepopup;
