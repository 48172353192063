import React from 'react'

 import "./index.css";

function NoDataFound() {
  return (
    <div className='container-main '>

    No Data found
      
    </div>
  )
}

export default NoDataFound
