import axios from "axios";
import http from "./http-common";
import httpcommon from "./httpcommon";

class DashboardService {
  getBusinessEventList(data) {
    var queryString = Object.keys(data).map(key => key + '=' + data[key]).join('&');
    return http.get(
      `/EventLog/list/filter?${queryString}`
    );
  }
  fetchMapMyIndiaToken() {
    return httpcommon.post('mapMyIndia/token', { code: "web" })
  }
  getEclinicsByPincode(payload) {
    return http.post('medicare/eClinics', payload);
  };

  getSurgeryAppointmentsList(data) {
    var queryString = Object.keys(data).map(key => key + '=' + data[key]).join('&');
    return http.get(
      `surgery/allSurgBookedAppointments?${queryString}`
    );
  }

  getFranchiseEnquiryList() {
    // var queryString = Object.keys(data).map(key => key + '=' + data[key]).join('&');
    // return http.get(
    //   `/EventLog/list/filter?${queryString}`
    // );
    return http.get(`Fanchise/franchiseDetails`)
  }

  // getCarePlandetails(data) {
  //   var queryString = Object.keys(data).map(key => key + '=' + data[key]).join('&');
  //   return http.get(
  //     `Medicare/subscription?${queryString}`
  //   );
  // }


  updatePreferTime(data) {
    var queryString = Object.keys(data).map(key => key + '=' + data[key]).join('&');
    return http.put(
      `PatientLabTestsOrder/updatePreferTime?${queryString}`
    );
  }



  getInputEventList(data) {
    var queryString = Object.keys(data).map(key => key + '=' + data[key]).join('&');
    return http.get(
      `allservices/orders?${queryString}`
    );
  }

  getOrderDetails(data) {
    var queryString = Object.keys(data).map(key => key + '=' + data[key]).join('&');
    return http.get(
      `EventLog/list/filter/type?${queryString}`
    );
  }

  getsurgeryOrderDetails(data) {
    var queryString = Object.keys(data).map(key => key + '=' + data[key]).join('&');
    return http.get(
      `backOfficeEventLog/list/filter/type?${queryString}`
    );
  }
  getInventoryDoctor(data) {
    var queryString = Object.keys(data).map(key => key + '=' + data[key]).join('&');
    return http.get(
      `EventLog/list/filter?${queryString}`
    );
  }


  getInventoryDoctor(data) {
    var queryString = Object.keys(data).map(key => key + '=' + data[key]).join('&');
    return http.get(
      `EventLog/list/filter?${queryString}`
    );
  }


  onUpdateAppointment(data) {
    var queryString = Object.keys(data).map(key => key + '=' + data[key]).join('&');
    return http.put(
      `EventLog/appointment/update?${queryString}`
    );
  }


  onRefundProcess(data) {
    var queryString = Object.keys(data).map(key => key + '=' + data[key]).join('&');
    return http.get(
      `RefundProcess/connectPG?${queryString}`
    );
  }

  onLogin(data) {

    return http.post(
      // `bo/UserLogInWithEmail`, data
      `NewLogin/withOutAd/`, data
    );
  }


  onAddComment(data) {
    var queryString = Object.keys(data).map(key => key + '=' + data[key]).join('&');
    return http.get(
      `backOfficeEvent/log?${queryString}`
    );
  }

  onAddstatus(data) {
    var queryString = Object.keys(data).map(key => key + '=' + data[key]).join('&');
    return http.get(
      `surgery/updateSurgeryStatus?${queryString}`
    );
  }

  onAddpackagestatus(data) {
    var queryString = Object.keys(data).map(key => key + '=' + data[key]).join('&');
    return http.get(
      `Packages/patient/id?${queryString}`
    );
  }





  callToken = async (data) => {
    //   var data = new FormData();
    //   // @ts-ignore
    //   data.append('username', process.env.REACT_APP_USERNAME);
    //   // @ts-ignore
    //   data.append('password', process.env.REACT_APP_PASSWORD);
    //   // @ts-ignore
    //   data.append('grant_type', process.env.REACT_APP_GRANT_TYPE);
    //   // @ts-ignore
    //  return await axios.post(process.env.REACT_APP_BASEURL + 'gcp/oauth/token', data, {
    //     // @ts-ignore
    //     auth: { username: process.env.REACT_APP_AUTH_USERNAME, password: process.env.REACT_APP_AUTH_PASSWORD }
    //   })
    try {
      const configurationObject = {
        method: 'POST',
        url: process.env.REACT_APP_BASEURL + 'gcp/backofficenew/oauth/token',
        data: data,
      };
      const response = await axios(configurationObject);
      return response;
    } catch (error) {
      return error.response;
    }
  }

}

export default new DashboardService();