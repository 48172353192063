import React from "react";

import DropDownAction from "../../components/DropDownAction";

import { getLocalTime, getLocalOnlyDate } from '../../utils/LocalTimeFormat'

function RowBody({ medicineList, isSelectedOption, handleDropDownSelectNew, isApproveOrRejectPlaceHolder, onClickOrderIdNew, onClickAddCommentNew,status }) {

  const onClickOrderId = (item) => {
    onClickOrderIdNew(item);
  };

  const handleDropDownClick = () => {

  };

  const onClickAddComment = (item) => {
    onClickAddCommentNew(item);
  };

  const handleDropDownSelect = (selected, item, index) => {
    handleDropDownSelectNew(selected, item, index)

  };

  return (
    <div className="boxMain">
      {medicineList.map((item, i) => (
        <div className="rowList" key={i}>
          <div className="boxSub">
            {isSelectedOption !== "Medicine Order" ? (
              <div>

                <p className="patientIdNew" onClick={() => onClickOrderId(item)}>{item.orderId}</p>

                <p className="patientComment" onClick={() => onClickAddComment(item)}>Add Remark</p>
              </div>

            ) : (
              <div>
                <p className="serviceName">{item.patientName}</p>
                <p className="patientId">{item.patientId}</p>
              </div>
            )}
          </div>

          <div className="boxSub">

            <div>
              <p className="patientId">{item?.patientName}</p>
              <p className="patientId">{item?.mobileNo}</p>
              <p className="patientId">{item?.patientEmailId}</p>
              <p className="patientId">{item?.patientId}</p>
            </div>



          </div>

          <div className="boxSub">
            {isSelectedOption === "Doctor Consultation" ? (
              <div>
                <p className="patientId">{item?.doctor}</p>
                <p className="patientId">{item?.userId}</p>
              </div>
            ) : isSelectedOption === "Hospital Enquiry" ? (
              <p className="patientId">{item.orderInfo}</p>
            ) : isSelectedOption === "Medicine Order" ? (
              <div>
                <p className="serviceName">{item.orderNumber}</p>
                <p className="patientId">{item.orderInfo}</p>
              </div>
            ) : (
              <div>
                <p className="patientId">{item.labName}</p>
                <p className="patientId">{item.labId}</p>
              </div>

            )}
          </div>

          <div className="boxSub">
            {isSelectedOption === "Doctor Consultation" ? (
              <div>

                <p className="transactionDate">{getLocalOnlyDate(item.whenAppointment)}</p>
                <p className="transactionDate">{item.fromTime} to {item.toTime}</p>
              </div>


            ) : (
              <div>
                <p className="transactionDate">{item.locationName}</p>
                <p className="transactionDate">{item.locationId}</p>

              </div>

            )}
          </div>


          <div className="boxSub">
            {isSelectedOption === "Doctor Consultation" ? (
              <p className="transactionDate">{item.consultationReason}</p>

            ) : (
              <p className="transactionDate">{getLocalTime(item?.plannedDate)}</p>
            )}
          </div>


        

          <div className="boxSub">
            {isSelectedOption === "Doctor Consultation" ? (
              <p className="transactionDate">{item.status}</p>

            ) : (
              <p className="transactionDate">{item.status}</p>
            )}
          </div>

          {
            status ==="Pending" &&
            <div className="boxSub">

            <div className="divRefund">
              <DropDownAction
                onArrowClick={handleDropDownClick}
                onOptionClick={(e) => handleDropDownSelect(e, item, i)}
                options={["Approve", "Reject"]}
                item={item}
                placeholder={isApproveOrRejectPlaceHolder}
              />

            </div>

          </div>
          }

          
        </div>
      ))}
    </div>
  );
}

export default RowBody;
