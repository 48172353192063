import "./index.css";
import { useDispatch } from "react-redux";
import React, { useState, useEffect } from "react";
import DateView from "../../components/DateView/dateView";
import SearchView from "../../components/SearchView/searchView";
import calender_img from "../../Assets/Images/calender_img.svg";
import MedicineOrderList from "./MedicineOrderList";
import DialogAddComment from "../../components/DialogAddComment/dialogAddComment";
import DialogLabTestReschedule from "../../components/DialogLabTestReschedule/dialogRefundMoney";
import DoctorAppointmentDetails from "../../components/DoctorAppointmentDetails/DoctorAppointmentDetails";
import moment from "moment";
import DropDownCancelReson from "../../components/DropDownCancelResonBusiness";
import { ToastContainer, toast } from "react-toastify";
import {
  getBusinessEvent,
  getOrderDetails,
  onAddComment,
  updatePreferTime
} from "../../Redux/Actions/dashboardaction";

import InfiniteScroll from "react-infinite-scroll-component";

import { onConvertJsonToExcel } from "../../utils/JsonToExcelConverter";

const BusinessEvent = () => {
  const dispatch = useDispatch();
  const [isSelectedOption] = useState("Lab Test");
  const [preferDateTime,setPreferDateTime] = useState("");
  const [preferTime,setPreferTime] = useState("");
  const [orderId,setOrderId] = useState("");

  const [fromDate, setFromDate] = useState(
    moment().add(-1, "days").format("DD-MM-yyyy")
  );
  const [toDate, setToDate] = useState(
    moment().add("days").format("DD-MM-yyyy")
  );

  const [preferredLabTime, setPreferredLabTime] = useState([
    {
      label: "07 AM - 08 AM",
      value: "07 AM - 08 AM",
    },
    {
      label: "08 AM - 09 AM",
      value: "07 AM - 08 AM",
    },
    {
      label: "09 AM - 10 AM",
      value: "07 AM - 08 AM",
    },
    {
      label: "10 AM - 11 AM",
      value: "07 AM - 08 AM",
    },
    {
      label: "11 AM - 12 PM",
      value: "07 AM - 08 AM",
    },
    {
      label: "12 PM - 01 PM",
      value: "07 AM - 08 AM",
    },
    {
      label: "01 PM - 02 PM",
      value: "07 AM - 08 AM",
    },
    {
      label: "02 PM - 03 PM",
      value: "07 AM - 08 AM",
    },
    {
      label: "03 PM - 04 PM",
      value: "07 AM - 08 AM",
    },
    {
      label: "04 PM - 05 PM",
      value: "07 AM - 08 AM",
    },
    {
      label: "05 PM - 06 PM",
      value: "07 AM - 08 AM",
    },
    {
      label: "07 PM - 08 PM",
      value: "07 AM - 08 AM",
    },
  ]);
  const [isDialog, setDialogView] = useState(false);
  const [isDialogLabTest, setDialogLabTest] = useState(false);
  const [hasMore, setHasMore] = useState(true);
  const [pageStart, setPageStart] = useState(1);
  const [isDialogAppointment, setDialogAppointment] = useState(false);
  const [isSelectedService, setSelectedService] = useState("2");
  const [businessEventData, setBusinessEventData] = useState([]);
  const [businessEventDataExcel, setBusinessEventDataExcel] = useState([]);
  const [orderDetails, setOrderDetails] = useState();

  const [commentText, setCommentText] = useState("");
  const [filterKey, setFilterKey] = useState("");
  const [isLoader, setLoader] = useState(false);

  const [selectedItem, setSelectedItem] = useState("");
  const [isStatus, setStatus] = useState(1);
  const [isPalaceHolder, setPalaceHolder] = useState("Ordered");

  const [statusOfBusiness] = useState([

   
    {
      status: 1,
      statusText: "Ordered",
    }
  ]);

  useEffect(() => {
    setLoader(true);
    let payload = {
      fromDate: moment(fromDate, "DD-MM-YYYY").format("YYYY-MM-DD"),
      toDate: moment(toDate, "DD-MM-YYYY").format("YYYY-MM-DD"),
      pageNo: "1",
      pageSize: "20",
      type: "2",
      status: 1,
    };

    dispatch(getBusinessEvent(payload)).then((res) => {

      let filterData=res.filter((item)=>item.procedureStatus==1 &&  moment().isBefore(
        res.patientPlannedDate,
        "YYYY-MM-DD"
      ))
      setBusinessEventData([...filterData]);
      setLoader(false);
      //getEventApiExcel("1", toDate);
    });
  }, [dispatch]);

  const getEventApiExcel = (type, toDate) => {
    let payload = {
      fromDate: moment(fromDate, "DD-MM-YYYY").format("YYYY-MM-DD"),
      toDate: moment(toDate, "DD-MM-YYYY").format("YYYY-MM-DD"),
      type: type,
    };

    dispatch(getBusinessEvent(payload)).then((res) => {
      setBusinessEventDataExcel([...res]);
    });
  };



  const getEventApiDateFilter = (pageNo, e) => {
    setBusinessEventData([]);
    setLoader(true);
    let payload = {
      fromDate: moment(fromDate, "DD-MM-YYYY").format("YYYY-MM-DD"),
      toDate: moment(e, "DD-MM-YYYY").format("YYYY-MM-DD"),
      type: pageNo,
      pageNo: "1",
      pageSize: "20",
      status: isStatus,
    };

    dispatch(getBusinessEvent(payload)).then((res) => {

      let filterData=res.filter((item)=>item.procedureStatus==1 &&  moment().isBefore(
        item.patientPlannedDate,
        "yyyy-MM-dd HH:mm:ss"
      ))
      setBusinessEventData([...filterData]);

     
    //  getEventApiExcel(pageNo, e);

      setLoader(false);
    });
  };

  const onClickYesBtn = () => {
    setDialogView(false);

  };

  const onClickCancelBtn = () => {
    setDialogAppointment(false);
  };


  const onClickYesBtnLabTest = () => {
    let payload={
      preferDateTime:`${moment?.utc(preferDateTime)?.local().format("MM/DD/yyyy")} ${convertTime(preferTime.substring(0,5))}:00:00` ,
      preferTime:preferTime,
      orderId:orderId
    }

    dispatch(updatePreferTime(payload)).then((res) => {
      setDialogLabTest(false);
      getEventApiDateFilter(isSelectedService, toDate);
    
      toast(res?.message)
    }).catch(error => {
      console.log('Error during service worker registration:', JSON.stringify(error));
      if (error?.message) {
        toast(error?.message)
      }
     
    });
  };



  const convertTime = timeStr => {
    const [time, modifier] = timeStr.split(' ');
    let [hours] = time.split(':');
    if (hours === '12') {
       hours = '00';
    }
    if (modifier === 'PM') {
       hours = parseInt(hours, 10) + 12;
    }
    return `${hours}`;
 };

  const onClickCancelBtnLabTest = () => {
    setDialogLabTest(false);
  };

  const [doctorConsultation] = useState([
    "OrderID",
    "Patient Details",
    "Lab Details",
    "Location Details",
    "Planned Date",
    "Status",
    "Order Time",
    "Paid Amount",
  ]);

 

  const onClickDate = (e) => {
    setFromDate(e);
    setToDate();
  };

 
  const onClickToDate = (e) => {
    setToDate(e);
    getEventApiDateFilter(isSelectedService, e);
  };

  const handleDropDownClick = () => {};



  const onTextChange = (filterKey) => {
    setFilterKey(filterKey);
  };

  const onInFiniteScrollFinal = () => {};

  const onClickOrderIdMain = (item) => {
    setOrderDetails();
    setDialogAppointment(true);
    setSelectedItem(item);
    let payload = {
      patientOrderId: item?.patientOrderId,
      type: isSelectedService,
    };
    dispatch(getOrderDetails(payload)).then((res) => {
      setOrderDetails(res);
    });
  };

  const onClickAddCommentMain = (item) => {
    setDialogView(true);

    setSelectedItem(item);
  };

  const onClickEditLabTestSampleMain=(item)=>{
    setPreferDateTime(new Date(item?.patientPlannedDate))
    setPreferTime(item?.preferTime)
    setOrderId(item?.patientOrderId)
   setDialogLabTest(true)
  }

  const onChangeTextRemarks = (text) => {
    setCommentText(text);
  };

  const onClickYesBtnDialog = () => {
    let userData = JSON.parse(localStorage.getItem("userData"));
    let payload = {
      orderId: selectedItem?.patientOrderId,
      serviceType:
        isSelectedOption === "Doctor Consultation"
          ? "Doctor"
          : isSelectedOption === "Hospital Enquiry"
          ? "Hospital"
          : isSelectedOption === "Medicine Order"
          ? "Medicine"
          : "LabTest",
      action: "comment",
      remarks: commentText,
      userId: userData?.firstName + " " + userData?.lastName,
    };

    dispatch(onAddComment(payload)).then((res) => {
      setDialogView(false);
    });
  };

  const onClickCancelBtnDialog = () => {
    setDialogView(false);
  };

  const loadFunc = async () => {
    let payload = {
      fromDate: moment(fromDate, "DD-MM-YYYY").format("YYYY-MM-DD"),
      toDate: moment(toDate, "DD-MM-YYYY").format("YYYY-MM-DD"),
      pageNo: pageStart + 1,
      pageSize: "20",
      type: isSelectedService,
    };

    dispatch(getBusinessEvent(payload)).then((res) => {

      let filter=res.filter((item)=>item.procedureStatus==1)
    
      if (filter.length === 0) {
        setHasMore(false);
      } else {
        setPageStart(pageStart + 1);
        if (businessEventData?.length) {



          setBusinessEventData([...businessEventData, ...filter]);
        } else {
          setBusinessEventData([...filter]);
        }

        setHasMore(true);
      }
    });
  };



  const onClickSearch = () => {
    if (filterKey?.length == 0) {
      return;
    }
    setFilterKey("");
    setHasMore(false);
    let payload;
    if (filterKey?.length < 10) {
      payload = {
        patientOrderId: filterKey,
        type: isSelectedService,
        // fromDate: moment(fromDate, "DD-MM-YYYY").format("YYYY-MM-DD"),
        // toDate: moment(toDate, "DD-MM-YYYY").format("YYYY-MM-DD"),
      };
    } else {
      payload = {
        patientMobileNo: filterKey,
        type: isSelectedService,
        //fromDate: moment(fromDate, "DD-MM-YYYY").format("YYYY-MM-DD"),
        //toDate: moment(toDate, "DD-MM-YYYY").format("YYYY-MM-DD"),
      };
    }
    dispatch(getBusinessEvent(payload)).then((res) => {

      let filterData=res.filter((item)=>item.procedureStatus==1 &&  moment().isBefore(
        res.patientPlannedDate,
        "YYYY-MM-DD"
      ))
      setBusinessEventData([...filterData]);
    });
  };

  const onKeyDownEnter = (e) => {
    if (e?.key === "Enter") {
      setFilterKey("");

      setHasMore(false);

      let payload;
      if (filterKey?.length < 10) {
        payload = {
          patientOrderId: filterKey,
          type: isSelectedService,
          //fromDate: moment(fromDate, "DD-MM-YYYY").format("YYYY-MM-DD"),
          //toDate: moment(toDate, "DD-MM-YYYY").format("YYYY-MM-DD"),
        };
      } else {
        payload = {
          patientMobileNo: filterKey,
          type: isSelectedService,
          //fromDate: moment(fromDate, "DD-MM-YYYY").format("YYYY-MM-DD"),
          //toDate: moment(toDate, "DD-MM-YYYY").format("YYYY-MM-DD"),
        };
      }
      dispatch(getBusinessEvent(payload)).then((res) => {
        let filterData=res.filter((item)=>item.procedureStatus==1 &&  moment().isBefore(
          res.patientPlannedDate,
          "YYYY-MM-DD"
        ))
        setBusinessEventData([...filterData]);
      });
    }
  };

  const handleDropDownSelectStatus = (item) => {
    setStatus(item?.status);
    setPalaceHolder(item?.statusText);
    setLoader(true);
    let payload = {
      status: item?.status,
      fromDate: moment(fromDate, "DD-MM-YYYY").format("YYYY-MM-DD"),
      toDate: moment(toDate, "DD-MM-YYYY").format("YYYY-MM-DD"),
      type: isSelectedService,
      pageNo: "1",
      pageSize: "20",
    };
    dispatch(getBusinessEvent(payload)).then((res) => {
      let filterData=res.filter((item)=>item.procedureStatus==1 &&  moment().isBefore(
        res.patientPlannedDate,
        "YYYY-MM-DD"
      ))
      setBusinessEventData([...filterData]);
      setLoader(false);
    });
  };

  const onSelectChange=(date)=>{

    setPreferDateTime(date)
  }

  const onPreferTime=(time)=>{
    setPreferTime(time)

  }

  async function saveAsExcel() {

    if(!businessEventDataExcel.length){
      return
    }

    const newArrOfObjects = businessEventDataExcel.map((obj) =>
      Object.keys(obj)
        .filter((e) => obj[e] !== null)
        .reduce((o, e) => {
          o[e] = obj[e];
          return o;
        }, {})
    );

    setTimeout(() => {
      onConvertJsonToExcel(newArrOfObjects, "BusinessEvent" + isSelectedOption);
    }, 4000);
  }

  return (
    <>

    <ToastContainer/>
      <div className="profilepage-breadcrumb"></div>

      <div className="titleBusinessEvent"> Select Filters & Services:</div>

      <div className="dateContainer">
        <div className="dateContainerSub">
          <DateView
            title="From Date"
            value={fromDate}
            placeholder="From Date"
            onClickDate={onClickDate}
          />

          <SearchView
            title="Search by Order Id/Mobile Number"
            onTextChange={onTextChange}
            onClickSearch={onClickSearch}
            onKeyDownEnter={onKeyDownEnter}
            filterKey={filterKey}
          />
        </div>

        <div className="dateContainerSub">
          <DateView
            title="To Date"
            value={toDate}
            placeholder="To Date"
            onClickDate={onClickToDate}
            fromDate={fromDate}
          />
           
          <DropDownCancelReson
         
          onArrowClick={handleDropDownClick}
          onOptionClick={handleDropDownSelectStatus}
          options={statusOfBusiness}
          placeholder="Select Service"
          isPalaceHolder={isPalaceHolder}
        />

        </div>
      </div>

     

      <p className="hospitalEnquiry">Service: {isSelectedOption}</p>

      <div className="hospitalEnquiryBody">
        {fromDate && toDate ? (
          <div className="selectedDateContainer">
            <img src={calender_img} alt={"Goto icon"} className="calenderImg" />
            <p className="selectDate">
              Selected date results from: {fromDate} to {toDate}
            </p>
          </div>
        ) : (
          <div></div>
        )}

        <div className="hospitalEnquiryBody">
          <p className="response">
            {" "}
            Total there are {businessEventData?.length} responses
          </p>

          {/* {businessEventDataExcel?.length > 0 && (
            <p className="downLoadFile" onClick={() => saveAsExcel()}>
              Download File
            </p>
          )} */}
        </div>
      </div>

      <div
        id="scrollableDiv"
        className="listNewContainer"
        style={{
          height: 800,
          overflow: "auto",
        }}
      >
        <InfiniteScroll
          dataLength={businessEventData.length}
          next={loadFunc}
          hasMore={hasMore}
          pullDownToRefreshThreshold={50}
          scrollableTarget="scrollableDiv"
        ></InfiniteScroll>

        <MedicineOrderList
          medicineList={businessEventData}
          doctorConsultation={doctorConsultation}
          isSelectedOption={isSelectedOption}
          onClickOrderIdMain={onClickOrderIdMain}
          onClickAddCommentMain={onClickAddCommentMain}
          onInFiniteScrollFinal={onInFiniteScrollFinal}
          onClickEditLabTestSampleChild={onClickEditLabTestSampleMain}
          isLoader={isLoader}
          hasMore={hasMore}
        />
      </div>

      {isDialogAppointment && (
        <DoctorAppointmentDetails
          onClickYesBtn={onClickYesBtn}
          onClickCancelBtn={onClickCancelBtn}
          isSelectedOption={isSelectedOption}
          isDialog={isDialogAppointment}
          orderDetails={orderDetails}
          selectedItem={selectedItem}
          formActivity="business"
        />
      )}

      {isDialog && (
        <DialogAddComment
          onClickYesBtn={onClickYesBtnDialog}
          onClickCancelBtn={onClickCancelBtnDialog}
          isDialog={isDialog}
          isApproveOrReject={true}
          onTextChange={onChangeTextRemarks}
          remarks={commentText}
        />
      )}


      {isDialogLabTest && (
        <DialogLabTestReschedule
          onClickYesBtn={onClickYesBtnLabTest}
          onClickCancelBtn={onClickCancelBtnLabTest}
          isDialog={isDialogLabTest}
          preferredLabTime={preferredLabTime}
          onSelectChange={onSelectChange}
          preferDateTime={preferDateTime}
          onPreferTime={onPreferTime}
          preferTime={preferTime}
         
        />
      )}
    </>
  );
};

export default BusinessEvent;
