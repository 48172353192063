export const DASH = {
    

    REQUEST_BUSINESS_EVENT: "REQUEST_BUSINESS_EVENT",
    SUCCESS_BUSINESS_EVENT: "SUCCESS_BUSINESS_EVENT",
    FAILED_BUSINESS_EVENT: "FAILED_BUSINESS_EVENT",


    REQUEST_REFUND_PROCESS: "REQUEST_REFUND_PROCESS",
    SUCCESS_REFUND_PROCESS: "SUCCESS_REFUND_PROCESS",
    FAILED_REFUND_PROCESS: "FAILED_REFUND_PROCESS",

    REQUEST_ORDER_DETAILS: "REQUEST_ORDER_DETAILS",
    SUCCESS__ORDER_DETAILS: "SUCCESS__ORDER_DETAILS",
    FAILED_ORDER_DETAILS: "FAILED_ORDER_DETAILS",


    REQUEST_ADD_COMMENT: "REQUEST_ADD_COMMENT",
    SUCCESS_ADD_COMMENT: "SUCCESS_ADD_COMMENT",
    FAILED_ADD_COMMENT: "FAILED_ADD_COMMENT",

    REQUEST_LOGIN: "REQUEST_LOGIN",
    SUCCESS_LOGIN: "SUCCESS_LOGIN",
    FAILED_LOGIN: "FAILED_LOGIN",

    REQUEST_TOKEN: "REQUEST_TOKEN",
    SUCCESS_TOKEN: "SUCCESS_TOKEN",
    FAILED_TOKEN: "FAILED_TOKEN",


    REQUEST_UPDATE_LAB: "REQUEST_UPDATE_LAB",
    SUCCESS_UPDATE_LAB: "SUCCESS_UPDATE_LAB",
    FAILED_UPDATE_LAB: "FAILED_UPDATE_LAB",


  
    
}