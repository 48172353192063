import React,{useEffect,useState} from 'react';
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import axios from 'axios';
import moment from 'moment';

function Marketing() {
    const [marketingapi,setmarketingapi] = useState()
    useEffect(() => {
       
        axios
          .get(`${process.env.REACT_APP_BASEURL}medicare/getPreassessmentForChild?requestType=Enquiry`)
          .then((res) => {
            setmarketingapi(res.data);
    
         
          })
          .catch((err) => {
            console.log(err, "Something went wrong1");
          });
        // };
        // return () => getPlan();
      }, []);
  return (
    <div>
         <DataTable
            value={marketingapi}
            paginator
            rows={50}
            rowsPerPageOptions={[5, 10, 25, 50]}
          
            globalFilterFields={["Status"]}
           
            tableStyle={{ padding: "10px" }}
            rowClassName={(rowData) =>
              rowData.serviceStatus === 2 ? "rowcolor" : ""
            }
          >
            <Column field="giverName" header="Giver Name" sortable />
          
              <Column field="giverEmail" header="Email" sortable />
          
           
            
         
            <Column
              field="timeRange"
              header="Mobile"
              body={(rowData) => {
                const formattedDate = rowData.countryCode
             

                const formattedYear = rowData.giverMobile;
                return `${formattedDate} ${formattedYear}`;
              }}
            />
           
            <Column
              field="timeRange"
              header="Whatsapp"
              body={(rowData) => {
                const formattedDate = rowData.whatsnoCountryCode
             

                const formattedYear = rowData.giverWhatsapp;
                return `${formattedDate} ${formattedYear}`;
              }}
            />

         

<Column field="city" header="District" />
           
            <Column
              field="state"
              header="State"
              filterMenuStyle={{ width: "14rem" }}
             
              style={{ width: "200px" }}
            />

          
          
     

          





          </DataTable>
    </div>
  )
}

export default Marketing