export const storeToken = async (token_data) => {
    try {
        await localStorage.setItem('token', token_data.access_token);
    } catch (error) {
        console.log('AsyncStorage error during token store:', error);
    }
}

// export const isUserLoggedIn = () => {
//     return getDataFromStore("loggedIn")
// }
export const setDataInStore = (key, data) => {
    try {
        localStorage.setItem(key, data);
    } catch (error) {
        console.log('AsyncStorage error during set data in store:', error);
    }
}
export const getDataFromStore = (key) => {
    try {
        return localStorage.getItem(key);
    } catch (error) {
        console.log('AsyncStorage error during get data from store:', error);
    }
}

export const clearDataFromStore = (key) => {
    try {
        return localStorage.removeItem(key);
    } catch (error) {
        console.log('AsyncStorage error during get data from store:', error);
    }
}

export const formatDate = (date) => {
    let day = date.getDate()
    let month = date.getMonth() + 1;
    let year = date.getFullYear();

    day = day < 10 ? "0" + day : day
    month = month < 10 ? "0" + month : month
    // let formattedDate = day + "/" + month + "/" + year;
    return year + "-" + month + "-" + day;
    // let formattedDate = date.getDate() + "/" + 1 + date.getMonth() + "/" + date.getFullYear();
}