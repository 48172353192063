export const CHE = {
    REQUEST_CHEFEEDBACK: "REQUEST_CHEFEEDBACK",
    SUCCESS_CHEFEEDBACK: "SUCCESS_CHEFEEDBACK",
    FAILED_CHEFEEDBACK: "FAILED_CHEFEEDBACK",

    REQUEST_CHEADMINDETAILS: "REQUEST_CHEADMINDETAILS",
    SUCCESS_CHEADMINDETAILS: "SUCCESS_CHEADMINDETAILS",
    FAILED_CHEADMINDETAILS: "FAILED_CHEADMINDETAILS",

    REQUEST_CHEADMINNOTIFICATIONS: "REQUEST_CHEADMINNOTIFICATIONS",
    SUCCESS_CHEADMINNOTIFICATIONS: "SUCCESS_CHEADMINNOTIFICATIONS",
    FAILED_CHEADMINNOTIFICATIONS: "FAILED_CHEADMINNOTIFICATIONS",

    REQUEST_CHEADMINALLCHEORDERS: "REQUEST_CHEADMINALLCHEORDERS",
    SUCCESS_CHEADMINALLCHEORDERS: "SUCCESS_CHEADMINALLCHEORDERS",
    FAILED_CHEADMINALLCHEORDERS: "FAILED_CHEADMINALLCHEORDERS",

    REQUEST_CHECHANGEPASSWORD: "REQUEST_CHECHANGEPASSWORD",
    SUCCESS_CHECHANGEPASSWORD: "SUCCESS_CHECHANGEPASSWORD",
    FAILED_CHECHANGEPASSWORD: "FAILED_CHECHANGEPASSWORD",


    REQUEST_SAVECHEADMINDETAILS: "REQUEST_SAVECHEADMINDETAILS",
    SUCCESS_SAVECHEADMINDETAILS: "SUCCESS_SAVECHEADMINDETAILS",
    FAILED_SAVECHEADMINDETAILS: "FAILED_SAVECHEADMINDETAILS",

   


}