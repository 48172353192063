import DatePicker from "react-datepicker";
import { useState, React, useRef } from "react";
import { useEffect } from "react";
import { Dropdown } from "primereact/dropdown";
import http from "../../../src/Redux/services/http-common.jsx";
import "../CarePlan/index.css";
import moment from "moment";
import { ToastContainer, collapseToast, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import DoctorAppointmentViewPopup from "./DoctorAppointmentViewPopup.js";
import { Sidebar } from "primereact/sidebar";
import { AutoComplete } from "primereact/autocomplete";
import { TabView, TabPanel } from "primereact/tabview";
import { InputText } from "primereact/inputtext";
import infobutton from "../../Assets/Images/infobutton.svg";
import { Accordion, AccordionTab } from "primereact/accordion";
import { Tag } from "primereact/tag";
import AppointmentReschedule from "./AppointmentReschedule.jsx";
import { Backdrop, Button, CircularProgress } from "@mui/material";
import accept from "../../Assets/Images/accept.svg";
import reject from "../../Assets/Images/reject.svg";
import { login } from "../../Redux/Actions/dashboardaction.jsx";
import red from "../../Assets/Images/red.svg";
import green from "../../Assets/Images/green.svg";
import VerifyAccount from "../../Assets/Images/VerifyAccount.svg";
import { secureStorage } from "../../../src/Redux/Reducers/authReducer.jsx";

import { Paginator } from "primereact/paginator";

function InventoryDoctorsData({}) {
  const [remarks, setRemarks] = useState({});
  const [doctorsData, setDoctorsData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [fromDate, setfromDate] = useState(new Date());
  const [toDate, settoDate] = useState(new Date());
  const [actualPayment, setActualPayment] = useState({});
  const [searchTerm, setSearchTerm] = useState("");
  const [filteredDoctors, setFilteredDoctors] = useState([]);
  const [pagination, setPagination] = useState({});
  const loginData = JSON.parse(localStorage.getItem("loginData"));

  const options = [
    { name: "Pending" },
    { name: "Approved", code: "A" },
    { name: "Rejected", code: "R" },
  ];
  const [selectedItem, setSelectedItem] = useState(
    options.find((option) => option.name === "Pending")
  );
  const handleRemarkChange = (userId, serviceCode, value) => {
    const compositeKey = `${userId}:${serviceCode}`;
    setRemarks((prevState) => ({
      ...prevState,
      [compositeKey]: value,
    }));
  };

  const handleActualPaymentChange = (userId, serviceCode, value) => {
    const compositeKey = `${userId}:${serviceCode}`;

    setActualPayment((prevState) => ({
      ...prevState,
      [compositeKey]: value,
    }));
  };

  const inventoryGetAPI = async () => {
    setDoctorsData([]);
    try {
      setLoading(true);

      let url = `${process.env.REACT_APP_DOC_URL}backOfficeAutoPayment/getDoctorPayment?type=0`;
      if (fromDate) {
        url += `&fromDate=${moment(fromDate).format("YYYY-MM-DD")}`;
      }
      if (toDate) {
        const adjustedDate = moment(toDate).add(1, "days").format("YYYY-MM-DD");
        url += `&toDate=${adjustedDate}`;
      }

      if (selectedItem && ["A", "R"].includes(selectedItem.code)) {
        url += `&status=${selectedItem.code}`;
      }

      const response = await fetch(url);
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }

      const text = await response.text();
      let data;
      if (!text) {
        // toast("No data found");
      } else {
        try {
          data = JSON.parse(text);
        } catch (error) {
          console.error("Error parsing JSON:", error);
          toast("Error fetching inventory data");
          throw error;
        }

        if (!data?.data || data?.data.length === 0) {
          // toast("No data found");
          setDoctorsData([]);
        } else {
          setDoctorsData(data?.data);
        }
      }

      setLoading(false);
      return data;
    } catch (error) {
      console.error("Error fetching inventory data:", error);
      setLoading(false);
      setDoctorsData([]);

      throw error;
    }
  };
  const onPageChange = (key, e) => {
    setPagination((prev) => ({
      ...prev,
      [key]: { first: e.first, rows: e.rows },
    }));
  };
  useEffect(() => {
    if (fromDate && toDate) {
      inventoryGetAPI();
    } else if (selectedItem) {
      inventoryGetAPI();
    } else if (fromDate && toDate && selectedItem) {
      inventoryGetAPI();
    }
  }, [fromDate, toDate, selectedItem]);
  console.log(fromDate, toDate, "DATES");
  const accepted = async (
    userId,
    toBePaid,
    serviceCode,
    consultationAmount,
    consultationCount
  ) => {
    const compositeKey = `${userId}:${serviceCode}`;

    let payload = {
      userid: userId,
      type: 0,
      serviceCode: serviceCode,
      totalAmount: consultationAmount,
      fromDate: moment(fromDate).format("YYYY-MM-DD 00:00:00"),
      toDate: moment(toDate).format("YYYY-MM-DD 00:00:00"),
      remark: remarks[compositeKey] || "",
      status: "A",
      createdBy: loginData?.firstName + " " + loginData?.lastName,
      toBePaid: toBePaid,
      totalDeduction:
        consultationAmount -
        (actualPayment[compositeKey] && actualPayment[compositeKey] !== ""
          ? actualPayment[compositeKey]
          : toBePaid),
      actualAmount:
        actualPayment[compositeKey] && actualPayment[compositeKey] !== ""
          ? actualPayment[compositeKey]
          : toBePaid,
          consultationCount: consultationCount
    };

    console.log(payload, "AcceptedPayload");
    try {
      const response = await http.post(
        `${process.env.REACT_APP_DOC_URL}doctorBilling/saveDoctorPayment`,
        payload
      );

      console.log(response.data, "Response from saveDoctorPayment");
      inventoryGetAPI();
    } catch (error) {
      console.error("Error in saveDoctorPayment", error);
    }
  };

  const rejected = async (
    userId,
    toBePaid,
    serviceCode,
    consultationAmount,
    consultationCount
  ) => {
    console.log("Rejected");

    const compositeKey = `${userId}:${serviceCode}`;

    if (!remarks[compositeKey] || remarks[compositeKey].trim() === "") {
      toast("Please enter a remark.");
      return;
    }

    let payload = {
      userid: userId,
      type: 0,
      serviceCode: serviceCode,
      totalAmount: consultationAmount,
      fromDate: moment(fromDate).format("YYYY-MM-DD 00:00:00"),
      toDate: moment(toDate).format("YYYY-MM-DD 00:00:00"),
      remark: remarks[compositeKey],
      status: "R",
      createdBy: loginData?.firstName + " " + loginData?.lastName,
      toBePaid: toBePaid,
      totalDeduction:
        consultationAmount -
        (actualPayment[compositeKey] && actualPayment[compositeKey] !== ""
          ? actualPayment[compositeKey]
          : toBePaid),
          actualAmount:
        actualPayment[compositeKey] && actualPayment[compositeKey] !== ""
          ? actualPayment[compositeKey]
          : toBePaid,
          consultationCount: consultationCount
    };

    console.log(payload, "RejectedPayload");
    try {
      const response = await http.post(
        `${process.env.REACT_APP_DOC_URL}doctorBilling/saveDoctorPayment`,
        payload
      );

      console.log(response.data, "Response from saveDoctorPayment");

      setRemarks("");
      inventoryGetAPI();
    } catch (error) {
      console.error("Error in saveDoctorPayment", error);
      toast.error("Error saving payment details.");
    }
  };
  const verifyAccount = async (userId) => {
    console.log("Verify Account Function");
    let s_token = secureStorage.getItem("token");
    try {
      const response = await http.post(
        `${process.env.REACT_APP_ADMINURL}user/createRazorCustomerAccountId?userId=${userId}`,

        {
          headers: {
            Authorization: `Bearer ${s_token}`,
          },
        }
      );

      console.log(response.data, "Response from Verify Account");

      inventoryGetAPI();
      toast.success("Account Verification Successful");
    } catch (error) {
      console.error("Error in Verifying Account", error);
      toast.error("Error in Verifying Account.");
    }
  };

  useEffect(() => {
    if (!searchTerm.trim()) {
      setFilteredDoctors(doctorsData);
    } else {
      const filtered = doctorsData.filter((doctor) =>
        doctor.doctorName.toLowerCase().includes(searchTerm.toLowerCase())
      );
      setFilteredDoctors(filtered);
    }
  }, [searchTerm, doctorsData]);

  const [sideBarData, setSideBarData] = useState();
  const [sideDialog, setSideDialog] = useState(false);

  const onClickInfo = async (coloumndata) => {
    setLoading(true);
    let url = `${process.env.REACT_APP_DOC_URL}backOfficeAutoPayment/getDoctorPaymentDetails?type=0&userId=${coloumndata.userId}`;
    if (fromDate) {
      url += `&fromDate=${moment(fromDate).format("MM/DD/YYYY")}`;
    }
    if (toDate) {
      const adjustedDate = moment(toDate).add(1, "days").format("MM/DD/YYYY");
      url += `&toDate=${adjustedDate}`;
    }
    const response = await fetch(url);

    if (!response.ok) {
      setLoading(false);
      throw new Error("Network response was not ok");
    }
    const text = await response.text();
    let data;
    try {
      data = JSON.parse(text);
    } catch (error) {
      setLoading(false);
      console.error("Error parsing JSON:", error);
      toast("Error fetching inventory data");
      throw error;
    }
    if (!data || data?.data.length === 0) {
    } else {
      setSideBarData(data?.data);
      setSideDialog(true);
      setLoading(false);
    }
  };
  console.log(sideBarData, "CAMP");
  console.log(sideBarData?.Y, "CAMPSideBarData");
  console.log(sideBarData?.N, "NOCAMPSideBarData");

  const accHeader = (tabContent) => {
    return (
      <>
        <div style={{ display: "flex", marginTop: "7px" }}>
          <div style={{ width: "270px", textAlign: "left" }}>
            <label>{tabContent?.N}</label>
          </div>
        </div>
      </>
    );
  };

  const subaccHeader = (tabContent) => {
    return (
      <>
        <div style={{ display: "flex", marginTop: "7px" }}>
          <div style={{ width: "270px", textAlign: "left" }}>
            <label>Without Camp</label>
          </div>

          <div style={{ width: "150px", textAlign: "right" }}>
            <Tag value={"Success"} severity={"success"}></Tag>
          </div>
        </div>
      </>
    );
  };

  return (
    <>
      <ToastContainer />
      <Backdrop
        sx={{ color: "#5579C6", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={loading}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      {doctorsData === undefined ? (
        <div
          style={{
            height: "1000px",
            textAlign: "center",
            justifyItems: "center",
          }}
        >
          <div>
            <i
              class="pi pi-spin pi-spinner"
              style={{
                fontSize: " 2rem",
                marginTop: "200px",
                color: "blue",
                fontWeight: "700",
              }}
            ></i>
          </div>
        </div>
      ) : (
        <>
          <div style={{ marginTop: "5px" }}>
            <form>
              <div className="maindivsearchbar">
                {/* <AutoComplete
                  value={searchTerm}
                  onChange={(e) => setSearchTerm(e.value)}
                  suggestions={filteredDoctors.map(
                    (doctor) => doctor.doctorName
                  )}
                  completeMethod={(e) => setSearchTerm(e.query)}
                  field="doctorName"
                  dropdown
                  placeholder="Search by Doctor"
                /> */}

                <div
                  className="searchbar"
                  style={{ marginBottom: "20px" }}
                  title="Search by date"
                >
                  <DatePicker
                    showIcon
                    className="datepicker1"
                    selected={fromDate}
                    onChange={(date) => setfromDate(date)}
                    customInput={
                      <div
                        style={{
                          position: "relative",
                          display: "flex",
                          flexDirection: "column",
                        }}
                      >
                        <div>
                          <input
                            type="text"
                            className="form-control"
                            value={
                              fromDate
                                ? moment(fromDate).format("MM/DD/yyyy")
                                : "From date"
                            }
                            placeholder="Select from date"
                            readOnly
                          />
                        </div>
                        <img
                          src="images/calander.svg"
                          alt="Calendar"
                          style={{
                            position: "absolute",
                            right: "10px",
                            top: "1px",
                            cursor: "pointer",
                          }}
                        />
                      </div>
                    }
                  ></DatePicker>
                </div>
                <div
                  className="searchbar"
                  style={{ marginBottom: "20px" }}
                  title="Search by date"
                >
                  <DatePicker
                    showIcon
                    className="datepicker1"
                    selected={toDate}
                    onChange={(date) => settoDate(date)}
                    customInput={
                      <div
                        style={{
                          position: "relative",
                          display: "flex",
                          flexDirection: "column",
                        }}
                      >
                        <div>
                          <input
                            type="text"
                            className="form-control"
                            value={
                              toDate
                                ? moment(toDate).format("MM/DD/yyyy")
                                : "To Date"
                            }
                            placeholder="Select To date"
                            readOnly
                          />
                        </div>
                        <img
                          src="images/calander.svg"
                          alt="Calendar"
                          style={{
                            position: "absolute",
                            right: "10px",
                            top: "1px",
                            cursor: "pointer",
                          }}
                        />
                      </div>
                    }
                  ></DatePicker>
                </div>
                <div style={{ marginBottom: "20px" }}>
                  <Dropdown
                    value={selectedItem}
                    onChange={(e) => setSelectedItem(e.value)}
                    options={options}
                    optionLabel="name"
                    placeholder="Select Status"
                    className="w-32"
                  />
                </div>
              </div>
            </form>
          </div>
          <DataTable
            value={doctorsData}
            paginator
            rows={10}
            rowsPerPageOptions={[10, 25, 50]}
            scrollHeight="flex"
            scrollWidth="100%"
            tableStyle={{ minWidth: "1200px" }}
          >
            {/* <Column field="giver_name" header="Name" sortable></Column> */}
            <Column
              // field="receiverName"
              header="Doctor Name"
              body={(rowbody) => (
                <>
                  <div style={{ display: "flex" }}>
                    <div style={{ display: "block", marginLeft: "15px" }}>
                      <div style={{ fontSize: "12px" }}>
                        {rowbody.doctorName}
                      </div>
                    </div>
                  </div>
                </>
              )}
            ></Column>
            <Column
              field="mobile_number"
              header="Consultation amount "
              body={(rowbody) => (
                <>
                  <div style={{ display: "flex" }}>
                    <div style={{ fontSize: "12px" }}>
                      {rowbody.consultationAmount}
                    </div>
                  </div>
                </>
              )}
            ></Column>
            <Column
              field="consultationCount"
              header="No of consultation done "
            ></Column>

            {/* <Column
              header="Comission"
              body={(rowData) => {
                return rowData.commissionPercentage ||
                  rowData.commissionPercentage === 0
                  ? `${rowData.commissionPercentage}%`
                  : "";
              }}
            /> */}
            <Column
              header="TDS"
              body={(rowData) => {
                return rowData.tds || rowData.tds === 0
                  ? `${rowData.tds}%`
                  : "";
              }}
            />

            <Column
              field="isPanVerified"
              header="PAN Confirm"
              // style={{ width: "200px" }}
            ></Column>
            <Column
              field="transportationAmount"
              header="Transportation Amount"
              // style={{ width: "200px" }}
            ></Column>
            <Column
              header="To be paid"
              body={(rowbody) => {
                const compositeKey = `${rowbody.userId}:${rowbody.serviceCode}`;

                const actualPaymentEntered =
                  actualPayment[compositeKey] !== undefined &&
                  actualPayment[compositeKey] !== "";

                const isMatch =
                  Number(rowbody.toBePaid) ===
                  Number(actualPayment[compositeKey] || 0);

                console.log("Actual Payments:", actualPayment);
                console.log("Is Match for", compositeKey, ":", isMatch);

                return (
                  <div
                    style={{
                      position: "relative",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      height: "60px",
                      width: "100px",
                    }}
                  >
                    <span
                      style={{
                        zIndex: 2,
                        position: "absolute",
                        color: actualPaymentEntered ? "white" : "black",
                        fontWeight: "bold",
                      }}
                    >
                      {rowbody.toBePaid}
                    </span>
                    {actualPaymentEntered && (
                      <img
                        src={isMatch ? green : red}
                        alt={isMatch ? "Match" : "Mismatch"}
                        style={{
                          position: "absolute",
                          height: "100%",
                          width: "100%",
                          zIndex: 1,
                        }}
                      />
                    )}
                  </div>
                );
              }}
            />

            {!(selectedItem?.code === "A" || selectedItem?.code === "R") && (
              <Column
                field="actualPayment"
                header="Actual payment "
                body={(rowbody) => (
                  <>
                    <div style={{ display: "flex" }}>
                      <div style={{ fontSize: "12px" }}>
                        <InputText
                          placeholder="Enter Actual Amount"
                          type="number"
                          value={
                            actualPayment[
                              `${rowbody.userId}:${rowbody.serviceCode}`
                            ] || ""
                          }
                          onChange={(e) =>
                            handleActualPaymentChange(
                              rowbody.userId,
                              rowbody.serviceCode,
                              e.target.value
                            )
                          }
                          style={{ width: "140px" }}
                        />
                      </div>
                    </div>
                  </>
                )}
              ></Column>
            )}
            <Column
              field="status"
              header="Status"
              body={(rowData) => {
                if (selectedItem.code === "A") {
                  return "Approved";
                } else if (selectedItem.code === "R") {
                  return "Rejected";
                } else {
                  return "Pending";
                }
              }}
            />

            {!(selectedItem?.code === "A" || selectedItem?.code === "R") && (
              <Column
                header="Remarks"
                body={(rowbody) => (
                  <>
                    <div style={{ display: "flex" }}>
                      <div style={{ fontSize: "12px" }}>
                        <InputText
                          placeholder="Enter Remarks"
                          value={
                            remarks[
                              `${rowbody.userId}:${rowbody.serviceCode}`
                            ] || ""
                          }
                          onChange={(e) =>
                            handleRemarkChange(
                              rowbody.userId,
                              rowbody.serviceCode,
                              e.target.value
                            )
                          }
                          style={{ width: "140px" }}
                        />
                      </div>
                    </div>
                  </>
                )}
              />
            )}

            {!(selectedItem?.code === "A" || selectedItem?.code === "R") && (
              <Column
                field=""
                header="Action Button "
                body={(rowbody) => (
                  <>
                    {rowbody.isBankDetailsVerified === "Y" && (
                      <div style={{ display: "flex" }}>
                        <div style={{ fontSize: "12px", cursor: "pointer" }}>
                          <img
                            src={accept}
                            alt=""
                            onClick={(e) =>
                              accepted(
                                rowbody.userId,
                                rowbody.toBePaid,
                                rowbody.serviceCode,
                                rowbody.consultationAmount,
                                rowbody.consultationCount
                              )
                            }
                          />
                          <img
                            src={reject}
                            alt=""
                            onClick={(e) =>
                              rejected(
                                rowbody.userId,
                                rowbody.toBePaid,
                                rowbody.serviceCode,
                                rowbody.consultationAmount,
                                rowbody.consultationCount
                              )
                            }
                          />
                        </div>
                      </div>
                    )}
                    {rowbody.isBankDetailsVerified === "N" && (
                      <div style={{ display: "flex" }}>
                        <div style={{ fontSize: "10px", cursor: "pointer" }}>
                          <img
                            src={VerifyAccount}
                            alt=""
                            onClick={(e) => verifyAccount(rowbody.userId)}
                            style={{ width: "110px" }}
                          />
                        </div>
                      </div>
                    )}
                  </>
                )}
              ></Column>
            )}
            <Column
              field=""
              header="Details"
              body={(rowbody) => (
                <>
                  <div style={{ display: "flex" }}>
                    <div style={{ fontSize: "12px" }}>
                      <img
                        src={infobutton}
                        alt=""
                        title="Doctor Info"
                        onClick={(e) => onClickInfo(rowbody)}
                        style={{ cursor: "pointer" }}
                      />
                    </div>
                  </div>
                </>
              )}
            ></Column>
          </DataTable>
          <Sidebar
            visible={sideDialog}
            position="right"
            onHide={() => setSideDialog(false)}
            style={{ width: "580px" }}
          >
            <>
              <div className="">
                <div>
                  <h2 style={{ color: "black", marginTop: "0px" }}>
                    Payment Details
                  </h2>
                </div>
                <div className="mt-10 mb-10">
                  <h3
                    style={{
                      color: "black",
                      marginTop: "20px",
                      marginBottom: "20px",
                    }}
                  ></h3>
                </div>
                <div className="mt-3">
                  {sideBarData &&
                    Object.entries(sideBarData).map(
                      ([key, dataArray], keyIndex) => (
                        <Accordion key={keyIndex}>
                          <AccordionTab
                            header={
                              key === "Y" ? "Camp Data" : "Consultation Data"
                            }
                          >
                            <Accordion>
                              {dataArray
                                .slice(
                                  pagination[key]?.first || 0,
                                  (pagination[key]?.first || 0) +
                                    (pagination[key]?.rows || 5)
                                )
                                .map((item, index) => (
                                  <AccordionTab
                                    key={index}
                                    header={
                                      item.serviceCode || "Unknown Service Code"
                                    }
                                  >
                                    <div
                                      style={{
                                        display: "flex",
                                        justifyContent: "space-between",
                                        marginTop: "15px",
                                      }}
                                    >
                                      <div
                                        style={{
                                          width: "50%",
                                          textAlign: "left",
                                        }}
                                      >
                                        <label>Status</label>
                                      </div>
                                      <div
                                        style={{
                                          width: "50%",
                                          textAlign: "right",
                                        }}
                                      >
                                        <Tag
                                          value={"Success"}
                                          severity={"success"}
                                        ></Tag>
                                      </div>
                                    </div>
                                    <div
                                      style={{
                                        display: "flex",
                                        justifyContent: "space-between",
                                        marginTop: "15px",
                                      }}
                                    >
                                      <div
                                        style={{
                                          width: "50%",
                                          textAlign: "left",
                                        }}
                                      >
                                        <label>Appointment Number</label>
                                      </div>
                                      <div
                                        style={{
                                          width: "50%",
                                          textAlign: "right",
                                        }}
                                      >
                                        {item.appointmentId}
                                      </div>
                                    </div>
                                    <div
                                      style={{
                                        display: "flex",
                                        justifyContent: "space-between",
                                        marginTop: "15px",
                                      }}
                                    >
                                      <div
                                        style={{
                                          width: "50%",
                                          textAlign: "left",
                                        }}
                                      >
                                        <label>Appointment date</label>
                                      </div>
                                      <div
                                        style={{
                                          width: "50%",
                                          textAlign: "right",
                                        }}
                                      >
                                        {moment(item.whenAppointment).format(
                                          "DD MMMM YYYY"
                                        )}
                                      </div>
                                    </div>
                                    <div
                                      style={{
                                        display: "flex",
                                        justifyContent: "space-between",
                                        marginTop: "15px",
                                      }}
                                    >
                                      <div
                                        style={{
                                          width: "50%",
                                          textAlign: "left",
                                        }}
                                      >
                                        <label>Consultation Amount</label>
                                      </div>
                                      <div
                                        style={{
                                          width: "50%",
                                          textAlign: "right",
                                        }}
                                      >
                                        {item.consultationAmount}
                                      </div>
                                    </div>
                                    <div
                                      style={{
                                        display: "flex",
                                        justifyContent: "space-between",
                                        marginTop: "15px",
                                      }}
                                    >
                                      <div
                                        style={{
                                          width: "50%",
                                          textAlign: "left",
                                        }}
                                      >
                                        <label>Consultation Type</label>
                                      </div>
                                      <div
                                        style={{
                                          width: "50%",
                                          textAlign: "right",
                                        }}
                                      >
                                        {item.serviceName}
                                      </div>
                                    </div>
                                    <div
                                      style={{
                                        display: "flex",
                                        justifyContent: "space-between",
                                        marginTop: "15px",
                                      }}
                                    >
                                      <div
                                        style={{
                                          width: "50%",
                                          textAlign: "left",
                                        }}
                                      >
                                        <label>Comission Percentage</label>
                                      </div>
                                      <div
                                        style={{
                                          width: "50%",
                                          textAlign: "right",
                                        }}
                                      >
                                        {item.commissionPercentage} %
                                      </div>
                                    </div>
                                    <div
                                      style={{
                                        display: "flex",
                                        justifyContent: "space-between",
                                        marginTop: "15px",
                                      }}
                                    >
                                      <div
                                        style={{
                                          width: "50%",
                                          textAlign: "left",
                                        }}
                                      >
                                        <label>TDS</label>
                                      </div>
                                      <div
                                        style={{
                                          width: "50%",
                                          textAlign: "right",
                                        }}
                                      >
                                        {item.tds} %
                                      </div>
                                    </div>

                                    <div
                                      style={{
                                        display: "flex",
                                        justifyContent: "space-between",
                                        marginTop: "15px",
                                      }}
                                    >
                                      <div
                                        style={{
                                          width: "50%",
                                          textAlign: "left",
                                        }}
                                      >
                                        <label>Transaction Number</label>
                                      </div>
                                      <div
                                        style={{
                                          width: "50%",
                                          textAlign: "right",
                                        }}
                                      >
                                        {item.transactionNumber}
                                      </div>
                                    </div>
                                    <div
                                      style={{
                                        display: "flex",
                                        justifyContent: "space-between",
                                        marginTop: "15px",
                                      }}
                                    >
                                      <div
                                        style={{
                                          width: "50%",
                                          textAlign: "left",
                                        }}
                                      >
                                        <label>Transaction Date</label>
                                      </div>
                                      <div
                                        style={{
                                          width: "50%",
                                          textAlign: "right",
                                        }}
                                      >
                                        {moment(item.transactionDate).format(
                                          "DD MMMM YYYY"
                                        )}
                                      </div>
                                    </div>
                                  </AccordionTab>
                                ))}
                            </Accordion>
                            <Paginator
                              first={pagination[key]?.first || 0}
                              rows={pagination[key]?.rows || 5}
                              totalRecords={dataArray.length}
                              rowsPerPageOptions={[5, 10, 20]}
                              onPageChange={(e) => onPageChange(key, e)}
                            />
                          </AccordionTab>
                        </Accordion>
                      )
                    )}
                </div>
              </div>
            </>
          </Sidebar>
        </>
      )}
    </>
  );
}

export default InventoryDoctorsData;
