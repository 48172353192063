import "./index.css";
import { useDispatch } from "react-redux";
import React, { useState, useEffect } from "react";
import DateView from "../../components/DateView/dateView";
import SearchView from "../../components/SearchView/searchView";
import calender_img from "../../Assets/Images/calender_img.svg";
import Flag from "../../Assets/Images/Flag.svg";
import MedicineOrderList from "./MedicineOrderList";
import DropDownService from "../../components/DropDownService";
import DialogAddComment from "../../components/DialogAddComment/dialogAddComment";
import DoctorAppointmentDetails from "../../components/DoctorAppointmentDetails/DoctorAppointmentDetails";
import SurgeryAppointmentDetails from "../../components/DoctorAppointmentDetails/SurgeryAppointmentDetails";
import moment from "moment";
import DropDownCancelReson from "../../components/DropDownCancelResonBusiness";
import {
  getBusinessEvent,
  getOrderDetails,
  onAddComment,
  getSurgerylist
} from "../../Redux/Actions/dashboardaction";

import InfiniteScroll from "react-infinite-scroll-component";

import { onConvertJsonToExcelBusinessEvent } from "../../utils/JsonToExcelConverterBusinessEvent";

const BusinessEvent = () => {
  const dispatch = useDispatch();
  const [isSelectedOption, setSelectedOption] = useState("Doctor Consultation");

  const [fromDate, setFromDate] = useState(
    moment().add(-1, "days").format("DD-MM-yyyy")
  );
  const [toDate, setToDate] = useState(
    moment().add("days").format("DD-MM-yyyy")
  );
  const [isDialog, setDialogView] = useState(false);
  const [hasMore, setHasMore] = useState(true);
  const [pageStart, setPageStart] = useState(1);

  const [isChecked, setIsChecked] = useState(false);
  const [isDialogAppointment, setDialogAppointment] = useState(false);
  const [isSelectedService, setSelectedService] = useState("3");
  const [businessEventData, setBusinessEventData] = useState([]);
  const [businessEventDataExcel, setBusinessEventDataExcel] = useState([]);
  const [orderDetails, setOrderDetails] = useState();

  const [commentText, setCommentText] = useState("");

  const [filterKey, setFilterKey] = useState("");
  const [isLoader, setLoader] = useState(false);

  const [selectedItem, setSelectedItem] = useState("");
  const [isStatus, setStatus] = useState("");
  const [isPalaceHolder, setPalaceHolder] = useState("All");
  const [surgeryDetails, setSurgeryDetails] = useState("");
  const [statusOfBusiness, setStatusOfBusiness] = useState([
    {
      status: "",
      statusText: "All",
    },
    {
      status: 0,
      statusText: "Not Paid",
    },
    {
      status: 1,
      statusText: "Join Pre-Consultation / Upcoming",
    },
    {
      status: 2,
      statusText: "Download Prescription / Completed",
    },
    {
      status: 3,
      statusText: "Cancelled By Patient",
    },
    {
      status: 4,
      statusText: "Cancelled By Doctor",
    },
    {
      status: 5,
      statusText: "Cancelled By Admin",
    },
    {
      status: 6,
      statusText: "Cancelled By Customer Care",
    },
    {
      status: 7,
      statusText: "Cancelled By CHE Centre",
    },
    {
      status: 8,
      statusText: "Join Call",
    },
    {
      status: 9,
      statusText: "Expired",
    },
    {
      status: 10,
      statusText: "Refund Initiated",
    },
    {
      status: 11,
      statusText: "Refund Queue",
    },
    {
      status: 12,
      statusText: "Refund Successfully",
    },
    {
      status: 13,
      statusText: "Rejected from Back Office",
    },
    {
      status: 14,
      statusText: "Request For Preferred Time",
    },
    {
      status: 15,
      statusText: "Doctor available for requested time slot",
    },
    {
      status: 16,
      statusText: "Order closed, requested slot not available",
    },
  ]);

  useEffect(() => {
    setLoader(true);
    let payload = {
      fromDate: moment(fromDate, "DD-MM-YYYY").format("YYYY-MM-DD"),
      toDate: moment(toDate, "DD-MM-YYYY").format("YYYY-MM-DD"),
      pageNo: "1",
      pageSize: "20",
      type: "3",
    };

    dispatch(getBusinessEvent(payload)).then((res) => {
      setBusinessEventData([...res]);
      setLoader(false);
      getEventApiExcel("1", toDate);
    });
  }, [dispatch]);

  const getEventApiExcel = (type, toDate) => {
    let payload = {
      fromDate: moment(fromDate, "DD-MM-YYYY").format("YYYY-MM-DD"),
      toDate: moment(toDate, "DD-MM-YYYY").format("YYYY-MM-DD"),
      type: type,
    };

    if (type == "5") {
      dispatch(getSurgerylist(payload)).then((res) => {
        setBusinessEventDataExcel([...res]);
      });
    }
    else {
      dispatch(getBusinessEvent(payload)).then((res) => {
        setBusinessEventDataExcel([...res]);
      });
    }


  };

  const getEventApi = (pageNo) => {
    setLoader(true);

    setBusinessEventData([]);
    setSelectedService(pageNo);
    setPalaceHolder("All");

    let payload = {
      fromDate: moment(fromDate, "DD-MM-YYYY").format("YYYY-MM-DD"),
      toDate: moment(toDate, "DD-MM-YYYY").format("YYYY-MM-DD"),
      pageNo: "1",
      pageSize: "20",
      type: pageNo,
    };
    if (pageNo == "5") {
      dispatch(getSurgerylist(payload)).then((res) => {
        setBusinessEventData([...res]);
        getEventApiExcel(pageNo, toDate);
        setLoader(false);
      });
    }
    else {

      dispatch(getBusinessEvent(payload)).then((res) => {
        setBusinessEventData([...res]);
        getEventApiExcel(pageNo, toDate);
        setLoader(false);
      });
    }
    // alert(pageNo);
    // setLoader(true);

    // setBusinessEventData([]);
    // setSelectedService(pageNo);
    // setPalaceHolder("All");

    // let payload = {
    //   fromDate: moment(fromDate, "DD-MM-YYYY").format("YYYY-MM-DD"),
    //   toDate: moment(toDate, "DD-MM-YYYY").format("YYYY-MM-DD"),
    //   pageNo: "1",
    //   pageSize: "20",
    //   type: pageNo,
    // };

    // dispatch(getBusinessEvent(payload)).then((res) => {
    //   setBusinessEventData([...res]);
    //   getEventApiExcel(pageNo, toDate);
    //   setLoader(false);
    // });

  };

  const getEventApiDateFilter = (pageNo, e) => {
    setBusinessEventData([]);
    setLoader(true);
    let payload = {
      fromDate: moment(fromDate, "DD-MM-YYYY").format("YYYY-MM-DD"),
      toDate: moment(e, "DD-MM-YYYY").format("YYYY-MM-DD"),
      type: pageNo,
      pageNo: "1",
      pageSize: "20",
      status: isStatus,
    };

    if (pageNo == "5") {
      dispatch(getSurgerylist(payload)).then((res) => {
        setBusinessEventData([...res]);
        getEventApiExcel(pageNo, e);

        setLoader(false);
      });
    }
    else {
      dispatch(getBusinessEvent(payload)).then((res) => {
        setBusinessEventData([...res]);
        getEventApiExcel(pageNo, e);

        setLoader(false);
      });
    }


  };

  const onClickYesBtn = () => {
    setDialogView(false);
  };

  const onClickCancelBtn = () => {
    setDialogAppointment(false);
  };

  const [doctorConsultation, setDoctorConsultation] = useState([
    "OrderID",
    "Patient Details",
    "Doctor Details",
    "Appointment Time",
    "Consultation",
    "Status",
    "Consultations Type",
    "Order Time",
    "Paid Amount",
  ]);

  const doctConsultation = [
    "OrderID",
    "Patient Details",
    "Doctor Details",
    "Appointment Time",
    "Consultation",
    "Status",
    "Consultations Type",
    "Order Time",
    "Paid Amount",
  ];

  const hospitalEnquiry = [
    "OrderID",
    "Patient Details",
    "Hospital Details",
    "Location Details",
    "Planned Date",
    "Status",
    "Order Time",
  ];

  const medicineOrder = [
    "OrderID",
    "Patient Details",

    "System Status",
    "Activity Status",
    "Order Time",
    "Paid Amount",
    "Fulfillment Duration "
  ];

  const LabTest = [
    "OrderID",
    "Patient Details",
    "Lab Details",
    "Location Details",
    "Planned Date",
    "Status",
    "Order Time",
    "Paid Amount",
  ];
  const Surgery = [
    "OrderID",
    "Patient Details",
    "Surgery Name",
    "Status",
    // "State",
    "City",
    "Create Date"
  ];

  const onClickDate = (e) => {
    setFromDate(e);
    setToDate();
  };

  const medicineStatus = [
    {
      status: "",
      statusText: "All",
    },
    {
      status: 0,
      statusText: "Order Placed",
    },
    {
      status: 1,
      statusText: "Order Confirmed",
    },
    {
      status: 2,
      statusText: "Accepted",
    },
    {
      status: 3,
      statusText: "Picked Up",
    },
    {
      status: 4,
      statusText: "In Transit",
    },
    {
      status: 5,
      statusText: "Delivered",
    },
    {
      status: 6,
      statusText: "Cancelled By Patient",
    },
    {
      status: 7,
      statusText: "Cancelled By Pharmacy",
    },
    {
      status: -2,
      statusText: "Packed",
    },
    {
      status: -2,
      statusText: "Ready For Delivery",
    },
    {
      status: -2,
      statusText: "Dispatch",
    },

    {
      status: 9,
      statusText: "Not Paid",
    },
    {
      status: 11,
      statusText: "Requesting For Quote",
    },
    {
      status: 13,
      statusText: "Quote Sent",
    },
    {
      status: 14,
      statusText: "Refunded",
    },
    {
      status: 15,
      statusText: "Requesting For Quote",
    },
    {
      status: 15,
      statusText: "Partially Refunded",
    },
    {
      status: 16,
      statusText: "Assigned",
    },
    {
      status: 8,
      statusText: "Rejected",
    },
    {
      status: 17,
      statusText: "Confirm By Pharmacy",
    },
    {
      status: 18,
      statusText: "Quotation Not Sent",
    },
  ];

  const LabTestsStatus = [
    {
      status: "",
      statusText: "All",
    },
    {
      status: 0,
      statusText: "Pending",
    },
    {
      status: 1,
      statusText: "Ordered",
    },
    {
      status: 2,
      statusText: "Completed",
    },
    {
      status: 3,
      statusText: "Cancelled",
    },
    {
      status: 5,
      statusText: "Cancelled By Patient",
    },
    {
      status: 6,
      statusText: "Cancelled By Lab",
    },
    {
      status: 9,
      statusText: "Expired",
    },
    {
      status: 10,
      statusText: "Refund Initiated",
    },
    {
      status: 11,
      statusText: "Refund Queue",
    },
    {
      status: 12,
      statusText: "Refund Successfully",
    },
    {
      status: 13,
      statusText: "Rejected from Back Office",
    },
  ];

  const doctorStatus = [
    {
      status: "",
      statusText: "All",
    },
    {
      status: 0,
      statusText: "Not Paid",
    },
    {
      status: 1,
      statusText: "Join Pre-Consultation / Upcoming",
    },
    {
      status: 2,
      statusText: "Download Prescription / Completed",
    },
    {
      status: 3,
      statusText: "Cancelled By Patient",
    },
    {
      status: 4,
      statusText: "Cancelled By Doctor",
    },
    {
      status: 5,
      statusText: "Cancelled By Admin",
    },
    {
      status: 6,
      statusText: "Cancelled By Customer Care",
    },
    {
      status: 7,
      statusText: "Cancelled By CHE Centre",
    },
    {
      status: 8,
      statusText: "Join Call",
    },
    {
      status: 9,
      statusText: "Expired",
    },
    {
      status: 10,
      statusText: "Refund Initiated",
    },
    {
      status: 11,
      statusText: "Refund Queue",
    },
    {
      status: 12,
      statusText: "Refund Successfully",
    },
    {
      status: 13,
      statusText: "Rejected from Back Office",
    },
    {
      status: 14,
      statusText: "Request For Preferred Time",
    },
    {
      status: 15,
      statusText: "Doctor available for requested time slot",
    },
    {
      status: 16,
      statusText: "Order closed, requested slot not available",
    },
  ];

  const hospitalStatus = [
    {
      status: "",
      statusText: "All",
    },
    {
      status: 0,
      statusText: "Rejected",
    },
    {
      status: 1,
      statusText: "Under Process",
    },
    {
      status: 2,
      statusText: "Discussing With Hospital",
    },
    {
      status: 3,
      statusText: "Discussing With Patient",
    },
    {
      status: 4,
      statusText: "Patient Denied",
    },
    {
      status: 5,
      statusText: "Hospital Denied",
    },
    {
      status: 6,
      statusText: "Procedure Confirmed",
    },
  ];
  const SurgeryStatus = [

    {
      status: "",
      statusText: "All",
    },
    {
      status: 1,
      statusText: "Pending",

    },
    {
      status: 2,
      statusText: "Completed",

    },
    {
      status: 3,
      statusText: "Appointment Book",

    },
    {
      status: 4,
      statusText: "Hospital Enquiry",

    },

  ];

  const onClickToDate = (e) => {
    setToDate(e);
    getEventApiDateFilter(isSelectedService, e);
  };

  const handleDropDownClick = () => { };

  const handleDropDownSelect = (item) => {
    setSelectedOption(item);
    setHasMore(true);
    setPageStart(1);

    if (item === "Doctor Consultation") {
      setDoctorConsultation(doctConsultation);
      setStatusOfBusiness(doctorStatus);
      getEventApi("3");
    } else if (item === "Hospital Enquiry") {
      setDoctorConsultation(hospitalEnquiry);
      setStatusOfBusiness(hospitalStatus);
      getEventApi("4");
    } else if (item === "Medicine Order") {
      getEventApi("1");
      setDoctorConsultation(medicineOrder);
      setStatusOfBusiness(medicineStatus);
    } else if (item === "Lab Test") {
      setDoctorConsultation(LabTest);
      getEventApi("2");
      setStatusOfBusiness(LabTestsStatus);
    }
    else {
      setDoctorConsultation(Surgery);
      getEventApi("5");
      setStatusOfBusiness(SurgeryStatus);
    }
  };

  const onTextChange = (filterKey) => {
    setFilterKey(filterKey);
  };

  const onInFiniteScrollFinal = () => { };

  const onClickOrderIdMain = (item) => {
    setOrderDetails();
    setDialogAppointment(true);
    setSelectedItem(item);
    console.log(item, "hello123")
    if (isSelectedService == "5") {
      let payload = {
        patientOrderId: item?.id,
        type: isSelectedService,
      };

      dispatch(getOrderDetails(payload)).then((res) => {
        setOrderDetails(res);
        console.log(res, "hiiii")
      });
    }
    else {
      let payload = {
        patientOrderId: item?.patientOrderId,
        type: isSelectedService,
      };

      dispatch(getOrderDetails(payload)).then((res) => {
        setOrderDetails(res);
        console.log(setOrderDetails, "hiiii")
      });
    }

  };

  const onClickAddCommentMain = (item) => {
    setDialogView(true);

    setSelectedItem(item);
  };

  console.log(selectedItem)

  const onChangeTextRemarks = (text) => {
    setCommentText(text);
  };
  // const onChangeTextRemarksnew = (text) => {
  //   setCommentTextnew(text);
  // };

  const onClickYesBtnDialog = () => {
    let userData = JSON.parse(localStorage.getItem("userData"));
    if (isSelectedOption == "Surgery") {
      let payload = {
        orderId: selectedItem?.id,
        serviceType: "Surgery",
        action: "comment",
        remarks: commentText,
        userId: userData?.firstName + " " + userData?.lastName,
      };
      dispatch(onAddComment(payload)).then((res) => {
        setDialogView(false);
      });
    }
    else {
      let payload = {
        orderId: selectedItem?.patientOrderId,
        serviceType:
          isSelectedOption === "Doctor Consultation"
            ? "Doctor"
            : isSelectedOption === "Hospital Enquiry"
              ? "Hospital"
              : isSelectedOption === "Medicine Order"
                ? "Medicine"
                : "LabTest",
        action: "comment",
        remarks: commentText,
        userId: userData?.firstName + " " + userData?.lastName,
      };
      // console.log(payload.orderId,"hiiiiii");
      dispatch(onAddComment(payload)).then((res) => {
        setDialogView(false);
      });
    }


  };

  const onClickCancelBtnDialog = () => {
    setDialogView(false);
  };

  const loadFunc = async () => {
    let payload = {
      fromDate: moment(fromDate, "DD-MM-YYYY").format("YYYY-MM-DD"),
      toDate: moment(toDate, "DD-MM-YYYY").format("YYYY-MM-DD"),
      pageNo: pageStart + 1,
      pageSize: "20",
      type: isSelectedService,
    };
    if (payload.type == "5") {
      dispatch(getSurgerylist(payload)).then((res) => {
        if (res.length === 0) {
          setHasMore(false);
        } else {
          setPageStart(pageStart + 1);
          if (businessEventData?.length) {
            setBusinessEventData([...businessEventData, ...res]);
          } else {
            setBusinessEventData([...res]);
          }

          setHasMore(true);
        }
      });
    }
    else {
      dispatch(getBusinessEvent(payload)).then((res) => {
        if (res.length === 0) {
          setHasMore(false);
        } else {
          setPageStart(pageStart + 1);
          if (businessEventData?.length) {
            setBusinessEventData([...businessEventData, ...res]);
          } else {
            setBusinessEventData([...res]);
          }

          setHasMore(true);
        }
      });

    }

  };

 
  const handleOnChange = (e) => {
    if (filterKey?.length == 0) {
      return;
    }
    setIsChecked(!isChecked);

    setLoader(true);
    setHasMore(false);

    let payload = {
      type: isSelectedService,
      redAllert: !isChecked ? "1" : "0",
      fromDate: moment(fromDate, "DD-MM-YYYY").format("YYYY-MM-DD"),
      toDate: moment(toDate, "DD-MM-YYYY").format("YYYY-MM-DD"),
    };
    if (payload.type == "5") {
      dispatch(getSurgerylist(payload)).then((res) => {
        setBusinessEventData([...res]);
        setSurgeryDetails(...res);
        setLoader(false);
        setFilterKey("");
      });

    } else {
      dispatch(getBusinessEvent(payload)).then((res) => {
        setBusinessEventData([...res]);
        setLoader(false);
        setFilterKey("");
      });
    }

  };

  console.log(surgeryDetails, "surgery123");

  const onClickSearch = () => {
    if (filterKey?.length == 0) {
      return;
    }
    setFilterKey("");
    setHasMore(false);
    let payload;
    if (filterKey?.length < 10) {
      payload = {
        patientOrderId: filterKey,
        type: isSelectedService,
        // fromDate: moment(fromDate, "DD-MM-YYYY").format("YYYY-MM-DD"),
        // toDate: moment(toDate, "DD-MM-YYYY").format("YYYY-MM-DD"),
      };
    } else {
      payload = {
        patientMobileNo: filterKey,
        type: isSelectedService,
        //fromDate: moment(fromDate, "DD-MM-YYYY").format("YYYY-MM-DD"),
        //toDate: moment(toDate, "DD-MM-YYYY").format("YYYY-MM-DD"),
      };
    }
    if (payload.isSelectedService == "5") {
      dispatch(getSurgerylist(payload)).then((res) => {
        setBusinessEventData([...res]);
      });
    } else {
      dispatch(getBusinessEvent(payload)).then((res) => {
        setBusinessEventData([...res]);
      });
    }

  };

   const updateComplete = () => {
  
    let payload = {
      type: isSelectedService,
      redAllert: !isChecked ? "1" : "0",
      fromDate: moment(fromDate, "DD-MM-YYYY").format("YYYY-MM-DD"),
      toDate: moment(toDate, "DD-MM-YYYY").format("YYYY-MM-DD"),
    };
    if (payload.type == "5") {
      dispatch(getSurgerylist(payload)).then((res) => {
        setBusinessEventData([...res]);
        setSurgeryDetails(...res);
        setLoader(false);
        setFilterKey("");
      });
    }
    // handleOnChange()
 
  }

console.log()
  const onKeyDownEnter = (e) => {
    if (e?.key === "Enter") {
      setFilterKey("");

      setHasMore(false);

      let payload;
      if (filterKey?.length < 10) {
        payload = {
          patientOrderId: filterKey,
          type: isSelectedService,
          //fromDate: moment(fromDate, "DD-MM-YYYY").format("YYYY-MM-DD"),
          //toDate: moment(toDate, "DD-MM-YYYY").format("YYYY-MM-DD"),
        };
      } else {
        payload = {
          patientMobileNo: filterKey,
          type: isSelectedService,
          //fromDate: moment(fromDate, "DD-MM-YYYY").format("YYYY-MM-DD"),
          //toDate: moment(toDate, "DD-MM-YYYY").format("YYYY-MM-DD"),
        };
      }

      if (payload.type == "5") {
        dispatch(getSurgerylist(payload)).then((res) => {
          setBusinessEventData([...res]);
        });

      } else {
        dispatch(getBusinessEvent(payload)).then((res) => {
          setBusinessEventData([...res]);
        });
      }

    }
  };

  const handleDropDownSelectStatus = (item) => {
    setStatus(item?.status);
    setPalaceHolder(item?.statusText);

    setLoader(true);
    let payload = {
      status: item?.status,
      fromDate: moment(fromDate, "DD-MM-YYYY").format("YYYY-MM-DD"),
      toDate: moment(toDate, "DD-MM-YYYY").format("YYYY-MM-DD"),
      type: isSelectedService,
      pageNo: "1",
      pageSize: "20",
    };
    if (payload.type == "5") {
      dispatch(getSurgerylist(payload)).then((res) => {
        setBusinessEventData([...res]);
        setBusinessEventDataExcel([...res]);
        setLoader(false);
      });
    } else {
      dispatch(getBusinessEvent(payload)).then((res) => {
        setBusinessEventData([...res]);
        setBusinessEventDataExcel([...res]);
        setLoader(false);
      });
    }

  };

  console.log(businessEventData, "surgery1")

  async function saveAsExcel() {

    console.log(
      "businessEventDataExcel",
      JSON.stringify(businessEventDataExcel)
    );



    const newArrOfObjects = businessEventDataExcel.map((obj) =>
      Object.keys(obj)
        .filter((e) => obj[e] !== null)
        .reduce((o, e) => {
          o[e] = obj[e];
          return o;
        }, {})
    );

    setTimeout(() => {
      onConvertJsonToExcelBusinessEvent(newArrOfObjects, isSelectedOption);
    }, 4000);
  }

  return (
    <>
      <div className="profilepage-breadcrumb"></div>

      <div className="titleBusinessEvent"> Select Filters & Services:</div>

      <div className="dateContainer">
        <div className="dateContainerSub">
          <DateView
            title="From Date"
            value={fromDate}
            placeholder="From Date"
            onClickDate={onClickDate}
          />

          <SearchView
            title="Search by Order Id/Mobile Number"
            onTextChange={onTextChange}
            onClickSearch={onClickSearch}
            onKeyDownEnter={onKeyDownEnter}
            filterKey={filterKey}
          />
        </div>


        <div className="dateContainerSub">

          <DateView
            title="To Date"
            value={toDate}
            placeholder="To Date"
            onClickDate={onClickToDate}
            fromDate={fromDate}
          />

          <DropDownService
            onArrowClick={handleDropDownClick}
            onOptionClick={handleDropDownSelect}
            options={[
              "Doctor Consultation",
              "Hospital Enquiry",
              "Medicine Order",
              "Lab Test",
              "Surgery",
            ]}
            placeholder="Select Service"
            style={{ marginTop: "20px" }}
          />
        </div>
      </div>

      <div className="dateContainer">
        <DropDownCancelReson
          style={{ marginTop: 20 }}
          onArrowClick={handleDropDownClick}
          onOptionClick={handleDropDownSelectStatus}
          options={statusOfBusiness}
          placeholder="Select Service"
          isPalaceHolder={isPalaceHolder}
        />

        <div className="topping">
          <input
            type="checkbox"
            id="topping"
            name="topping"
            value="alert"
            checked={isChecked}
            onChange={handleOnChange}
          />
          Filter By Red Alert
          <img src={Flag} alt={"Goto icon"} className="calenderFlag" />
        </div>
      </div>

      <p className="hospitalEnquiry">Service: {isSelectedOption}</p>

      <div className="hospitalEnquiryBody">
        {fromDate && toDate ? (
          <div className="selectedDateContainer">
            <img src={calender_img} alt={"Goto icon"} className="calenderImg" />
            <p className="selectDate">
              Selected date results from: {fromDate} to {toDate}
            </p>
          </div>
        ) : (
          <div></div>
        )}

        <div className="hospitalEnquiryBody">
          <p className="response">
            {" "}
            Total there are {businessEventData?.length} responses :
          </p>

          {businessEventDataExcel?.length > 0 && (
            <p className="downLoadFile" onClick={() => saveAsExcel()}>
              Download File
            </p>
          )}

        </div>
      </div>

      <div
        id="scrollableDiv"
        className="listNewContainer"
        style={{
          height: 800,
          overflow: "auto",
        }}
      >
        <InfiniteScroll
          dataLength={businessEventData.length}
          next={loadFunc}
          hasMore={hasMore}
          pullDownToRefreshThreshold={50}
          scrollableTarget="scrollableDiv"
        ></InfiniteScroll>

        <MedicineOrderList
          medicineList={businessEventData}
          doctorConsultation={doctorConsultation}
          isSelectedOption={isSelectedOption}
          onClickOrderIdMain={onClickOrderIdMain}
          onClickAddCommentMain={onClickAddCommentMain}
          onInFiniteScrollFinal={onInFiniteScrollFinal}
          isLoader={isLoader}
          hasMore={hasMore}
        />
      </div>

      {isDialogAppointment && isSelectedService == "5" ? (
        <SurgeryAppointmentDetails
          updateComplete={updateComplete}
          surgeryList={businessEventData}
          onClickYesBtn={onClickYesBtn}
          onClickCancelBtn={onClickCancelBtn}
          isSelectedOption={isSelectedOption}
          isDialog={isDialogAppointment}
          orderDetails={orderDetails}
          selectedItem={selectedItem}
          formActivity="business"
        //  onTextChange={onChangeTextRemarksnew}
        //  remarks={commentTextnew}
        />
      )
        : isDialogAppointment && (
          <DoctorAppointmentDetails
            onClickYesBtn={onClickYesBtn}
            onClickCancelBtn={onClickCancelBtn}
            isSelectedOption={isSelectedOption}
            isDialog={isDialogAppointment}
            orderDetails={orderDetails}
            selectedItem={selectedItem}
            formActivity="business"
          />
        )
      }

      {isDialog && (
        <DialogAddComment
          onClickYesBtn={onClickYesBtnDialog}
          onClickCancelBtn={onClickCancelBtnDialog}
          isDialog={isDialog}
          isApproveOrReject={true}
          onTextChange={onChangeTextRemarks}
          remarks={commentText}
        />
      )}
    </>
  );
};

export default BusinessEvent;
