import React, {  useState } from 'react'
import { useNavigate } from 'react-router-dom';
import floral from "../../Assets/Images/floral.svg"
import eyeIcon from "../../Assets/Images/eye.svg"
import doctorBg from "../../Assets/Images/docterSection.png"
import http from "../../../src/Redux/services/http-common.jsx"
import axios from 'axios'

function Index() {
    const navigate = useNavigate();
    const [emailId, setEmailId] = useState("")
    const [password, setPassword] = useState("");
    const [newpassword, setnewPassword] = useState("");
    const [errorMsg, setErrorMsg] = useState("");
    const [passwordType, setPasswordType] = useState(false)
    const [newpasswordType, setnewPasswordType] = useState(false)

    const handleSubmit = async (e) => {
        e.preventDefault();
        setErrorMsg('');
        if (emailId === "" || password === "" || newpassword === "") {
            setErrorMsg("Please fill required fields");
            return
        }
        http.post(`${process.env.REACT_APP_BASEURL}bo/changePassword`,{
            email: emailId,
            password:password,
            newPassword:newpassword,
        })
        .then((response) => {
            console.log(response.data, "sospost");
            navigate("/login", { replace: true })
          })
          .catch((error) =>  setErrorMsg(error?.response?.data?.details[0]));
    
    }
  return (
    <div style={{ display: "flex", height: "100vh", overflowY: "hidden" }}>
            <div className='login-bgSection'>
                {/* <img style={{ marginLeft: "auto", display: "block", padding: 30 }} src={cureBayLogo} /> */}
                <img style={{ width: "100%", height: "100%", transform: "scale(1.02) translateX(-1rem)" }} src={doctorBg} alt={"Doctor Bg"} />
            </div>
            <div className='login-formSection'>
                <div className='login-formSectionContainer'>
                    <h1 className='login-heading'>CureBay</h1>
                    <h3 className='login-tagline'>Improving Lives Together.</h3>
                    <img src={floral} width="200px" style={{ display: "block", margin: "auto" }} alt={"Floral"} />
                    <p className='login-subheading'>Please Log In</p>
                    <form className='login-formContainer' onSubmit={handleSubmit}>
                        <label htmlFor='emailId'>Enter Your Email Id</label>
                        <input type="text" id='emailId' placeholder='Enter Email Id' name='emailId' value={emailId}
                            onChange={(e) => setEmailId(e.target.value)} pattern="^[a-zA-Z0-9+_.-]+@[a-zA-Z0-9.-]+[.][a-zA-Z0-9.-]{2,}$" />

                        <label htmlFor='password'>Current Password</label>
                        <div style={{ position: "relative" }}>
                            <input placeholder='Enter Password' id="password"
                                type={passwordType ? "text" : "password"}
                                name='password'
                                value={password}
                                onChange={(e) => setPassword(e.target.value)} />

                            {password &&
                                <img
                                    onPointerDown={() => {
                                        setPasswordType(true)
                                    }}
                                    onPointerUp={() => {
                                        setPasswordType(false)
                                    }}
                                    style={{ cursor: "pointer", position: "absolute", right: 6, top: 5, borderRadius: 5, padding: 5, background: passwordType && "#004171" }} src={eyeIcon}
                                    alt="View Password"
                                />

                            }
                        </div>
                        <label htmlFor='newpassword'> New Password</label>
                        <div style={{ position: "relative" }}>
                            <input placeholder='New Password' id="password"
                                type={newpasswordType ? "text" : "password"}
                                name='password'
                                value={newpassword}
                                onChange={(e) => setnewPassword(e.target.value)} />

                            {newpassword &&
                                <img
                                    onPointerDown={() => {
                                        setnewPasswordType(true)
                                    }}
                                    onPointerUp={() => {
                                        setnewPasswordType(false)
                                    }}
                                    style={{ cursor: "pointer", position: "absolute", right: 6, top: 5, borderRadius: 5, padding: 5, background: newpasswordType && "#004171" }} src={eyeIcon}
                                    alt="View Password"
                                />

                            }
                        </div>
                        
                        <p className='login-ErrorMsg'>{errorMsg}</p>

                        <button type='submit' className='login-formBtn'>Save</button>
                    </form>
                </div>
            </div>
        </div>  
  )
}

export default Index