import React, { useState } from "react";
import { Dropdown } from "primereact/dropdown";
import image from "../../Assets/Images/MicrosoftTeams-image (1).png";
import axios from "axios";
import { Grid } from "@mui/material";
import http from "../../../src/Redux/services/http-common.jsx"

function SignInForm() {
  const [selectedRole, setSelectedRole] = useState(null);
  const cities = [
    { name: "USER", value: "USER" },
    { name: "ADMIN", value: "ADMIN" },
    { name: "MARKETING", value: "MARKETING" },
  ];

  const [userDetails, setUserDetails] = useState({
    firstName: "",
    lastName: "",
    email: "",
    password: "Welcome@123",
    role: selectedRole?.name,
  });

  const handleChange = (e) => {
    setUserDetails({ ...userDetails, [e.target.name]: e.target.value });
  };

  const handleOnSubmit = (evt) => {
    evt.preventDefault();

    http
      .post(`${process.env.REACT_APP_BASEURL}bo/save`, {
        ...userDetails,
        role: selectedRole,
      })
      .then((response) => {
        console.log(response.data, "sospost");
        // toast("Data Inserted successfully")
      })
      .catch((error) => console.error(error));
  };
  console.log(userDetails, "firstName");

  return (
    <div>
      <div style={{ display: "flex" }}>
        <div
          style={{
            margin: "0px 0px 0px 110px", width:"50%"
          }}
        >
          <form onSubmit={handleOnSubmit}>
            <h1 style={{ paddingTop: "100px" }}>Sign in</h1>{" "}
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                gap: "21px",
                paddingTop: "21px",
              }}
            >
              <div>
                <label style={{ fontSize: "21px", fontWeight: "700" }}>
                  First Name<span style={{ color: "red" }}> *</span>
                </label>
                <input
                  name="firstName"
                  className="custom-input"
                  type="text"
                  value={userDetails?.firstName}
                  onChange={(e) => handleChange(e)}
                  required
                />
              </div>
              <div className="grid">
                <label style={{ fontSize: "21px", fontWeight: "700" }}>
                  Last Name<span style={{ color: "red" }}> *</span>
                </label>
                <input
                  name="lastName"
                  className="custom-input"
                  type="text"
                  value={userDetails?.lastName}
                  onChange={(e) => handleChange(e)}
                  required
                />
              </div>
              <div className="grid pt-2 pb-6">
                <label style={{ fontSize: "21px", fontWeight: "700" }}>
                  Email<span style={{ color: "red" }}> *</span>
                </label>
                <input
                  name="email"
                  style={{ margin: "0px 0px 0px 112px" }}
                  className="custom-input"
                  type="text"
                  onChange={(e) => handleChange(e)}
                  value={userDetails?.email}
                  required
                />
              </div>
              {/* <div className="grid">
                <label style={{ fontSize: "21px", fontWeight: "700" }}>
                  Password<span style={{ color: "red" }}> *</span>
                </label>
                <input
                  type="password"
                  name="password"
                  style={{ margin: "0px 0px 0px 82px" }}
                  placeholder="Password"
                  className="custom-input"
                  value={userDetails?.password}
                  onChange={(e) => handleChange(e)}
                />
              </div> */}
              <div className="grid">
                <label style={{ fontSize: "21px", fontWeight: "700" }}>
                  Role<span style={{ color: "red" }}> *</span>
                </label>

                {/* <Dropdown
                  value={selectedRole}
                  onChange={(e) => setSelectedRole(e.value)}
                  options={cities}
                  optionLabel="name"
                  editable
                  placeholder="Select Role"
                  // className="w-full md:w-14rem"
                /> */}
                <select
  value={selectedRole}
  onChange={(e) => setSelectedRole(e.target.value)}
  className="custom-input"
  style={{width:"304px",marginLeft:"128px",fontSize:"20px"}}
 
>
  <option value=""  style={{ margin: "0px 0px 0px 112px" }}>Select Role</option>
  {cities.map(city => (
    <option key={city.id} value={city.value}>{city.name}</option>
  ))}
</select>

              </div>
            </div>
         
            <button
              style={{
                backgroundColor: "#004171",
                // focus: outline - none,
                border: "solid",
                height: "60px",
                borderRadius: "132px",
                width: "50%",
                color: "white",
                margin: "0px 0px 0px 82px",
                fontSize: "20px",
                marginTop:"20px"
              }}
            >
            Save
            </button>
          </form>
        </div>
        <div style={{width:"50%"}}>
          <img src={image}></img>
        </div>
      </div>
    </div>
  );
}

export default SignInForm;
