import React from 'react'
import "./dash.css";
import { useState } from 'react';
import MedicareDash from './MedicareDash';
import { Dropdown } from 'primereact/dropdown';
import moment from 'moment';


function Dash() {

  const [selectedBranch, setSelectedBranch] = useState("Totalcare");
  


  const dropdownOptions = [
    { label: 'EClinic', value: 'EClinic' },
    { label: 'B2C', value: 'B2C' },
    { label: 'Totalcare', value: 'Totalcare' },
   
  ];
  
  const currentDate = moment().format('DD MMMM YYYY');
  

  return (
    <>

    <div className=' ' style={{display:"flex",gap:"10px" ,backgroundColor:"white",padding:"10px" }}>
   
      <div>
       
      <img src='/images/calanderHeader.svg'/>
      </div>
      <div style={{marginTop:"10px"}}>Today {currentDate}</div>
      <div>
     

<Dropdown value={selectedBranch} onChange={(e) => setSelectedBranch(e.value)} options={dropdownOptions} optionLabel="label" 
                placeholder="Select Branch" optionValue="value" className="dropdowndashcss" />
      </div>
      
    </div>

    {/* <div className='frststpart'>
      <div className='text' style={{width:"70%"}}>
        <h1>Hi, Welcome </h1>
        <p></p>
      </div>
      <div className='imgdiv' style={{width:"30%"}}>
        <img src="./images/dash.svg" className='img' alt='#'/>
      </div>
    </div> */}

    <div>

    {/* <div className='secpart' >
      <div className='partdiv'>
        <h2 className='texthead'>Summary</h2>
        <div className='partdiv1'>
          
        <div className='part1'>
          <div style={{width:"40%"}}>
         <img src='./images/todo.svg'  style={{height:"40px",marginTop:"10px"}}/>
         </div>
         <div style={{width:"60%",display:"flex",gap:"20px"}}>
         <p>To do</p>
         <p>60</p>
         </div>
        
        </div>

        <div className='part1'>
        <div style={{width:"40%"}}>
        <img src='./images/pending.svg'style={{height:"40px",marginTop:"10px"}}/>
        </div>
        <div style={{width:"60%",display:"flex",gap:"20px"}}>
        <p>Pending</p>
         <p>15</p>
         </div>
        </div>

        <div className='part1'>
        <div style={{width:"40%"}}>
        <img src='./images/completed.svg'  style={{height:"50px"}}/> </div>
        <div style={{width:"60%",display:"flex",gap:"20px"}}>
        <p>Completed</p>
         <p>40</p>
         </div>
        
        </div>
        </div>
      </div>
      <div></div>
    </div> */}

    {/* <div className="catogorydiv" >
      <div className={`box ${selectedBox === 1 ? 'active' : ''}`} onClick={() => handleClick(1)} class="custom-btn btn-4">
        <div className='textdash'>
       Eclinic</div>
      </div>
      <div className={`box ${selectedBox === 2 ? 'active' : ''}`} onClick={() => handleClick(2)} class="custom-btn btn-4">
        <div className='textdash'>B2C</div>
      </div>
      <div className={`box ${selectedBox === 3 ? 'active' : ''}`} onClick={() => handleClick(3)} class="custom-btn btn-4">
      <div className='textdash'>MediCare</div>
      </div>
      </div> */}

      {/* <button class="custom-btn btn-4"><span>Click!</span><span>Read More</span></button> */}
      <div >
      { selectedBranch === 'B2C' && <img src="./images/comingsoon.png"/>}
      { selectedBranch === 'EClinic' && <img src="./images/comingsoon.png"/>}
      </div>
      <div >
      { selectedBranch === 'Totalcare' && <MedicareDash  />}
      </div>
    </div>

  

    {/* <div style={{ width: 700 }}>
        <LineChart chartData={userData} />
      </div>
     */}
    
    
    </>
    
  )
}

export default Dash