import { saveAs } from "file-saver";
import XlsxPopulate from "xlsx-populate";

export function onConvertJsonToExcelBusinessEvent(businessEventData, fileName) {

  let headerOfExcel = businessEventData[0]

  // delete headerOfExcel?.appointmentDate

  // let result =  Object.entries(headerOfExcel).reduce((a,[k,v]) => (v == null ? a : (a[k]=v, a)), {});



  //Object.entries(headerOfExcel).reduce((a,[k,v]) => (v === null ? a : (a[k]=v, a)), {})



  console.log('businessEventDatahai \n', JSON.stringify(businessEventData), "Type: \n", fileName)

  let exportArr = []
  let exportHeading

  for (let i = 0; i < businessEventData.length; i++) {
    let obj
    if (fileName == "Doctor Consultation") {
      exportHeading = ["patientId", "patientName", "patientEmailId","patientNumber","status","statusName",
      "procedureStatus","procedureStatusName","paymentDateTime","redAllert","paidAmount","pharmacyQuotedAmount"]
      obj = {
        patientId : businessEventData[i].patientId,
        patientName: businessEventData[i].patientName,
        patientEmailId: businessEventData[i].patientEmailId,
        patientNumber: businessEventData[i].patientNumber,
        status : businessEventData[i].status,
        statusName : businessEventData[i].statusName,
        procedureStatus : businessEventData[i].procedureStatus,
        procedureStatusName : businessEventData[i].procedureStatusName,
        paymentDateTime : businessEventData[i].paymentDateTime,
        redAllert : businessEventData[i].redAllert,
        paidAmount : businessEventData[i].paidAmount,
        pharmacyQuotedAmount : businessEventData[i].pharmacyQuotedAmount,

      }

    } else if (fileName == "Hospital Enquiry") {
      exportHeading = ["patientOrderId", "patientId", "patientName","patientEmailId","patientNumber","patientProcedureName",
      "status","statusName","consultationsReason","paymentDateTime","hospitalId","hospitalName","locationId","redAllert"]
      obj = {
        patientOrderId : businessEventData[i].patientOrderId,
        patientId: businessEventData[i].patientId,
        patientName: businessEventData[i].patientName,
        patientEmailId: businessEventData[i].patientEmailId,
        patientNumber : businessEventData[i].patientNumber,
        patientProcedureName : businessEventData[i].patientProcedureName,
        status : businessEventData[i].status,
        statusName : businessEventData[i].statusName,
        consultationsReason : businessEventData[i].consultationsReason,
        paymentDateTime : businessEventData[i].paymentDateTime,
        hospitalId : businessEventData[i].hospitalId,
        hospitalName : businessEventData[i].hospitalName,
        locationId : businessEventData[i].locationId,
        redAllert : businessEventData[i].redAllert,

      }
    } else if (fileName == "Surgery") {
      exportHeading = ["id", "patientId", "patientName", "surgeryName","mobileNo","state","city","status","createdDate","sourceChannel"]
      obj = {
        id:businessEventData[i].id,
        patientId : businessEventData[i].patientId,
        patientName: businessEventData[i].patientName,
        surgeryName: businessEventData[i].surgeryName,
        mobileNo: businessEventData[i].mobileNo,
        state : businessEventData[i].state,
        city : businessEventData[i].city,
        status : businessEventData[i].status,
        createdDate : businessEventData[i].createdDate,
        sourceChannel : businessEventData[i].sourceChannel,

      }
    } else if (fileName == "Lab Test") {
      exportHeading = ["patientOrderId", "patientId", "patientName", "patientEmailId","patientNumber","patientPlannedDate","status","statusName","procedureStatus",
      "procedureStatusName","paymentDateTime","hospitalId","hospitalName","locationId","locationName","redAllert","paidAmount","preferTime"]
      obj = {
        patientOrderId:businessEventData[i].patientOrderId,
        patientId : businessEventData[i].patientId,
        patientName: businessEventData[i].patientName,
        patientEmailId: businessEventData[i].patientEmailId,
        patientNumber: businessEventData[i].patientNumber,
        patientPlannedDate : businessEventData[i].patientPlannedDate,
        status : businessEventData[i].status,
        statusName : businessEventData[i].statusName,
        procedureStatus : businessEventData[i].procedureStatus,
        procedureStatusName : businessEventData[i].procedureStatusName,
        paymentDateTime : businessEventData[i].paymentDateTime,
        hospitalId : businessEventData[i].hospitalId,
        hospitalName : businessEventData[i].hospitalName,
        locationId : businessEventData[i].locationId,
        locationName : businessEventData[i].locationName,
        redAllert : businessEventData[i].redAllert,
        paidAmount : businessEventData[i].paidAmount,
        preferTime : businessEventData[i].preferTime,

      }
    }
    else if (fileName == "Medicine Order") {
      exportHeading = ["patientOrderId", "patientId", "patientName","patientEmailId","patientNumber","status",
      "statusName","procedureStatus","procedureStatusName","paymentDateTime","redAllert","paidAmount","pharmacyQuotedAmount"]
      obj = {
        patientOrderId : businessEventData[i].patientOrderId,
        patientId: businessEventData[i].patientId,
        patientName: businessEventData[i].patientName,
        patientEmailId: businessEventData[i].patientEmailId,
        patientNumber : businessEventData[i].patientNumber,
        status : businessEventData[i].status,
        statusName : businessEventData[i].statusName,
        procedureStatus : businessEventData[i].procedureStatus,
        procedureStatusName : businessEventData[i].procedureStatusName,
        paymentDateTime : businessEventData[i].paymentDateTime,
        redAllert : businessEventData[i].redAllert,
        paidAmount : businessEventData[i].paidAmount,
        pharmacyQuotedAmount : businessEventData[i].pharmacyQuotedAmount,

      }
    }

    exportArr.push(obj)

  }

  console.log('EXPORT ARRAY DATA: \n', JSON.stringify(exportArr))

  const keys = Object.keys(headerOfExcel)
  let header = [...keys];

  XlsxPopulate.fromBlankAsync().then(async (workbook) => {
    const sheet1 = workbook?.sheet(0);
    const sheetData = getSheetData(exportArr, exportHeading);
    const totalColumns = sheetData[0]?.length;
    sheet1.cell("A1").value(sheetData);
    const range = sheet1.usedRange();
    const endColumn = String.fromCharCode(64 + totalColumns);
    sheet1.row(1).style("bold", true);
    sheet1.range("A1:" + endColumn + "1").style("fill", "BFBFBF");
    range.style("border", true);
    return workbook.outputAsync().then((res) => {
      saveAs(res, fileName + new Date()?.getTime() + "file.xlsx");
    });
  });
}

function getSheetData(data, header) {
  var fields = Object?.keys(data[0]);
  var sheetData = data?.map(function (row) {
    return fields.map(function (fieldName) {
      if (fieldName)
        return row[fieldName] ? row[fieldName] : "";
    });
  });
  sheetData.unshift(header);
  return sheetData;
}
