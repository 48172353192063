import moment from 'moment';

export function getLocalTime(time){
    if(time===null)
    return ""
    
    let localTime=""
    localTime =  moment?.utc(time)?.local().format("DD-MM-yyyy HH:mm:ss")
    return localTime !==""?localTime : moment?.utc(new Date())?.local().format("DD-MM-yyyy HH:mm:ss");
}


export function getLocalOnlyDate(time){

    if(time===null)
    return ""

    let localTime=""
    localTime =  moment?.utc(time)?.local().format("DD-MM-yyyy")
    return localTime !==""?localTime : moment?.utc(new Date())?.local().format("DD-MM-yyyy");
}
