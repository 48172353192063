import React, { useState, useEffect } from "react";
import SellectedDropDown from "../../Assets/Images/SellectedDropDown.svg";
import UnsellectedDropDown from "../../Assets/Images/UnsellectedDropDown.svg";
import DownArrow from "../../Assets/Images/DownArrow.svg";
import "./index.css";

export default function DropDownComponent({
  onArrowClick,
  onOptionClick,
  options,
  placeholder,
  style,
  isPalaceHolder
}) {
  const [isDropDownOpen, setisDropDownOpen] = useState(false);
  const [sellectedOption, setsellectedOption] = useState(isPalaceHolder);

  const arrowClickHandler = () => {
    onArrowClick(!isDropDownOpen);
    setisDropDownOpen(!isDropDownOpen);
  };

  const optionsClickHandler = (option) => {
    //setsellectedOption(option.statusText);
    onOptionClick(option);
    setisDropDownOpen(false);
  };

  useEffect(() => {
    //setsellectedOption(placeholder);
  }, [placeholder])


  return (
    <>
      <div className="dropdownTopContainer" style={{ ...style }}>
        <div className="dropdownContainer" onClick={arrowClickHandler}>
          {/* <p className="dropdownText">{placeholder}</p> */}
          <p className="dropdownText">{isPalaceHolder}</p>
          <div className="dropdownArrowContainer">
            <img className="dropdownDownArrow" src={DownArrow} alt={"Dropdown Down Arrow"} />
          </div>
        </div>
        {isDropDownOpen ? (
          <div className="dropdownOptionsContainer">
            {options.map((option, index) => {
              return (
                <div
                  key={index}
                  className="dropDownListContainer"
                  onClick={() => {
                    optionsClickHandler(option);
                  }}
                >
                  {option.statusText === isPalaceHolder ? (
                    <img src={SellectedDropDown} alt={"Sellected DropDown Item"} />
                  ) : (
                    <img src={UnsellectedDropDown} alt={"Unsellected DropDown Item"} />
                  )}

                  {option?.statusText}
                </div>
              );
            })}
          </div>
        ) : null}
      </div>
      {isDropDownOpen ? (
        <div
          className="overlayForDropdown"
          onClick={() => {
            setisDropDownOpen(false);
          }}
        ></div>
      ) : null}
    </>
  );
}
