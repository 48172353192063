import React from "react";
import "./index.css";
import date_icon from "../../Assets/Images/date_icon.svg";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import moment from "moment";

function searchView({ onClickDate, value, placeholder,fromDate }) {
  const changeDate = (e) => {
    console.log(JSON.stringify(fromDate));
    onClickDate(moment(e).format("DD-MM-yyyy"));
  };

  return (
    <div className="border-container">
      <DatePicker
        id="fromDate"
        name="fromDate"
        dropdownMode="select"
        showMonthDropdown
        showYearDropdown
        maxDate={ fromDate===undefined? new Date():null}
        dateFormat="DD-MM-yyyy"
        minDate={fromDate!=undefined?new Date(moment(fromDate,'DD-MM-YYYY').format('MM-DD-YYYY')) :null}

        
        value={value}
        onSelect={changeDate}
        disabledKeyboardNavigation={true}
        autoFocus={false}
        autoComplete="off"
        placeholderText={placeholder}
        className="placeholderDate"
      />

      <img src={date_icon} alt={"Goto icon"} className="navImg" />
    </div>
  );
}

export default searchView;
