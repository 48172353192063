import React from 'react';
import DatePicker from 'react-datepicker';
import { useRef } from "react";
import 'react-datepicker/dist/react-datepicker.css';
import { Dropdown } from 'primereact/dropdown';
import { Calendar } from 'primereact/calendar';
import { useState } from 'react';
import "./sos.css";
import axios from 'axios';
import moment from 'moment';
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { OverlayPanel } from 'primereact/overlaypanel';
import http from "../../../Redux/services/http-common"


function PopupSos({ onHide }) {
    const [date, setDate] = useState(null);
    const [selectedDate, setSelectedDate] = useState(null);
    const [selectedDate1, setSelectedDate1] = useState(null);
    const [selectedStatus, setSelectedStatus] = useState(null);
    const [searchName, setSearchName] = useState('');

    const op = useRef(null);
    const status = [
        { name: 'Pending', code: 'NY' },
        { name: 'Completed', code: 'RM' },

    ];
    const [sosaddSearch, setSosaddSearch] = useState('')
    const [severity,setSeverity] = useState('')
    const [formdata, setFormData] = useState({
        // patientName: sosaddSearch.receiverName,
        // age: sosaddSearch.receiverAge,
        // patientMobile: sosaddSearch.receiverMobile,
        // patientAddress: sosaddSearch.address,
        issue: " ",
        remarks: "",

        status: "In Progress",



    })

    const handleSubmit = (e, rowbody) => {
        console.log(rowbody.receiverName, rowbody.receiverAge, rowbody.receiverMobile, rowbody.address, "event dataaaaa")
        const currentDate = new Date();
        e.preventDefault();
        console.log(formdata, "event data")
        http.post(`${process.env.REACT_APP_BASEURL}medicare/sos/`, {
            ...formdata,
            patientName: rowbody.receiverName,
            age: rowbody.receiverAge,
            patientMobile: rowbody.receiverMobile,
            patientAddress: rowbody.address,
            createdDate: currentDate.toISOString().substring(0, 10),
            severity:severity
        })
            .then((response) => {
                console.log(response.data, "sospost")
                toast("Data Inserted successfully")
                onHide(e)
            })
            .catch((error) => console.error(error));
    };

    const handleChange = (e) => {

        setFormData({ ...formdata, [e.target.name]: e.target.value });
    };

    const handleSearch = (e) => {
        e.preventDefault();
        console.log(e, "item coming");

        http.get(`${process.env.REACT_APP_BASEURL}medicare/sos/search/name?receiverName=${searchName}`)

            .then((res) => {
                console.log(res.data, "search")
                setSosaddSearch(res.data);

            })


            .catch((err) => {

                console.log(err, "Something went wrong");

            })

        http.get(`${process.env.REACT_APP_BASEURL}medicare/sos/search/mobile?receiverMobile=${searchName}`)

            .then((res) => {
                console.log(res.data, "search2")
                setSosaddSearch(res.data);

            })


            .catch((err) => {

                console.log(err, "Something went wrong");

            })
        console.log(sosaddSearch, "setSosaddSearch");


    }




    console.log(severity, "hello")
    // const onTextChange = (filterKey) => {
    //     setFilterKey(filterKey);
    // };


    return (
        <>
            <ToastContainer />

            <div>
                {/* searchbar details */}
                <form>
                    <div style={{ display: "flex", gap: "20px" }}>
                        <div className='searchbar' style={{ marginBottom: "20px" }} title="Search by Name and number">



                            <input type="text" className='placeholder'
                                value={searchName}
                                onChange={(e) => setSearchName(e.target.value)}
                                placeholder="Search by Name/ Mobileno." name="search"></input>

                            <i class="fa fa-search" aria-hidden="true" className='navImg'></i>



                        </div>
                        <button className="buttn" type="submit" onClick={(e) => handleSearch(e)}>Search</button>
                    </div>

                </form>
                {/* table */}

                <div className="card">
                    <DataTable value={sosaddSearch} tableStyle={{ minWidth: '50rem' }}>
                        <Column field="receiverCode" header="Code"></Column>
                        <Column field="receiverName" header="Name"></Column>
                        <Column field="receiverMobile" header="Mobile No."></Column>
                        <Column field="receiverAge" header="Age"></Column>
                        <Column field="address" header="Address"></Column>

                        <Column
                            body={(rowbody) => (

                                <>
                                    <span onClick={(e) => op.current.toggle(e)}>
                                        <i class="fa fa-plus-circle plush" aria-hidden="true" ></i></span>
                                    {/* <span label="Open Popup"  style={{fontSize:"14px"}} > </span> */}
                                    <OverlayPanel ref={op} style={{ width: "400px" }}>
                                        <form onSubmit={(e) => handleSubmit(e, rowbody)} onChange={handleChange}>
                                            <div>
                                                <label className='labelcss' >Remarks :</label>
                                                <textarea
                                                    name='remarks'
                                                    value={formdata.remarks}
                                                    onChange={handleChange}
                                                    className="inputpatientname_textarea"
                                                ></textarea>
                                            </div>

                                            <div>
                                                <div>
                                                    <label className='labelcss'>Issue Type :</label>

                                                    <textarea
                                                        name='issue'
                                                        value={formdata.issue}
                                                        onChange={handleChange}
                                                        className="inputpatientname_textarea"
                                                    ></textarea></div>
                                            </div>

                                            <div>
                                                <div>
                                                    <label className='labelcss' >Severity :</label>
                                                </div>
                                                <div>

                                                    <select style={{ fontSize: "14px", padding: "8px 5px" }} className='inputpatientname_dropdown' name="Severity" onChange={(e)=>setSeverity(e.target.value)} >
                                                        <option select>Select Severity option</option>

                                                        <option value="High">High</option>
                                                        <option value="Low">Low</option>
                                                        <option value="Medium">Medium</option>

                                                    </select>


                                                </div>
                                                <div style={{ marginTop: "10px", marginBottom: "15px", float: "right" }}>
                                                    <button className='addbtn'>ADD</button>
                                                </div>

                                            </div>
                                        </form>

                                    </OverlayPanel>
                                </>
                            )}
                        >

                        </Column>
                    </DataTable>
                </div>

            </div>


        </>
    )
}

export default PopupSos