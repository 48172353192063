import "./index.css";
import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";

import DateView from "../../components/DateView/dateView";
import SearchView from "../../components/SearchView/searchView";
import calender_img from "../../Assets/Images/calender_img.svg";
import MedicineOrderList from "./MedicineOrderList";
import DropDownService from "../../components/DropDownService";
import DropDownCancelReson from "../../components/DropDownCancelReson";
import DialogRefundMoney from "../../components/DialogRefundMoney/dialogRefundMoney";
import DialogAddComment from "../../components/DialogAddComment/dialogAddComment";

import {
  getInputEvent,
  onRefundProcess,
} from "../../Redux/Actions/dashboardaction";
import moment from "moment";
import DoctorAppointmentDetails from "../../components/DoctorAppointmentDetails/DoctorAppointmentDetails";

import { getOrderDetails,onAddComment } from "../../Redux/Actions/dashboardaction";

import InfiniteScroll from "react-infinite-scroll-component";
import {onConvertJsonToExcel} from '../../utils/JsonToExcelConverter'

const InboxEvent = () => {
  const [isSelectedOption, setSelectedOption] = useState("Doctor Consultation");
  const [fromDate, setFromDate] = useState(
    moment().add(-1, "days").format("DD-MM-yyyy")
  );
  const [toDate, setToDate] = useState(
    moment().add("days").format("DD-MM-yyyy")
  );


  
  const [isDialogComment, setDialogViewComment] = useState(false);
  const [isLoader,setLoader]=useState(false)
  const dispatch = useDispatch();
  const [isSelectedService, setSelectedService] = useState("DOCTOR");
  const [isApproveOrReject, setApproveOrReject] = useState("Approve");
  const [isApproveOrRejectPlaceHolder, setApproveOrRejectPlaceHolder] =
    useState("Select");
  const [inputEventData, setInputEventData] = useState([]);
  const [inputEventDataExcel, setInputEventDataExcel] = useState([]);
  const [onSelectedData, setSelectedData] = useState();
  
  const [isDialogAppointment, setDialogAppointment] = useState(false);
  const [orderDetails, setOrderDetails] = useState();
  const [isPositionOption, setPositionOption] = useState(0);
  const [remarks, setRemarks] = useState("");
  const [selectedItem, setSelectedItem] = useState("");
  const [commentText, setCommentText] = useState("");
  const [isDialog, setDialogView] = useState(false);
  const [filterKey, setFilterKey] = useState("");
  const [status, setStatus] = useState("Pending");

  const [hasMore, setHasMore] = useState(true);
  const [pageStart, setPageStart] = useState(1);



  const onClickYesBtn = () => {
    setDialogView(false);

    setApproveOrRejectPlaceHolder("Select");
    inputEventData[isPositionOption].placeholder = "Select";

    let payload = {
      merchantTokenID: Math.random().toString().slice(2, 11),
      amount: onSelectedData.totalAmount !=undefined?onSelectedData.totalAmount:onSelectedData.paidAmount,
      serviceType: isSelectedService === "DOCTOR" ? "doctor" : "labtest",
      orderId: onSelectedData?.orderId,
      remarks: remarks,
      action: isApproveOrReject,
    };

    dispatch(onRefundProcess(payload)).then((res) => {
      console.log("resresres", JSON.stringify(res));

      getEventApi(
        isSelectedOption === "Doctor Consultation" ? "DOCTOR" : "LABTEST"
      );
    });
  };

  const onClickCancelBtn = () => {
    setDialogView(false);
    setApproveOrRejectPlaceHolder("Select");
    inputEventData[isPositionOption].placeholder = "Select";
  };

  const onRefundMoneyBtnMain = () => {
    setDialogView(true);
  };

  const [doctorConsultation, setDoctorConsultation] = useState([
    "OrderID",
    "Patient Details",
    "Doctor Details",
    "Appointment Time",
    "Consultation",
    "Status",
    "Action",
  ]);

  const doctConsultation = [
    "OrderID",
    "Patient Details",
    "Doctor Details",
    "Appointment Time",
    "Consultation",
    "Status",
    "Action",
  ];

  const hospitalEnquiry = [
    "Patient Name",
    "Treatment Name",
    "Patient Statement",
    "Admit Date",
    "Add Remark",
  ];

  const medicineOrder = [
    "OrderID",
    "Patient Details",
    "Service Provider",
    "Order Info",
    "Transaction Date",
    "Add Remark",
  ];

  const LabTest = [
    "OrderID",
    "Patient Details",
    "Lab Details",
    "Location Details",
    "Planned Date",
    "Status",
    "Action",
  ];

  const onClickDate = (e) => {
    setFromDate(e);
    setToDate();
  };

  const onClickToDate = (e) => {
    setToDate(e);


    setLoader(true)
    let payload = {
      fromDate:moment(fromDate, 'DD-MM-YYYY').format('YYYY-MM-DD'),
      toDate: moment(e, 'DD-MM-YYYY').format('YYYY-MM-DD'),
      serviceType: isSelectedService,
      pageNo: "1",
      pageSize: "20",
      status: status,

    };

    dispatch(getInputEvent(payload)).then((res) => {
      getDataForExcel(e)
      if(isSelectedService==="DOCTOR"){
        setInputEventData([...res?.doctorAppointments]);
      }else{
        setInputEventData([...res?.labTestOrders]);
      }
      setLoader(false)
    });
  };

  const getDataForExcel=(e)=>{

    let payload = {
      fromDate:moment(fromDate, 'DD-MM-YYYY').format('YYYY-MM-DD'),
      toDate: moment(e, 'DD-MM-YYYY').format('YYYY-MM-DD'),
      serviceType: isSelectedService,
      status: status,
      pageNo: "1",
    };

    dispatch(getInputEvent(payload)).then((res) => {
      if(isSelectedService==="DOCTOR"){
        setInputEventDataExcel([...res?.doctorAppointments]);
      }else{
        setInputEventDataExcel([...res?.labTestOrders]);
      }
      setLoader(false)
    });


  }

  const handleDropDownClick = () => {};

  const handleDropDownSelect = (item) => {
    setSelectedOption(item);

    setPageStart(1)
    setHasMore(true)

    if (item === "Doctor Consultation") {
      setDoctorConsultation(doctConsultation);

      getEventApi("DOCTOR");
    } else if (item === "Hospital Enquiry") {
      setDoctorConsultation(hospitalEnquiry);
    } else if (item === "Medicine Order") {
      setDoctorConsultation(medicineOrder);
    } else {
      setDoctorConsultation(LabTest);
      getEventApi("LABTEST");
    }
  };

  const handleDropDownSelectStatus=(item)=>{

    setStatus(item)

     setLoader(true)
    let payload = {
      fromDate:moment(fromDate, 'DD-MM-YYYY').format('YYYY-MM-DD'),
      toDate: moment(toDate, 'DD-MM-YYYY').format('YYYY-MM-DD'),
      serviceType:isSelectedService,
      pageNo: "1",
      pageSize: "20",
      status:item

    };

    dispatch(getInputEvent(payload)).then((res) => {

      if(isSelectedService==="DOCTOR"){
        setInputEventData([...res?.doctorAppointments]);
      }else{
        setInputEventData([...res?.labTestOrders]);
      }


      setLoader(false)
    });
   
  }

  useEffect(() => {
    setLoader(true)
    let payload = {
      fromDate:moment(fromDate, 'DD-MM-YYYY').format('YYYY-MM-DD'),
      toDate: moment(toDate, 'DD-MM-YYYY').format('YYYY-MM-DD'),
      serviceType: "DOCTOR",
      pageNo: "1",
      pageSize: "20",
      status:status

    };

    dispatch(getInputEvent(payload)).then((res) => {

      setInputEventData([...res?.doctorAppointments]);
      getDataForExcel(toDate)
      setLoader(false)
    });
  }, [dispatch]);

  const getEventApi = (pageNo) => {

    setInputEventData([]);
    setSelectedService(pageNo);
    let payload = {
      serviceType: pageNo,
      fromDate:moment(fromDate, 'DD-MM-YYYY').format('YYYY-MM-DD'),
      toDate: moment(toDate, 'DD-MM-YYYY').format('YYYY-MM-DD'),
      pageNo: "1",
      pageSize: "20",
      status: status,
      
    };

    dispatch(getInputEvent(payload)).then((res) => {
      getDataForExcel(toDate)
      if (pageNo === "DOCTOR") {
       
        setInputEventData([...res?.doctorAppointments]);
      } else if (pageNo === "LABTEST") {
       
        setInputEventData([...res?.labTestOrders]);
      } else {
      
        setInputEventData([]);
      }
    });
  };

  const handleDropDownSelectBtn = (select, object, index) => {
    setRemarks("");
    inputEventData[index].placeholder = select;
    setPositionOption(index);
    setApproveOrRejectPlaceHolder(select);
    setApproveOrReject(select);
    setDialogView(true);
    setSelectedData(object);
    setInputEventData([...inputEventData]);
  };

  const onClickOrderIdMain = (item) => {
    setOrderDetails();
    setDialogAppointment(true);
    setSelectedItem(item)
    let payload = {
      patientOrderId: item?.orderId,
      type: isSelectedService === "DOCTOR" ? "3" : "2",
    };
    dispatch(getOrderDetails(payload)).then((res) => {
      setOrderDetails(res);
    });
  };

  const onTextChange = (filterKey) => {
  
    setFilterKey(filterKey);
  };
  const onChangeTextRemarks = (remarks) => {
    setRemarks(remarks);
  };

  

  const onClickCancelBtnDetals = () => {
    setDialogAppointment(false);
  };



  const onClickAddCommentMain = (item) => {
    setDialogViewComment(true);

    setSelectedItem(item)
  };


  const onChangeTextDialog = (text) => {
    setCommentText(text);
  };

  const onClickYesBtnDialog = () => {

    let userData = JSON.parse(localStorage.getItem("userData"));
    let payload = {
      orderId: selectedItem?.orderId,
      serviceType:
        isSelectedOption === "Doctor Consultation"
          ? "Doctor"
          : isSelectedOption === "Hospital Enquiry"
          ? "Hospital"
          : isSelectedOption === "Medicine Order"
          ? "Medicine"
          : "LabTest",
      action: "comment",
      remarks: commentText,
      userId: userData?.firstName +" "+ userData?.lastName,

    };

    dispatch(onAddComment(payload)).then((res) => {

      setDialogViewComment(false)
     
    });


  };

  const onClickCancelBtnDialog = () => {
    setDialogViewComment(false);
  };


  const onClickSearch=()=>{

    if(filterKey?.length==0){
      return
    }

    setLoader(true)
    setHasMore(false)
    setFilterKey("")
   
    let payload ;
    if(filterKey?.length<10){
      payload = {
        orderId: filterKey,
        serviceType: isSelectedService,
      //  fromDate:moment(fromDate, 'DD-MM-YYYY').format('YYYY-MM-DD'),
       // toDate: moment(toDate, 'DD-MM-YYYY').format('YYYY-MM-DD'),
        pageNo: "1",
        pageSize: "20",
        status: status,
      
      };
    }else{
      payload = {
        mobileNo: filterKey,
        serviceType: isSelectedService,
       // fromDate:moment(fromDate, 'DD-MM-YYYY').format('YYYY-MM-DD'),
       // toDate: moment(toDate, 'DD-MM-YYYY').format('YYYY-MM-DD'),
        pageNo: "1",
        pageSize: "20",
        status: status,
      
      };

    }
    dispatch(getInputEvent(payload)).then((res) => {

      

      setLoader(false)
      if ( isSelectedOption === "Doctor Consultation") {
        setInputEventData([...res?.doctorAppointments]);
      } else {
       
        setInputEventData([...res?.labTestOrders]);
      }
      
    });
    
  }


  const onKeyDownEnter=(e)=>{
    if(filterKey?.length==0){
      return
    }

    if(e?.key==="Enter"){
      setLoader(true)

      setHasMore(false)
      setFilterKey("")
   
    let payload ;
    if(filterKey?.length<10){
      payload = {
        orderId: filterKey,
        serviceType: isSelectedService,
       // fromDate:moment(fromDate, 'DD-MM-YYYY').format('YYYY-MM-DD'),
        //toDate: moment(toDate, 'DD-MM-YYYY').format('YYYY-MM-DD'),
        pageNo: "1",
        pageSize: "20",
        status: status,
      
      };
    }else{
      payload = {
        mobileNo: filterKey,
        serviceType: isSelectedService,
       // fromDate:moment(fromDate, 'DD-MM-YYYY').format('YYYY-MM-DD'),
       // toDate: moment(toDate, 'DD-MM-YYYY').format('YYYY-MM-DD'),
        pageNo: "1",
        pageSize: "20",
        status: status,
      
      };

    }
    dispatch(getInputEvent(payload)).then((res) => {
      setLoader(false)

      if ( isSelectedOption === "Doctor Consultation") {
        setInputEventData([...res?.doctorAppointments]);
      } else {
       
        setInputEventData([...res?.labTestOrders]);
      }
    });
    }
  }


  const loadFunc = async () => {

    let payload = {
      fromDate:moment(fromDate, 'DD-MM-YYYY').format('YYYY-MM-DD'),
        toDate: moment(toDate, 'DD-MM-YYYY').format('YYYY-MM-DD'),
      pageNo: pageStart+1,
      pageSize: "20",
      serviceType: isSelectedService,
      status: status,
    };

    dispatch(getInputEvent(payload)).then((res) => {
      if (res.length === 0) {
        setHasMore(false);
      } else {
        setPageStart(pageStart + 1);

        if(isSelectedService === "DOCTOR" ){
          if (inputEventData?.length) {
            setInputEventData([...inputEventData, ...res.doctorAppointments]);
          } else {
            setInputEventData([...res.doctorAppointments]);
          }
        }else{
          if (inputEventData?.length) {
            setInputEventData([...inputEventData, ...res.labTestOrders]);
          } else {
            setInputEventData([...res.labTestOrders]);
          }
        }
        setHasMore(true);
      }
    });


  }

  async function saveAsExcel() {
    onConvertJsonToExcel(inputEventDataExcel,"Refund"+isSelectedOption)
  }


  return (
    <>
      <div className="profilepage-breadcrumb"></div>

      <div className="titleBusinessEvent"> Select Filters & Services:</div>

      <div className="dateContainer">
        <div className="dateContainerSub">
          <DateView
            title="From Date"
            value={fromDate}
            placeholder="From Date"
            onClickDate={onClickDate}
          />

          <SearchView
            title="Order Id/Mobile Number"
            onTextChange={onTextChange}
            onClickSearch={onClickSearch}
            onKeyDownEnter={onKeyDownEnter}
            filterKey={filterKey}
          />


      <DropDownCancelReson style={{marginTop:20}}
            onArrowClick={handleDropDownClick}
            onOptionClick={handleDropDownSelectStatus}
            options={[
              "Pending",
              "Refund Successfully",
              "Refund Queue",
              "Refund Initiated",
              "Rejected from Back Office",
            ]}
            placeholder="Select Service"
          />
        </div>

        <div className="dateContainerSub">
          <DateView
            title="To Date"
            value={toDate}
            placeholder="To Date"
            onClickDate={onClickToDate}
            fromDate={fromDate}
          />
           
          <DropDownService
            onArrowClick={handleDropDownClick}
            onOptionClick={handleDropDownSelect}
            options={[
              "Doctor Consultation",
              // "Hospital Enquiry",
              // "Medicine Order",
              "Lab Test",
            ]}
            placeholder="Select Service"
          />
        </div>

        
      </div>
      <div>
        <p className="hospitalEnquiry">Service: {isSelectedOption}</p>

        <div className="hospitalEnquiryBody">
          <div className="selectedDateContainer">
            <img src={calender_img} alt={"Goto icon"} className="calenderImg" />
            <p className="selectDate">
              Selected date results from: {fromDate} to {toDate}
            </p>
          </div>
          
          <div className="hospitalEnquiryBody">
        <p className="response">
          {" "}
          Total there are {inputEventData?.length} responses :
        </p>

        {
          inputEventDataExcel?.length>0 &&
          <p className="downLoadFile" onClick={()=>saveAsExcel()}>
           Download File
        </p>
        }

        </div>
        </div>

        <div
        id="scrollableDiv"
        className="listNewContainer"
        style={{
          height: 800,
          overflow: "auto",
        }}
      >
        <InfiniteScroll
          dataLength={inputEventData.length}
          next={loadFunc}
          hasMore={hasMore}
          pullDownToRefreshThreshold={50}
          scrollableTarget="scrollableDiv"
        ></InfiniteScroll>
          <MedicineOrderList
            medicineList={inputEventData}
            status={status}
            doctorConsultation={doctorConsultation}
            isSelectedOption={isSelectedOption}
            onRefundMoneyBtnMain={onRefundMoneyBtnMain}
            handleDropDownSelectBtn={handleDropDownSelectBtn}
            isApproveOrRejectPlaceHolder={isApproveOrRejectPlaceHolder}
            onClickOrderIdMain={onClickOrderIdMain}
            onClickAddCommentMain={onClickAddCommentMain}
            isLoader={isLoader}
          />
        </div>
      </div>

      {isDialog && (
        <DialogRefundMoney
          onClickYesBtn={onClickYesBtn}
          onClickCancelBtn={onClickCancelBtn}
          isDialog={isDialog}
          isApproveOrReject={isApproveOrReject}
          onTextChange={onChangeTextRemarks}
          remarks={remarks}
        />
      )}

      {isDialogAppointment && (
        <DoctorAppointmentDetails
        
          onClickCancelBtn={onClickCancelBtnDetals}
          isSelectedOption={isSelectedOption}
          isDialog={isDialogAppointment}
          orderDetails={orderDetails}
          selectedItem={selectedItem}
         isLoader={isLoader}

        />
      )}

      {isDialogComment && (
        <DialogAddComment
          onClickYesBtn={onClickYesBtnDialog}
          onClickCancelBtn={onClickCancelBtnDialog}
          isDialog={isDialogComment}
          isApproveOrReject={true}
          onTextChange={onChangeTextDialog}
          remarks={commentText}
        />
      )}
    </>
  );
};

export default InboxEvent;
