import "./index.css";
import React, { useState, useEffect } from "react";

import userImage from "../../Assets/Images/iv_user.svg";
import InputWithTitleView from "../../components/InputViewTitle/searchView";

import DropDownWithTitle from "../../components/DropDownWithTitle/dropDownView";

const Profile = () => {

  const [userName, setUserName] = useState("")

  useEffect(() => {
    let userData = JSON.parse(localStorage.getItem("userData"));
    setUserName(userData?.firstName +" "+userData?.lastName)
   
}, [userName])
  return (
    <>
      <div className="profilepage-breadcrumb">
        <div className="subMainContainer">
          <div className="leftBox">


           
            <div className="profileContainer">
            <img src={userImage} alt={"Goto icon"} className="userImage" />

              <div className="nameTitle">{userName}</div>
              <div className="service">Customer Service</div>
            </div>

            <div>
              <div className="aboutTitle">About Him</div>

              <div className="aboutHim">
                I booked a blood test on 17 Sep but no person came to collect
                those test, so would like to know about other date?
              </div>
            </div>
          </div>

          <div className="rightBox">
            <div className="myprofilebtnContainer">
              <p className="myProfile">My Profile</p>

              <div className="editProfile">
                <p className="editTxt">Edit</p>
              </div>
            </div>
            <div className="divBorder"></div>

            <div className="subContainerAll">
              <div className="namePhoneContainer">
                <div>
                  <div className="title">Name</div>
                  <div className="titleBlack">{userName}</div>
                </div>

                <div>
                  <div className="title">Phone No</div>
                  <div className="titleBlack">7982649280</div>
                </div>
              </div>

              <div className="inputMainContainer">
                <div>
                  <InputWithTitleView
                    title={"Email Id"}
                    placeholder={"Type Email Id.."}
                  />

                  <InputWithTitleView
                    title={"Pincode"}
                    placeholder={"Type Pincode.."}
                  />
                </div>

                <div className="inputDivider">
                  <DropDownWithTitle
                    title={"State"}
                    placeholder={"Select State"}
                  />

                  <DropDownWithTitle title={"Country"} placeholder={"India"} />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Profile;
