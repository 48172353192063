import { useState, React, useRef } from "react";
import { TabView, TabPanel } from "primereact/tabview";
import AppointmentReschedule from "./AppointmentReschedule.jsx";
import CampDoctorsData from "./CampDoctorsData.jsx";
import EmpanelledDoctorsData from "./EmpanelledDoctorsData.jsx";
import InventoryDoctorsData from "./InventoryDoctorsData.jsx";
function Doctor({ }) {
  const [activeIndex, setActiveIndex] = useState(0);
  return (
    <>
      <TabView
        activeIndex={activeIndex}
        onTabChange={(e) => setActiveIndex(e.index)}
        style={{ marginTop: "20px" }}
      >
        <TabPanel header="Appointment data">
          <AppointmentReschedule />
        </TabPanel>
        <TabPanel header="Inventory Doctors">
          <InventoryDoctorsData />
        </TabPanel>
        <TabPanel header="Empanelled Doctors">
          <EmpanelledDoctorsData />
        </TabPanel>
        {/* <TabPanel header="Camp Doctors">
          <CampDoctorsData />
        </TabPanel> */}
      </TabView>
    </>
  );
}

export default Doctor;
