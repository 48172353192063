import DatePicker from "react-datepicker";
import { useState, React, useRef } from "react";
import { useEffect } from "react";
import http from "../../../src/Redux/services/http-common.jsx";
import "react-datepicker/dist/react-datepicker.css";
import "../CarePlan/index.css";
import moment from "moment";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import DoctorAppointmentViewPopup from "./DoctorAppointmentViewPopup.js";
import { Sidebar } from "primereact/sidebar";
import { AutoComplete } from "primereact/autocomplete";
import { TabView, TabPanel } from "primereact/tabview";
import { InputText } from "primereact/inputtext";
import infobutton from "../../Assets/Images/infobutton.svg";
import { Accordion, AccordionTab } from "primereact/accordion";
import { Tag } from "primereact/tag";
import accept from "../../Assets/Images/accept.svg";
import reject from "../../Assets/Images/reject.svg";
import AppointmentReschedule from "./AppointmentReschedule.jsx";
import { login } from "../../Redux/Actions/dashboardaction.jsx";
import red from "../../Assets/Images/red.svg";
import green from "../../Assets/Images/green.svg";
import { Dropdown } from "primereact/dropdown";
import { Backdrop, Button, CircularProgress } from "@mui/material";

function CampDoctorsData({}) {
  const [remarks, setRemarks] = useState({});
  const [infoDetailsBar, setinfoDetailsBar] = useState(false);
  const [selectedDoctorDetails, setSelectedDoctorDetails] = useState(null);
  const [doctorsData, setDoctorsData] = useState([]);
  const [actualPayment, setActualPayment] = useState({});
  const [loading, setLoading] = useState(false);
  const [fromDate, setfromDate] = useState(new Date());
  const [toDate, settoDate] = useState(new Date());
  const options = [
    { name: "Pending" },
    { name: "Approved", code: "A" },
    { name: "Rejected", code: "R" },
  ];
  const [selectedItem, setSelectedItem] = useState(
    options.find((option) => option.name === "Pending")
  );

  const infodata = (doctorDetails) => {
    setSelectedDoctorDetails(doctorDetails);
    setinfoDetailsBar(true);
  };
  const loginData = JSON.parse(localStorage.getItem("loginData"));

  const handleActualPaymentChange = (userId, serviceCode, value) => {
    const compositeKey = `${userId}:${serviceCode}`;
    setActualPayment((prevState) => ({
      ...prevState,
      [compositeKey]: value,
    }));
  };

  const campDoctorGetAPI = async () => {
    try {
      setLoading(true);

      let url = `${process.env.REACT_APP_DOC_URL}backOfficeAutoPayment/getDoctorPayment?type=2`;
      if (fromDate) {
        url += `&fromDate=${moment(fromDate).format("YYYY-MM-DD")}`;
      }
      if (toDate) {
        url += `&toDate=${moment(toDate).format("YYYY-MM-DD")}`;
      }
      if (selectedItem && ["A", "R"].includes(selectedItem.code)) {
        url += `&status=${selectedItem.code}`;
      }

      const response = await fetch(url);
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }

      const data = await response.json();
      if (!data || data.data.length === 0) {
        setDoctorsData([]);
      } else {
        setDoctorsData(data?.data);
      }
    } catch (error) {
      console.error("Error fetching inventory data:", error);
      toast.error("Error fetching inventory data");
    } finally {
      setLoading(false); // This will always execute, ensuring UI is unfrozen
    }
  };

  useEffect(() => {
    if (fromDate) {
      campDoctorGetAPI();
    } else if (toDate) {
      campDoctorGetAPI();
    } else if (fromDate && toDate) {
      campDoctorGetAPI();
    } else if (selectedItem) {
      campDoctorGetAPI();
    } else if (fromDate && toDate && selectedItem) {
      campDoctorGetAPI();
    }
  }, [fromDate, toDate, selectedItem]);

  const handleRemarkChange = (userId, serviceCode, value) => {
    const compositeKey = `${userId}:${serviceCode}`;
    setRemarks((prevState) => ({
      ...prevState,
      [compositeKey]: value,
    }));
  };

  const accepted = async (
    userId,
    toBePaid,
    serviceCode,
    consultationAmount
  ) => {
    const compositeKey = `${userId}:${serviceCode}`;

    let payload = {
      userid: userId,
      type: 2,
      serviceCode: serviceCode,
      totalAmount:
        actualPayment[compositeKey] && actualPayment[compositeKey] !== ""
          ? actualPayment[compositeKey]
          : consultationAmount - toBePaid,
      fromDate: moment(fromDate).format("YYYY-MM-DD 00:00:00"),
      toDate: moment(toDate).format("YYYY-MM-DD 00:00:00"),
      remark: remarks[compositeKey] || "",
      status: "A",
      createdBy: loginData?.firstName + " " + loginData?.lastName,
      toBePaid:
        actualPayment[compositeKey] && actualPayment[compositeKey] !== ""
          ? actualPayment[compositeKey]
          : toBePaid,
      totalDeduction: consultationAmount - toBePaid,
      camp: "Y",
    };

    console.log(payload, "AcceptedPayload");
    try {
      const response = await http.post(
        `${process.env.REACT_APP_DOC_URL}doctorBilling/saveDoctorPayment`,
        payload
      );

      console.log(response.data, "Response from saveDoctorPayment");
      campDoctorGetAPI();
    } catch (error) {
      console.error("Error in saveDoctorPayment", error);
    }
  };

  const rejected = async (
    userId,
    toBePaid,
    serviceCode,
    consultationAmount
  ) => {
    console.log("Rejected");

    const compositeKey = `${userId}:${serviceCode}`;

    if (!remarks[compositeKey] || remarks[compositeKey].trim() === "") {
      toast("Please enter a remark.");
      return;
    }

    let payload = {
      userid: userId,
      type: 2,
      serviceCode: serviceCode,
      totalAmount:
        actualPayment[compositeKey] && actualPayment[compositeKey] !== ""
          ? actualPayment[compositeKey]
          : consultationAmount - toBePaid,
      fromDate: moment(fromDate).format("YYYY-MM-DD 00:00:00"),
      toDate: moment(toDate).format("YYYY-MM-DD 00:00:00"),
      remark: remarks[compositeKey],
      status: "R",
      createdBy: loginData?.firstName + " " + loginData?.lastName,
      toBePaid:
        actualPayment[compositeKey] && actualPayment[compositeKey] !== ""
          ? actualPayment[compositeKey]
          : toBePaid,
      totalDeduction: consultationAmount - toBePaid,
      camp: "Y",
    };

    console.log(payload, "RejectedPayload");
    try {
      const response = await http.post(
        `${process.env.REACT_APP_DOC_URL}doctorBilling/saveDoctorPayment`,
        payload
      );

      console.log(response.data, "Response from saveDoctorPayment");
      toast("Save successful!");

      setRemarks("");
      campDoctorGetAPI();
    } catch (error) {
      console.error("Error in saveDoctorPayment", error);
      toast.error("Error saving payment details.");
    }
  };
  const [sideBarData, setSideBarData] = useState();
  const [sideDialog, setSideDialog] = useState(false);
  const onClickInfo = async (coloumndata) => {
    let url = `${process.env.REACT_APP_DOC_URL}backOfficeAutoPayment/getDoctorPaymentDetails?type=2&userId=${coloumndata.userId}&serviceCode=${coloumndata.serviceCode}`;
    if (fromDate) {
      url += `&fromDate=${moment(fromDate).format("MM/DD/YYYY")}`;
    }
    if (toDate) {
      url += `&toDate=${moment(toDate).format("MM/DD/YYYY")}`;
    }
    const response = await fetch(url);
    if (!response.ok) {
      throw new Error("Network response was not ok");
    }
    const text = await response.text();
    let data;
    try {
      data = JSON.parse(text);
    } catch (error) {
      console.error("Error parsing JSON:", error);
      toast("Error fetching inventory data");
      throw error;
    }
    if (!data || data?.data.length === 0) {
    } else {
      setSideBarData(data?.data);
      setSideDialog(true);
    }
  };
  const accHeader = (tabContent) => {
    return (
      <>
        <div style={{ display: "flex", marginTop: "7px" }}>
          <div style={{ width: "270px", textAlign: "left" }}>
            <label>{tabContent.appointmentId}</label>
          </div>

          <div style={{ width: "150px", textAlign: "right" }}>
            <Tag value={"Success"} severity={"success"}></Tag>
          </div>
        </div>
      </>
    );
  };
  return (
    <>
      <ToastContainer />
      <Backdrop
        sx={{ color: "#5579C6", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={loading}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      {doctorsData === undefined ? (
        <div
          style={{
            height: "1000px",
            textAlign: "center",
            justifyItems: "center",
          }}
        >
          <div>
            <i
              class="pi pi-spin pi-spinner"
              style={{
                fontSize: " 2rem",
                marginTop: "200px",
                color: "blue",
                fontWeight: "700",
              }}
            ></i>
          </div>
        </div>
      ) : (
        <>
          <div style={{ marginTop: "5px" }}>
            <form>
              <div className="maindivsearchbar">
                <AutoComplete
                  style={{
                    borderColor: "transparent !important",
                    height: "42px",
                  }}
                  placeholder="Serach by Doctor"
                  // value={selectedDoctor}
                  field="userName"
                  dropdown
                  // suggestions={filteredDoctors}
                  //   itemTemplate={(item) => <div>{item.userName}</div>}
                  // completeMethod={search}
                  // onChange={handleSelect} // Use the handleSelect function
                />

                <div
                  className="searchbar"
                  style={{ marginBottom: "20px" }}
                  title="Search by date"
                >
                  <DatePicker
                    showIcon
                    className="datepicker1"
                    selected={fromDate}
                    onChange={(date) => setfromDate(date)}
                    customInput={
                      <div
                        style={{
                          position: "relative",
                          display: "flex",
                          flexDirection: "column",
                        }}
                      >
                        <div>
                          <input
                            type="text"
                            className="form-control"
                            value={
                              fromDate
                                ? moment(fromDate).format("MM/DD/yyyy")
                                : "From date"
                            }
                            placeholder="Select from date"
                            readOnly
                          />
                        </div>
                        <img
                          src="images/calander.svg"
                          alt="Calendar"
                          style={{
                            position: "absolute",
                            right: "10px",
                            top: "1px",
                            cursor: "pointer",
                          }}
                        />
                      </div>
                    }
                  ></DatePicker>
                </div>
                <div
                  className="searchbar"
                  style={{ marginBottom: "20px" }}
                  title="Search by date"
                >
                  <DatePicker
                    showIcon
                    className="datepicker1"
                    selected={toDate}
                    onChange={(date) => settoDate(date)}
                    customInput={
                      <div
                        style={{
                          position: "relative",
                          display: "flex",
                          flexDirection: "column",
                        }}
                      >
                        <div>
                          <input
                            type="text"
                            className="form-control"
                            value={
                              toDate
                                ? moment(toDate).format("MM/DD/yyyy")
                                : "To Date"
                            }
                            placeholder="Select To date"
                            readOnly
                          />
                        </div>
                        <img
                          src="images/calander.svg"
                          alt="Calendar"
                          style={{
                            position: "absolute",
                            right: "10px",
                            top: "1px",
                            cursor: "pointer",
                          }}
                        />
                      </div>
                    }
                  ></DatePicker>
                </div>
                <div style={{ marginBottom: "20px" }}>
                  <Dropdown
                    value={selectedItem}
                    onChange={(e) => setSelectedItem(e.value)}
                    options={options}
                    optionLabel="name"
                    placeholder="Select Status"
                    className="w-32"
                  />
                </div>
              </div>
            </form>
          </div>
          <DataTable
            value={doctorsData}
            paginator
            rows={10}
            rowsPerPageOptions={[10, 25, 50]}
            tableStyle={{ minWidth: "50rem" }}
          >
            {/* <Column field="giver_name" header="Name" sortable></Column> */}
            <Column
              // field="receiverName"
              header="Doctor Name"
              body={(rowbody) => (
                <>
                  <div style={{ display: "flex" }}>
                    <div style={{ display: "block", marginLeft: "15px" }}>
                      <div style={{ fontSize: "12px" }}>
                        {rowbody.doctorName}
                      </div>
                    </div>
                  </div>
                </>
              )}
            ></Column>
            <Column
              field="consultationAmount"
              header="Camp Amount"
              body={(rowbody) => (
                <>
                  <div style={{ display: "flex" }}>
                    <div style={{ fontSize: "12px" }}>
                      {rowbody.consultationAmount}
                    </div>
                  </div>
                </>
              )}
            ></Column>

            <Column
              header="Comission"
              body={(rowData) => {
                return rowData.commissionPercentage ||
                  rowData.commissionPercentage === 0
                  ? `${rowData.commissionPercentage}%`
                  : "";
              }}
            />
            <Column
              header="TDS"
              body={(rowData) => {
                return rowData.tds || rowData.tds === 0
                  ? `${rowData.tds}%`
                  : "";
              }}
            />
            <Column
              field="isPanVerified"
              header="PAN Confirm"
              // style={{ width: "200px" }}
            ></Column>
            <Column
              header="To be paid"
              body={(rowbody) => {
                const compositeKey = `${rowbody.userId}:${rowbody.serviceCode}`;

                const actualPaymentEntered =
                  actualPayment[compositeKey] !== undefined &&
                  actualPayment[compositeKey] !== "";

                const isMatch =
                  Number(rowbody.toBePaid) ===
                  Number(actualPayment[compositeKey] || 0);

                console.log("Actual Payments:", actualPayment);
                console.log("Is Match for", compositeKey, ":", isMatch);

                return (
                  <div
                    style={{
                      position: "relative",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      height: "60px",
                      width: "100px",
                    }}
                  >
                    <span
                      style={{
                        zIndex: 2,
                        position: "absolute",
                        color: actualPaymentEntered ? "white" : "black",
                        fontWeight: "bold",
                      }}
                    >
                      {rowbody.toBePaid}
                    </span>
                    {actualPaymentEntered && (
                      <img
                        src={isMatch ? green : red}
                        alt={isMatch ? "Match" : "Mismatch"}
                        style={{
                          position: "absolute",
                          height: "100%",
                          width: "100%",
                          zIndex: 1,
                        }}
                      />
                    )}
                  </div>
                );
              }}
            />
            {!(selectedItem?.code === "A" || selectedItem?.code === "R") && (
              <Column
                field="actualPayment"
                header="Actual payment "
                body={(rowbody) => (
                  <>
                    <div style={{ display: "flex" }}>
                      <div style={{ fontSize: "12px" }}>
                        <InputText
                          placeholder="Enter Actual Amount"
                          value={
                            actualPayment[
                              `${rowbody.userId}:${rowbody.serviceCode}`
                            ] || ""
                          }
                          onChange={(e) =>
                            handleActualPaymentChange(
                              rowbody.userId,
                              rowbody.serviceCode,
                              e.target.value
                            )
                          }
                          style={{ width: "140px" }}
                        />
                      </div>
                    </div>
                  </>
                )}
              ></Column>
            )}
            <Column
              field="status"
              header="Status"
              body={(rowData) => {
                if (selectedItem.code === "A") {
                  return "Approved";
                } else if (selectedItem.code === "R") {
                  return "Rejected";
                } else {
                  return "Pending";
                }
              }}
            />
            {!(selectedItem?.code === "A" || selectedItem?.code === "R") && (
              <Column
                header="Remarks"
                body={(rowbody) => (
                  <>
                    <div style={{ display: "flex" }}>
                      <div style={{ fontSize: "12px" }}>
                        <InputText
                          placeholder="Enter Remarks"
                          value={
                            remarks[
                              `${rowbody.userId}:${rowbody.serviceCode}`
                            ] || ""
                          }
                          onChange={(e) =>
                            handleRemarkChange(
                              rowbody.userId,
                              rowbody.serviceCode,
                              e.target.value
                            )
                          }
                          style={{ width: "140px" }}
                        />
                      </div>
                    </div>
                  </>
                )}
              />
            )}
            {!(selectedItem?.code === "A" || selectedItem?.code === "R") && (
              <Column
                field=""
                header="Action Button "
                body={(rowbody) => (
                  <>
                    <div style={{ display: "flex" }}>
                      <div style={{ fontSize: "12px", cursor: "pointer" }}>
                        <img
                          src={accept}
                          alt=""
                          onClick={(e) =>
                            accepted(
                              rowbody.userId,
                              rowbody.toBePaid,
                              rowbody.serviceCode,
                              rowbody.consultationAmount
                            )
                          }
                        />
                        <img
                          src={reject}
                          alt=""
                          onClick={(e) =>
                            rejected(
                              rowbody.userId,
                              rowbody.toBePaid,
                              rowbody.serviceCode,
                              rowbody.consultationAmount
                            )
                          }
                        />
                      </div>
                    </div>
                  </>
                )}
              ></Column>
            )}
            <Column
              field=""
              header="Details"
              body={(rowbody) => (
                <>
                  <div style={{ display: "flex" }}>
                    <div style={{ fontSize: "12px" }}>
                      <img
                        src={infobutton}
                        alt=""
                        title="Doctor Info"
                        onClick={(e) => onClickInfo(rowbody)}
                        style={{ cursor: "pointer" }}
                      />
                    </div>
                  </div>
                </>
              )}
            ></Column>
          </DataTable>
          <Sidebar
            visible={sideDialog}
            position="right"
            onHide={() => setSideDialog(false)}
            style={{ width: "580px" }}
          >
            <>
              {sideBarData && (
                <div className="">
                  <div>
                    <h2 style={{ color: "black", marginTop: "0px" }}>
                      {" "}
                      Payment Details{" "}
                    </h2>
                  </div>
                  <div className="mt-10 mb-10">
                    <h3
                      style={{
                        color: "black",
                        marginTop: "20px",
                        marginBottom: "20px",
                      }}
                    >
                      {" "}
                      {sideBarData[0].doctorName}
                    </h3>
                  </div>
                  <div className="mt-3">
                    <Accordion>
                      {sideBarData.map((tab, index) => (
                        <AccordionTab key={index} header={accHeader(tab)}>
                          <h3 className="mt-2">Payment details</h3>
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "space-between",
                              marginTop: "15px",
                            }}
                          >
                            <div style={{ width: "50%", textAlign: "left" }}>
                              <label>Status</label>
                            </div>
                            <div style={{ width: "50%", textAlign: "right" }}>
                              <Tag value={"Success"} severity={"success"}></Tag>
                            </div>
                          </div>
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "space-between",
                              marginTop: "15px",
                            }}
                          >
                            <div style={{ width: "50%", textAlign: "left" }}>
                              <label>Appointment Number</label>
                            </div>
                            <div style={{ width: "50%", textAlign: "right" }}>
                              {tab.appointmentId}
                            </div>
                          </div>
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "space-between",
                              marginTop: "15px",
                            }}
                          >
                            <div style={{ width: "50%", textAlign: "left" }}>
                              <label>Appointment date</label>
                            </div>
                            <div style={{ width: "50%", textAlign: "right" }}>
                              {moment(tab.whenAppointment).format(
                                "DD MMMM YYYY"
                              )}
                            </div>
                          </div>
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "space-between",
                              marginTop: "15px",
                            }}
                          >
                            <div style={{ width: "50%", textAlign: "left" }}>
                              <label>Consultation Amount</label>
                            </div>
                            <div style={{ width: "50%", textAlign: "right" }}>
                              {tab.consultationAmount}
                            </div>
                          </div>
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "space-between",
                              marginTop: "15px",
                            }}
                          >
                            <div style={{ width: "50%", textAlign: "left" }}>
                              <label>Consultation Type</label>
                            </div>
                            <div style={{ width: "50%", textAlign: "right" }}>
                              {tab.serviceName}
                            </div>
                          </div>
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "space-between",
                              marginTop: "15px",
                            }}
                          >
                            <div style={{ width: "50%", textAlign: "left" }}>
                              <label>Curebay Commission</label>
                            </div>
                            <div style={{ width: "50%", textAlign: "right" }}>
                              {tab.commissionPercentage}%
                            </div>
                          </div>
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "space-between",
                              marginTop: "15px",
                            }}
                          >
                            <div style={{ width: "50%", textAlign: "left" }}>
                              <label>Total Amount</label>
                            </div>
                            <div style={{ width: "50%", textAlign: "right" }}>
                              {tab.toBePaid}
                            </div>
                          </div>
                        </AccordionTab>
                      ))}
                    </Accordion>
                  </div>
                </div>
              )}
            </>
          </Sidebar>
        </>
      )}
    </>
  );
}

export default CampDoctorsData;
