import React from 'react';

// import { Dialog } from "primereact/dialog";
import { useState } from 'react';
import axios from 'axios';
import moment from "moment";

import "./index.css";

import { useNavigate } from 'react-router-dom';
import { useHistory } from 'react-router-dom';
import http from "../../../src/Redux/services/http-common.jsx"


function Popup( {particularPatient,transactionMonth,transactionYear,giverdetails,activeIndex}) {
 
 console.log(activeIndex,"length")

  const [open, setOpen] = useState(true);

  const [show, setShow] = useState([]);
  const[plan,setPlan] = useState("");
  const navigate = useNavigate();
  const [selectplanid, setSelectplanid] =useState();

  const handleClose = () => {

    setOpen(false);

  };
  // const currentDate = new Date();
  // const currentMonth = currentDate.toLocaleString('en-US', { month: 'numeric' });
  // const currentYear = currentDate.getFullYear();
  // console.log(currentMonth,"hlo_month")
  // console.log(currentYear,"hlo_mont")

  const onClickOrderIdMain = (e) => {
   const formatYear = moment(e.purchasedDate).format("YYYY")
   const formatMonth = moment(e.purchasedDate).format("MM")
   console.log(moment(e.purchasedDate).format("YYYY"),"item_data1")
  //  receiverName
  //  planname
  //  subSeqId
  
   setSelectplanid(e.planId);
   console.log(selectplanid,"user data is coming")
    http.get(`${process.env.REACT_APP_BASEURL}medicare/careplanservice?transactionYear=${formatYear}&transactionMonth=${formatMonth}&subSeqId=${e.subSeqId}&type=1`)

      .then((res) => {
        console.log(res.data, "datanew")

       
       const fetchedData = res.data;
       const searchData =e.planname;
       const receiverdata =e.receiverName;
       const month= formatMonth;
       const year= formatYear;
      
        setPlan(fetchedData);
        
        navigate('/careplansSchedule', { state: { data: fetchedData ,searchData:e.planId,receiverdata:receiverdata, month: month,
           year : year} });
        console.log( transactionMonth,"qwerty")

      })
      .catch((err) => {

        console.log(err, "Something went wrong1");

      })
     

  };
  
  console.log(plan, "data is coming");
  const newpage = () => {
    navigate('/careplansSchedule');
  }

  return (
    <>


      {activeIndex === 0 &&( particularPatient.receiverDetailsDTO?.length > 0 || particularPatient.planDetailsDTO?.length > 0) ?
     
        <div >
          {/* {particularPatient?.receiverDetailsDTO?.map((item, i) => ( */}
          <div className='headerdiv'>
                      <div> <img src='images/dummyimg_icon.svg' alt='dummyimg_icon' className='dummyimg_icon' /></div>
                      <div className='mainhead'>

                      
                        <h4 className='head_name'>{particularPatient?.receiverDetailsDTO[0]?.giverName}</h4>
                        <p className='usercode'>{particularPatient?.receiverDetailsDTO[0]?.userCode}</p>
                      </div>


                    </div>
        
        <div>
            <h2 className='table_heading'>Care Recipient Details: {particularPatient.receiverDetailsDTO.length} </h2>
            <div className='table-container'>
            <table className='my-tablepopup'>
              <tr className='tblepopuptr'>

                <th className='heading'>Name</th>
                <th className='heading'>Phone No.</th>
                <th className='heading'>Relations</th>
                <th className='heading'>Age</th>
                <th className='heading'>Email Id</th>
                <th className='heading'>Address</th>
              </tr>
              {particularPatient?.receiverDetailsDTO?.map((item, i) => (
                <tr className="tr border_bottom" key={i}>
                  <td style={{padding:"0px"}}>
                    {item?.receiverName}
                  </td>
                  <td>
                    {item?.receiverMobile}
                  </td>
                  <td>
                    {item?.relation}
                  </td>
                  <td>
                    {item?.receiverAge}
                  </td>

                  <td>
                    {item?.email}
                  </td>
                  <td style={{textAlign:"left"}}>
                    {item?.address}
                  </td>
                </tr>
              ))}
            </table>
            </div>
          </div>

          <div>

            <h2 className='table_heading'>Plan Details: {particularPatient.planDetailsDTO?.length}</h2>
            <div className='table-container'>
              {/* <table className='tblepopup tblepopup1'> */}
              <table class="my-table">
                <tr className='tblepopuptr'>

                  <th className='heading'>Name</th>
                  <th className='heading'>Plan</th>
                  <th className='heading'>Plan Type</th>
                  <th className='heading'>Duration</th>
                  <th className='heading'>Purchased Date</th>
                  <th className='heading'>Start Date</th>
                  <th className='heading'>Expiry Date</th>
                  <th className='heading'>Total Amount</th>
                  <th className='heading'>Paid Amount</th>
                  
                  
                  {/* onClick={newpage} */}
                  
                </tr>
                { particularPatient?.planDetailsDTO?.map((item, i) => (
                  <tr className="tr border_bottom_popup" key={i}>
                    <td>
                      {/* <i  className="fa fa-pencil-square-o" onClick={(e) => onClickOrderIdMain(item)} aria-hidden="true"></i> */}
                        {item?.receiverName} </td>
                    <td> {item?.planname}</td>
                    <td> {item?.packageType}  </td>
                    <td>{item?.subsPeriod} months </td>
                    <td> {moment(item?.purchasedDate).format("DD-MM-YYYY")} </td>
                    <td> {moment(item?.startDate).format("DD-MM-YYYY")}</td>
                    <td> {moment(item?.expiryDate).format("DD-MM-YYYY")}</td>
                    <td >₹ {item?.totalAmount} </td>
                    <td>₹ {item?.paidAmount}</td>
                  </tr>
                ))}
              </table>
            </div>
          </div>
        </div>
     :
     activeIndex === 1 && (giverdetails.giverDetails?.length > 0 || giverdetails.planDetailsDTO?.length > 0) ?
     <div >
    
     <div className='headerdiv'>
                 <div> <img src='images/dummyimg_icon.svg' alt='dummyimg_icon' className='dummyimg_icon' /></div>
                 <div className='mainhead'>

                 
                   <h4 className='head_name'>{giverdetails?.planDetailsDTO[0]?.receiverName}</h4>
                   <p className='usercode'>{giverdetails?.planDetailsDTO[0]?.receiverCode}</p>
                 </div>


               </div>
   
   <div>
       <h2 className='table_heading'>Care Sponsor Details: {giverdetails.giverDetails.length} </h2>
       <div className='table-container'>
       <table className='my-tablepopup'>
         <tr className='tblepopuptr'>

           <th className='heading'>Name</th>
           <th className='heading'>Phone No.</th>
           {/* <th className='heading'>Relations</th> */}
           <th className='heading'>Age</th>
           <th className='heading'>Email Id</th>
           <th className='heading'>Address</th>
         </tr>
         {giverdetails?.giverDetails?.map((item, i) => (
           <tr className="tr border_bottom" key={i}>
             <td style={{padding:"0px"}}>
               {item?.giver_name}
             </td>
             <td>
               {item?.mobile_number}
             </td>
             <td>
             {item?.age}
              
             </td>
             <td>
             {item?.email}
             </td>
             <td style={{textAlign:"left"}}>
               {item?.address}
             </td>
           </tr>
         ))}
       </table>
       </div>
     </div>

     <div>

       <h2 className='table_heading'>Plan Details: {giverdetails.planDetailsDTO?.length}</h2>
       <div className='table-container'>
         {/* <table className='tblepopup tblepopup1'> */}
         <table class="my-table">
           <tr className='tblepopuptr'>

             <th className='heading'>Name</th>
             <th className='heading'>Plan</th>
             <th className='heading'>Plan Type</th>
             <th className='heading'>Status</th>
             <th className='heading'>Duration</th>
             <th className='heading'>Purchased Date</th>
             <th className='heading'>Start Date</th>
             <th className='heading'>Expiry Date</th>
             <th className='heading'>Total Amount</th>
             <th className='heading'>Paid Amount</th>
             
             
             {/* onClick={newpage} */}
             
           </tr>
           { giverdetails?.planDetailsDTO?.map((item, i) => (
             <tr className="tr border_bottom_popup" key={i}>
               <td>
                {/* <i  className="fa fa-pencil-square-o"
                onClick={(e) => onClickOrderIdMain(item)}
                 aria-hidden="true"></i> */}
                   {item?.receiverName} </td>
               <td> {item?.planname}</td>
               <td> {item?.packageType}  </td>
               <td>{item?.subscriptionStatus}  </td>
               <td>{item?.subsPeriod} months </td>
               <td> {moment(item?.purchasedDate).format("DD-MM-YYYY")} </td>
               <td> {moment(item?.startDate).format("DD-MM-YYYY")}</td>
               <td> {moment(item?.expiryDate).format("DD-MM-YYYY")}</td>
               <td > ₹ {item?.totalAmount} </td>
               <td> ₹ {item?.paidAmount}</td>
             </tr>
           ))}
         </table>
       </div>
     </div>
   </div> :

     <div style={{textAlign:"center"}}>No data found</div>
                }
    </>
  )
}

export default Popup