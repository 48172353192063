export const QUOT = {
    REQUEST_DRUG_PRES_LIST: "REQUEST_DRUG_PRES_LIST",
    SUCCESS_DRUG_PRES_LIST: "SUCCESS_DRUG_PRES_LIST",
    FAILED_DRUG_PRES_LIST: "FAILED_DRUG_PRES_LIST",

    REQUEST_PHARMA_CENTERS: "REQUEST_PHARMA_CENTERS",
    SUCCESS_PHARMA_CENTERS: "SUCCESS_PHARMA_CENTERS",
    FAILED_PHARMA_CENTERS: "FAILED_PHARMA_CENTERS",

    REQUEST_PAT_LAB_TESTS: "REQUEST_PAT_LAB_TESTS",
    SUCCESS_PAT_LAB_TESTS: "SUCCESS_PAT_LAB_TESTS",
    FAILED_PAT_LAB_TESTS: "FAILED_PAT_LAB_TESTS",

    REQUEST_PRES_DIAGONSIS_CENTERS: "REQUEST_PRES_DIAGONSIS_CENTERS",
    SUCCESS_PRES_DIAGONSIS_CENTERS: "SUCCESS_PRES_DIAGONSIS_CENTERS",
    FAILED_PRES_DIAGONSIS_CENTERS: "FAILED_PRES_DIAGONSIS_CENTERS",

    CLEAR_MSG: "QUOT_CLEAR_MSG"
}