export const hos = {
    REQUEST_HOSLIST: "REQUEST_HOSLIST",
    SUCCESS_HOSLIST: "SUCCESS_HOSLIST",
    FAILED_HOSLIST: "FAILED_HOSLIST",

    REQUEST_SPECIFICHOSLIST: "REQUEST_SPECIFICHOSLIST",
    SUCCESS_SPECIFICHOSLIST: "SUCCESS_SPECIFICHOSLIST",
    FAILED_SPECIFICHOSLIST: "FAILED_SPECIFICHOSLIST",

    REQUEST_ELASTICSPECIFICHOSLIST: "REQUEST_ELASTICSPECIFICHOSLIST",
    SUCCESS_ELASTICSPECIFICHOSLIST: "SUCCESS_ELASTICSPECIFICHOSLIST",
    FAILED_ELASTICSPECIFICHOSLIST: "FAILED_ELASTICSPECIFICHOSLIST",

    REMOVE_HOSLIST:'REMOVE_HOSLIST',

    REQUEST_PROCEDURELIST: "REQUEST_PROCEDURELIST",
    SUCCESS_PROCEDURELIST: "SUCCESS_PROCEDURELIST",
    FAILED_PROCEDURELIST: "FAILED_PROCEDURELIST",

    REQUEST_HOSPENQUIRYFORM: "REQUEST_HOSPENQUIRYFORM",
    SUCCESS_HOSPENQUIRYFORM: "SUCCESS_HOSPENQUIRYFORM",
    FAILED_HOSPENQUIRYFORM: "FAILED_HOSPENQUIRYFORM",

 
}