import AppHeader from "../../../components/Header";

import React, { useRef, useEffect } from "react";
import { Outlet } from "react-router-dom";
import { useState } from "react";
// import AppSidebar from '../../../components/SideBar'
import AppSidebar from "../../../components/NewSidebar";
import NewHeader from "../../../components/NewHeader/header";

function HomePage() {
  const appContentRef = useRef(null);

  const [Menus, setMenus] = useState([
    {
      title: "Dashboard",
      src: "dashboard",
      id: 0,
      path: "/dashboard",
      isSelected: false,
    },
    { title: "E-clinic", src: "eclinic", id: 1, isSelected: false },
    {
      title: "B2C",
      src: "B2C",
      id: 2,
      childrens: [
        {
          title: "Refund",
          nevLink: "/inbox",
          isSelected: false,
          subid: 0,
        },
        {
          title: "Business Events",
          nevLink: "/businessEvent",
          isSelected: false,
          subid: 1,
        },
        {
          title: "Elite appointments",
          nevLink: "/inventoryDoctorAppointment",
          isSelected: false,
          subid: 2,
        },
        {
          title: "Booked Lab Test",
          nevLink: "/bookLabTest",
          isSelected: false,
          subid: 3,
        },
        {
          title: "Translated Prescription",
          nevLink: "/translatedPrescription",
          isSelected: false,
          subid: 4,
        },
        {
          title: "Packages",
          nevLink: "/packages",
          isSelected: false,
          subid: 5,
        },
        {
          title: "Membership",
          nevLink: "/membership",
          isSelected: false,
          subid: 6,
        },
      ],
    },
    {
      title: "Totalcare ",
      src: "careplan",
      id: 3,
      childrens: [
        {
          title: "Patient Details",
          nevLink: "/bookCarePlan",
          subid: 0,
          isSelected: false,
        },

        {
          title: "Appointment booking",
          nevLink: "/careplansSchedule",
          subid: 1,
          isSelected: false,
        },
        {
          title: "Schedule",
          nevLink: "/Schedule",
          subid: 2,
          isSelected: false,
        },
        {
          title: "Careplan SOS",

          nevLink: "/careplanSos",
          subid: 3,
          isSelected: false,
        },
        {
          title: "Business Event",

          nevLink: "/careplanbusinessevent",
          subid: 4,
          isSelected: false,
        },
        {
          title: "Care Plan",
          nevLink: "/carePlan",
          subid: 5,
          isSelected: false,
        },
        {
          title: "Free Health Assessment",
          nevLink: "/preassesment",
          subid: 6,
          isSelected: false,
        },
        // {
        //   title: "Visitors Details",

        //   nevLink: "/marketing",
        //   subid: 7,
        //   isSelected: false,
        // },

      ]
    },
    {
      title: "Doctor",
      src: "dashboard",
      id: 4,
      path: "/doctor",
      isSelected: false,
    },
  ]);

  useEffect(() => {
    const storedHeaderText = localStorage.getItem("headerText");

    if (storedHeaderText) {
      const updatedMenus = [...Menus];
      let headerFound = false;

      // Iterate through menus and submenus to find the selected item
      updatedMenus.forEach((menu) => {
        if (menu.title === storedHeaderText) {
          menu.isSelected = true;
          headerFound = true;
        } else if (menu.childrens) {
          menu.childrens.forEach((submenu) => {
            if (submenu.title === storedHeaderText) {
              menu.isSelected = true;
              submenu.isSelected = true;
              headerFound = true;
            }
          });
        }
      });

      if (headerFound) {
        setMenus(updatedMenus);
      }
    }
  }, []);

  const handleMenuSelect = (index) => {
    const updatedMenus = [...Menus];

    // Reset isSelected flag for all menu items
    updatedMenus.forEach((menu) => {
      menu.isSelected = false;
    });

    // Set isSelected flag for the selected menu item
    updatedMenus[index].isSelected = true;

    setMenus(updatedMenus);
    localStorage.setItem("headerText", headerText);
  };

  const handleSubmenuSelect = (parentIndex, submenuIndex) => {
    const updatedMenus = [...Menus];

    // Reset isSelected flag for all submenu items
    updatedMenus.forEach((menu) => {
      if (menu.childrens) {
        menu.childrens.forEach((submenu) => {
          submenu.isSelected = false;
        });
      }
    });

    // Set isSelected flag for the selected submenu item and its parent menu
    updatedMenus[parentIndex].isSelected = true;
    updatedMenus[parentIndex].childrens[submenuIndex].isSelected = true;

    setMenus(updatedMenus);
    localStorage.setItem("headerText", headerText);
  };

  const getHeaderTitle = () => {
    const selectedMenu = Menus.find((menu) => {
      if (menu.isSelected) {
        return true;
      }
      if (menu.childrens) {
        return menu.childrens.some((submenu) => submenu.isSelected);
      }
      return false;
    });

    if (selectedMenu && selectedMenu.childrens) {
      const selectedSubmenu = selectedMenu.childrens.find(
        (submenu) => submenu.isSelected
      );
      if (selectedSubmenu) {
        return selectedSubmenu.title;
      }
    }

    return selectedMenu ? selectedMenu.title : "";
  };

  const headerText = getHeaderTitle();

  console.log(headerText, "headerText");
  return (
    <div className=" flex">
      {/* <div>
    <AppHeader/>
    </div> */}
      {/* <div style={{display:"flex"}}> */}
      <div
        style={{
          display: "flex",
          boxShadow: " 0 0 5px rgba(0,0,0,.25)",
          height: "3000px",
        }}
      >
        {/* <div style={{
                    flex: 2, height: '90vh', zIndex: 1,
                    maxWidth: 250
                }}> */}

        <AppSidebar
          Menus={Menus}
          setMenus={setMenus}
          handleMenuSelect={handleMenuSelect}
          handleSubmenuSelect={handleSubmenuSelect}
        />

        {/* </div> */}
        <div style={{ display: "block", width: "2000px" }}>
          <div className="headerpart">
            <NewHeader text={headerText} items={Menus} />
          </div>

          <div
            ref={appContentRef}
            id="app-content"
            style={{
              flex: 7,
              background: "#f4faff",
              //   height: "120vh", overflow: 'auto' ,
              borderLeft: "2px solid #f4faff",
              marginTop: "64px",
            }}
          >
            <Outlet context={{ appContentRef }} />
          </div>
        </div>
      </div>
    </div>

    // </div>
  );
}

export default HomePage;
