import React from "react";
import "./index.css";
import dropDownImg from "../../Assets/Images/greenDropDown.svg";
function dropDownView(props) {
  return (
    <div>
      <div className="title">{props.title}</div>

      <div className="borderCity">
        <input className='borderNewInput' placeholder={props.placeholder} />

        <img src={dropDownImg} alt={"Goto icon"} className="navImg" />
      </div>
    </div>
  );
}

export default dropDownView;
