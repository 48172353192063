import { saveAs } from "file-saver";
import XlsxPopulate from "xlsx-populate";

export function jsonToExcelConvetercareplan(careplandata, fileName) {

console.log(careplandata, fileName,"careplandata")
let headerOfExcel = careplandata[0]
console.log('careplanDatahai \n', JSON.stringify(careplandata), "Type: \n", fileName)
let exportArr = []
let exportHeading

for (let i = 0; i < careplandata.length; i++) {
  let obj
    exportHeading = ["Giver Name", "Mobile Number","Email","SignedUp Date","Address"]

    // if (careplandata[i].serviceStatus === 0) {
        obj = {
            giver_name: careplandata[i].giver_name,
          mobile_number: careplandata[i].mobile_number,
          email: careplandata[i].email,
          signUpDate: careplandata[i].signUpDate,
          address: careplandata[i].address,
        
        };
   
    

 
  exportArr.push(obj)

}
console.log('EXPORT ARRAY DATAaa: \n', JSON.stringify(exportArr))

const keys = Object.keys(headerOfExcel)
let header = [...keys];

XlsxPopulate.fromBlankAsync().then(async (workbook) => {
  const sheet1 = workbook?.sheet(0);
  const sheetData = getSheetData(exportArr, exportHeading);
  const totalColumns = sheetData[0]?.length;
  sheet1.cell("A1").value(sheetData);
  const range = sheet1.usedRange();
  const endColumn = String.fromCharCode(64 + totalColumns);
  sheet1.row(1).style("bold", true);
  sheet1.range("A1:" + endColumn + "1").style("fill", "BFBFBF");
  range.style("border", true);
  return workbook.outputAsync().then((res) => {
    saveAs(res, fileName + new Date()?.getTime() + "file.xlsx");
  });
});
}

function getSheetData(data, header) {
    var fields = Object?.keys(data[0]);
    var sheetData = data?.map(function (row) {
      return fields.map(function (fieldName) {
        if (fieldName)
          return row[fieldName] ? row[fieldName] : "";
      });
    });
    sheetData.unshift(header);
    return sheetData;
  }
