import DatePicker from "react-datepicker";
import { useState, React, useRef } from "react";
import { useEffect } from "react";
import http from "../../../src/Redux/services/http-common.jsx";
import "react-datepicker/dist/react-datepicker.css";
import "../CarePlan/index.css";
import moment from "moment";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import DoctorAppointmentViewPopup from "./DoctorAppointmentViewPopup.js";
import { Sidebar } from "primereact/sidebar";
import { AutoComplete } from "primereact/autocomplete";
import { TabView, TabPanel } from "primereact/tabview";
import { InputText } from "primereact/inputtext";
import infobutton from "../../Assets/Images/infobutton.svg";
import { Accordion, AccordionTab } from "primereact/accordion";
import { Tag } from 'primereact/tag';
function AppointmentReschedule({ }) {
    const [searchall, setSearchall] = useState();
    const [selectedRowData, setSelectedRowData] = useState(null);
    const [sideDialog, setSideDialog] = useState(false);
    const [infoDetailsBar, setinfoDetailsBar] = useState(false);
    const [fromDate, setFromDate] = useState();
    const [toDate, setToDate] = useState();
    const [options, setOptions] = useState([]);
    const [filteredDoctors, setFilteredDoctors] = useState([]);
    const [selectedDoctor, setSelectedDoctor] = useState(null);
    const handleRowClick = (rowData) => {
        setSelectedRowData(rowData);
        setSideDialog(true);
    };
    const closeSidebar = () => {
        setSideDialog(false);
    };
    const handleSearchSubmit = () => {
        let url = `${process.env.REACT_APP_BASEURL}PatientAppointment/list?status=16&photoRequired=Y`;
        if (fromDate) {
            url += `&fromDate=${moment(fromDate).format("yyyy-MM-DD")}`;
        }
        if (toDate) {
            url += `&toDate=${moment(toDate).format("yyyy-MM-DD")}`;
        }
        if (selectedDoctor?.userId) {
            url += `&userId=${selectedDoctor.userId}`;
        }
        http
            .get(url)
            .then((res) => {
                setSearchall(res.data);
            })
            .catch((error) => {
                console.log(error, "went wrong");
            });
    };

    useEffect(() => {
        handleSearchSubmit();
    }, [fromDate, toDate, selectedDoctor]);
    useEffect(() => {
        let url = `${process.env.REACT_APP_ELASTIC_BASEURL}doctors?freeTextSearch=&pageNo=1&pageSize=500`;
        http
            .get(url)
            .then((res) => {
                setOptions(res.data);
            })
            .catch((error) => {
                console.log(error, "went wrong");
            });
    }, []);
    const header = (
        <div className="flex flex-wrap justify-content-end gap-2"></div>
    );
    const search = (event) => {
        const filtered = options.filter((doctor) =>
            doctor.userName.toLowerCase().includes(event.query.toLowerCase())
        );
        setFilteredDoctors(filtered);
    };
    const handleSelect = (e) => {
        setSelectedDoctor(e.value);
    };
    return (
        <>
            <ToastContainer />

            <div style={{ padding: "10px" }}>
                <div className="maincareplan"></div>
            </div>
            <div style={{ marginTop: "5px" }}>
                <form>
                    <div className="maindivsearchbar">
                        {options.length ? (
                            <AutoComplete
                                style={{
                                    borderColor: "transparent !important",
                                    height: "42px",
                                }}
                                placeholder="Serach by Doctor"
                                value={selectedDoctor}
                                field="userName"
                                dropdown
                                suggestions={filteredDoctors}
                                itemTemplate={(item) => <div>{item.userName}</div>}
                                completeMethod={search}
                                onChange={handleSelect} // Use the handleSelect function
                            />
                        ) : null}
                        <div
                            className="searchbar"
                            style={{ marginBottom: "20px" }}
                            title="Search by date"
                        >
                            <DatePicker
                                showIcon
                                className="datepicker1"
                                selected={fromDate}
                                onChange={(date) => setFromDate(date)}
                                customInput={
                                    <div
                                        style={{
                                            position: "relative",
                                            display: "flex",
                                            flexDirection: "column",
                                        }}
                                    >
                                        <div>
                                            <input
                                                type="text"
                                                className="form-control"
                                                value={
                                                    fromDate
                                                        ? moment(fromDate).format("MM/DD/yyyy")
                                                        : "From date"
                                                }
                                                readOnly
                                            />
                                        </div>
                                        <img
                                            src="images/calander.svg"
                                            alt="Calendar"
                                            style={{
                                                position: "absolute",
                                                right: "10px",
                                                top: "1px",
                                                cursor: "pointer",
                                            }}
                                        />
                                    </div>
                                }
                            ></DatePicker>
                        </div>
                        <div
                            className="searchbar"
                            style={{ marginBottom: "20px" }}
                            title="Search by date"
                        >
                            <DatePicker
                                showIcon
                                className="datepicker1"
                                selected={toDate}
                                onChange={(date) => setToDate(date)}
                                customInput={
                                    <div
                                        style={{
                                            position: "relative",
                                            display: "flex",
                                            flexDirection: "column",
                                        }}
                                    >
                                        <div>
                                            <input
                                                type="text"
                                                className="form-control"
                                                value={
                                                    toDate
                                                        ? moment(toDate).format("MM/DD/yyyy")
                                                        : "To Date"
                                                }
                                                readOnly
                                            />
                                        </div>
                                        <img
                                            src="images/calander.svg"
                                            alt="Calendar"
                                            style={{
                                                position: "absolute",
                                                right: "10px",
                                                top: "1px",
                                                cursor: "pointer",
                                            }}
                                        />
                                    </div>
                                }
                            ></DatePicker>
                        </div>
                    </div>
                </form>
            </div>

            {searchall === undefined ? (
                <div
                    style={{
                        height: "1000px",
                        textAlign: "center",
                        justifyItems: "center",
                    }}
                >
                    <div>
                        <i
                            class="pi pi-spin pi-spinner"
                            style={{
                                fontSize: " 2rem",
                                marginTop: "200px",
                                color: "blue",
                                fontWeight: "700",
                            }}
                        ></i>
                    </div>
                </div>
            ) : (
                <>
                    <DataTable
                        value={searchall}
                        paginator
                        rows={50}
                        rowsPerPageOptions={[5, 10, 25, 50]}
                        globalFilterFields={["Status"]}
                        header={header}
                        tableStyle={{ padding: "10px" }}
                        rowClassName={(rowData) =>
                            rowData.serviceStatus === 2 ? "rowcolor" : ""
                        }
                    >
                        <Column
                            field="patientName"
                            header="Patient Name"
                            sortable
                            body={(rowData, column) => (
                                <>
                                    <div class="">{rowData.patientName}</div>
                                    <div class="">{rowData.patientMobileNo}</div>
                                </>
                            )}
                        />
                        <Column field="whenAppointment" header="Appt Date" />
                        <Column
                            field="fromTime"
                            header="Time"
                            body={(rowData, column) => (
                                <>
                                    <div class="">
                                        {rowData.fromTime} - {rowData.toTime}
                                    </div>
                                </>
                            )}
                        />
                        <Column sortable header="User Name" field="userName" />
                        <Column
                            field="consultationsType"
                            header="Consultation Type"
                            body={(rowData, column) => (
                                <>
                                    <div class="  tooltip">
                                        {rowData.consultationsType === "I"
                                            ? "Inperson"
                                            : rowData.consultationsType === "A"
                                                ? "Audio"
                                                : rowData.consultationsType === "V"
                                                    ? "Video"
                                                    : "Quick"}
                                    </div>
                                </>
                            )}
                        />
                        <Column
                            header="Status"
                            filterMenuStyle={{ width: "14rem" }}
                            body={(rowData) => (
                                <>
                                    <div class="">{"Appointment on Hold"}</div>
                                </>
                            )}
                        />
                        <Column field="id" header="Appt Id" />
                        <Column
                            header="Action"
                            body={(rowData) => (
                                <>
                                    <span>
                                        <i
                                            className="fa fa-pencil-square-o"
                                            aria-hidden="true"
                                            onClick={() => handleRowClick(rowData)}
                                            style={{ cursor: "pointer" }}
                                        ></i>
                                    </span>
                                </>
                            )}
                        />
                    </DataTable>
                    <Sidebar
                        visible={sideDialog}
                        position="right"
                        onHide={() => setSideDialog(false)}
                        style={{ width: "480px" }}
                    >
                        <>
                            <DoctorAppointmentViewPopup
                                scheduledata={selectedRowData}
                                refreshData={handleSearchSubmit}
                                handleSearch={handleSearchSubmit}
                                closeFun={() => setSideDialog(false)}
                                closeSidebar={closeSidebar}
                            />
                        </>
                    </Sidebar>
                </>
            )}
        </>
    );
}

export default AppointmentReschedule;