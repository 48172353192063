import React from "react";
import RowBody from "./RowBody";
import Loader from "../../components/Loader/Loader";
import NoDataFound from "../../components/NoDataFound/NoDataFound";

function MedicineOrderList({
  onRefundMoneyBtnMain,
  medicineList,
  doctorConsultation,
  isSelectedOption,
  handleDropDownSelectBtn,
  isApproveOrRejectPlaceHolder,
  onClickOrderIdMain,
  onClickAddCommentMain,
  isLoader,
  status
}) {
  const onClickOrderIdChild = (item) => {
    onClickOrderIdMain(item);
  };
  const onRefundMoneyBtn = () => {
    onRefundMoneyBtnMain();
  };

  const handleDropDownSelectNew = (e, object, index) => {
    handleDropDownSelectBtn(e, object, index);
  };

  const onClickAddCommentChild = (item) => {
    onClickAddCommentMain(item);
  };

  return (
    <div className="listContainer">
      <div className="listBorder">
        {status==="Pending"? doctorConsultation.map((item, i) => (
          <p key={i} className="listTitle">
            {item}
          </p>
        )):doctorConsultation.slice(0, doctorConsultation.length-1).map((item, i) => (
          
          <p key={i} className="listTitle">
            {item}
          </p>
        ))
        }
      </div>

      <RowBody
        medicineList={medicineList}
        isSelectedOption={isSelectedOption}
        onRefundMoneyBtn={onRefundMoneyBtn}
        handleDropDownSelectNew={handleDropDownSelectNew}
        onClickOrderIdNew={onClickOrderIdChild}
        isApproveOrRejectPlaceHolder={isApproveOrRejectPlaceHolder}
        onClickAddCommentNew={onClickAddCommentChild}
        status={status}
      />

      {isLoader != false && <Loader />}
      {medicineList.length == 0 && !isLoader && <NoDataFound />}
    </div>
  );
}

export default MedicineOrderList;
